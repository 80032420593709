<script setup>
import spinBgLeftIcon from '@/assets//images/spin/spin-bg-left.png';
import spinBgRightIcon from '@/assets//images/spin/spin-bg-right.png';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import wgDialog from '@/components/common/wgDialog';
import wgButton from '@/components/common/wgButton.vue';
import { getReceivePromotionsPrizeService } from '@/assets/network/service/turntable';
import emitter from '@/assets/tools/eventBus';

const { t } = useI18n();

const emits = defineEmits(['close']);

const isShow = ref(false);
const tipModelText = ref('');
const info = ref({});

const getReceivePromotionsPrize = async () => {
  try {
    const res = await getReceivePromotionsPrizeService({
      promotion_id: info.value.promotion_id
    });
    if (res.data.code === 200) {
      closeSelf();
    }
  } catch (e) {
    console.error(e);
  }
};

const freeSpinResDialogShow = (val) => {
  console.log(val, 'custom-show-free-spin-res');
  info.value = val;
  tipModelText.value = info.value.prize_symbol + info.value.promotion_amount;
  isShow.value = true;
};

const closeSelf = () => {
  isShow.value = false;
  emits('close');
};

onMounted(() => {
  emitter.on('custom-show-free-spin-res', freeSpinResDialogShow);
});
</script>

<template>
  <div>
    <wg-dialog :show="isShow" myClass="!z-[1700]">
      <div class="relative bg-spin-model-bg bg-bottom bg-cover w-[31.5rem] h-[21rem]">
        <img class="absolute left-[-15rem] top-[-2rem]" :src="spinBgLeftIcon" alt="" />
        <img class="absolute right-[-10rem] top-[-3rem]" :src="spinBgRightIcon" alt="" />
        <div class="relative z-1000">
          <div class="text-[#FDE25D] text-center font-bold text-[2.5rem] mt-[3rem]">
            {{ tipModelText }}
          </div>
          <div class="text-[#FFF] text-[1.5rem] font-bold text-center mt-[0.94rem] px-[7rem]">
            {{ t('The bonus has arrived in the wallet') }}
          </div>
          <div class="flex justify-center mt-[1.03rem]" v-if="info.accept_prize_type === false">
            <wgButton class="w-[19rem] py-[0.69rem] text-[#fff]" @click="getReceivePromotionsPrize">
              {{ t('get').toLocaleUpperCase() }}
            </wgButton>
          </div>
          <div class="flex justify-center mt-[0.8rem]">
            <svg class="w-[3.375rem] h-[4.375rem]" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closeSelf">
              <rect width="54" height="54" rx="27" fill="#3E4B79" />
              <path d="M16.099 16.099C16.8976 15.3003 18.1924 15.3003 18.9911 16.099L37.9009 35.0089C38.6995 35.8076 38.6995 37.1024 37.9009 37.901C37.1023 38.6997 35.8074 38.6997 35.0088 37.901L16.099 18.9911C15.3003 18.1925 15.3003 16.8976 16.099 16.099Z" fill="white" />
              <path d="M37.901 16.099C38.6997 16.8976 38.6997 18.1925 37.901 18.9911L18.9912 37.901C18.1926 38.6997 16.8977 38.6997 16.0991 37.901C15.3005 37.1024 15.3005 35.8075 16.0991 35.0089L35.0089 16.099C35.8076 15.3003 37.1024 15.3003 37.901 16.099Z" fill="white" />
            </svg>
          </div>
        </div>
      </div>
    </wg-dialog>
  </div>
</template>
