<script setup>
// 导入
import { ref, onBeforeMount } from 'vue';
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import { useI18n } from 'vue-i18n';
import ListTab from './module/list-tab.vue';
import ListItem from './module/list-item.vue';
import pageLoading from '@/components/loading/pageLoading.vue';
import jiazai from '@/assets/animation/expott_jiazai.json';
import { getPromotionList } from '@/assets/network/service/home';

// 传参
// 属性
const { t } = useI18n();
const tabList = [
  {
    text: t('Letest_Promotions'),
    value: 'lasted'
  },
  {
    text: t('Sports_Promotions'),
    value: 'SPORTS'
  },
  {
    text: t('Casino_Promotions'),
    value: 'CASINO'
  },
  {
    text: t('Archived'),
    value: 'archived'
  }
]
const tabIndex = ref(tabList[0])
const listData = ref(null)
const isLoading = ref(false)
// 计算属性
// 生命周期
onBeforeMount(async () => {
  getPromotionListData()
});
// 方法
const getPromotionListData = async ()=> {
  isLoading.value = true
  const par = {
    promotion_type: tabIndex.value.value
  }
  const res = await getPromotionList(par)
  listData.value = res.data.data;
  isLoading.value = false
}

const changeTab = (item)=> {
  tabIndex.value = item
  getPromotionListData()
}

const updateItem = ()=> {
  getPromotionListData()
}
</script>

<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus @menuChange="menuChange"></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <myHeader :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></myHeader>
      <div class="main-content">
        <div class="body">
          <div class="py-[10px] md:py-[20px] flex">
            <ListTab :tabIndex="tabIndex" :tabList="tabList" @changeTab="changeTab"/>
          </div>
          <page-loading :animationData="jiazai" :container-height="'10rem'" :containerHeightMobile="'10rem'" :show="isLoading"></page-loading>
          <div class="grid grid-cols-1 gap-[15px] overflow-hidden md:grid-cols-2 xl:grid-cols-3">
            <template v-if="!isLoading">
              <ListItem v-for="(item, index) in listData" :key="index" :itemData="item" @updateItem="updateItem"/>
            </template>
          </div>
        </div>
      </div>
      <myFooter :isHomeFooter="true"></myFooter>
    </div>
  </div>
</template>

<style scoped>
</style>
