<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
// 传参
const props = defineProps({
  selectItem: {
    type: Object
  },
  inputAmount: {},
  fee: {
    type: String
  }
});
// 属性
const { t } = useI18n();
// 计算属性
// 生命周期
// 方法
const getFeeNum = (_num) => {
  if (_num) {
    return parseFloat(_num).toString();
  } else {
    return 0;
  }
};
const getNetAmountReceived = () => {
  const amount = props.inputAmount;
  const fee = props.fee;
  const res = (Number(amount) - Number(fee)).toFixed(4);
  if (res < 0) {
    return 0;
  } else {
    return getFeeNum(res);
  }
};
</script>

<template>
  <div class="relative bg-[#1C243F] rounded-[4px] p-[10px] flex flex-col gap-[10px]">
    <div class="flex justify-between items-center">
      <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('Withdraw Amount').toUpperCase() }}</div>
      <div class="text-[#ffffff] text-[14px] font-[700]">{{ getFeeNum(props.inputAmount) }} {{ props.selectItem.symbol }}</div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('fee').toUpperCase() }}</div>
      <div class="text-[#ffffff] text-[14px] font-[700]">{{ getFeeNum(fee) }} {{ props.selectItem.symbol }}</div>
    </div>
    <div class="flex justify-between items-center">
      <div class="text-[#5B7BAB] text-[12px] font-[700]">{{ t('Net Amount Received').toUpperCase() }}</div>
      <div class="text-[#34E6A8] text-[14px] font-[700]">{{ getNetAmountReceived() }} {{ props.selectItem.symbol }}</div>
    </div>
  </div>
</template>

<style scoped></style>
