<script setup>
import { ref, onMounted } from 'vue';
import wgDialog from '@/components/common/wgDialog';
import Turntable from './components/turntable.vue';
import { useI18n } from 'vue-i18n';
import emitter from '@/assets/tools/eventBus';
import wgButton from '@/components/common/wgButton.vue';
import { isMobile } from '@/assets/tools/CommonTool';
import { watch } from 'vue';
import { getTokensPriceService } from '@/assets/network/service/home';

defineOptions({
  name: 'freeTurntableDialog'
});

const { t } = useI18n();

const emits = defineEmits(['close']);

const isShow = defineModel('isShow');
const visible = ref(false);
const cryptoList = ref([]);

const tipModelShow = ref(false);

const tipModelText = ref('');

// 创建对抽奖组件的引用
const turntableRef = ref(null);

const sections = ref([]);

const turntableInfo = ref({});
const props = defineProps({
  data: {
    type: []
  }
});

watch(
  isShow,
  (newVal) => {
    if (newVal) {
      sections.value = props.data.free_prize_config.map((item) => {
        return {
          text: item.prize_amount,
          icon: item.icon,
          ...item
        };
      });
      visible.value = true;
    }
  },
  {
    immediate: true
  }
);

const beforeStartCallback = async () => {
  if (turntableInfo.value) {
    try {
      turntableRef.value.play(0);
    } catch (e) {
      console.log(e);
    }
  }
};

const turnTableEndCallback = (info) => {
  tipModelText.value = info.prize_symbol + info.text;
  visible.value = false;
  tipModelShow.value = true;
};

const closeHandle = () => {
  visible.value = false;
  emits('close');
};

const getCoinPriceList = () => {
  getTokensPriceService()
    .then((response) => {
      cryptoList.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

onMounted(() => {
  getCoinPriceList();
});
</script>

<template>
  <div>
    <wg-dialog :show="visible" myClass="!z-[1600] overflow-y-auto">
      <div>
        <div class="relative">
          <div class="relative w-[22.8rem] mode-bg p-[1rem] bg-cover overflow-y-auto" :class="isMobile() ? 'w-[calc(100vw-3.5rem)] h-[calc(100vh-15rem)] max-h-[37rem]' : 'h-[calc(100vh-10rem)] max-h-[37rem]'">
            <div class="flex justify-end">
              <div class="cursor-pointer" @click="closeHandle">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M1.00385 0.33994C1.4571 -0.113312 2.19196 -0.113312 2.64521 0.33994L13.3772 11.072C13.8304 11.5252 13.8304 12.2601 13.3772 12.7133C12.9239 13.1666 12.1891 13.1666 11.7358 12.7133L1.00385 1.98131C0.550598 1.52806 0.550597 0.793191 1.00385 0.33994Z" fill="#fff" />
                  <path d="M13.3773 0.339939C13.8305 0.79319 13.8305 1.52806 13.3773 1.98131L2.64528 12.7133C2.19203 13.1666 1.45717 13.1666 1.00392 12.7133C0.55067 12.2601 0.55067 11.5252 1.00392 11.072L11.7359 0.339939C12.1891 -0.113313 12.924 -0.113313 13.3773 0.339939Z" fill="#fff" />
                </svg>
              </div>
            </div>
            <Turntable class="mt-[2rem]" ref="turntableRef" :sections="sections" :cryptoList="cryptoList" @beforeStartCallback="beforeStartCallback" @endCallback="turnTableEndCallback"> </Turntable>
            <div class="font-bold text-center">
              <svg class="w-full mt-[-1.5rem]">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                    <!-- 这里设置了上下渐变 -->
                    <stop offset="10%" style="stop-color: #f0f7ff; stop-opacity: 1" />
                    <stop offset="100%" style="stop-color: #acf0ff; stop-opacity: 1" />
                  </linearGradient>
                </defs>
                <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" class="text-outline">
                  {{ t('scheduleInfo_010') }}
                </text>
                <text x="50%" y="75%" dominant-baseline="middle" text-anchor="middle" class="text-outline">
                  {{ t('on a free spin').toLocaleUpperCase() }}
                </text>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </wg-dialog>
    <wg-dialog :show="tipModelShow" myClass="!z-[1700]">
      <div class="relative bg-spin-model-bg bg-bottom bg-cover w-[31.5rem] h-[21rem]">
        <img class="absolute left-[-15rem] top-[-2rem]" src="../../assets//images/spin/spin-bg-left.png" alt="" />
        <img class="absolute right-[-10rem] top-[-3rem]" src="../../assets//images/spin/spin-bg-right.png" alt="" />
        <div class="relative z-1000">
          <div class="text-[#fff] font-bold text-center text-[1.5rem] mt-[3rem]">{{ t('scheduleInfo_010') }}!</div>
          <div class="text-[#FDE25D] text-center font-bold text-[2.5rem] mt-[3rem]">
            {{ tipModelText }}
          </div>
          <div class="text-[#AAC4ED] text-[1.125rem] text-center mt-[0.94rem]">
            {{ t('Register to the wallet') }}
          </div>
          <div class="flex justify-center mt-[1.03rem]">
            <wgButton
              class="w-[19rem] py-[0.69rem] text-[#fff]"
              @click="
                () => {
                  tipModelShow = false;
                  emitter.emit('custom-login', 'sign_up');
                }
              "
            >
              {{ t('sign up now').toLocaleUpperCase() }}
            </wgButton>
          </div>
        </div>
      </div>
    </wg-dialog>
  </div>
</template>

<style scoped>
.text-outline {
  font-weight: bold;
  font-size: 1.8rem;
  fill: url(#gradient);
  /* 使用 SVG 渐变填充 */
  text-shadow:
    2px 5px 0px #2148ae,
    -2px -2px 0px #2148ae,
    2px -2px 0px #2148ae,
    -2px 2px 0px #2148ae;
}

.mode-bg {
  background: url('../../assets/images/free-spin/body_c1.webp');
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
