<template>
  <div class="bg-[#121829]">
    <wgTable :title="tableState.title" :request="getTableList" :search="tableState.searchConfig" :columns="tableState.columns" :initParams="tableState.initParams" :pagination="tableState.paginationConfig">
      <template #tableType="{ row }">
        {{ formatTableType(row.type) }}
      </template>
      <template #tableAmount="{ row }">
        <div class="flex items-center gap-[5px]">
          <img :src="getSymbolIconUrl(row.symbol)" class="w-[1rem] h-[1rem]" />
          {{ formatNumberWithCommas(row.amount) }}
        </div>
      </template>
      <template #tableTime="{ row }">
        {{ formatDate(row.create_time) }}
      </template>
      <template #tableStatus="{ row }">
        <div class="flex items-center gap-[5px] cursor-pointer" @click="clickTableDetail(row)">
          <el-icon v-if="getTableStatus(row.type, row.status) == 0" style="color: #e6a23c"><WarningFilled /></el-icon>
          <el-icon v-else-if="getTableStatus(row.type, row.status) == 1" style="color: #00b555"><SuccessFilled /></el-icon>
          <el-icon v-else-if="getTableStatus(row.type, row.status) == 2" style="color: #f15a5a"><CircleCloseFilled /></el-icon>
          <div class="flex items-center">
            {{ formatTableStatus(row.type, row.status) }}
            <el-icon style="color: #aac4ed"><ArrowRight /></el-icon>
          </div>
        </div>
      </template>
    </wgTable>
    <txDialog :row="detailRow" />
  </div>
</template>
<script setup>
import { ref, reactive, watchEffect, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import wgTable from '@/components/common/wgTable/wgTable.vue';
import { getTransactionsList } from '@/assets/network/service/user';
import { isMobile, formatNumberWithCommas, formatDate } from '@/assets/tools/CommonTool';
import txDialog from '@/pages/profile/components/txDialog.vue';
import { useAppStore } from '@/store/store';
import useWalletStore from '@/store/wallet';

const appStore = useAppStore();
const walletStore = useWalletStore();
const { t } = useI18n();
const detailRow = ref(null);
const tableState = reactive({
  title: t('transaction'),
  initParams: {
    date_type: 'days'
  },
  columns: isMobile()
    ? [
        {
          label: t('amount'),
          tdSlot: 'tableAmount'
        },
        {
          label: t('time'),
          tdSlot: 'tableTime',
          width: 170
        },
        {
          label: t('status'),
          tdSlot: 'tableStatus',
          width: 110
        }
      ]
    : [
        {
          label: t('type'),
          tdSlot: 'tableType'
        },
        {
          label: t('amount'),
          tdSlot: 'tableAmount'
        },
        {
          label: t('time'),
          tdSlot: 'tableTime',
          width: 210
        },
        {
          label: t('order_no'),
          prop: 'order_id',
          copy: true
        },
        {
          label: t('status'),
          tdSlot: 'tableStatus'
        }
      ],
  // 搜索配置
  searchConfig: {
    fields: [
      {
        width: '10rem',
        name: 'transaction_type',
        type: 'select',
        defaultValue: 'Deposit',
        options: [
          {
            name: t('Deposit'),
            value: 'Deposit'
          },
          {
            name: t('Withdraw'),
            value: 'Withdraw'
          }
        ]
      },
      {
        width: '10rem',
        name: 'symbol',
        type: 'select',
        defaultValue: '',
        options: []
      },
      {
        width: '10rem',
        name: 'date_time',
        type: 'select',
        defaultValue: '60',
        options: [
          {
            name: t('All'),
            value: '0'
          },
          {
            name: t('past_24_hours'),
            value: '1'
          },
          {
            name: t('past_30_days'),
            value: '30'
          },
          {
            name: t('past_60_days'),
            value: '60'
          },
          {
            name: t('past_70_days'),
            value: '70'
          }
        ]
      },
      {
        width: '10rem',
        name: 'state',
        type: 'select',
        defaultValue: '',
        options: [
          {
            name: t('All'),
            value: ''
          },
          {
            name: t('Processing'),
            value: '0'
          },
          {
            name: t('success'),
            value: '1'
          },
          {
            name: t('Failure'),
            value: '2'
          }
        ]
      }
    ]
  },
  // 分页配置
  paginationConfig: {
    layout: isMobile() ? 'prev,slot,next' : 'slot,->,prev,pager,next',
    pageSize: 10, // 每页条数
    pageSizes: [5, 10, 20, 50]
  }
});

const clickTableDetail = (row) => {
  detailRow.value = row;
  appStore.txDetailShow = true;
};

const formatTableType = (type) => {
  if (type == 1) {
    return t('Deposit');
  } else if (type == 2) {
    return t('Withdraw');
  } else {
    return '';
  }
};

const getTableStatus = (type, status) => {
  if (type == 1) {
    return status;
  } else if (type == 2) {
    if (status == 2 || status == 3 || status == 7 || status == 8 || status == 9) {
      return 0;
    } else if (status == 1) {
      return 1;
    } else if (status == 4 || status == 5 || status == 6 || status == 10) {
      return 2;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
const formatTableStatus = (type, status) => {
  const state = getTableStatus(type, status);
  if (state == 0) {
    return t('Processing');
  } else if (state == 1) {
    return t('success');
  } else if (state == 2) {
    return t('Failure');
  } else {
    return '';
  }
};

const getSymbolIconUrl = computed(() => (symbol) => {
  if (walletStore.currencyTokens.length === 0) return '';
  const token = walletStore.currencyTokens.find((token) => token.symbol === symbol);
  return token ? token.icon_url : '';
});

// 请求函数
const getTableList = async (params) => {
  // params是从组件接收的，包含分页和搜索字段。
  try {
    const res = await getTransactionsList(params);
    // 必须要返回一个对象，包含data数组和total总数
    return {
      data: res.data.data,
      total: res.data.total_count
    };
  } catch (e) {
    return {
      data: [],
      total: 0
    };
  }
};

watchEffect(() => {
  const symbolOptions = walletStore.currencyTokens.map((item) => {
    return {
      name: item.symbol,
      value: item.symbol
    };
  });
  tableState.searchConfig.fields.find((field) => field.name === 'symbol').options = [
    {
      name: t('all_assets'),
      value: ''
    },
    ...symbolOptions
  ];
});
</script>
<style scoped>
::v-deep .el-form--inline .el-form-item {
  margin-right: 18px;
}
</style>
