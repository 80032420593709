import Cookies from 'js-cookie';

export const filterDecimal = (inputString) => {
  const decimalPattern = /(\d*\.\d*|\d+)/;

  const decimalMatch = inputString.match(decimalPattern);

  if (decimalMatch) {
    return decimalMatch[0];
  } else {
    return '';
  }
};

export const loadScriptJS = (url)=> {
  const script = document.createElement('script');
  script.async = true;
  script.src = url;
  document.head.appendChild(script);
}

export const getCookieAccessToken = () => {
  return Cookies.get('accessToken') !== undefined && Cookies.get('accessToken').length > 0 ? Cookies.get('accessToken') : '';
};
export const getCookieUserId = () => {
  return Cookies.get('userId') !== undefined && Cookies.get('userId').length > 0 ? Cookies.get('userId') : '';
};
export const getCookieUsername = () => {
  return Cookies.get('username') !== undefined && Cookies.get('username').length > 0 ? Cookies.get('username') : '';
};
export const getCookieEmail = () => {
  return Cookies.get('email') !== undefined && Cookies.get('email').length > 0 ? Cookies.get('email') : '';
};
export const getCookieUserNiceName = () => {
  return Cookies.get('userNiceName') !== undefined && Cookies.get('userNiceName').length > 0 ? Cookies.get('userNiceName') : '';
};
export const setCookieAccessToken = (token) => {
  Cookies.set('accessToken', token);
};
export const setCookieUserId = (userId) => {
  Cookies.set('userId', userId);
};
export const setCookieUserNiceName = (userNiceName) => {
  Cookies.set('userNiceName', userNiceName);
};
export const setCookieUsername = (username) => {
  Cookies.set('username', username);
};
export const setCookieEmail = (email) => {
  Cookies.set('email', email);
};
export const setCookiesGmailLogin = (gmailLogin) => {
  Cookies.set('gmailLogin', gmailLogin);
};
export const setCookiesWinGameStyle = (winGameStyle) => {
  Cookies.set('winGame-style', winGameStyle);
};

export const getCookiesWinGameStyle = () => {
  return Cookies.get('winGame-style') !== undefined && Cookies.get('winGame-style').length > 0 ? Cookies.get('winGame-style') : '';
};
export const getUserDefSymbol = () => {
  return Cookies.get('symbol') !== undefined && Cookies.get('symbol').length > 0 ? Cookies.get('symbol') : '';
};
export const setUserDefSymbol = (symbol) => {
  Cookies.set('symbol', symbol);
};
export const getGameInfo = () => {
  let game = Cookies.get('TTLatestGame') !== undefined && Cookies.get('TTLatestGame').length > 0 ? Cookies.get('TTLatestGame') : '';
  return game;
};
export const setGameInfo = (game) => {
  Cookies.set('TTLatestGame', game);
};
export const getFingerPrintInfo = () => {
  return Cookies.get('WinGameFingerPrint');
};
export const getCookiesGmailLogin = () => {
  let gmailLogin = Cookies.get('gmailLogin') !== undefined && Cookies.get('gmailLogin').length > 0 ? Cookies.get('gmailLogin') : '';
  return gmailLogin;
};

export const setCookiesByKey = (key, value) => {
  if (key) {
    Cookies.set(key, value ? value : '');
  }
};
export const getCookiesByKey = (key) => {
  if (!key) {
    return;
  }
  let gmailLogin = Cookies.get(key) !== undefined && Cookies.get(key).length > 0 ? Cookies.get(key) : '';
  return gmailLogin;
};
export const setFingerPrintInfo = (uuid) => {
  Cookies.set('WinGameFingerPrint', uuid);
};
export const clearUserCookies = () => {
  Cookies.remove('accessToken');
  Cookies.remove('userId');
  Cookies.remove('username');
  Cookies.remove('email');
  Cookies.remove('symbol');
  Cookies.remove('userNiceName');
  Cookies.remove('TTLatestGame');
};
export const convertCurrency = (currency, fixedNumber) => {
  if (!fixedNumber) {
    fixedNumber = 4;
  }
  if (currency && !isNaN(currency)) {
    return Number(currency).toFixed(fixedNumber);
  } else {
    return Number('0').toFixed(fixedNumber);
  }
};

export const checkLoading = (loadObj) => {
  for (let k in loadObj) {
    let f = loadObj[k];
    if (f) {
      return true;
    }
  }
  return false;
};
export const gameResize = (configList) => {
  let width = window.innerWidth;
  let config = {};
  for (let i in configList) {
    if (width > configList[i].breakPx) {
      config = configList[i];
      break;
    }
  }
  return config;
};
export const searchGameListBreakPx = [
  {
    breakPx: 1440,
    setupSize: 8,
    style: 'grid-template-columns: repeat(8, 1fr);gap: 20px 10px;display:grid;',
    gameMainStyle: 'padding-right:10px;'
  },
  {
    breakPx: 1199,
    setupSize: 7,
    style: 'grid-template-columns: repeat(7, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 1138,
    setupSize: 6,
    style: 'grid-template-columns: repeat(6, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 960,
    setupSize: 7,
    style: 'grid-template-columns: repeat(7, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 780,
    setupSize: 5,
    style: 'grid-template-columns: repeat(5, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 585,
    setupSize: 4,
    style: 'grid-template-columns: repeat(4, 1fr);gap: 10px 5px;display:grid;',
    gameMainStyle: 'padding-right:5px;'
  },
  {
    breakPx: 0,
    setupSize: 3,
    style: 'grid-template-columns: repeat(3, 1fr);gap: 10px 5px;display:grid;',
    gameMainStyle: 'padding-right:5px;'
  }
];
export const gameListBreakPx = [
  {
    breakPx: 1199,
    setupSize: 7,
    style: 'grid-template-columns: repeat(7, 1fr);gap: 30px 10px;display:grid;',
    gameMainStyle: 'padding-right:10px;'
  },
  {
    breakPx: 1138,
    setupSize: 6,
    style: 'grid-template-columns: repeat(6, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 960,
    setupSize: 7,
    style: 'grid-template-columns: repeat(7, 1fr);gap: 30px 10px;display:grid;',
    gameMainStyle: 'padding-right:10px;'
  },
  {
    breakPx: 780,
    setupSize: 5,
    style: 'grid-template-columns: repeat(5, 1fr);gap: 30px 15px;display:grid;',
    gameMainStyle: 'padding-right:15px;'
  },
  {
    breakPx: 585,
    setupSize: 4,
    style: 'grid-template-columns: repeat(4, 1fr);gap: 10px 5px;display:grid;',
    gameMainStyle: 'padding-right:5px;'
  },
  {
    breakPx: 0,
    setupSize: 3,
    style: 'grid-template-columns: repeat(3, 1fr);gap: 10px 5px;display:grid;',
    gameMainStyle: 'padding-right:5px;'
  }
];
//{
//     baseURL: "https://api.wingaming.com/",
//    botId: '6708794248',
//    useTokenAuthorization: true, // 是否开启 token 认证
//    withCredentials: false, // 跨域请求是否需要携带 cookie
//    isGmail: false,
//    breakPx:[1301,780]
//};

export const isMobile = () => {
  const userAgent = navigator.userAgent;
  const isMobileUserAgent = /Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const isMobileScreen = window.innerWidth <= 768;
  return isMobileUserAgent || isMobileScreen;
};

export const formatNumberWithCommas = (num) => {
  const [integerPart, decimalPart] = num.toString().split('.');
  // 使用正则表达式格式化整数部分
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // 拼接整数部分和小数部分
  if (decimalPart !== undefined) {
    return `${formattedIntegerPart}.${decimalPart}`;
  } else {
    return formattedIntegerPart;
  }
};

export const obfuscateEmail = (email) => {
  if (email === undefined || email === null || email === '') {
    return null;
  }
  // 将邮箱地址分成用户名和域名
  const [user, domain] = email.split('@');
  // 保留用户名的前三位，剩余部分替换为4个星号
  const obfuscatedUser = user.slice(0, 3) + '****';
  // 将域名部分处理为星号，但保留开头的一个字符和最后的 ".com"
  const domainParts = domain.split('.');
  const obfuscatedDomain = domainParts[0].charAt(0) + '**' + '.' + domainParts.slice(1).join('.');
  return `${obfuscatedUser}@${obfuscatedDomain}`;
};

export const obfuscateTG = (str) => {
  if (str === undefined) {
    return null;
  }
  if (str.length <= 3 || !str) {
    // 如果字符串长度小于等于3，直接返回原字符串
    return str;
  }
  // 获取字符串的前2位和末尾1位
  const start = str.slice(0, 2);
  const end = str.slice(-1);
  // 中间部分用4个星号替代
  const middle = '****';
  return `${start}${middle}${end}`;
};

export const transformedStr = (str, a = 3, b = 1, len = 5, symbol = '***') => {
  let res = '';
  if (str) {
    if (str.length > len) {
      res = str.substring(0, a) + symbol + str.substring(str.length - b);
    } else {
      res = str;
    }
  }
  return res;
};

export const getBrowser = () => {
  const userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  console.log('userAgent', userAgent);
  const isOpera = userAgent.indexOf('Opera') > -1;
  //判断是否IE浏览器
  if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
    return 'IE';
  }
  //判断是否chorme浏览器
  else if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  }
  //判断是否Safari浏览器
  else if (userAgent.indexOf('Safari') > -1) {
    return 'Safari';
  }
  //判断是否Firefox浏览器
  else if (userAgent.indexOf('Firefox') > -1) {
    return 'FF';
  }
  //判断是否Edge浏览器
  else if (userAgent.indexOf('Trident') > -1) {
    return 'Edge';
  }
  //判断是否Opera浏览器
  else if (userAgent.indexOf('Opera') > -1) {
    return 'Opera';
  }
  //未知浏览器
  else {
    return '';
  }
};

// timestamp translation America date
export const formatDate = (timestamp, type = 's') => {
  if (!timestamp) return '--';
  const date = new Date(type === 's' ? timestamp * 1000 : timestamp);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
};

export const getWebsiteName = () => {
  const currentDomain = window.location.hostname;
  if (currentDomain.includes('www.wingame.com')) {
    return 'www.wingame.com';
  } else if (currentDomain.includes('www.wingaming.com')) {
    return 'www.wingaming.com';
  } else if (currentDomain.includes('testwingame.ttgamelab.com')) {
    return 'testwingame.ttgamelab.com';
  } else if (currentDomain.includes('testproxy1.wingame.com')) {
    return 'testproxy1.wingame.com';
  } else {
    return '';
  }
};

export const formatTimeStamp = (time) => {
  if (time) {
    const dateParts = time.split(/[- :]/);
    const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], dateParts[3], dateParts[4], dateParts[5]);
    const milliseconds = dateObject.getTime();
    return milliseconds;
  } else {
    const currentTime = new Date().getTime();
    return currentTime;
  }
};

export const isTimeInRange = (start, end, current) => {
  if (current > start && current < end) {
    return true;
  } else {
    return false;
  }
};

export const getType = (param) => {
  if (typeof param === 'number') {
    return 'Number';
  } else if (typeof param === 'string') {
    return 'String';
  } else if (typeof param === 'boolean') {
    return 'Boolean';
  } else if (typeof param === 'function') {
    return 'Function';
  } else if (typeof param === 'object') {
    if (Array.isArray(param)) {
      return 'Array';
    } else if (param === null) {
      return 'Null';
    } else {
      return 'Object';
    }
  } else {
    return 'Undefined';
  }
};
