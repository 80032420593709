<script setup>
import { defineProps, defineEmits, watchEffect, onUnmounted } from 'vue';
const props = defineProps({
  show: Boolean,
  myClass: String
});
const emit = defineEmits(['close']);

const handleCloseClick = () => {
  emit('close');
};

watchEffect(() => {
  if (props.show) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
});

onUnmounted(() => {
  document.body.style.overflow = 'auto';
});
</script>

<template>
  <Teleport to="body">
    <div :class="props.myClass ? props.myClass + ' dialog' : ' dialog'" v-if="props.show" @click.self.stop.prevent="handleCloseClick">
      <div class="dialog-container">
        <slot />
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.dialog-no-bg.dialog {
  background-color: var(--body-bg-color);
}
.dialog {
  background-color: var(--my-dialog-out-main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  animation: bgColor 0.3s ease-out;
}
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: scale 0.3s ease-out;
}
.login-Z-index {
  z-index: 1501;
}
@keyframes bgColor {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>
