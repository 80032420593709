<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import myMenus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
import myFooter from '@/components/footer/footer.vue';
import { getNotificationMessagesService, messageMakeAsReadService } from '@/assets/network/service/notification';
import pageLoading from '@/components/loading/pageLoading.vue';
import { toast } from 'vue3-toastify';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';

const { t } = useI18n();
const textData = {
  notifications: t('notifications'),
  no_notifications_available: t('no_notifications_available'),
  your_interactions__here: t('your_interactions__here'),
  view_details: t('view_details'),
  make_as_read: t('make_as_read')
};

const router = useRouter();
const handleCloseClick = () => {
  router.go(-1);
};

const messageList = ref({
  total_count: Number,
  data: [
    {
      id: 1,
      subject: '',
      content: '',
      is_read: false,
      detail_link: '',
      created_at: 0
    }
  ]
});
const isLoading = ref(false);
const confirmLoadingJson = ref(loadingAniJson);
const confirmLoading = ref(false);

const handleFetchMessages = async () => {
  try {
    const response = await getNotificationMessagesService();
    isLoading.value = false;
    messageList.value.total_count = response.data.total_count;
    messageList.value.data = response.data.data;
  } catch (error) {
    isLoading.value = false;
    messageList.value.data = [];
  }
};
const handleMessageMakeAsReadToServer = async (index) => {
  try {
    const param = {
      message_id: messageList.value.data[index].id,
      message_type: messageList.value.data[index].message_type
    };
    const response = await messageMakeAsReadService(param);
    confirmLoading.value = false;
    if (response.data.code == 200) {
      messageList.value.data[index].is_read = true;
    } else {
      toast(response.data.msg, { autoClose: 3000, type: 'error' });
    }
  } catch (error) {
    confirmLoading.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

onMounted(() => {
  isLoading.value = true;
  handleFetchMessages();
});

const makeAsReadClick = (index) => {
  confirmLoading.value = true;
  handleMessageMakeAsReadToServer(index);
};
const handleViewDetailClick = (index) => {
  // window.open(messageList.value.data[index].detail_link, '_blank')
  openUrl(messageList.value.data[index]);
};
const openUrl = (notifyDate) => {
  if (notifyDate.link_type == 'External' || notifyDate.link_type == 'external') {
    if (notifyDate.detail_link) {
      if (notifyDate.detail_link.startsWith('https://') || notifyDate.detail_link.startsWith('http://')) {
        window.open(notifyDate.detail_link, '_blank');
      } else {
        window.open('https://' + notifyDate.detail_link, '_blank');
      }
    }
  } else {
    try {
      router.push({ path: notifyDate.detail_link });
    } catch (e) {
      console.log(e);
    }
  }
};
const formattedDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString().slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${month}/${day}/${year}, ${hours}:${minutes}`;
};
</script>

<template>
  <div class="root_div" @click="providerShow = false">
    <my-menus></my-menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header></my-header>
      <div class="main-content">
        <div class="body width-100">
          <div class="bg-content">
            <div class="header">
              <div class="header-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                  <path
                    d="M7.60589 1.24444C7.60589 0.559222 6.98756 0 6.22456 0C5.46233 0 4.84167 0.556889 4.84167 1.24444C4.84167 1.31522 4.84711 1.38444 4.85956 1.45056C2.95089 2.04944 1.38289 3.88889 1.38289 6.06433V7.77778C1.38289 7.77778 1.38289 9.93689 0.708556 9.95789C0.308778 9.95789 0 10.2379 0 10.5817C0 10.927 0.308778 11.2031 0.690667 11.2031H11.7538C12.138 11.2031 12.4444 10.9247 12.4444 10.5809C12.4444 10.2348 12.1357 9.96022 11.7538 9.96022C11.0616 9.96022 11.0616 7.79722 11.0616 7.79722V6.06589C11.0616 3.88733 9.597 2.04711 7.58489 1.45056C7.59889 1.38211 7.60744 1.31522 7.60744 1.24522L7.60589 1.24444ZM8.29656 11.9C8.295 12.9313 7.60978 14 6.22378 14C4.83778 14 4.15178 12.9344 4.15022 11.9H8.29656Z"
                    fill="var(--icon-bg-color)"
                  />
                </svg>
                <div class="header-left-title">{{ textData.notifications }}</div>
              </div>

              <button class="header-close" @click="handleCloseClick">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
                  <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
                </svg>
              </button>
            </div>

            <page-loading :show="isLoading"></page-loading>

            <!-- 消息中心空页面 -->
            <div class="empty" v-if="!isLoading && messageList.data.length == 0">
              <svg xmlns="http://www.w3.org/2000/svg" width="83" height="89" viewBox="0 0 83 89" fill="none">
                <path d="M63.9755 35.1451C63.9755 31.096 60.8604 27.7214 57.077 27.271C56.8542 29.746 56.8542 32.2209 56.8542 34.6958C56.8542 37.62 57.077 40.3202 57.2997 43.0192C60.8604 42.5699 63.9755 39.1953 63.9755 35.1451Z" fill="#1184FA" />
                <path d="M15.0194 52.2448L29.0381 85.9909C29.7052 87.7914 31.7088 88.6911 33.4886 88.4659C35.7139 88.0155 37.0492 85.9909 36.6037 83.7412L29.4825 44.1456L15.0183 52.2448H15.0194Z" fill="#2C394E" />
                <path d="M69.5384 0.5C63.9752 0.5 59.0792 9.94934 57.2994 23.2226C63.3081 23.8971 67.9803 29.0721 67.9803 35.3709C67.9803 41.6698 63.307 46.6196 57.2994 47.2941C59.302 60.117 63.9752 69.117 69.5384 69.117C76.6596 69.117 82.6672 53.8192 82.6672 34.9206C82.6672 16.0219 76.8813 0.5 69.5384 0.5Z" fill="#4C5B73" />
                <path
                  d="M13.4616 14.6747C9.67827 14.6747 6.56312 21.4239 5.89598 30.4229C2.78083 30.6481 0.332825 33.3471 0.332825 36.7217C0.332825 40.0963 3.00249 43.0205 6.11872 43.0205H6.34146C7.67684 49.995 10.3465 54.7196 13.4627 54.7196C17.6905 54.7196 21.2511 45.7207 21.2511 34.6972C21.2511 23.6737 17.6905 14.6747 13.4627 14.6747H13.4616ZM2.33534 36.7217C2.33534 34.6972 3.67072 32.8967 5.45049 32.6726V34.9224C5.45049 37.1721 5.67323 39.1977 5.89489 41.2212C3.89237 40.7719 2.33534 38.9715 2.33534 36.7217Z"
                  fill="#384861"
                />
                <path d="M52.4037 34.6962C52.4037 20.5233 55.7416 8.37379 60.4149 3.19983C55.0745 7.25002 39.2749 17.3738 12.7945 14.8989V54.7197C39.2749 52.2448 55.0745 62.3686 60.4149 66.4188C55.7416 61.0197 52.4037 48.8713 52.4037 34.6984V34.6962Z" fill="#384861" />
              </svg>
              <div class="empty-title">{{ textData.no_notifications_available }}</div>
              <div class="empty-desc">{{ textData.your_interactions__here }}</div>
            </div>

            <!-- 消息中心列表 -->
            <div class="container" v-if="!isLoading && messageList.data.length > 0">
              <div class="container-row" v-for="(item, index) in messageList.data" :key="index">
                <div class="container-row-time">{{ formattedDate(item.created_at) }}</div>
                <div class="container-row-desc">{{ item.content }}</div>
                <button class="container-row-button" @click="handleViewDetailClick(index)" v-if="item.detail_link">{{ textData.view_details }}</button>
                <div class="container-row-state">
                  <button class="container-row-state-make-read" v-if="!confirmLoading && !item.is_read" @click="makeAsReadClick(index)">
                    {{ textData.make_as_read }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M9.00029 0C13.9717 0 18 4.02947 18 9.00029C18 13.9717 13.9705 18 9.00029 18C4.02773 18 0 13.9705 0 9.00029C0 4.02773 4.02947 0 9.00029 0ZM9.00029 1.16073C4.67019 1.16073 1.16073 4.66961 1.16073 9.00029C1.16073 13.3298 4.66961 16.8393 9.00029 16.8393C13.3298 16.8393 16.8393 13.3304 16.8393 9.00029C16.8393 4.67019 13.331 1.16073 9.00029 1.16073ZM12.9195 5.25636C13.0472 5.13232 13.2183 5.06296 13.3964 5.06301C13.5744 5.06307 13.7455 5.13253 13.8731 5.25665C14.0008 5.38077 14.0751 5.54977 14.0801 5.72776C14.0852 5.90575 14.0207 6.0787 13.9003 6.2099L7.95157 12.3281C7.82647 12.4566 7.6559 12.5309 7.47658 12.5349C7.29727 12.5389 7.12355 12.4724 6.99281 12.3496L4.10376 9.62999C4.03457 9.56944 3.97829 9.49557 3.93828 9.41278C3.89828 9.33 3.87537 9.24001 3.87092 9.14817C3.86647 9.05634 3.88057 8.96455 3.91239 8.87829C3.9442 8.79203 3.99308 8.71306 4.05609 8.64611C4.1191 8.57916 4.19496 8.52559 4.27914 8.48861C4.36331 8.45162 4.45408 8.43198 4.54601 8.43086C4.63795 8.42974 4.72916 8.44716 4.81422 8.48207C4.89927 8.51699 4.97642 8.56869 5.04104 8.63408L7.44607 10.8975L12.9195 5.25636Z"
                        fill="var(--icon-bg-color)"
                      />
                    </svg>
                  </button>
                  <div class="container-row-state-readed" v-if="!confirmLoading && item.is_read">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path
                        d="M9 0C4.02985 0 0 4.02985 0 9C0 13.9701 4.02985 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02985 13.9701 0 9 0ZM13.5825 6.96205L9.56034 12.3237C9.34925 12.6038 9.01919 12.7689 8.66994 12.7727C8.46269 12.7727 8.25544 12.7151 8.07889 12.6038L4.8435 10.6158C4.49424 10.4085 4.27932 10.0324 4.28699 9.62559C4.29467 9.21876 4.52111 8.85032 4.88188 8.65842C5.24264 8.46652 5.67633 8.48571 6.01791 8.70831L8.38593 10.1591L11.7902 5.61876C12.1625 5.12367 12.8648 5.02388 13.3638 5.39616C13.855 5.76461 13.9548 6.46695 13.5825 6.96205Z"
                        fill="#1CA45F"
                      />
                    </svg>
                  </div>
                  <div class="container-row-state-confirm-loading" v-if="confirmLoading">
                    <vue3-lottie :animationData="confirmLoadingJson" :width="30" :height="30"></vue3-lottie>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-content">
        <my-testimonials-list></my-testimonials-list>
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
</template>

<style scoped>
.bg-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.75rem;
}
.header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-left-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  margin-left: 0.5rem;
  text-transform: capitalize;
}
.header-close {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  border: none;
  cursor: pointer;
}
.header-close:hover {
  filter: brightness(0) invert(1);
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 26rem;
}
.empty-title {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: capitalize;
  margin-top: 1.75rem;
}
.empty-desc {
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.875rem;
  text-transform: capitalize;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: -2.5rem;
}
.container-row {
  background-color: #2c394e;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.875rem;
  width: calc(100% - 1.75rem);
}
.container-row-time {
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-weight: 700;
  width: 100%;
  line-height: 2rem;
  text-align: left;
}
.container-row-desc {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  width: 100%;
  line-height: 1.8rem;
  text-align: left;
  text-transform: capitalize;
  white-space: normal;
}
.container-row-button {
  background-color: transparent;
  border: none;
  color: #2283f6;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2rem;
  min-height: 2rem;
  text-align: left;
  padding: 0;
  width: max-content;
  text-transform: capitalize;
  cursor: pointer;
}
.container-row-button:hover {
  opacity: 0.8;
}
.container-row-button:active {
  opacity: 0.8;
}
.container-row-state {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.container-row-state-make-read {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0;
  cursor: pointer;
}
.container-row-state-make-read:hover {
  opacity: 0.8;
}
.container-row-state-make-read:active {
  opacity: 0.8;
}
.container-row-state-readed {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-row-state-confirm-loading {
  display: flex;
  justify-content: end;
  align-items: center;
}
</style>
