// import Adjust from '@adjustcom/adjust-web-sdk';

// 应用识别码：lp4w0emtf3sw
// login           事件识别码：cnjj3q
// Registration    事件识别码：n107ty
// Purchase        事件识别码：hqyl6g
export const initAdjust = () => {
  if (window.jsBridge) {
    // window.Adjust.initSdk({
    //   appToken: 'lp4w0emtf3sw',
    //   environment: 'production' // sandbox or production
    // });
    // console.log('adjust初始化', 'lp4w0emtf3sw');
    // alert('adjust初始化')
  }
};

export const sendAdjustEventLogin = () => {
  if (window.jsBridge) {
    window.jsBridge?.postMessage('login','');
    // window.Adjust.trackEvent({
    //   eventToken: 'cnjj3q'
    // });
    // console.log('adjust发送登录事件', 'cnjj3q');
    // alert('adjust发送登录事件')
  }
};

export const sendAdjustEventRegister = () => {
  if (window.jsBridge) {
    window.jsBridge?.postMessage('Registration','');
    window.jsBridge?.postMessage('login','');
    // window.Adjust.trackEvent({
    //   eventToken: 'n107ty'
    // });
    // console.log('adjust发送注册事件', 'n107ty');
    // alert('adjust发送注册事件')
  }
};

export const sendAdjustEventPurchase = (data) => {
  // console.log(123, window.Adjust)
  if (window.jsBridge) {
    let symbol;
    if (data.symbol == 'USDT') {
      symbol = 'USD';
    } else if (data.symbol == 'BRL') {
      symbol = 'BRL';
    } else {
      symbol = '';
    }
    if (symbol) {
      let amount = Number(data.amount);
      let amountString = amount.toString();
      const purchaseJsonString = JSON.stringify({  amount: amountString, currency: symbol, isFirst: 1, success: 1 });
      const escapedString = purchaseJsonString.replace(/"/g, '\\"');
      window.jsBridge?.postMessage('firstrecharge', escapedString);
      // window.Adjust.trackEvent({
      //   eventToken: 'hqyl6g',
      //   revenue: data.amount,
      //   currency: symbol
      // });
      // console.log('adjust发送充值事件', 'hqyl6g', symbol, data.amount);
      // alert(`adjust发送充值事件:${symbol},${data.amount}`)
    } else {
      console.log(`symbol:${symbol}, 不发送adjust事件`);
    }
  }
};

// export const jsbPostMessage = (_event, _params) => {
//   if (window.jsBridge) {
//     window.jsBridge?.postMessage(_event, _params || 'none');
//   }
// };

// export const formatParams = (_data) => {
//   let symbol;
//   if (_data.symbol == 'USDT') {
//     symbol = 'USD';
//   } else if (_data.symbol == 'BRL') {
//     symbol = 'BRL';
//   } else {
//     symbol = ''
//   }
//   if (symbol) {
//     return {
//       isFirst: 0,
//       success: 1,
//       currency: symbol,
//       amount: _data.amount
//     };
//   } else {
//     return null;
//   }
// };
