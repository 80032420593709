<script setup>
// 导入
import { ref, computed, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';
import { gameResize, gameListBreakPx } from '@/assets/tools/CommonTool';
import { getTokensPriceService } from '@/assets/network/service/home';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
let taskInterval = null;
// 属性
const dataList = ref([]);
const myCarousel = ref(null);
const configs = ref({});
// 计算属性
const dataListFilter = computed(() => {
  return dataList.value.filter((obj) => obj.symbol != 'USDT');
});
// 生命周期
onBeforeMount(() => {
  configs.value = gameResize(gameListBreakPx);
  getCoinPriceList();
});
onMounted(() => {
  window.addEventListener('resize', resize);
  if (!taskInterval) {
    taskInterval = setInterval(getCoinPriceList, 1800000);
  }
});
onUnmounted(() => {
  window.removeEventListener('resize', resize);
  clearInterval(taskInterval);
});
// 方法
const getCoinPriceList = () => {
  getTokensPriceService()
    .then((response) => {
      dataList.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const clickPrev = () => {
  myCarousel.value.prev();
};
const clickNext = () => {
  myCarousel.value.next();
};
const resize = () => {
  configs.value = gameResize(gameListBreakPx);
};
const getRateClass = (rate) => {
  const tem = rate * 100;
  if (tem < 0) {
    return 'down';
  } else {
    return 'up';
  }
};
const getRateText = (rate) => {
  const tem = rate * 100;
  return tem.toFixed(2);
  // if (tem < 0) {
  //   return tem;
  // } else {
  //   return rate * 100;
  // }
};
</script>

<template>
  <div class="game-list-content">
    <div class="game-title">
      <div class="game-title-name">
        <img style="transform: translateX(0)" src="@/assets/images/new-version/icon-coin-price.svg" />
        <div style="transform: translateX(0); padding-left: 5px">{{ t('Crypto Futures').toUpperCase() }}</div>
      </div>
      <div class="game-title-bar">
        <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="home-prev" @click="clickPrev">
          <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
          <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
        </svg>
        <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickNext">
          <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
          <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
        </svg>
      </div>
    </div>
    <div class="game-list">
      <carousel ref="myCarousel" :itemsToShow="configs.setupSize" :itemsToScroll="configs.setupSize" snapAlign="start">
        <slide v-for="(item, index) in dataListFilter" :key="index" class="coin-price-container">
          <div class="coin-price-item">
            <div class="item-top">
              <img class="top-coin-img" :src="item.icon_url" />
              <div class="top-text-div">
                <div class="top-text-name">{{ item.symbol }}</div>
                <div :class="getRateClass(item.fluctuation_rate)">{{ `${getRateText(item.fluctuation_rate)}%` }}</div>
              </div>
            </div>
            <div class="item-bottom">{{ item.price.toFixed(4) }}</div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<style scoped>
.coin-price-container {
  .coin-price-item {
    width: 100%;
    margin: 0 5px;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: var(--New-Rollbit-Brand-Linear-Rio-Grande-Rio-Grande, linear-gradient(0deg, rgba(203, 215, 255, 0.12) 0%, rgba(203, 215, 255, 0.03) 100%));
    .item-top {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      .top-coin-img {
        width: 32px;
        height: 32px;
      }
      .top-text-div {
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      .top-text-name {
        color: #ffffff;
      }
    }
    .item-bottom {
      margin-top: 10px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .up {
      color: #34e6a8;
    }
    .down {
      color: #f04167;
    }
  }
  .coin-price-item:hover {
    .item-cover {
      opacity: 0.7;
    }
  }
}
</style>
