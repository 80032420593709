<template>
  <div>
    <div>
      <div class="flex web py-[0.8rem]">
        <span class="text-[0.75rem] text-left text-[#fff] font-bold">{{ t('balance') }}</span>
      </div>
      <div class="mt-[0.87rem]">
        <wgCard>
          <div>
            <div class="flex">
              <div class="text-[0.75rem] text-[#fff] font-bold leading-[2.5rem]">{{ t('Est_total_value') }}</div>
              <hidden-button class="ml-[0.62rem]" :isHide="isHideInfo" @click="isHideInfo = !isHideInfo"></hidden-button>
            </div>
            <div class="grid grid-flow-row md:grid-flow-col gap-6 md:gap-4 bg-[#1C243F] mt-[2rem] p-[1rem]">
              <div class="flex" v-for="item in listData" :key="item.name">
                <img :src="item.icon" class="w-[2.5rem] h-[2.5rem]" />
                <div class="text-left ml-[1rem]">
                  <div class="text-[0.75rem] text-[#AAC4ED] font-bold">
                    {{ item.text }}
                  </div>
                  <div class="text-[#fff] text-[1.125rem] mt-[0.35rem]">
                    {{ defaultSymbol }}
                    <span style="margin-left: 0.5rem">{{ isHideInfo ? '****' : formatNumberWithCommas(item.num) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </wgCard>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import { getBalanceTotal } from '@/assets/network/service/balance';
import { useI18n } from 'vue-i18n';
import icon1 from '@/assets/images/profile/profile-total-bets.svg';
import icon2 from '@/assets/images/profile/profile/ico_profile_2.svg';
import { isMobile, formatNumberWithCommas } from '@/assets/tools/CommonTool';
import wgCard from '@/components/common/wgCard';
import hiddenButton from './hiddenButton';



const { t } = useI18n();
const defaultSymbol = ref(isMobile() ? '$' : 'evUSD');
const isHideInfo = ref(false)
const betInfo = ref({});
const listData = ref([]);
const getDataHandle = () => {
    getBalanceTotal()
    .then((response) => {
      if (!response.data.error) {
        betInfo.value = response.data.data;
        listData.value = [
          {
            icon: icon1,
            text: t('Total_Balance').toLocaleUpperCase(),
            num: betInfo.value.total_balance || '0.0000'
          },
          {
            icon: icon2,
            text: t('Real_Money').toLocaleUpperCase(),
            num: betInfo.value.total_real_amount || '0.0000'
          }
        ];
      }
    })
    .catch((error) => {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};

onMounted(() => {
    getDataHandle();
});
</script>
