<script setup>
// 导入
import { useI18n } from 'vue-i18n';
// import { useRouter } from 'vue-router';
// import { setGameInfo } from '@/assets/tools/CommonTool';

const { t } = useI18n();
// const router = useRouter();
// 传参
const props = defineProps({
  gameList: {
    type: Array,
    default: () => []
  },
  toPlay: {
    type: Function,
    default: () => {}
  }
});
// 属性
// 计算属性
// 生命周期
// 方法
// const toPlay = (game) => {
//   setGameInfo(JSON.stringify(game));
//   router.push('/gameDetail');
// };
</script>

<template>
  <div v-if="props.gameList.length > 0" class="relative">
    <div class="text-[14px] text-[#ffffff] font-[700]">{{ t('gamelist_title').toLocaleUpperCase() }}</div>
    <div class="grid grid-cols-3 md:grid-cols-4 gap-[10px] mt-[15px]">
      <div class="game-item" v-for="(game, index) in props.gameList" :key="index" @click="props.toPlay(game)">
        <img class="w-full rounded-[8px]" :src="game.image || game.game_image || game.image_url" />
        <div class="text-[12px] text-[#AAC4ED] font-[400] text-center">{{ game.game_name }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.game-item {
  cursor: pointer;
  transition: transform 0.3s ease;
}
.game-item:hover {
  transform: translateY(-10px);
  img {
    opacity: 0.7;
  }
}
</style>
