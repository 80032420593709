<template>
  <div class="main-content">
    <div class="body game-list-content top-1rem" ref="myBody">
      <div class="game-title">
        <div class="game-title-name">
          <img :src="game.gameImage" v-if="game.gameImage" />
          <div>{{ t(game.gameCode).toUpperCase() }}</div>
        </div>
        <div class="game-title-bar">
          <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :class="pageNumber == 1 ? 'home-prev  not-allowed' : 'home-prev '" v-if="pageNumber == 0">
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="pageNumber == 1 ? 'home-prev  not-allowed' : 'home-prev '"
            v-else
            @click="
              setupSizes(setupSize, allSize);
              goToPrevSlide();
            "
          >
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>
          <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" :class="pageNumber >= allSize ? 'not-allowed' : ''" v-if="pageNumber >= allSize">
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>
          <svg
            width="28"
            height="28"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :class="pageNumber >= allSize ? 'not-allowed' : ''"
            v-else
            @click="
              setupSizes(setupSize, allSize);
              goToNextSlide();
            "
          >
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>

          <!-- img src="../../assets/images/home/btn_cb_1.svg" :class="pageNumber == 1 ? 'home-prev  not-allowed':'home-prev '"
               v-if="pageNumber==0"/>
          <img src="../../assets/images/home/btn_cb_1.svg" :class="pageNumber == 1 ? 'home-prev  not-allowed':'home-prev '"
               v-else @click="setupSizes(setupSize,allSize);goToPrevSlide()"/>
          <img src="../../assets/images/home/btn_cb_1.svg" :class="pageNumber >= (allSize) ? 'not-allowed':''"
               v-if="pageNumber >= (allSize)"/>
          <img src="../../assets/images/home/btn_cb_1.svg" :class="pageNumber >= (allSize) ? 'not-allowed':''"
               v-else @click="setupSizes(setupSize,allSize);goToNextSlide()"/ -->
        </div>
      </div>
      <div style="clear: both"></div>
      <div :class="'games-list' + (gameList.length < config.setupSize ? ' translateX_0' : '') + (game.gameCode == 'game_support' ? ' game_support' : '')" :style="config.style" ref="gamesList" v-if="gameList.length > 0">
        <carousel :key="config.setupSize" :style="'width:' + bodyWidth2 + 'px;' + 'max-width:' + bodyWidth2 + 'px;'" v-if="config.setupSize" v-model="pageNumber" :items-to-show="config.setupSize" :itemsToSlide="config.setupSize" :pagination="true" ref="carouselRef">
          <slide v-for="(myGame, index) in gameList" :key="index">
            <div :class="'game-main' + (index - setupSize + 1 > pageNumber ? ' faded' : '')" :style="config.gameMainStyle" ref="gameMain">
              <div class="game-main-image" @click.stop="toPlay(myGame)" v-if="index > -1">
                <!-- <a
                  v-if="myGame.game_uid"
                  :href="'/gameDetail?id=' + myGame.game_uid"
                  @mousedown.left="
                    () => {
                      myGame.leftDown = true;
                    }
                  "
                  @mouseleave="mouseLeave(myGame)"
                  :class="myGame.leftDown ? 'events_none' : ''"
                > -->
                <div class="game-mask">
                  <img :src="myGame.image || myGame.game_image || myGame.image_url" />
                  <div v-if="game.gameCode != 'game_support'" class="mask-hover-div">
                    <div>
                      <div class="hover-text1">{{ $t('EFFECTIVE RTP') }}</div>
                      <div class="hover-text2">{{ '98.00%' }}</div>
                    </div>
                    <div v-if="appStore.isLogin" @click.stop="favoriteGam(myGame)">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="favorite-btn">
                        <path d="M12.5 21.3519L11.05 20.0319C5.9 15.3619 2.5 12.2819 2.5 8.50189C2.5 5.42189 4.92 3.00189 8 3.00189C9.74 3.00189 11.41 3.81189 12.5 5.09189C13.59 3.81189 15.26 3.00189 17 3.00189C20.08 3.00189 22.5 5.42189 22.5 8.50189C22.5 12.2819 19.1 15.3619 13.95 20.0419L12.5 21.3519Z" :fill="myGame.is_favorite ? '#F04167' : '#8EA3C3'" />
                      </svg>
                    </div>
                  </div>
                </div>
                <!-- </a> -->
                <!-- <img v-else :src="myGame.image || myGame.game_image || myGame.image_url" /> -->
              </div>
              <div
                class="game-main-image"
                @click="
                  setupSizes(config.setupSize);
                  goToNextSlide();
                "
                v-else
                style="opacity: 0.3"
              >
                <a v-if="myGame.game_uid" :href="'/gameDetail?id=' + myGame.game_uid">
                  <img :src="myGame.image || myGame.game_image || myGame.image_url" />
                </a>
                <img v-else :src="myGame.image || myGame.game_image || myGame.image_url" />
              </div>
              <div style="clear: both"></div>
              <div class="game-main-bottom" v-if="game.showBottom">
                <div class="left" v-if="myGame.playing_count || !myGame.is_maintain">
                  <div :class="!myGame.is_maintain ? 'dot' : 'no'"></div>
                  <div class="text" v-show="!myGame.is_maintain">{{ myGame.playing_count }}</div>
                  <div style="padding-left: 0.3rem; max-width: calc(100% - 7px - 0.3rem)">
                    {{ !myGame.is_maintain ? $t('playing') : $t('in_maintenance') }}
                  </div>
                </div>
                <div class="left" v-else>{{ ' ' }}</div>
              </div>
            </div>
          </slide>
          <slide v-if="game.isAll">
            <div :class="'game-main look_all_div' + (looking ? ' looking' : '')" :style="config.gameMainStyle" ref="gameMain">
              <div>
                <div class="game-main-image" @click="toPlay(game)" style="opacity: 0.3">
                  <img :src="game.allImg || allImg" />
                </div>
                <div @click="toPlay(game)" class="look_all" @mouseover="look_1()" @mouseleave="look_2()">
                  {{ t('look_all') }}
                </div>
                <div style="clear: both"></div>
                <div class="game-main-bottom" v-if="game.showBottom">
                  <div class="left" style="line-height: 1.2rem">{{ ' ' }}</div>
                </div>
                <div style="clear: both"></div>
              </div>
            </div>
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<script setup>
import allImg from '../../assets/images/home/home_all.png';
import { useI18n } from 'vue-i18n';
import { ref, onMounted, watchEffect, onUnmounted, defineProps } from 'vue';
import { favoriteGameService, unfavoriteGameService } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';
import { useAppStore } from '@/store/store';
const appStore = useAppStore();
const { t } = useI18n();

const props = defineProps({
  game: {},
  gameList: {
    type: Array,
    default: () => []
  },
  menuChangeIndex: {
    type: Number,
    default: 0
  }
});

const carouselRef = ref(null);
const setupSize2 = ref(7);
const pageNumber = ref(1);
const allSize2 = ref(0);
const looking = ref(false);
const look_1 = () => {
  looking.value = true;
};
const look_2 = () => {
  looking.value = false;
};

const setupSizes = (setupSize, allSize) => {
  setupSize2.value = setupSize;
  allSize2.value = allSize;
};
const goToNextSlide = () => {
  console.log(setupSize2.value);
  pageNumber.value = pageNumber.value + setupSize2.value >= allSize2.value ? allSize2.value : pageNumber.value + setupSize2.value;
  console.log(pageNumber.value);
};

const goToPrevSlide = () => {
  pageNumber.value = pageNumber.value - setupSize2.value < 1 ? 1 : pageNumber.value - setupSize2.value;
  bodyWidth2.value = myBody.value.offsetWidth;
};
const myBody = ref('myBody');
let bodyWidth2 = ref(0);
const widthChange = () => {
  if (myBody.value && myBody.value.style) {
    bodyWidth2.value = myBody.value.offsetWidth;
    if (bodyWidth2.value == 0) {
      setTimeout(widthChange, 5);
    } else {
      if (window.innerWidth <= 780) {
        bodyWidth2.value = bodyWidth2.value - 16;
      }
    }
  } else {
    setTimeout(widthChange, 5);
  }
};
watchEffect(() => {
  if (props.menuChangeIndex > 0) {
    widthChange();
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', widthChange);
});
onMounted(() => {
  widthChange();
  window.addEventListener('resize', widthChange);
});
// const mouseLeave = (myGame) => {};
widthChange();

const favoriteGam = (game) => {
  if (game.is_favorite) {
    console.log('游戏已收藏，执行取消收藏');
    unfavoriteGameService({ game_uid: game.game_uid })
      .then((response) => {
        toast(t('un_favorite_successful'), { autoClose: 2000, type: 'success' });
        game.is_favorite = !game.is_favorite;
        game.favorite_count = response.data.total_count;
      })
      .catch((error) => {
        const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
        let errorMsg = msg.length > 0 ? msg[0] : error.message;
        if (error.response.status === 500) {
          errorMsg = error.message;
        }
        toast(errorMsg, { autoClose: 2000, type: 'error' });
      });
  } else {
    console.log('游戏未收藏，执行收藏');
    favoriteGameService({ game_uid: game.game_uid })
      .then((response) => {
        toast(t('favorite_successful'), { autoClose: 2000, type: 'success' });
        game.is_favorite = !game.is_favorite;
        game.favorite_count = response.data.total_count;
      })
      .catch((error) => {
        const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
        let errorMsg = msg.length > 0 ? msg[0] : error.message;
        if (error.response.status === 500) {
          errorMsg = error.message;
        }
        toast(errorMsg, { autoClose: 2000, type: 'error' });
      });
  }
};
</script>
<script>
import serverConfig from '@/assets/network/serverConfig';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { setGameInfo, gameResize, gameListBreakPx } from '@/assets/tools/CommonTool';

export default {
  name: 'App',
  watch: {
    setupSize: function () {
      localStorage.setItem(this.game.gameCode + '_setupSize', this.setupSize);
    },
    gameList: function (newVal) {
      this.pageGameList = newVal;
      this.allSize = this.gameList.length;
      const that = this;
      if (that.gameList.length % 22 !== 0) {
        that.hasNextFlag = false;
      }
    }
  },
  components: {
    // myI18n
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
    if (this.initT) {
      clearInterval(this.initT);
    }
  },
  data() {
    return {
      bodyWidth: 1130,
      margin_left_total: 0,
      min_margin_left_total: 0,
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 7,
      hasNextFlag: true,
      initFinish: false,
      initT: null,
      topClass: 'web',
      margin_left: 13.5,
      action: 0,
      config: {
        breakPx: 1199,
        setupSize: 7,
        style: 'grid-template-columns: repeat(7, 1fr);gap: 30px 11px;display:grid;',
        gameMainStyle: 'padding-right:10px;',
        margin: 10
      }
    };
  },
  created() {
    let menuStr = localStorage.getItem('showMenu');
    let showMenu = false;
    if (menuStr) {
      showMenu = menuStr === 'true';
    } else {
      showMenu = true;
    }

    this.topClass = this.$isMobile() ? 'phone' : 'web';
    let sub308 = 308;
    if (this.topClass == 'phone') {
      sub308 = 308;
    } else if (window.innerWidth <= 780) {
      this.topClass = 'phone';
    } else if (window.innerWidth <= serverConfig.breakPx[0]) {
      this.topClass = 'web web-960';
      sub308 = 140;
    }
    if (!showMenu) {
      sub308 = 140;
    }
    let max_width = window.innerWidth;
    if (this.topClass == 'phone') {
      max_width = window.innerWidth - sub308;
    } else {
      max_width = window.innerWidth - 16;
    }
    this.bodyWidth = max_width;
    this.allSize = this.gameList.length;
    localStorage.setItem(this.game.gameCode + '_setupSize', this.setupSize);
    this.config = gameResize(gameListBreakPx);
    this.setupSize = this.config.setupSize;
  },
  mounted() {
    // this.init()
    const that = this;
    that.init();
    window.addEventListener('resize', this.resize);

    setTimeout(this.resize, 200);
  },
  methods: {
    toPlay(game) {
      if (game.isAll && game.allUrl) {
        this.$router.push(game.allUrl);
        return;
      }
      if (this.game && this.game.gameCode && this.game.gameCode == 'game_support') {
        if (this.$route.path == this.game.toUrl) {
          const query = { ...this.$route.query, providerName: game.name };
          this.$router.replace({ query });
          this.$emit('reload', game.name);
          return;
        }
        this.$router.push(this.game.toUrl + '?providerName=' + game.name);
        return;
      }
      if (!game.game_uid) {
        return;
      }
      setGameInfo(JSON.stringify(game));
      if (this.$route.path == '/gameDetail') {
        window.location.reload();
      } else {
        this.$router.push('/gameDetail');
      }
    },
    init() {
      if (this.gameList.length % 22 !== 0) {
        this.hasNextFlag = false;
      }
      this.topClass = this.$isMobile() ? 'phone' : 'web';
      if (this.topClass == 'phone') {
        this.setupSize = 3;
      } else if (window.innerWidth <= serverConfig.breakPx[1]) {
        this.setupSize = 3;
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.setupSize = 5;
      } else {
        if (this.game.gameCode == 'game_support') {
          this.setupSize = 5;
        } else {
          this.setupSize = 7;
        }
      }
      this.pageGameList = this.gameList;
      // this.isPhone = this.$isMobile()

      // this.$nextTick(()=>{
      // })
      this.resize();
      let menuStr = localStorage.getItem('showMenu');
      let showMenu = false;
      if (menuStr) {
        showMenu = menuStr === 'true';
      } else {
        showMenu = true;
      }

      this.topClass = this.$isMobile() ? 'phone' : 'web';
      let sub308 = 308;
      if (this.topClass == 'phone') {
        // this.changeLoginPageDiv()
      } else if (window.innerWidth <= serverConfig.breakPx[1]) {
        this.topClass = 'phone';
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.topClass = 'web web-960';
        sub308 = 140;
      }
      if (!showMenu) {
        sub308 = 140;
      }
      // this.changeLoginPageDiv()
      if (this.topClass == 'phone') {
        let divs2 = document.getElementsByClassName('body');
        if (divs2 && divs2.length > 0) {
          for (let i = 0; i < divs2.length; i++) {
            divs2[i].style = '';
          }
        }
        let divs3 = document.getElementsByClassName('body2');
        if (divs3 && divs3.length > 0) {
          for (let i = 0; i < divs3.length; i++) {
            divs3[i].style = '';
          }
        }
        return;
      }

      let divs2 = document.getElementsByClassName('body');
      let bodyStyle = '';
      let max_width = window.innerWidth - sub308;
      if (max_width > serverConfig.breakPx[0]) {
        max_width = serverConfig.breakPx[0];
      }
      if (max_width < serverConfig.breakPx[1]) {
        max_width = serverConfig.breakPx[1] - 64;
      }
      bodyStyle = 'max-width:' + max_width + 'px;width:' + max_width + 'px;';
      if (divs2 && divs2.length > 0) {
        for (let i = 0; i < divs2.length; i++) {
          divs2[i].style = bodyStyle;
        }
      }
    },
    resize() {
      this.topClass = this.$isMobile() ? 'phone' : 'web';
      if (this.topClass == 'phone') {
        this.setupSize = 3;
        this.margin_left = 33.3;
      } else if (window.innerWidth <= serverConfig.breakPx[1]) {
        this.setupSize = 3;
        this.topClass = 'phone';
        this.margin_left = 33.3;
      } else if (window.innerWidth <= serverConfig.breakPx[0]) {
        this.setupSize = 5;
        this.topClass = 'web web-960';
        this.margin_left = 18;
      } else {
        this.margin_left = 13.5;
        if (this.game.gameCode == 'game_support') {
          this.setupSize = 5;
        } else {
          this.setupSize = 7;
        }
      }

      this.config = gameResize(gameListBreakPx);
      this.setupSize = this.config.setupSize;
    },
    changeGameIndex(action) {
      let gameIndex = this.gameIndex;
      if (action == 1) {
        this.action = action;
        gameIndex++;
        if (this.hasNextFlag && this.allSize < this.setupSize * (gameIndex + 1) + 2) {
          this.$emit('next');
          this.gameIndex = gameIndex;
          return;
        }
      } else if (action == -1) {
        this.action = action;
        if (gameIndex > 0) {
          gameIndex--;
        }
      }
      this.gameIndex = gameIndex;
      this.pageGameList = this.gameList;
      this.margin_left_total = this.setupSize * this.gameIndex;
      let min = this.setupSize * (this.gameIndex + this.action);
      this.min_margin_left_total = min > 0 ? min : 0;
    }
  }
};
</script>
<style scoped>
.web .game-list-content .games-list .carousel__slide .game-main {
  width: calc(100%);
  padding-right: 1rem;
  margin-right: 0;
  padding-top: 0.5rem;
}
.web .carousel__slide {
  /* width: 13.5% !important;*/
}

.web .game-list-content .games-list.game_support .carousel__slide {
  /* width: 18% !important;*/
  padding-right: 0.5%;
  padding-top: 0.5rem;
}
.web.web-960 .carousel__slide {
  /* width: 17.5% !important;*/
}
.phone .game-list-content .games-list .game-main {
  width: 95% !important;
  max-width: 95% !important;
  padding-right: 5%;
  margin-right: 0 !important;
}
.phone .carousel__slide {
  margin-right: 0 !important;
}
.look_all {
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1399;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1rem;
}
::v-deep .looking img {
  cursor: pointer;
  will-change: transform;
  transition: 0.3s;
}
.looking .look_all {
  top: -0.5rem;
  cursor: pointer;
  transition: 0.3s;
}
.game-list-content .games-list .game-main.looking .game-main-image img {
  top: -0.5rem;
  position: relative;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s;
}
.game-list-content .games-list .game-main .game-main-bottom {
  height: auto;
}
.carousel__slide {
  transition: all 20ms ease 0.02s;
}

.left {
  align-items: center;
}
.text {
  padding: 0 0.3rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--game-list-text-color);
}

.game-main {
  scroll-snap-align: start;
  will-change: opacity;
  transition: all 0.3s;
}
.game-main.faded {
  opacity: 0.2;
  cursor: pointer;
}

.dot {
  width: 0.415rem;
  height: 0.415rem;
  margin-left: 2px;
  background-color: var(--positive-colors);
  border-radius: 50%;
  animation: blink 1s infinite ease-out;
  margin-left: 2px;
}

.no {
  width: 0.415rem;
  height: 0.415rem;
  background-color: var(--no-positive-colors);
  border-radius: 50%;
  animation: blink2 1s infinite ease-out;
  margin-left: 2px;
}
@keyframes blink {
  0% {
    box-shadow: 0 0 var(--positive-colors-box);
  }
  30% {
    box-shadow: 0 0 2px 2px var(--positive-colors-box);
  }
  70% {
    box-shadow: 0 0 2px 2px var(--positive-colors-box);
  }
  100% {
    box-shadow: 0 0 var(--positive-colors-box);
  }
}
@keyframes blink2 {
  0% {
    box-shadow: 0 0 var(--no-positive-colors-box);
  }
  30% {
    box-shadow: 0 0 2px 2px var(--no-positive-colors-box);
  }
  70% {
    box-shadow: 0 0 2px 2px var(--no-positive-colors-box);
  }
  100% {
    box-shadow: 0 0 var(--no-positive-colors-box);
  }
}
.events_none {
  pointer-events: none;
}
</style>
