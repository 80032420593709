<script setup>
import { defineProps } from 'vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import { Vue3Lottie } from 'vue3-lottie';
import jiazai from '@/assets/animation/expott_jiazai.json';

const props = defineProps({
  containerHeight: {
    type: String,
    default: '30rem'
  },
  containerHeightMobile: {
    type: String,
    default: '15rem'
  },
  width: {
    type: String,
    default: '12rem'
  },
  height: {
    type: String,
    default: '12rem'
  },
  widthMobile: {
    type: String,
    default: '8rem'
  },
  heightMobile: {
    type: String,
    default: '8rem'
  },
  animationData: {
    type: Object,
    default: jiazai
  },
  show: Boolean
});

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);

const containerStyles = {
  height: props.containerHeight
};
const containerMobileStyles = {
  height: props.containerHeightMobile
};
const styles = {
  width: props.width,
  height: props.height
};
const stylesMobile = {
  width: props.widthMobile,
  height: props.heightMobile
};
const animationData = {
  data: props.animationData
};
</script>

<template>
  <div class="container" :style="matchSM ? containerMobileStyles : containerStyles" v-if="show">
    <vue3-lottie :animationData="animationData.data" :width="matchSM ? stylesMobile.width : styles.width" :height="matchSM ? stylesMobile.height : styles.height" class="animation"></vue3-lottie>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 30rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.animation {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
