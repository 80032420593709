<template>
  <div ref="wodeBanner" class="main-content" v-if="bannerList.length > 0">
    <div class="game-list-content">
      <div class="game-title">
        <div class="game-title-name">
          <img style="transform: translateX(0)" src="@/assets/images/new-version/icon-banner-title.svg" />
          <div style="transform: translateX(0); padding-left: 5px">{{ t('Explore Wingame').toUpperCase() }}</div>
        </div>
        <div class="game-title-bar">
          <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="home-prev" @click="clickPrev">
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>
          <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="clickNext">
            <circle cx="8" cy="8" r="8" fill="var(--btn-cb-1-bg-color)" />
            <path d="M6.8001 11.1998L11.6001 7.9998L6.8001 4.7998" fill="var(--btn-cb-1-bg-path-color)" />
          </svg>
        </div>
      </div>
      <div :class="'games-list'">
        <carousel ref="carousel" :itemsToShow="itemsToShow" :itemsToScroll="1" snapAlign="start">
        <!-- <carousel ref="carousel" v-model="pageNumber" :items-to-show="itemsToShow" :itemsToScroll="itemsToShow" :pagination="true" :autoplay="false" :snapAlign="'start'"> -->
          <slide v-for="(banner, index) in bannerList" :key="'web_' + index">
            <div class="home-banner" @click="bannerClick(banner, index)">
              <div class="banner-bg">
                <img :src="banner.image_url" />
              </div>
              <div class="banner-text-div">
                <div class="text-left">
                  <div class="banner-type">
                    <div>{{ banner.desc }}</div>
                  </div>
                  <div class="banner-title omit-multi" style="--clamp-num: 2">{{ banner.title }}</div>
                  <div class="banner-desc omit-multi" style="--clamp-num: 4">{{ banner.sub_title }}</div>
                </div>
                <div class="banner-btn" v-if="banner.switch">
                  <div>{{ banner.button_text }}</div>
                </div>
              </div>
            </div>
          </slide>
          <!-- <slide v-if="itemsToShow > bannerList.length"> </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template> -->
        </carousel>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
// import { ref, watchEffect } from 'vue';
// import { gameResize, gameListBreakPx } from '@/assets/tools/CommonTool';
const { t } = useI18n();
// // const pageNumber = ref(0);
// const wodeBanner = ref(null)
// watchEffect(() => {
//   if (wodeBanner.value) {
//     const newWidth = wodeBanner.value.offsetWidth;
//     console.log('Div宽度变化为：', newWidth);
//   }
// });
</script>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import banner1 from '../../assets/images/home/home_banner_ico1.png';
import banner2 from '../../assets/images/home/home_banner_ico2.png';
import banner3 from '../../assets/images/home/home_banner_ico3.png';
import banner4 from '../../assets/images/home/home_banner_ico2.png';
import { getBannerConfig } from '@/assets/network/service/home';
// import serverConfig from '@/assets/network/serverConfig';
export default {
  name: 'App',
  props: {
    showMenu: {
      type: Boolean,
      required: true
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      isPhone: false,
      itemsToShow: 3,
      settings: {
        itemsToShow: 3,
        itemsToScroll: 1
      },
      bannerList: [
        {
          type: '促销活动',
          title: '百万美元',
          desc: '琴晚奖金，不容错过阅读更多精彩',
          image: banner1,
          pageUrl: '',
          btnText: ''
        },
        {
          type: '促销活动',
          title: '奖金瓜分',
          desc: '琴晚奖金，不容错过阅读更多精彩',
          image: banner2,
          pageUrl: '',
          btnText: ''
        },
        {
          type: '促销活动',
          title: '￥12000美金',
          desc: '琴晚奖金，不容错过阅读更多精彩',
          image: banner3,
          pageUrl: '',
          btnText: ''
        },
        {
          type: '促销活动',
          title: '百万美元奖金瓜分￥12000美金4',
          desc: '琴晚奖金，不容错过阅读更多精彩',
          image: banner4,
          pageUrl: '',
          btnText: ''
        }
      ]
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  created() {
    this.bannerList = [];
    this.isPhone = this.$isMobile();
    this.$emit('changeBanner', true);
    this.getBannerList();
    window.addEventListener('resize', this.resize);

  },
  updated() {
    this.resize();
  },
  mounted() {
    this.resize();
  },
  methods: {
    clickPrev() {
      this.$refs.carousel.prev();
    },
    clickNext() {
      this.$refs.carousel.next();
    },
    bannerClick(banner, index) {
      console.log('index:' + index);
      if (banner.link_type == 'External') {
        if (banner.link) {
          if (banner.link.startsWith('https://') || banner.link.startsWith('http://')) {
            window.open(banner.link, '_blank');
          } else {
            window.open('https://' + banner.link, '_blank');
          }
        }
      } else {
        this.$router.push(banner.link);
      }
    },
    getBannerList() {
      getBannerConfig()
        .then((response) => {
          this.$emit('changeBanner', false);
          let bannerList = response.data.banner_config.items;
          // bannerList = bannerList.filter((item) => {
          //   return item.switch;
          // });
          this.bannerList = bannerList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resize() {
      if (window.innerWidth >= 1280) {
        this.itemsToShow = 3;
        // if (this.$refs.carousel) {
        //   this.$refs.carousel.updateSlideWidth()
        // }
        this.isPhone = false;
        return;
      }
      else if (window.innerWidth < 1280 && window.innerWidth > 780) {
        this.itemsToShow = 2;
        // if (this.$refs.carousel) {
        //   this.$refs.carousel.updateSlideWidth()
        // }
        this.isPhone = true;
        return;
      }
      else if (window.innerWidth <= 780) {
        this.itemsToShow = 1;
        // if (this.$refs.carousel) {
        //   this.$refs.carousel.updateSlideWidth()
        // }
        this.isPhone = true;
        return;
      }
      this.isPhone = this.$isMobile();
    }
  }
};
</script>
<style></style>
<style scoped>
.web .home-banner {
  width: 100%;
  min-height: 200px;
}
.phone .carousel__slide {
  /* width: 97% !important; */
  /* margin-right: 1.5%; */
}
.web.web-960 .home-banner {
  /* max-width: 95%; */
  /* height: 12rem; */
}
.phone .home-banner {
  /* margin-right: 0; */
  /* min-height: 13.75rem;
  height: 13.75rem; */
}
.home-banner {
  width: 100%;
  background-color: var(--home-banner-bg-color);
  border-radius: 0.5rem;
  margin: 0 5px;
  cursor: pointer;
  height: 200px;
  position: relative;
  overflow: hidden;
  .banner-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
    }
  }

  .banner-text-div {
    position: relative;
    width: 70%;
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    .text-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
.home-banner:hover {
  opacity: 0.8;
}
.home-banner .banner-left {
  text-align: left;
  width: 55%;
  margin: 1.125rem 0 1.125rem 1.125rem;
}
.home-banner .banner-left div {
  margin-right: 1rem;
}
.home-banner .banner-right {
  margin: 1.125rem 1.125rem 1.125rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  background-color: var(--home-banner-bg2-color);
  border-radius: 1.125rem;
}
.home-banner .banner-title {
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  color: #ffffff;
  white-space: break-spaces;
  text-align: left;
}
.home-banner .banner-type {
  display: flex;
  font-weight: 700;
  font-size: 1rem;
}
.home-banner .banner-type div {
  background-color: var(--color-8ea3c3);
  border-radius: 0.125rem;
  color: #0c131d;
  line-height: 1.5rem;
  padding: 0 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-banner .banner-desc {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  white-space: break-spaces;
  text-align: left;
}
.home-banner .banner-btn {
  line-height: 1.6rem;
  display: flex;
  bottom: 1.125rem;
}
.home-banner .banner-btn div {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1.8rem;
  font-size: 0.9rem;
  padding: 0 1.6rem;
  cursor: pointer;
  font-weight: 700;
}
::v-deep .carousel__prev--disabled {
  opacity: 0 !important;
  cursor: default;
}
::v-deep .carousel__next--disabled {
  opacity: 0 !important;
  cursor: default;
}
/* .carousel__slide.carousel__slide--visible.carousel__slide--next .home-banner {
  margin-right: 0;
} */
</style>
