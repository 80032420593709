<script setup>
// 导入
import { ref, computed, watchEffect, onBeforeMount, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import { useRoute } from 'vue-router';
import { getPromotionDetail, getPromotionRankService, getPromotionGames } from '@/assets/network/service/home';
import ButtonItem from './module/button-item.vue';
import { formatTimeStamp, getCookieAccessToken, isTimeInRange } from '@/assets/tools/CommonTool';
import { joinPromotion, receivePromotion } from '@/assets/network/service/home';
import BigNumber from 'bignumber.js';
import rank1 from '@/assets/images/common/rank-1.svg';
import rank2 from '@/assets/images/common/rank-2.svg';
import rank3 from '@/assets/images/common/rank-3.svg';
import promotionPositionIcon from '@/assets/images/common/promotion-position.svg';
import promotionWageredIcon from '@/assets/images/common/promotion-wagered.svg';
import useWalletStore from '@/store/wallet';
import { getHeaderImg } from '@/assets/tools/avatar.js';
import DetailDesc from './module/detail-desc.vue';
import GameList from './module/game-list.vue';
import { setGameInfo } from '@/assets/tools/CommonTool';

const { t } = useI18n();
const route = useRoute();
const walletStore = useWalletStore();
const accessToken = getCookieAccessToken();
const countdownTime = ref('');
let countdownInterval;
const router = useRouter();
// 传参
// 属性
const detailData = ref(null);
const promotionType = ref('');
const activeNames = ref([]);
const gameList = ref([]);
const rankInfo = ref(null);
// 计算属性
const btnText = computed(() => {
  return getBtnText();
});
watchEffect(() => {
  if (!detailData.value) {
    return;
  }
  if (detailData.value.promotion_type == 'first_deposit' || detailData.value.promotion_type == 'second_deposit' || detailData.value.promotion_type == 'three_deposit' || detailData.value.promotion_type == 'total_deposit' || detailData.value.promotion_type == 'single_deposit' || detailData.value.promotion_type == 'deposit') {
    promotionType.value = 'deposit';
    activeNames.value = ['1'];
  } else if (detailData.value.type == 3 && detailData.value.pre_condition && (detailData.value.pre_condition.game_type.length > 0 || detailData.value.pre_condition.game_provider.length > 0 || detailData.value.pre_condition.game_uid.length > 0)) {
    promotionType.value = 'game';
    activeNames.value = [];
  } else {
    promotionType.value = '';
    activeNames.value = ['1'];
  }
});
const startCountdown = (remainingTime) => {
  const updateCountdown = () => {
    if (remainingTime <= 0) {
      clearInterval(countdownInterval);
      countdownTime.value = '00:00:00:00';
      getPromotionRank();
      return;
    }

    const days = Math.floor(remainingTime / (60 * 60 * 24));
    const hours = Math.floor((remainingTime % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
    const seconds = remainingTime % 60;

    countdownTime.value = `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    remainingTime -= 1;
  };

  updateCountdown();
  if (countdownInterval) {
    clearInterval(countdownInterval);
  }
  countdownInterval = setInterval(updateCountdown, 1000);
};
// 生命周期
onBeforeMount(async () => {
  getPromotionDetailData();
});
onUnmounted(() => {
  if (countdownInterval) {
    clearInterval(countdownInterval);
  }
});
onUnmounted(() => {
  if (countdownInterval) {
    clearInterval(countdownInterval);
  }
});
// 方法
const getPromotionDetailData = async () => {
  const res = await getPromotionDetail({ id: route.query.id });
  if (res.data.code == 200) {
    detailData.value = res.data.data;
    if (detailData.value.type == 3 && detailData.value.pre_condition && (detailData.value.pre_condition.game_type.length > 0 || detailData.value.pre_condition.game_provider.length > 0 || detailData.value.pre_condition.game_uid.length > 0)) {
      const par = {
        start: 0,
        end: -1,
        game_uids: detailData.value.pre_condition.game_uid,
        game_provider: detailData.value.pre_condition.game_provider,
        game_type: detailData.value.pre_condition.game_type
      };
      const games = await getPromotionGames(par);
      gameList.value = games.data.data || [];
    }
  }
  if (detailData.value.type == 4) {
    getPromotionRank();
  }
};
const getPromotionRank = async () => {
  try {
    const response = await getPromotionRankService({ promotion_id: route.query.id });
    rankInfo.value = response.data.data;

    if (rankInfo.value.end_time_diff !== undefined && rankInfo.value.end_time_diff !== null && rankInfo.value.end_time_diff > 0) {
      startCountdown(rankInfo.value.end_time_diff);
    }
  } catch (error) {
    console.log(error);
  }
};

const getPromotionStatus = (item) => {
  // 1 进行中 2 已结束 3 加入 4 领奖
  const startTime = formatTimeStamp(item.start_time);
  const endTime = formatTimeStamp(item.end_time);
  const currentTime = formatTimeStamp();
  const isProgress = isTimeInRange(startTime, endTime, currentTime);
  if (item.is_prize) {
    return 4;
  } else if (item.pre_condition?.is_join) {
    const joinStartTime = formatTimeStamp(item.pre_condition.join_start_time);
    const joinEndTime = formatTimeStamp(item.pre_condition.join_end_time);
    const isJoinTime = isTimeInRange(joinStartTime, joinEndTime, currentTime);
    if (item.is_joined) {
      if (isJoinTime) {
        return 1;
      } else {
        if (isProgress) {
          return 1;
        } else {
          return 2;
        }
      }
    } else {
      if (isJoinTime) {
        return 3;
      } else {
        if (isProgress) {
          return 1;
        } else {
          return 2;
        }
      }
    }
  } else if (isProgress) {
    return 1;
  } else {
    return 2;
  }
};

const getSymbolIconUrl = computed(() => (symbol) => {
  if (walletStore.currencyTokens.length === 0) return '';
  const token = walletStore.currencyTokens.find((token) => token.symbol === symbol);
  return token ? token.icon_url : '';
});

const getBtnText = () => {
  const status = getPromotionStatus(detailData.value);
  if (status == 3) {
    return t('Join_In').toLocaleUpperCase();
  } else if (status == 4) {
    return t('Claim_It').toLocaleUpperCase();
  } else {
    return '';
  }
};

const clickBtn = async () => {
  const status = getPromotionStatus(detailData.value);
  if (status == 3) {
    try {
      const res = await joinPromotion({ id: detailData.value.id });
      if (res.data.code == 200) {
        toast(t('success'), { autoClose: 2000, type: 'success' });
        getPromotionDetailData();
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    } catch (error) {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  } else if (status == 4) {
    try {
      const res = await receivePromotion({ promotion_id: detailData.value.id });
      if (res.data.code == 200) {
        toast(t('success'), { autoClose: 2000, type: 'success' });
        getPromotionDetailData();
      } else {
        toast(res.data.msg, { autoClose: 2000, type: 'error' });
      }
    } catch (error) {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  }
};
const toPlay = (game) => {
  setGameInfo(JSON.stringify(game));
  router.push('/gameDetail');
};
const toRanPlay = () => {
  const len = gameList.value.length;
  const ran = Math.floor(Math.random() * len);
  const temp = gameList.value[ran];
  toPlay(temp);
};
</script>

<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <myHeader></myHeader>
      <div class="main-content">
        <div class="body">
          <div v-if="detailData" class="pt-[20px] text-[#8ea3c3] leading-normal md:w-[650px] w-full text-left">
            <div class="flex mb-[20px]">
              <div class="bg-[#1C243F] rounded-[6px] px-[16px] py-[10px] text-[14px] font-[700] leading-none flex">
                <div class="text-[#5B7BAB]">{{ $t('promotion').toLocaleUpperCase() }}</div>
                <div class="text-[#5B7BAB] mx-[5px] text-[15px]">></div>
                <div class="text-[#FFFFFF]">{{ $t('details').toLocaleUpperCase() }}</div>
              </div>
            </div>
            <img class="w-full block" :src="detailData.image_url2" />
            <div v-if="rankInfo" class="w-full my-[20px]">
              <div v-if="rankInfo.total_prize && BigNumber(rankInfo.total_prize).isGreaterThan(0)" class="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center gap-[16px]">
                <div class="w-full md:w-auto flex py-[8px] justify-between md:justify-start items-center gap-[57px]" style="background: rgba(0, 0, 0, 0.4)">
                  <div class="whitespace-pre-line ml-[16px] text-[#FFF] text-[16px] font-[700] leading-none uppercase">{{ $t('total_prize') }}</div>
                  <div class="mr-[16px] text-[#FFB355] text-[24px] font-[700]">
                    {{ `${BigNumber(rankInfo.total_prize).toFormat(0, BigNumber.ROUND_DOWN)} ${rankInfo.total_prize_symbol}` }}
                  </div>
                </div>
                <button v-if="btnText != ''" class="w-full md:w-auto flex h-[48px] min-h-[48px] p-[11px_24px_12px_24px] border-none cursor-pointer justify-center items-center text-[#FFF] text-[14px] font-[700] uppercase rounded-[8px] bg-[#1897FB] shadow-[0_0_10px_0_rgba(0,10,255,0.60)_inset,0_-3px_0_0_rgba(74,78,163,0.55)_inset,0_0_10px_0_#0057FF]" @click="clickBtn">
                  {{ btnText }}
                </button>
              </div>
              <div v-if="rankInfo.is_start || rankInfo.is_end" class="w-full">
                <div class="mt-[16px] md:mt-[24px] flex justify-between items-center gap-[8px]">
                  <div class="flex items-center gap-[8px]">
                    <img :src="rank1" />
                    <div class="text-[#FFF] text-[12px] font-[700] uppercase">{{ $t('ranking') }}</div>
                  </div>
                  <div class="text-[#5B7BAB] text-[12px] text-right font-[700] uppercase">
                    {{ rankInfo.end_time_diff === 0 ? $t('backing_ended') : `${$t('backing_end_in')} ${countdownTime}` }}
                  </div>
                </div>
                <div class="overflow-auto">
                  <div class="flex flex-col gap-[4px]">
                    <div class="min-w-full mt-[8px] md:mt-[10px] bg-[#191F33] h-[40px] flex justify-between items-center text-[#80A4DB] text-[10px] font-[700] uppercase">
                      <div class="min-w-[112px] h-full px-[8px] bg-[#191F33] flex items-center">{{ '#' }}</div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center">{{ $t('player') }}</div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center">{{ $t('wagered') }}</div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center justify-end">{{ $t('prize') }}</div>
                    </div>
                    <div class="bg-[#191F33] h-[40px] flex justify-between" v-for="(item, index) in rankInfo?.rank_info" :key="index">
                      <div class="min-w-[112px] h-full px-[8px] bg-[#191F33] flex items-center">
                        <img v-if="index < 3" :src="index === 0 ? rank1 : index === 1 ? rank2 : rank3" class="ml-[4px]" />
                        <span v-if="index > 2" class="ml-[4px] text-[#FFF] text-[13px] font-[400]">{{ `${index + 1}th` }}</span>
                      </div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center text-[#FFF] text-[13px] font-[400]">
                        {{ item.user_name }}
                      </div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center gap-[8px] text-[#AAC4ED] text-[14px] font-[400]">
                        {{ BigNumber(item.bet_amount).toFormat(2, BigNumber.ROUND_DOWN) }}
                        <img :src="getSymbolIconUrl('USDT')" class="w-[16px] h-[16px]" />
                      </div>
                      <div class="min-w-[158px] h-full px-[8px] bg-[#191F33] flex items-center justify-end gap-[8px] text-[#AAC4ED] text-[14px] font-[400]">
                        {{ BigNumber(item.prize_amount).toFormat(2, BigNumber.ROUND_DOWN) }}
                        <img :src="getSymbolIconUrl(item.prize_symbol)" class="w-[16px] h-[16px]" />
                      </div>
                    </div>
                    <div v-if="rankInfo?.rank_info?.length === 0" class="bg-[#191F33] w-full h-[150px] flex flex-col justify-center items-center gap-[18px]">
                      <img src="@/assets/images/home/web_logo.svg" />
                      <div class="no-data">{{ t('no_data') }}</div>
                    </div>
                  </div>
                </div>
                <div class="text-[#5B7BAB] text-[14px] font-[400] mt-[8px]">{{ $t('final_rewards__rewards') }}</div>
                <div v-if="accessToken && accessToken.length > 0" class="mt-[16px] md:mt-[24px] w-full">
                  <div class="bg-[#191F33] rounded-[8px] py-[16px] px-[8px] md:px-[16px] flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center gap-[25px] md:gap-[8px]">
                    <div class="flex items-center gap-[8px]">
                      <img :src="getHeaderImg((rankInfo && rankInfo.my_info && rankInfo.my_info.my_profile_image_url) ?? '')" class="m-[5px] w-[30px] h-[30px]" />
                      <div class="text-[#FFF] text-[14px] font-[400]">{{ rankInfo?.my_info?.my_user_name || '' }}</div>
                    </div>
                    <div class="w-full md:w-auto flex items-center justify-between md:justify-start gap-[16px]">
                      <div class="flex items-center gap-[8px]">
                        <img :src="promotionPositionIcon" class="w-[40px] h-[40px]" />
                        <div class="flex flex-col gap-[2px]">
                          <div class="text-[#AAC4ED] text-[12px] font-[700] uppercase">{{ $t('my_position') }}</div>
                          <div class="text-[#FFF] text-[14px] font-[400]">
                            {{ rankInfo?.my_info ? (rankInfo.my_info.rank_num === 0 ? '--' : `${rankInfo.my_info.rank_num}th`) : '--' }}
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center gap-[8px]">
                        <img :src="promotionWageredIcon" class="w-[40px] h-[40px]" />
                        <div class="flex flex-col gap-[2px]">
                          <div class="text-[#AAC4ED] text-[12px] font-[700] uppercase">{{ $t('wager') }}</div>
                          <div class="text-[#FFF] text-[14px] font-[400]">{{ BigNumber(rankInfo?.my_info?.my_bet_amount || 0).toFormat(2, BigNumber.ROUND_DOWN) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bg-[#2D3759] w-full h-[24px] flex items-center justify-center text-[#FFF] text-[14px] font-[400]">
                    {{ rankInfo.my_info.rank_num === 1 ? (rankInfo.is_end ? $t('you_are__winner') : $t('you_are_1st')) : `${$t('wager')} ${BigNumber(rankInfo?.my_info?.reach_bet_amount || 0).toFormat(2, BigNumber.ROUND_DOWN)} ${$t('to_reach')}` }}
                  </div>
                </div>
              </div>
            </div>
            <DetailDesc v-else class="mt-[20px]" :promotionData="detailData" :promotionType="promotionType" :toRanPlay="toRanPlay" />
            <div class="mt-[20px] bg-[#191f33] rounded-[8px] px-[15px] text-[#8ea3c3]">
              <el-collapse v-model="activeNames" accordion>
                <el-collapse-item name="1">
                  <template #title>
                    <div class="text-[14px] text-[#ffffff] font-[700]">{{ t('details').toLocaleUpperCase() }}</div>
                  </template>
                  <div>
                    <div class="my-[20px]" v-html="detailData.promotion_content"></div>
                    <div class="flex justify-center items-center">
                      <ButtonItem v-if="btnText != ''" :btnText="btnText" @click.stop="clickBtn" />
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="mt-[20px]">
              <GameList :gameList="gameList" :toPlay="toPlay" />
            </div>
          </div>
        </div>
      </div>
      <myFooter :isHomeFooter="true"></myFooter>
    </div>
  </div>
</template>

<style scoped>
::v-deep .el-collapse {
  border: 0;
}
::v-deep .el-collapse-item__header {
  background-color: transparent;
  border: 0;
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent;
  border: 0;
}
::v-deep .el-collapse-item__content {
  color: #8ea3c3;
}
::v-deep .el-collapse-item__arrow {
  font-size: 16px;
  color: #ffffff;
}
</style>
