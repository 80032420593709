<script setup>
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
  totalCount: Number,
  page: Number,
  pageSize: Number,
  allPage: Number
});
const emit = defineEmits(['pageChange']);

const pageChange = (pageIndex) => {
  if (pageIndex == 0) {
    return;
  } else if (pageIndex > props.allPage) {
    return;
  }
  emit('pageChange', pageIndex);
};
</script>

<template>
  <div class="bgContent" v-if="props.allPage > 0">
    <div><img src="../../assets/images/home/Frame 33764.svg" class="page pre" @click="pageChange(props.page - 1)" /></div>
    <span class="page_text">{{ props.page }}/{{ props.allPage }}</span>
    <div><img src="../../assets/images/home/Frame 33764.svg" class="page next" style="rotate: -90deg" @click="pageChange(props.page + 1)" /></div>
  </div>
</template>

<style scoped>
.bgContent {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.5rem;
}
.bgContent .pre {
  rotate: 90deg;
}
.bgContent .next {
  rotate: -90deg;
}
.bgContent .page_text {
  color: var(--white-color);
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0 0.3rem;
}
.bgContent .page:hover {
  opacity: 0.7;
}
</style>
