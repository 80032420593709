<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, onUnmounted, ref } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import wgTypePass from '@/components/common/wgTypeWithdrawPass.vue';
import { add_withdraw_password } from '@/assets/network/service/wallet.js';
import { toast } from 'vue3-toastify';
const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['close', 'settingSuccess']);
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  withdrawal_pin: t('withdrawal_pin'),
  w_pin_pass_first: t('w_pin_pass_first'),
  set_withdrawal_PIN: t('set_withdrawal_PIN'),
  w_pin_pass_first_note: t('w_pin_pass_first_note'),
  confirm: t('confirm'),
  the_password___inconsistent: t('the_password___inconsistent'),
  success: t('success'),
  new_withdrawal_password: t('new_withdrawal_password'),
  confirm_new_withdrawal_password: t('confirm_new_withdrawal_password')
};
const handleClick = async () => {
  if (passWord.value !== confirmPassword.value) {
    toast(textData.the_password___inconsistent, { autoClose: 2000, type: 'error' });
    return;
  }
  try {
    await add_withdraw_password({ password: passWord.value });
    toast(textData.success, { autoClose: 2000, type: 'success' });
    emit('settingSuccess');
  } catch (error) {
    console.log('setting error');
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};
const passWord = ref('');
const confirmPassword = ref('');

const passWordChange = (val) => {
  passWord.value = val;
  if (confirmPassword.value.length == 6 && passWord.value.length == 6) {
    confirming.value = false;
    handleCanNext.value = true;
  } else {
    confirming.value = false;
    handleCanNext.value = false;
  }
};
const handleCanNext = ref(false);
const confirming = ref(false);
const confirmPassWordChange = (val) => {
  confirmPassword.value = val;
  if (confirmPassword.value.length == 6 && passWord.value.length == 6) {
    confirming.value = false;
    handleCanNext.value = true;
  }
};
onUnmounted(() => {});
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title">{{ textData.withdrawal_pin }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <div class="content_main">
        <div class="tip">{{ textData.w_pin_pass_first }}</div>

        <div class="setting-type" style="margin-top: 1.125rem">{{ textData.set_withdrawal_PIN }}</div>
        <wgTypePass @onValueChange="passWordChange" :pre-id="'w-pass-setting'" :text-title="textData.new_withdrawal_password"></wgTypePass>
        <wgTypePass @onValueChange="confirmPassWordChange" :pre-id="'w-pass-setting-confirm'" :text-title="textData.confirm_new_withdrawal_password"></wgTypePass>
        <div class="tip2">{{ textData.w_pin_pass_first_note }}</div>

        <div class="bottom margin-bottom-4_6">
          <button :class="handleCanNext ? 'bottom-button' : 'bottom-button-disable'" @click="handleClick" :disabled="confirming || !handleCanNext">
            {{ confirming ? '' : textData.confirm }}
            <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
          </button>
        </div>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.125rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content_main {
  padding: 0.625rem;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: var(--green-color);
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0;
  text-align: left;
}
.tip2 {
  color: var(--pin-setting-note-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
}
.setting-type {
  color: var(--white-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
}
.bottom-button-disable {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}
.margin-bottom-4_6 {
  margin-bottom: 4.6rem;
}
.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
