<script setup>
import { reactive, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import wgTable from '@/components/common/wgTable/wgTable.vue';
import { formatNumberWithCommas } from '@/assets/tools/CommonTool';
import { getTurntableResultList } from '@/assets/network/service/turntable'
import { useAppStore } from '@/store/store';


const { t } = useI18n();
const appStore = useAppStore();
const emits = defineEmits(['close'])
const props = defineProps({
    id: {
        type: String
    },
    userId: {
        type: String
    }
})

const tableState = reactive({
    initParams: {
        id: props.id,
        user_id:  appStore?.userInfo?.user_id 
    },
    columns: [
        {
            label: t('scheduleInfo_014'),
            prop: 'user_name',
            width: 140
        },
        {
            label: t('scheduleInfo_015'),
            prop: 'activity_name'
        },
        {
            label: t('scheduleInfo_016'),
            tdSlot: 'prize',
            width: 160
        }
    ]
});

// 请求函数
const getData = async (params) => {
    // params是从组件接收的，包含分页和搜索字段。
    try {
        const res = await getTurntableResultList(params);
        // 必须要返回一个对象，包含data数组和total总数
        return {
            data: res.data.data
        };
    } catch (e) {
        return {
            data: [],
        };
    }
};

const closeHandle = () => {
    emits('close')
}




</script>

<template>
    <div class="bg-[#121829] w-[26.5rem] rounded-md p-[1.5rem] max-h-[30rem] overflow-y-scroll">
        <div class="flex justify-between ">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
                    <path
                        d="M18.37 1.81237C17.4685 1.81237 16.7373 2.53732 16.7373 3.43267C16.7373 4.13283 17.185 4.72766 17.8108 4.95537C17.3492 6.5927 16.1549 7.79475 14.3565 7.79475C12.558 7.79475 11.0942 6.30613 11.0942 4.14367V4.03679C11.7866 3.68051 12.2606 2.96486 12.2606 2.13767C12.2606 0.957305 11.2956 0 10.1059 0C10.0703 0 10.0362 0.00929422 10.0006 0.0108433C9.96495 0.00929422 9.93087 0 9.89525 0C8.70559 0 7.74054 0.957305 7.74054 2.13767C7.74054 2.96486 8.21454 3.68051 8.90696 4.03679V4.14367C8.90696 6.30613 7.43073 7.79475 5.64469 7.79475C3.85865 7.79475 2.65195 6.5927 2.19034 4.95537C2.81615 4.72921 3.26382 4.13283 3.26382 3.43267C3.26382 2.53887 2.53268 1.81237 1.63114 1.81237C0.729596 1.81237 0 2.53732 0 3.43112C0 4.11889 0.43373 4.70752 1.04405 4.94143L1.98896 11.1794C2.01994 11.3854 2.08345 11.5852 2.17795 11.7696C2.28328 11.7417 2.39326 11.7278 2.50169 11.7278C3.16003 11.7278 3.7022 12.2111 3.79204 12.84C3.84006 12.8431 3.88963 12.8462 3.93765 12.8462H16.0604C16.1084 12.8462 16.158 12.8446 16.206 12.84C16.2959 12.2126 16.8396 11.7278 17.4979 11.7278C17.6094 11.7278 17.7179 11.7432 17.8217 11.7696C17.9162 11.5837 17.9797 11.3839 18.0106 11.1794L18.9556 4.94143C19.5659 4.70752 19.9996 4.11889 19.9996 3.43112C19.9996 2.53732 19.2685 1.81082 18.3669 1.81082L18.37 1.81237ZM16.2509 13.4085H3.75022C3.6201 13.8252 3.28241 14.152 2.85797 14.2713L2.95866 15.0164C3.03456 15.5787 3.51786 16 4.09101 16H15.9133C16.4848 16 16.9697 15.5802 17.0456 15.0164L17.1463 14.2713C16.7219 14.152 16.3842 13.8267 16.254 13.4085H16.2509Z"
                        fill="white" />
                </svg>
                <span class="text-[#fff] text-[0.875rem] font-bold ml-[1rem]">{{ t('scheduleInfo_013') }}</span>
            </div>
            <div class="cursor-pointer" @click="closeHandle">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="white" />
                </svg>
            </div>
        </div>
        <div class="mt-[1.25rem]">
            <wgTable :pagination="false" :request="getData" :hideTitleBar="true" :columns="tableState.columns"
                :initParams="tableState.initParams">
                <template #prize="{ row }">
                    {{ row.symbol }} {{ formatNumberWithCommas(row.balance) }}
                </template>
            </wgTable>
        </div>
    </div>
</template>