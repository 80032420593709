<template>
  <div class="root_div" @click="providerShowChange()">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></my-header>

      <div class="main-content" style="background-color: var(--menu-item-bg-color)">
        <div class="body slots_title">
          <div class="left">
            <img src="../../assets/images/home/btn_7.svg" />
            {{ t('hot_game') }}
          </div>
          <div class="right">
            <img src="../../assets/images/home/top_ico_slots_1.png" />
          </div>
        </div>
      </div>

      <slots-list :game_type="searchParams.game_type" :page_name="searchParams.page_name" :provider-show="providerShow" :game_code="'hot_game'" :game-search="isGameSearch"></slots-list>
      <my-game-support></my-game-support>
      <bets-list @parentLogin="parentLogin"></bets-list>
      <div class="main-content">
        <my-testimonials-list :gameList="testimonialsList" :game="testimonials"></my-testimonials-list>
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
</template>
<script setup>
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import myGameSupport from '@/components/game/game_support.vue';
import betsList from '@/components/profit/betsList.vue';
import slotsList from '@/components/game/slotsList.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<script>
import icon from '@/assets/images/footer/footer-token-1.png';
import gameSupportImg from '@/assets/images/home/Frame 427320293.svg';
import testimonialsImg1 from '@/assets/images/home/money 2.png';
import testimonialsLevelImg from '@/assets/images/home/ico_b1.png';
import testimonialsImg2 from '@/assets/images/home/money 3.png';
import testimonialsImg3 from '@/assets/images/home/money 4.png';
import testimonialsImg4 from '@/assets/images/home/money 1.png';
import { getCasinoHotGamesService } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';

export default {
  name: 'App',
  props: {
    game: {},
    gameList: []
  },
  components: {
    // myI18n
  },
  data() {
    return {
      showLogin: false,
      providerShow: false,
      providerShow2: false,
      providerSearchList: [
        {
          isChecked: false,
          name: 'Pragmatic Play',
          code: 'PragmaticPlay',
          number: 0
        },
        {
          isChecked: false,
          name: 'PG Soft',
          code: 'PGSoft',
          number: 0
        }
      ],
      isGameSearch: false,
      searchResult: [],
      searchResult2: [],
      testimonialsList: [
        {
          image: testimonialsImg1,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg2,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg3,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg4,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        }
      ],
      providerList: [],
      gameSupport: {
        gameCode: 'game_support',
        gameImage: gameSupportImg,
        showBottom: false
      },
      testimonials: {
        gameCode: 'testimonials',
        showBottom: true
      },
      profitList: [
        {
          rank: 1,
          time: '2024-01-18',
          player: {
            img: icon,
            playId: 'Playerr123a231',
            email: 'Play****@gmail.com'
          },
          game: {
            name: 'Dingdong',
            img: icon
          },
          symbol: 'UDST',
          profitImg: icon,
          multiplier: 7.02,
          betAmount: 100.0,
          amount: 702.0
        }
      ],
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 0,
      indexProfit: 'me',
      searchText: '',
      searchParams: {
        game_type: '',
        start: 0,
        end: 20,
        name: '',
        page_name: 'hot'
      },
      pageIndex: 0,
      pageSize: 20,
      total: 0,
      walletList: []
    };
  },
  created() {
    this.searchResult = [];
    // this.getCasinoHotGamesService()
  },
  methods: {
    loginAfter(walletList) {
      this.walletList = walletList;
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    parentLogin() {
      this.showLogin = true;
      this.$emit('parentLogin');
    },
    providerShowChange() {
      this.providerShow = true;
      this.$nextTick(() => {
        this.providerShow = false;
      });
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    getCasinoHotGamesService() {
      getCasinoHotGamesService(this.params)
        .then((response) => {
          if (this.params.pageIndex == 0) {
            this.searchResult = response.data.data;
          } else {
            this.searchResult = this.searchResult.concat(response.data.data);
          }
          this.total = response.data.total_count;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    next() {
      this.params.pageIndex++;
      this.params.start = this.params.pageIndex * this.pageSize;
      this.params.end = this.params.start + this.pageSize;
      this.getCasinoHotGamesService();
    },
    enterSearch() {
      this.searchResult = [];
      this.params.pageIndex = 0;
      this.params.name = this.searchText;
      this.getCasinoHotGamesService();
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    }
  }
};
</script>
<style scoped></style>
