// src\react-i18next\i18n.js
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import es from './locales/es.json';
import ko from './locales/ko.json';
import pt from './locales/pt.json';
import tr from './locales/tr.json';
import sw from "./locales/sw.json";

function loadLocaleMessages() {
  const locales = [{ en: en }, { es: es }, { ko: ko }, { pt: pt }, { tr: tr }, { sw: sw }];
  const messages = {};
  locales.forEach((lang) => {
    console.log(lang);
    const key = Object.keys(lang);
    messages[key] = lang[key];
  });
  return messages;
}

function setupLanguage() {
  let resultLanguage = 'en';
  const localLanguage = localStorage.getItem('locale');
  const navigatorLanguage = navigator.language;
  // console.log('###setupLanguage localLanguage:',localLanguage);
  // console.log('###setupLanguage navigatorLanguage:',navigatorLanguage);
  if (localLanguage && localLanguage.length > 0) {
    resultLanguage = localLanguage;
  } else if (navigatorLanguage && navigatorLanguage.length > 0) {
    const languageList = ['en', 'es', 'ko', 'pt', 'tr', 'sw'];
    const filterLanguage = languageList.filter(value => navigatorLanguage.toLowerCase().includes(value));
    // console.log('###setupLanguage filterLanguage:', filterLanguage);
    if (filterLanguage.length > 0) {
      resultLanguage = filterLanguage[0];
    }
  }

  if (!(localLanguage && localLanguage.length > 0)) {
    localStorage.setItem('locale', resultLanguage);
  }
  // console.log('###setupLanguage resultLanguage:', resultLanguage);

  return resultLanguage;
}

export default createI18n({
  locale: setupLanguage(),
  // fallbackLocale: "en",
  legacy: false,
  messages: loadLocaleMessages()
});
