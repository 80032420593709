<script setup>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
// googleLogout,
import { googleAuth } from '@/assets/network/service/user';
import wgTypeInput from '@/components/common/wgTypeInput.vue';
const { t } = useI18n();
const domain = ref(window.location.hostname);
const router = useRouter();

const callback = (response) => {
  if (!response) {
    return;
  }
  // 在这里处理用户登录后的操作
  console.log('处理登录后的操作', response);
  const idToken = response.credential;
  console.log('ID 令牌', idToken);
  let params = {
    token: idToken
  };
  const appStore = useAppStore();
  if (appStore.adsname) {
    params.adsname = appStore.adsname;
  }
  if (appStore.fpReferral) {
    params.invite_code = appStore.fpReferral;
  }
  googleAuth(params)
    .then((response) => {
      console.log('本地处理登录后的操作', response);
      setCookieAccessToken(response.data.access_token);
      setCookieUserId(response.data.user.pk);
      setCookieUsername(response.data.user.username);
      setCookieEmail(response.data.user.email);
      toast(t('login_successfully'), { autoClose: 2000 });
      setCookiesGmailLogin('true');

      setTimeout(function () {
        router.push({ path: '/' });
      }, 1000);
    })
    .catch((error) => {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};
onMounted(() => {
  // googleLogout()
  callback();
});
</script>

<template>
  <div :style="'width: 100%;clear: both;margin: auto;text-align: center;display: flex;justify-content: center;' + (isDialog ? '' : 'height: 100vh;')">
    <div class="login-page">
      <div class="left">
        <div class="main">
          <div class="white font-size-3rem bottom-2rem"><img style="height: 5.5rem" src="../../assets/images/login-and-register/Logo.svg" /></div>
          <div class="main-welcome">
            {{ t('welcome_back') }}<span class="main-host">{{ domain }}</span>
          </div>
          <div class="main-welcome bottom-2rem">{{ t('the_paradise_of') }}{{ t('i_gaming') }}</div>
          <div class="white bold bottom-1rem font-size-sm8">
            {{ t('start_your___now') }}
          </div>
          <div class="white bold font-size-sm8">
            {{ t('privacy_security') }}
          </div>
          <div class="gray bold bottom-1rem font-size-sm8">
            {{ t('end_to___company') }}
          </div>
          <div class="white bold font-size-sm8">
            {{ t('fairness') }}
          </div>
          <div class="gray bold bottom-1rem font-size-sm8">
            {{ t('to_archive___with') }}
          </div>
          <div class="white bold font-size-sm8">
            {{ t('funny_games') }}
          </div>
          <div class="gray bold bottom-1rem font-size-sm8">
            {{ t('over_hundreds___want') }}
          </div>
          <div class="white bold font-size-sm8">
            {{ t('instant_cash_out') }}
          </div>
          <div class="gray bold bottom-1rem font-size-sm8">
            {{ t('we_are___minutes') }}
          </div>
        </div>
      </div>
      <div class="right">
        <div class="right-main">
          <div class="close"><img src="../../assets/images/login-and-register/Vector.svg" @click="close" /></div>
          <div class="clear"></div>
          <div class="login-tab">
            <div @click="handleSwitchLoginOrRegister(true)" :class="isLogin ? 'selected' : ''">
              {{ t('login') }}
            </div>
            <div @click="handleSwitchLoginOrRegister(false)" :class="!isLogin ? 'selected' : ''">
              {{ t('sign_up') }}
            </div>
          </div>
          <div v-if="!isLogin">
            <div :class="'input-div top-2rem bottom-1rem ' + (registerData.emailError ? ' error ' : '') + (classConfig['email'] ? 'focus' : '')">
              <div class="img-div">
                <img src="../../assets/images/login-and-register/login-and-register-email.svg" v-if="!registerData.emailError" />
                <img src="../../assets/images/login-and-register/login-and-register-email-tip.svg" v-if="registerData.emailError" />
              </div>
              <div :class="'input'">
                <input placeholder="Example@wingame.com" type="input" aria-invalid="false" v-model="registerData.email" @mouseover="inputFocus('email')" @mouseleave="inputBlur('email')" @focus="inputFocus('email')" @blur="inputBlur('email')" />
              </div>
              <div v-if="registerData.emailError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(registerData.emailCode) }}</div>
            </div>
            <div :class="'input-div bottom-1rem ' + (registerData.passwordError ? ' error ' : '') + (classConfig['password'] ? 'focus' : '')">
              <div class="img-div">
                <img src="../../assets/images/login-and-register/Vector-2.svg" style="width: 1.03rem; height: 1.25rem" v-if="!registerData.passwordError" />
                <img src="../../assets/images/login-and-register/login-and-register-password-tip.svg" style="width: 1.03rem; height: 1.25rem" v-if="registerData.passwordError" />
              </div>
              <div class="input password-input">
                <input :placeholder="t('password')" :type="registerPassword" aria-invalid="false" v-model="registerData.password" @mouseover="inputFocus('password')" @mouseleave="inputBlur('password')" @focus="inputFocus('password')" @blur="inputBlur('password')" />
              </div>
              <div class="input send-code-btn">
                <div class="eyes">
                  <img v-show="registerPassword == 'password'" class="eyes-close" @click="registerPassword = 'text'" src="../../assets/images/login-and-register/Vector-4.svg" />
                  <img v-show="registerPassword !== 'password'" @click="registerPassword = 'password'" style="width: 1.42rem; height: 1rem" src="../../assets/images/login-and-register/Vector-3.svg" />
                </div>
              </div>

              <div v-if="registerData.passwordError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(registerData.passwordCode) }}</div>
            </div>
            <div :class="'input-div bottom-1rem ' + (registerData.codeError ? ' error ' : '') + (classConfig['code'] ? 'focus' : '')">
              <div class="img-div">
                <img style="width: 1.03rem; height: 1.25rem" src="../../assets/images/login-and-register/login-and-register-verify.svg" v-if="!registerData.codeError" />
                <img style="width: 1.03rem; height: 1.25rem" src="../../assets/images/login-and-register/login-and-register-verify-tip.svg" v-if="registerData.codeError" />
              </div>
              <div class="input send-code-input">
                <input :placeholder="t('verification')" type="input" v-model="registerData.code" @mouseover="inputFocus('code')" @mouseleave="inputBlur('code')" @focus="inputFocus('code')" @blur="inputBlur('code')" />
              </div>
              <div class="input send-code-btn" @click="sendCode()">
                <div class="send-code-btn">{{ sendCodeText }}</div>
              </div>
              <div v-if="registerData.codeError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(registerData.codeCode) }}</div>
            </div>

            <div :class="'input-div bottom-1rem ' + (classConfig['referral_code'] ? 'focus' : '')">
              <div class="img-div">
                <img style="width: 1.03rem; height: 1.25rem" src="../../assets/images/login-and-register/login-and-register-referral-tip.svg" v-if="registerData.inviteCodeError" />
                <img style="width: 1.03rem; height: 1.25rem" src="../../assets/images/login-and-register/login-and-register-referral.svg" v-if="!registerData.inviteCodeError" />
              </div>
              <div class="input send-code-input">
                <input :placeholder="t('referral_code')" type="input" v-model="registerData.referralCode" :disabled="registerData.referralCodeDisabled" @mouseover="inputFocus('referral_code')" @mouseleave="inputBlur('referral_code')" @focus="inputFocus('referral_code')" @blur="inputBlur('referral_code')" />
              </div>
              <div class="input send-code-btn">
                <div class="">({{ t('optional') }})</div>
              </div>
              <div v-if="registerData.inviteCodeError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(registerData.inviteCodeCode) }}</div>
            </div>

            <div :class="'bottom-1rem '">
              <div class="privacy">
                <img v-show="!readFlag" @click="readFlag = !readFlag" src="../../assets/images/login-and-register/login-and-register-privacy-normal.svg" />
                <img v-show="readFlag" @click="readFlag = !readFlag" src="../../assets/images/login-and-register/login-and-register-privacy-selected.svg" />
                <div style="line-height: 1.5625rem; width: 100%; border: 1px solid saddlebrown" v-if="!readFlag && errorShow">
                  {{ t('i_am___the') }}<span @click="jump('/privacy_policy')">{{ t('privacy_policy') }}</span
                  >{{ t('and') }}<span @click="jump('/term_of_service')">{{ t('terms_of_service') }}</span>
                </div>
                <div style="line-height: 1.5625rem" v-else>
                  {{ t('i_am___the') }}<span @click="jump('/privacy_policy')">{{ t('privacy_policy') }}</span
                  >{{ t('and') }}<span @click="jump('/term_of_service')">{{ t('terms_of_service') }}</span>
                </div>
              </div>
            </div>
            <div :class="'input-div bottom-1rem join-us'" @click="myRegister()">
              {{ isRegistering ? '' : t('join_us') }}
              <div v-if="isRegistering" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50"></vue3-lottie>
              </div>
            </div>

            <!-- 人机校验 注册 -->
            <human-verify :show="!isLogin && openRegisterHumanVerifyUI" @verified="handleHumanVerified"></human-verify>

            <div :class="'input-div none'">
              <div style="width: 35%">
                <hr style="height: 1px; background-color: #55657e; border: none" />
              </div>
              <div style="width: 30%">{{ t('or') }}</div>
              <div style="width: 35%">
                <hr style="height: 1px; background-color: #55657e; border: none" />
              </div>
            </div>
          </div>
          <div v-if="isLogin">
            <div :class="'input-div top-2rem bottom-1rem ' + (userLogin.emailError ? ' error ' : '') + (classConfig['email'] ? 'focus' : '')">
              <div class="img-div">
                <img src="../../assets/images/login-and-register/login-and-register-email.svg" v-if="!userLogin.emailError" />
                <img src="../../assets/images/login-and-register/login-and-register-email-tip.svg" v-if="userLogin.emailError" />
              </div>
              <div class="input">
                <input placeholder="Example@wingame.com" type="input" aria-invalid="false" v-model="userLogin.email" @mouseover="inputFocus('email')" @mouseleave="inputBlur('email')" @focus="inputFocus('email')" @blur="inputBlur('email')" />
              </div>
              <div v-if="userLogin.emailError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(userLogin.emailCode) }}</div>
            </div>
            <div :class="'input-div bottom-1rem ' + (userLogin.passwordError ? ' error ' : '') + (classConfig['password'] ? 'focus' : '')">
              <div class="img-div">
                <img src="../../assets/images/login-and-register/login-and-register-password.svg" style="width: 1.03rem; height: 1.25rem" v-if="!userLogin.emailError" />
                <img src="../../assets/images/login-and-register/login-and-register-password-tip.svg" style="width: 1.03rem; height: 1.25rem" v-if="userLogin.emailError" />
              </div>
              <div class="input password-input">
                <input :placeholder="t('password')" :type="password" aria-invalid="false" v-model="userLogin.password" @mouseover="inputFocus('password')" @mouseleave="inputBlur('password')" @focus="inputFocus('password')" @blur="inputBlur('password')" />
              </div>
              <div class="input send-code-btn">
                <div class="eyes">
                  <img v-show="password == 'password'" class="eyes-close" @click="password = 'text'" src="../../assets/images/login-and-register/Vector-4.svg" />
                  <img v-show="password !== 'password'" @click="password = 'password'" style="width: 1.42rem; height: 1rem" src="../../assets/images/login-and-register/Vector-3.svg" />
                </div>
              </div>
              <div v-if="userLogin.passwordError && errorShow" class="error-tip"><img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />{{ t(userLogin.passwordCode) }}</div>
            </div>

            <div :class="'input-div bottom-1rem join-us'" @click="login">
              {{ isLogining ? '' : t('join_us') }}
              <div v-if="isLogining" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50"></vue3-lottie>
              </div>
            </div>

            <!-- 人机校验 登录 -->
            <human-verify :show="isLogin && openLoginHumanVerifyUI" @verified="handleHumanVerified"></human-verify>

            <div :class="'input-div none forget_password'">
              <div style="width: 100%; text-align: left">
                <div style="line-height: 2rem; display: flex; align-items: center">
                  <img style="width: 1.125rem; height: 1.125rem; cursor: pointer" @click="reset_password = true" src="../../assets/images/login-and-register/login-and-register-forget.svg" />
                  <span @click="reset_password = true" style="cursor: pointer; padding-left: 0.5rem">{{ t('forget_password') }}</span>
                </div>
              </div>
            </div>

            <div :class="'input-div none'">
              <div style="width: 35%">
                <hr style="height: 1px; background-color: #55657e; border: none" />
              </div>
              <div style="width: 30%">{{ t('or') }}</div>
              <div style="width: 35%">
                <hr style="height: 1px; background-color: #55657e; border: none" />
              </div>
            </div>
          </div>
          <div :class="'input-div bottom-1rem join-us login-btn-bg google-login-btn-bg'">
            <img class="login-img" src="../../assets/images/login-and-register/login-and-register-google.svg" />{{ t('login_with_gmail') }}
            <GoogleLogin :callback="callback2" prompt ref="googleLoginFrame" :auto-login="false"> </GoogleLogin>
          </div>
          <div :class="'input-div bottom-1rem join-us login-btn-bg'" @click="loginWithTelegram()">
            <img class="login-img" src="../../assets/images/login-and-register/login-and-register-telegram.svg" />
            {{ t('login_with_telegram') }}
          </div>
        </div>
      </div>
    </div>
    <div class="my-dialog" style="" v-if="reset_password">
      <div class="dialog-main">
        <div class="dialog-header">
          <div class="dialog-title">{{ t('reset_password') }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="handleClose()" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="bottom-1rem">
            {{ t('you_are___continue') }}
          </div>
          <div style="display: flex; justify-content: center" class="input-div">
            <input :placeholder="t('please_input__email')" v-model="reset_password_data.email" />
          </div>
          <div style="display: flex; justify-content: center" class="reset-btn top-3rem" @click="resetPasswordEvent()">
            {{ t('reset') }}
          </div>
        </div>
      </div>
    </div>
    <div class="my-dialog" style="" v-if="set_password">
      <div class="dialog-main">
        <div class="dialog-header">
          <div class="dialog-title">{{ t('reset_password') }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="handleClose()" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="bottom-1rem">
            <wg-type-input type="password" :placeholder="t('password')" @onValueChange="handlePasswordValueChanged"></wg-type-input>
          </div>

          <div style="width: 100%" class="">
            <ul class="reset_password_ul">
              <li>6-12{{ t('characters_long') }}</li>
              <li>1{{ t('lowercase_character') }}</li>
              <li>1{{ t('uppercase_character') }}</li>
              <li>1{{ t('number') }}</li>
            </ul>
          </div>
          <div class="bottom-1rem">
            <wg-type-input type="password" :placeholder="t('confirm_password')" @onValueChange="handlePassword2ValueChanged"></wg-type-input>
          </div>

          <div class="bottom-1rem">
            <wg-type-input type="verifyCode" :placeholder="t('verification')" @onValueChange="handleVerifyValueChanged">
              <button class="verify-code-button" @click="resetSendCode" :disabled="sendCoding || registerT">
                {{ sendCoding ? '' : sendCodeText }}
                <vue3-lottie :animationData="confirmLoadingJson" :width="40" :height="40" v-if="sendCoding"></vue3-lottie>
              </button>
            </wg-type-input>
          </div>

          <div style="display: flex; justify-content: center" class="reset-btn top-2rem" @click="resetEmailPasswordServiceEvent()">
            {{ t('confirm') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { useRoute } from 'vue-router'
import md5 from 'crypto-js/md5';
import { useAppStore } from '../../store/store.js';
import { loginService, loginWithTelegramService, registerEmailSendCodeService, registerForceInputInviteCodeService, registerService, resetEmailPasswordSendCodeService, resetEmailPasswordService, getTGBotIdService } from '@/assets/network/service/user';
import { setCookieAccessToken, setCookieEmail, setCookieUserId, setCookieUsername, setCookiesGmailLogin } from '@/assets/tools/CommonTool';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import serverConfig from '@/assets/network/serverConfig';
import humanVerify from '@/components/humanVerify/humanVerify.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { fpCompleteRegistrationEvent, fpInit } from '@/assets/tools/FacebookPixelEvent';

export default {
  name: 'myLogin',
  components: {},
  props: {
    isDialog: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      sendCoding: false,
      isLogining: false,
      isRegistering: false,
      errorShow: false,
      errorShowTime: -1,
      sendCodeText: '',
      userLogin: {
        password: '',
        email: ''
      },
      reset_password_data: {
        email: '',
        password1: '',
        password2: '',
        key: ''
      },
      registerData: {
        email: '',
        password: '',
        code: '',
        referralCode: ''
      },
      registerPassword: 'password',
      resetPassword: 'password',
      password: 'password',
      dialogVisible: false,
      reset_password: false,
      set_password: false,
      set_passwordData: {
        password: ''
      },
      isLogin: true,
      readFlag: false,
      classConfig: {
        email: false,
        password: false,
        code: false,
        referral_code: false
      },
      registerT: null,
      errorShowT: null,
      force_invite_code: false,
      default_invite_code: '',
      confirmLoadingJson: loadingAniJson,
      // 人机验证
      openLoginHumanVerifyUI: false,
      openRegisterHumanVerifyUI: false,
      loginHumanVerifiedResult: null,
      registerHumanVerifiedResult: null
    };
  },
  created() {
    this.sendCodeText = this.$t('send_code');
    let loginCode = this.$route.query.type;
    if (loginCode == 'sign_up') {
      this.isLogin = false;
    }
    this.referral_code = this.$route.query.referral_code;
    this.registerData.referralCodeDisabled = false;
    if (this.referral_code) {
      this.isLogin = false;
      this.registerData.referralCode = this.referral_code;
      this.registerData.referralCodeDisabled = true;
      useAppStore.referralCode = this.registerData.referralCode;
    } else if (useAppStore.referralCode) {
      this.registerData.referralCode = useAppStore.referralCode;
      this.registerData.referralCodeDisabled = true;
    }
    console.log(useAppStore.referralCode);
    const that = this;
    this.registerForceInputInviteCodeService();
    setInterval(function () {
      if (that.errorShowTime > -1) {
        that.errorShowTime--;
        that.errorShow = that.errorShowTime >= 0;
      }
    }, 1000);

    console.log(this.isDialog);
    if (this.isDialog) {
      if (this.isDialog) {
        document.getElementsByTagName('body')[0].classList.add('hidden-overflow-y');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
      }
    }
    // console.log('fpPixelId:'+useAppStore().fpPixelId)
  },
  mounted() {
    // googleLogout()
  },
  unmounted() {
    if (this.registerT) {
      clearInterval(this.registerT);
    }
    if (this.errorShowT) {
      clearInterval(this.errorShowT);
    }
    if (this.isDialog) {
      document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
    }
  },
  methods: {
    callback2(response2) {
      const that = this;
      const callback = (response) => {
        // 在这里处理用户登录后的操作
        console.log('处理登录后的操作', response);
        const idToken = response.credential;
        console.log('ID 令牌', idToken);
        const appStore = useAppStore();
        let params = {
          token: idToken
        };
        if (appStore.fpPixelId) {
          params.facebook_pixel_id = appStore.fpPixelId;
        }
        if (appStore.fbToken) {
          params.fb_token = appStore.fbToken;
        }
        if (appStore.adsname) {
          params.adsname = appStore.adsname;
        }
        if (appStore.fpReferral) {
          params.invite_code = appStore.fpReferral;
        }
        googleAuth(params)
          .then((response) => {
            console.log('本地处理登录后的操作', response);

            if (response.data.facebook_pixel_id) {
              if (!appStore.fpInit) {
                fpInit(response.data.facebook_pixel_id);
                appStore.fpInit = true;
              }
              if (response.data.status && response.data.status == 'register') {
                fpCompleteRegistrationEvent({ userId: response.data.user.pk });
              }
            }

            setCookieAccessToken(response.data.access_token);
            setCookieUserId(response.data.user.pk);
            setCookieUsername(response.data.user.username);
            setCookieEmail(response.data.user.email);
            toast(this.$t('login_successfully'), { autoClose: 2000 });
            setCookiesGmailLogin('true');

            setTimeout(function () {
              console.log('ID 令牌', that.isDialog);
              if (that.isDialog) {
                that.loginAfter();
              } else {
                that.$router.push('/');
              }
              that.isRegistering = false;
            }, 1500);
          })
          .catch((error) => {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          });
      };
      callback(response2);
    },
    handlePasswordValueChanged(value) {
      this.reset_password_data.password1 = value;
    },
    handlePassword2ValueChanged(value) {
      this.reset_password_data.password2 = value;
    },
    handleVerifyValueChanged(value) {
      this.reset_password_data.key = value;
    },
    jump(path) {
      if (this.$isMobile()) {
        this.$router.push(path);
      } else {
        window.open(path, '_blank');
      }
    },
    loginAfter() {
      this.$emit('loginAfter');
    },
    async loginWithTelegram () {
      const botIdData = await getTGBotIdService();
      const botId = botIdData.data.data && botIdData.data.data.length > 0 ? botIdData.data.data : serverConfig.botId;
      window.Telegram.Login.auth({ bot_id: botId, request_access: 'write', embed: 1 }, (data) => {
        if (!data) {
          console.log(data);
        } else {
          const params = {
            data: data
          };
          console.log(params);
          this.handleTelegramService(params);
        }
      });
    },
    handleTelegramService(param) {
      const that = this;
      const appStore = useAppStore();
      let params = param;
      if (appStore.fpPixelId) {
        params.facebook_pixel_id = appStore.fpPixelId;
      }
      if (appStore.fbToken) {
        params.fb_token = appStore.fbToken;
      }
      if (appStore.adsname) {
        params.adsname = appStore.adsname;
      }
      if (appStore.fpReferral) {
        params.invite_code = appStore.fpReferral;
      }
      loginWithTelegramService(params)
        .then((response) => {
          setCookieAccessToken(response.data.access_token);
          setCookieUserId(response.data.user.pk);
          setCookieUsername(response.data.user.username);
          setCookieEmail(response.data.user.email);
          setCookiesGmailLogin('false');
          toast(that.$t('login_successfully'), { autoClose: 2000 });

          if (response.data.facebook_pixel_id) {
            if (!appStore.fpInit) {
              fpInit(response.data.facebook_pixel_id);
              appStore.fpInit = true;
            }
            if (response.data.status && response.data.status == 'register') {
              fpCompleteRegistrationEvent({ userId: response.data.user.pk });
            }
          }

          setTimeout(function () {
            if (that.isDialog) {
              that.loginAfter();
            } else {
              that.$router.push('/');
            }
            that.isRegistering = false;
          }, 1500);
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    registerForceInputInviteCodeService() {
      registerForceInputInviteCodeService()
        .then((res) => {
          console.log(res.data);
          this.force_invite_code = res.data.force_invite_code;
          this.default_invite_code = res.data.default_invite_code;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    // 切换登录或注册
    handleSwitchLoginOrRegister(isSwitchLogin) {
      this.isLogin = isSwitchLogin;
      if (this.isLogin) {
        this.openRegisterHumanVerifyUI = false;
        this.registerHumanVerifiedResult = null;
      } else {
        this.openLoginHumanVerifyUI = false;
        this.loginHumanVerifiedResult = null;
      }
    },
    // 人机已验证回调
    handleHumanVerified(result) {
      if (this.isLogin) {
        this.loginHumanVerifiedResult = result;
        this.openLoginHumanVerifyUI = false;

        this.login();
      } else {
        this.registerHumanVerifiedResult = result;
        this.openRegisterHumanVerifyUI = false;

        this.myRegister();
      }
    },
    myRegister() {
      let error = false;
      if (!this.registerData.email) {
        this.registerData.emailError = true;
        this.registerData.emailCode = 'email_is_required';
      } else if (!this.registerData.email.includes('@')) {
        this.registerData.emailError = true;
        this.registerData.emailCode = 'email_format___incorrect';
        // tip.registerEmailTip = emailFormatIncorrect;
        error = true;
        this.registerData.emailError = true;
      } else {
        this.registerData.emailError = false;
      }
      this.registerData.passwordError = false;
      if (!this.registerData.password) {
        this.registerData.passwordError = true;
        this.registerData.passwordCode = 'password_is_required';
      } else if (!this.registerData.password || !this.handlePasswordIsVerify(this.registerData.password)) {
        this.registerData.passwordError = true;
        this.registerData.passwordCode = 'characters_in___numbers';
        // tip.registerEmailTip = emailFormatIncorrect;
        error = true;
      }
      this.registerData.codeError = false;
      if (!this.registerData.code) {
        this.registerData.codeError = true;
        this.registerData.codeCode = 'characters_in___numbers';
        // tip.registerEmailTip = emailFormatIncorrect;
        error = true;
      }
      if (this.force_invite_code) {
        this.registerData.inviteCodeError = true;
        this.registerData.inviteCodeCode = 'referral_code_is_required';
        error = true;
      }
      if (!this.readFlag) {
        error = true;
      }
      if (error) {
        this.errorShowTime = 5;
        this.errorShow = true;
        return;
      }

      // 开始显示人机校验
      if (this.registerHumanVerifiedResult == null) {
        this.openRegisterHumanVerifyUI = true;
        return;
      }

      if (this.isRegistering) {
        return;
      }
      this.isRegistering = true;
      const saltPWD = md5(this.registerData.password + '{PWD_Salt_CC_Poker}').toString();
      let params = {
        email: this.registerData.email,
        password1: saltPWD,
        password2: saltPWD,
        key: this.registerData.code,
        from_website: true,
        hcaptcha_token: this.registerHumanVerifiedResult.token
      };
      const appStore = useAppStore();
      if (this.registerData.referralCode && this.registerData.referralCode.length > 0) {
        params.invite_code = this.registerData.referralCode;
      } else {
        params.invite_code = appStore.fpReferral;
      }
      if (appStore.adsname) {
        params.adsname = appStore.adsname;
      }
      if (appStore.fpPixelId) {
        params.facebook_pixel_id = appStore.fpPixelId;
      }
      if (appStore.fbToken) {
        params.fb_token = appStore.fbToken;
      }
      const that = this;
      registerService(params)
        .then((response) => {
          console.log(params);
          toast(this.$t('register_successfully'));
          that.isRegistering = false;
          that.registerHumanVerifiedResult = null;

          if (response.data.facebook_pixel_id) {
            if (!appStore.fpInit) {
              fpInit(response.data.facebook_pixel_id);
              appStore.fpInit = true;
            }
            fpCompleteRegistrationEvent({ userId: response.data.user.pk });
          }

          setCookieAccessToken(response.data.access_token);
          setCookieUserId(response.data.user.pk);
          setCookieUsername(response.data.user.username);
          setCookieEmail(this.registerData.email);
          useAppStore.referralCode = '';
          setTimeout(function () {
            if (that.isDialog) {
              that.loginAfter();
            } else {
              that.$router.push('/');
            }
            that.isRegistering = false;
          }, 1500);
        })
        .catch((error) => {
          that.isRegistering = false;
          that.registerHumanVerifiedResult = null;
          if (error.response.status && error.response.status === 412) {
            that.openRegisterHumanVerifyUI = true;
          } else {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          }
        });
    },
    resetEmailPasswordServiceEvent() {
      if (this.reset_password_data.password1 !== this.reset_password_data.password2) {
        toast(this.$t('the_password___inconsistent'), { autoClose: 2000, type: 'error' });
        return;
      }
      const saltPWD = md5(this.reset_password_data.password1 + '{PWD_Salt_CC_Poker}').toString();
      const saltPWD2 = md5(this.reset_password_data.password2 + '{PWD_Salt_CC_Poker}').toString();
      const isVerified = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(this.reset_password_data.password1);
      if (!isVerified) {
        toast(this.$t('the_new___invalid'), { autoClose: 2000, type: 'error' });
        return;
      }
      if (!this.reset_password_data.key) {
        toast(this.$t('please_input___code'), { autoClose: 2000, type: 'error' });
        return;
      }
      resetEmailPasswordService({
        key: this.reset_password_data.key,
        email: this.reset_password_data.email,
        password1: saltPWD,
        password2: saltPWD2
      })
        .then(() => {
          toast(this.$t('reset_successfully'), { autoClose: 2000 });
          this.reset_password_data.key = '';
          this.reset_password_data.email = '';
          this.reset_password_data.password1 = '';
          this.reset_password_data.password2 = '';
          this.set_password = false;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    resetSendCode() {
      if (this.registerT) {
        return;
      }
      this.sendCoding = true;
      resetEmailPasswordSendCodeService({
        email: this.reset_password_data.email
      })
        .then(() => {
          const that = this;
          that.sendCoding = false;
          let time = 59;
          toast(this.$t('sent_successfully'));
          that.sendCodeText = '(' + time + ')';
          this.registerT = setInterval(function () {
            time--;
            if (time < 0) {
              that.sendCodeText = that.$t('send_code');
              clearInterval(that.registerT);
              that.registerT = null;
              return;
            } else {
              that.sendCodeText = '(' + time + ')';
            }
          }, 1000);
        })
        .catch((error) => {
          this.sendCoding = false;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    sendCode() {
      if (this.registerT) {
        return;
      }
      let error = false;
      const tip = {};
      console.log(this.registerData.email);
      if (!this.registerData.email.includes('@')) {
        tip.registerEmailOpen = true;
        this.registerData.emailError = true;
        this.registerData.emailCode = 'email_format___incorrect';
        // tip.registerEmailTip = emailFormatIncorrect;
        error = true;
        this.registerData.emailError = true;
      } else {
        this.registerData.emailError = false;
      }
      console.log(this.registerData.email);
      if (error) {
        this.errorShowTime = 5;
        this.errorShow = true;
        // toast(this.$t('sent_successfully'),{autoClose:2000})
        return;
      }
      registerEmailSendCodeService({
        email: this.registerData.email
      })
        .then(() => {
          const that = this;
          let time = 59;
          toast(this.$t('sent_successfully'));
          this.registerT = setInterval(function () {
            time--;
            if (time == -1) {
              that.sendCodeText = that.$t('send_code');
              clearInterval(that.registerT);
              that.registerT = null;
              return;
            }
            that.sendCodeText = '(' + time + ')';
          }, 1000);
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    handlePasswordIsVerify(password) {
      return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(password);
    },
    login() {
      let error = false;
      this.userLogin.emailError = false;
      if (this.userLogin.email === undefined || this.userLogin.email.trim() === '') {
        this.userLogin.emailCode = 'email_format___incorrect';
        this.userLogin.emailError = true;
        // setAlertType('info');
        // setAlertMessage(emailIsRequired);
        // setOpenAlert(true);
        error = true;
        // tip.loginEmailTip = ;
      } else if (!this.userLogin.email.includes('@')) {
        this.userLogin.emailCode = 'email_format___incorrect';
        this.userLogin.emailError = true;
        error = true;
        // tip.loginEmailTip = emailFormatIncorrect;
      }
      if (!this.userLogin.password) {
        this.userLogin.passwordCode = 'password_is_required';
        this.userLogin.passwordError = true;
      } else if (!this.handlePasswordIsVerify(this.userLogin.password)) {
        this.userLogin.passwordCode = 'characters_in___numbers';
        this.userLogin.passwordError = true;
        error = true;
      }
      if (error) {
        this.errorShowTime = 5;
        this.errorShow = true;
        return;
      }

      // 开始显示人机校验
      if (this.loginHumanVerifiedResult == null) {
        this.openLoginHumanVerifyUI = true;
        return;
      }

      const saltPWD = md5(this.userLogin.password + '{PWD_Salt_CC_Poker}').toString();
      if (this.isLogining) {
        return;
      }
      this.isLogining = true;
      const that = this;

      const appStore = useAppStore();
      let params = {
        email: this.userLogin.email,
        // email:'',
        password: saltPWD,
        hcaptcha_token: this.loginHumanVerifiedResult.token
      };
      if (appStore.fpPixelId) {
        params.facebook_pixel_id = appStore.fpPixelId;
      }
      if (appStore.fbToken) {
        params.fb_token = appStore.fbToken;
      }
      loginService(params)
        .then((res) => {
          console.log(res.data);
          that.loginHumanVerifiedResult = null;

          if (res.data.facebook_pixel_id) {
            if (!appStore.fpInit) {
              fpInit(res.data.facebook_pixel_id);
              appStore.fpInit = true;
            }
          }

          setCookieAccessToken(res.data.access_token);
          setCookieUserId(res.data.user.pk);
          setCookieUsername(res.data.user.username);
          setCookieEmail(this.userLogin.email);
          toast(this.$t('login_successfully'), { autoClose: 2000 });

          setTimeout(function () {
            if (that.isDialog) {
              that.loginAfter();
            } else {
              that.$router.push('/');
            }
            that.isLogining = false;
          }, 1000);
        })
        .catch((e) => {
          that.isLogining = false;
          that.loginHumanVerifiedResult = null;
          if (e.response.status && e.response.status === 412) {
            that.openLoginHumanVerifyUI = true;
          } else {
            try {
              console.log(e.response.data);
              for (let key in e.response.data) {
                toast(e.response.data[key], { autoClose: 3000, type: 'error' });
              }
            } catch (e) {
              // toast(this.$t('copy_failed'), { autoClose: 1000 });
            }
          }
        });
    },
    inputFocus(name) {
      this.classConfig[name] = true;
    },
    inputBlur(name) {
      this.classConfig[name] = false;
    },
    close() {
      if (this.isDialog) {
        this.$emit('closeDialog');
      } else {
        this.$router.push('/');
      }
    },
    handleClose() {
      this.reset_password = false;
      this.set_password = false;
    },
    resetPasswordEvent() {
      let error = false;
      if (!this.reset_password_data.email) {
        this.reset_password_data.emailCode = 'email_is_required';
        this.reset_password_data.emailError = true;
        error = true;
        // tip.loginEmailTip = emailFormatIncorrect;
      } else if (!this.reset_password_data.email.includes('@')) {
        this.reset_password_data.emailCode = 'email_format___incorrect';
        this.reset_password_data.emailError = true;
        error = true;
        // tip.loginEmailTip = emailFormatIncorrect;
      }
      if (error) {
        toast(this.$t(this.reset_password_data.emailCode), { autoClose: 2000, type: 'error' });
        return;
      }
      this.reset_password = false;
      this.set_password = true;
    }
  }
};
</script>
<style scoped>
.login-page .right .right-main .input-div.forget_password {
  cursor: auto;
}
::v-deep .bg-content.active {
  border: 2px solid var(--input-color);
}
::v-deep .input {
  caret-color: var(--input-color);
  text-align: left;
}
::v-deep input::placeholder {
  color: var(--placeholder-color);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .S9gUrf-YoZ4jf {
  position: absolute !important;
  margin-left: -170px !important;
  margin-top: -20px !important;
  opacity: 0 !important;
}
</style>
