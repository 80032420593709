<template>
  <div :class="isMainClass ? 'main-content' : ''">
    <div class="profit">
      <div class="profit-header profit-header2 !bg-[#191F33]">
        <div :style="'width: 8%;'" class="web">
          {{ t('game_details_1_1_4') }}
        </div>
        <div :style="'width: 12%;'" class="phone">
          {{ t('game_details_1_1_4') }}
        </div>
        <div class="web" style="width: 25%">
          {{ t('game_details_1_1_5') }}
        </div>
        <div class="web" :style="!isAll ? 'width: 12%;' : 'width: 16%;'">
          {{ t('game_details_1_1_6') }}
        </div>
        <div class="web" :style="!isAll ? 'width: 19%;' : 'width: 23%;'">
          {{ t('game_details_1_1_7') }}
        </div>
        <div class="web" :style="!isAll ? 'width: 10%;' : 'width: 14%;'">
          {{ t('game_details_1_1_8') }}
        </div>
        <div class="web" :style="!isAll ? 'width: 19%;' : 'width: 23%;'">
          {{ t('game_details_1_1_9') }}
        </div>
        <div class="phone" style="width: 63%; display: flex; align-items: center; justify-content: center; margin-left: 3%">
          {{ t('game_details_1_1_5') }}
        </div>
        <div class="phone" style="width: 25%; display: flex; align-items: center; justify-content: right; margin-right: 3%">
          {{ t('game_details_1_1_9') }}
        </div>
      </div>
      <div class="profit-header" v-if="profitList.length == 0">
        {{ t('no_data') }}
      </div>
      <div class="profit-header profit-header2 !bg-[#222942] mt-2" v-for="(profit, index) in profitList" :key="index">
        <div :style="'width: 8%;'" class="web">
          <img src="../../assets/images/referral/referral-gold.png" v-if="index == 0" />
          <img src="../../assets/images/referral/referral-sliver.png" v-if="index == 1" />
          <img src="../../assets/images/referral/referral-bronze.png" v-if="index == 2" />
        </div>
        <div :style="'width: 12%;'" class="phone">
          <img src="../../assets/images/referral/referral-gold.png" v-if="index == 0" />
          <img src="../../assets/images/referral/referral-sliver.png" v-if="index == 1" />
          <img src="../../assets/images/referral/referral-bronze.png" v-if="index == 2" />
        </div>
        <div class="web" style="width: 25%"><img :src="getHeaderImg(profit.profile_image_url)" />{{ profit.user_name }}</div>
        <div class="web" :style="!isAll ? 'width: 12%;' : 'width: 16%;'">
          {{ getCreatedTime(profit.created_at) }}
        </div>
        <div class="web" :style="!isAll ? 'width: 19%;' : 'width: 23%;'"><img v-if="walletList.length > 0" :src="getSymbolIconUrl(profit.symbol)" />{{ profit.amount }}</div>
        <div class="web" :style="!isAll ? 'width: 10%;' : 'width: 14%;'">{{ profit.rate }}x</div>
        <div class="web" :style="!isAll ? 'width: 19%;' : 'width: 23%;'"><img v-if="walletList.length > 0" :src="getSymbolIconUrl(profit.symbol)" />{{ profit.win_amount }}</div>

        <div class="phone" style="width: 63%; display: flex; align-items: center; justify-content: left; margin-left: 3%"><img :src="getHeaderImg(profit.profile_image_url)" />{{ profit.user_name }}</div>
        <div class="phone" style="width: 40%; display: flex; align-items: center; justify-content: right; margin-right: 3%"><img v-if="walletList.length > 0" :src="getSymbolIconUrl(profit.symbol)" />{{ profit.win_amount }}</div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script setup>
import headerImg1 from '@/assets/images/profile/avatar/profile-avatar1.png';
import headerImg2 from '@/assets/images/profile/avatar/profile-avatar2.png';
import headerImg3 from '@/assets/images/profile/avatar/profile-avatar3.png';
import headerImg4 from '@/assets/images/profile/avatar/profile-avatar4.png';
import headerImg5 from '@/assets/images/profile/avatar/profile-avatar5.png';
import headerImg6 from '@/assets/images/profile/avatar/profile-avatar6.png';
import headerImg7 from '@/assets/images/profile/avatar/profile-avatar7.png';
import headerImg8 from '@/assets/images/profile/avatar/profile-avatar8.png';
import headerImg9 from '@/assets/images/profile/avatar/profile-avatar9.png';
import headerImg10 from '@/assets/images/profile/avatar/profile-avatar10.png';
import headerImg11 from '@/assets/images/profile/avatar/profile-avatar11.png';
import headerImg12 from '@/assets/images/profile/avatar/profile-avatar12.png';
import headerImg13 from '@/assets/images/profile/avatar/profile-avatar13.png';
import headerImg14 from '@/assets/images/profile/avatar/profile-avatar14.png';
import headerImg15 from '@/assets/images/profile/avatar/profile-avatar15.png';
import headerImg16 from '@/assets/images/profile/avatar/profile-avatar16.png';
import headerImg from '@/assets/images/profile/avatar/profile-avatar4.png';
import { ref, onMounted, onBeforeUnmount, watch, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// get props
const props = defineProps({
  walletList: {
    type: Array,
    default: () => []
  },
  showRank: {
    type: Boolean,
    default: false
  },
  isMainClass: {
    type: Boolean,
    default: true
  },
  isAll: {
    type: Boolean,
    default: false
  },
  profitList: {
    type: Array,
    default: () => []
  },
  headers: {
    type: Array,
    default: () => [
      {
        headerName: 'rank',
        headerTitleCode: 'Rank'
      }
    ]
  }
});

// 使用 toRefs 保持响应性
const { walletList, isMainClass, isAll, profitList } = toRefs(props);

const isPhone = ref(false);

const resize = () => {
  isPhone.value = window.innerWidth <= 768;
};

const getSymbolIconUrl = (symbol) => {
  if (walletList.value.length === 0) return ''; // 确保 walletList 不为空
  const wallet = walletList.value.find((wallet) => wallet.symbol === symbol);
  return wallet ? wallet.icon_url : '';
};

const getCreatedTime = (time) => {
  const date = new Date(time * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}/${month >= 10 ? month : '0' + month}/${day >= 10 ? day : '0' + day}`;
};

const getHeaderImg = (headerImageUrl) => {
  const header = (headerImageUrl + '').split('//')[1];
  const headerImages = {
    'profile-avatar1': headerImg1,
    'profile-avatar2': headerImg2,
    'profile-avatar3': headerImg3,
    'profile-avatar4': headerImg4,
    'profile-avatar5': headerImg5,
    'profile-avatar6': headerImg6,
    'profile-avatar7': headerImg7,
    'profile-avatar8': headerImg8,
    'profile-avatar9': headerImg9,
    'profile-avatar10': headerImg10,
    'profile-avatar11': headerImg11,
    'profile-avatar12': headerImg12,
    'profile-avatar13': headerImg13,
    'profile-avatar14': headerImg14,
    'profile-avatar15': headerImg15,
    'profile-avatar16': headerImg16
  };
  return headerImages[header] || headerImg;
};

// 监听 walletList 的变化
watch(
  walletList,
  (newVal) => {
    console.log('walletList updated:', newVal);
    // 在这里可以执行当 walletList 更新时的逻辑
  },
  { immediate: true }
);

onMounted(() => {
  isPhone.value = window.innerWidth <= 768;
  window.addEventListener('resize', resize);
  resize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});
</script>

<style scoped>
.profit .profit-header div {
  color: var(--color-8ea3c3);
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: left;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}
</style>
