<template>
  <div class="root_div web">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header></my-header>
      <div class="main-content">
        <div class="body width-100">
          <div class="text-[18px] text-[#ffffff] font-[700] text-left">{{ t('help_center') }}</div>
          <div class="help_content">
            <div class="left">
              <div @click="defaultHelpIndex = index" v-for="(help, index) in helpConfig" :key="index" :class="'item' + (defaultHelpIndex == index ? ' selected' : '')">
                {{ t(help.code) }}
              </div>
            </div>
            <div class="right">
              <div v-for="(help, index) in helpConfig" :key="index" v-show="defaultHelpIndex == index" class="flex flex-col gap-[20px]">
                <div v-for="(children, index2) in help.children" :key="index2" class="flex flex-col gap-[15px]">
                  <div class="item">{{ t(children.title).toUpperCase() }}</div>
                  <ul class="flex flex-col gap-[10px] m-0 pl-[20px]">
                    <template v-if="children.dot">
                      <li v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</li>
                    </template>
                    <template v-else>
                      <div v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</div>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
  <div class="phone main-content" style="padding-top: 0; min-height: 100vh">
    <div class="width-100 phone-top-title">
      <div style="display: flex; align-items: center; width: 80%; padding-left: 0.5rem; color: var(--color-8ea3c3); font-size: 1.125rem; font-weight: 400">
        <img src="../../assets/images/home/btn_top_jd.svg" style="transform: rotate(180deg); height: 1rem; margin-right: 0.5rem" @click="back()" />
        {{ t('help_center') }}
      </div>
    </div>
    <div class="phone_help">
      <div class="phone-top pt-[20px] pb-[20px]">
        <el-select v-model="defaultHelpIndex" class="help-select" :placeholder="t('Please select')" size="large" :teleported="false">
          <el-option v-for="(item, index) in helpConfig" :key="index" :label="t(item.code)" :value="index" />
        </el-select>
      </div>
      <div class="phone-bottom">
        <div v-for="(help, index) in helpConfig" :key="index" v-show="defaultHelpIndex == index" class="flex flex-col gap-[20px]">
          <div v-for="(children, index2) in help.children" :key="index2" class="flex flex-col gap-[15px]">
            <div class="item">{{ children.content.length > 0 ? t(children.title) : t(children.title).toUpperCase() }}</div>
            <ul class="flex flex-col gap-[10px] m-0 pl-[20px]">
              <template v-if="children.dot">
                <li v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</li>
              </template>
              <template v-else>
                <div v-for="(content, c_index) in children.content" :key="c_index" class="children-item">{{ t(content) }}</div>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.item-one {
  padding: 0 1.5rem;
  width: calc(100% - 3rem);
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  line-height: 2.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin: 0.5rem 0;
  color: var(--color-8ea3c3);
}
.item-one img {
  float: right;
}
.item-one.selected {
  color: var(--white-color);
  background-color: var(--help_content_phone_menu-bg-color);
}
.item-one.selected img {
  display: none;
}
.item-one:hover {
  color: var(--white-color);
  background-color: var(--tab-bg-color);
}
.item-one:hover img {
  display: none;
}
.phone_help {
  padding: 0 1.5rem;
  text-align: left;
}
.phone_help .item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 115%;
  text-align: left;
}
.phone_help .children-item {
  color: #aac4ed;
  font-size: 14px;
  font-weight: 400;
  line-height: 125%;
  text-align: left;
  white-space: pre-line;
}
.help_title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 4.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
  justify-content: left;
}
.help_title img {
  width: 1.6rem;
  height: 1.3rem;
  margin-right: 0.5rem;
}
.help_content {
  display: flex;
  width: 100%;
  gap: 30px;
}
.web .help_content .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.web .help_content .left .item {
  box-sizing: border-box;
  width: 170px;
  min-height: 36px;
  padding: 6px 16px;
  border-radius: 8px;
  background-color: #222942;
  color: #5b7bab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.web .help_content .left .item.selected {
  background-color: #3e4b79;
  color: #cee2ff;
}
.web .help_content .left .item:hover {
  background-color: #3e4b79;
  color: #cee2ff;
  cursor: pointer;
}
.web .help_content .right {
  width: calc(100% - 170px);
  border-radius: 8px;
  background-color: #191f33;
  padding: 20px;
}
.web .help_content .right .item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 115%;
  text-align: left;
}
.web .help_content .right .children-item {
  color: #aac4ed;
  font-size: 15px;
  font-weight: 400;
  line-height: 125%;
  text-align: left;
  white-space: pre-line;
}
.phone .help_content .left {
  width: 100%;
}
.phone .help_content .right {
  width: 100%;
}
</style>
<script setup>
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// const term_of_service_page_title = ref('term_of_service')
</script>
<script>
export default {
  name: 'App',
  props: {
    game: {},
    gameList: []
  },
  components: {
    // myI18n
  },
  data() {
    return {
      defaultHelpIndex: 0,
      phoneDefaultHelpIndex2: 0,
      sourceData: {
        codes: [
          'Privacy_Policy',
          'Term_of_Service',
          'Bonus_Terms',
          'Law_Enforcement',
          'Coin_Accuracy_Limit',
          'Support',
          'Fee',
          'Google_Authenticator',
          'FAQS',
          'Currency',
          'Registration_and_Lo',
          'Swap_Policy',
          'Gamble_Aware',
          'Protecting_Minors',
          'AML',
          'Responsible_Gambling'
        ],
        titleCount: [7,39,14,8,5,1,3,1,17,6,1,1,1,1,1,1],
        titleContentCount: [
          [9,14,11,1,1,1,3],
          [1,4,2,1,28,2,1,2,2,1,1,2,1,1,1,5,25,1,12,9,8,8,2,13,5,0,1,1,11,1,1,10,11,0,1,2,6,1,1],
          [1,1,2,1,1,1,1,1,1,1,1,1,1,1],
          [0,7,6,1,3,5,3,3],
          [1,1,1,1,2],
          [1],
          [1,2,1],
          [7],
          [3,0,1,1,1,1,3,1,1,3,1,1,1,1,1,1,1],
          [2,1,1,2,3,1],
          [3],
          [4],
          [4],
          [4],
          [7],
          [23]
        ],
        titleDots: [
          Array(7).fill(false),
          Array(39).fill(false),
          Array(14).fill(false),
          Array(8).fill(false),
          Array(5).fill(false),
          Array(1).fill(false),
          Array(3).fill(false),
          Array(1).fill(false),
          Array(17).fill(false),
          Array(6).fill(false),
          Array(1).fill(false),
          Array(1).fill(false),
          Array(1).fill(false),
          Array(1).fill(false),
          Array(1).fill(false),
          Array(1).fill(false)
        ]
      },
      helpConfig: []
    };
  },
  created() {
    this.helpConfig = this.initHelpData();
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    initHelpData() {
      let result = [];
      for (let index = 0; index < this.sourceData.codes.length; index++) {
        const code = this.sourceData.codes[index];
        const helpCategory = { code: code }
        let children = [];
        for (let index2 = 0; index2 < this.sourceData.titleCount[index]; index2++) {
          let item = { title: code.toLowerCase() + '_title_' + (index2 + 1), dot: this.sourceData.titleDots[index][index2] };
          let itemContentArr = [];
          for (let index3 = 0; index3 < this.sourceData.titleContentCount[index][index2]; index3++) {
            const itemContent = code.toLowerCase() + '_' + (index2 + 1) + '_' + (index3 + 1);
            itemContentArr.push(itemContent)
          }
          item.content = itemContentArr;

          children.push(item);
        }

        helpCategory.children = children;
        
        result.push(helpCategory);
      }
      return result;
    }
  }
};
</script>
<style scoped>
.phone-top-title {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  line-height: 2rem;
  background-color: var(--phone_help-top-title-bg-color);
}

::v-deep .el-select__wrapper {
  background-color: #16202c;
  box-shadow: 0 0 0 1px #0d101e inset;
}

::v-deep .el-select__placeholder {
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}
</style>
