<template>
  <div class="main-content">
    <div class="body term_of_conditions">
      <div class="title">
        {{ $t(term_of_conditions_page_title) }}
      </div>
      <div v-for="index in term_of_conditions_title.number" :key="index">
        <div class="content_title">
          {{ $t(term_of_conditions_title.code_pre + index) }}
        </div>
        <div class="content_desc" v-if="index == 3">
          {{ $t('term_of_conditions_title_3_1') }}
        </div>
        <div v-for="index2 in getNumber(term_of_conditions, term_of_conditions_title.code_pre + index)" :key="index2">
          <div class="content_desc">
            {{ $t('term_of_conditions_' + index + '_' + index2) }}
          </div>
          <div v-if="term_of_conditions[term_of_conditions_title.code_pre + index].contentChildren && term_of_conditions[term_of_conditions_title.code_pre + index].contentChildren['term_of_conditions_' + index + '_' + index2]">
            <div v-for="index3 in getNumber(term_of_conditions, term_of_conditions_title.code_pre + index, 'contentChildren', 'term_of_conditions_' + index + '_' + index2)" :key="index3" class="content_body">
              <div
                v-if="term_of_conditions[term_of_conditions_title.code_pre + index].contentChildren['term_of_conditions_' + index + '_' + index2 + '_class']"
                :class="'content_desc ' + (term_of_conditions[term_of_conditions_title.code_pre + index].contentChildren['term_of_conditions_' + index + '_' + index2 + '_class'] ? term_of_conditions[term_of_conditions_title.code_pre + index].contentChildren['term_of_conditions_' + index + '_' + index2 + '_class'] : '')"
              ></div>
              <div :class="'content_desc'">
                {{ $t('term_of_conditions_' + index + '_' + index2 + '_' + index3) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const term_of_conditions_page_title = ref('term_of_conditions');

const term_of_conditions_title = ref({
  code_pre: 'term_of_conditions_title_',
  number: 11
});
const getNumber = (term, key1, key2, key3) => {
  let obj = term[key1];
  if (key2 && obj) {
    obj = obj[key2];
  }
  if (key3 && obj) {
    obj = obj[key3];
  }
  if (obj && obj.number) {
    return obj.number;
  }
  return obj ? obj : 0;
};
const term_of_conditions = ref({
  term_of_conditions_title_1: {
    code_pre: 'term_of_conditions_1_',
    number: 4
  },
  term_of_conditions_title_2: {
    code_pre: 'term_of_conditions_2_',
    number: 18
  },
  term_of_conditions_title_3: {
    code_pre: 'term_of_conditions_3_',
    number: 19,
    contentChildren: {
      term_of_conditions_3_1: 4,
      term_of_conditions_3_2: 2,
      term_of_conditions_3_3: 3,
      term_of_conditions_3_4: 3,
      term_of_conditions_3_5: 4,
      term_of_conditions_3_8: 1,
      term_of_conditions_3_9: 1,
      term_of_conditions_3_10: 1,
      term_of_conditions_3_11: 1,
      term_of_conditions_3_12: 1,
      term_of_conditions_3_15: 2
    }
  },
  term_of_conditions_title_4: {
    code_pre: 'term_of_conditions_4_',
    number: 4
  },
  term_of_conditions_title_5: {
    code_pre: 'term_of_conditions_5_',
    number: 5
  },
  term_of_conditions_title_6: {
    code_pre: 'term_of_conditions_6_',
    number: 7
  },
  term_of_conditions_title_7: {
    code_pre: 'term_of_conditions_7_',
    number: 8
  },
  term_of_conditions_title_8: {
    code_pre: 'term_of_conditions_8_',
    number: 4
  },
  term_of_conditions_title_9: {
    code_pre: 'term_of_conditions_9_',
    number: 3
  },
  term_of_conditions_title_10: {
    code_pre: 'term_of_conditions_10_',
    number: 3,
    contentChildren: {
      term_of_conditions_10_1: 2,
      term_of_conditions_10_2: 2,
      term_of_conditions_10_3: 1
    }
  },
  term_of_conditions_title_11: {
    code_pre: 'term_of_conditions_11_',
    number: 9,
    contentChildren: {
      term_of_conditions_11_1: 1,
      term_of_conditions_11_2: 2,
      term_of_conditions_11_3: 1,
      term_of_conditions_11_4: 1,
      term_of_conditions_11_5: 1,
      term_of_conditions_11_6: 1,
      term_of_conditions_11_7: 1,
      term_of_conditions_11_8: 1,
      term_of_conditions_11_9: 2
    }
  }
});
</script>

<style scoped>
.web .term_of_conditions .title {
  margin-top: 3rem;
}
.web .term_of_conditions .footer {
  margin-bottom: 3rem;
}
.phone .term_of_conditions {
  padding: 1.5rem 1rem;
}
.term_of_conditions div {
  width: 100%;
}
.term_of_conditions .title {
  padding: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8125rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
}
.term_of_conditions .content_title {
  padding: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.1875;
  letter-spacing: 0em;
  text-align: left;
  color: var(--term_of__title_color);
}
.term_of_conditions .content_body {
  display: flex;
}
.term_of_conditions .content_desc.li {
  width: 2rem;
  padding-left: 1.5rem;
}
.term_of_conditions .content_desc {
  padding: 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9375rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
}
.term_of_conditions .content_desc.li::before {
  content: '\2022'; /* Unicode编码，代表圆点 */
  margin-right: 0.5; /* 调整圆点与div之间的距离 */
}
</style>
