<template>
  <div class="w-full max-w-full box-border">
    <page-loading :show="appStore.isLogin && isLoading"></page-loading>
    <!-- 已登录 -->
    <personInfoCard v-if="appStore.isLogin && !isLoading" :userInfo="userInfo" />
    <!-- 未登录 -->
    <div v-if="!appStore.isLogin" class="bg-[gray] md:px-[3rem] px-[1rem] py-[1.5rem] bg-[url('../../assets/images/vip/vip-banner.webp')] bg-cover rounded-lg">
      <div class="text-left text-base text-[#fff] font-bold">{{ t('vip_1_0') }}</div>
      <div class="text-left text-base text-[#fff] font-bold md:w-[29rem] text-gradient">{{ t('vip_1_1') }}</div>
      <wgButton class="w-[8.75rem] py-[0.69rem] mt-[1.63rem]" @click="handleStartGameClick">{{ t('start_game') }}</wgButton>
    </div>
    <div class="container-vip-system">
      <div :class="'container-vip-system-title ' + (matchSM ? 'matchSM' : '')">{{ textData.wingame_vip__system }}</div>
      <div :class="'container-vip-system-level ' + (matchSM ? 'matchSM' : '')">
        <div :class="'container-vip-system-level-item ' + (matchSM ? 'matchSM' : '')" v-for="(item, index) in levelArr" :key="index">
          <div class="container-vip-system-level-item-header">
            <div class="container-vip-system-level-item-header-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" :fill="item.levelColor">
                <path
                  d="M13.5999 10.3522V18.0667H15.2212V15.2652C17.8122 15.3036 19.1261 15.1884 19.4898 12.9772C19.6053 12.3306 19.3744 11.6048 19.0874 11.2068C18.2326 10.0492 15.662 10.3362 13.5999 10.3522ZM15.2212 13.9541V11.7045C16.424 11.6661 17.697 11.5327 17.9072 12.5049C18.2533 14.1306 16.5732 13.9925 15.2212 13.9541ZM10.4775 10.3906C10.4957 12.9432 10.4957 15.514 10.5159 18.0667H12.0985V10.3522C11.5649 10.3749 11.0291 10.3749 10.4772 10.3906H10.4775ZM13.5458 0.0666504C11.0473 2.56043 8.55574 5.03625 6.03931 7.53473V0.0666504H0C0.018229 0.561871 0.018229 1.07504 0.0383913 1.57247H1.50582V18.0553C7.50674 12.05 13.4892 6.06729 19.4901 0.0666504H13.5458Z"
                  :fill="item.levelColor"
                />
              </svg>
            </div>
            <div class="container-vip-system-level-item-header-line"></div>
          </div>
          <div class="container-vip-system-level-item-content">
            <div class="container-vip-system-level-item-content-title" :style="{ backgroundColor: item.levelColor }">{{ item.levelName }}</div>
            <div class="container-vip-system-level-item-content-line"></div>
            <div class="container-vip-system-level-item-content-wager-amount">{{ item.wagerAmount }}</div>
            <div class="container-vip-system-level-item-content-wager">{{ textData.wager_amount }}</div>
            <div class="container-vip-system-level-item-content-intro">
              <div class="container-vip-system-level-item-content-intro-item" v-for="(item2, index2) in item.introArr" :key="index2">
                <div style="display: flex; align-items: center; justify-content: center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" :fill="item.levelColor">
                    <path
                      d="M7 0.5C3.13433 0.5 0 3.63433 0 7.5C0 11.3657 3.13433 14.5 7 14.5C10.8657 14.5 14 11.3657 14 7.5C14 3.63433 10.8657 0.5 7 0.5ZM10.5642 5.91493L7.43582 10.0851C7.27164 10.303 7.01493 10.4313 6.74328 10.4343C6.58209 10.4343 6.4209 10.3896 6.28358 10.303L3.76716 8.75672C3.49552 8.59552 3.32836 8.30298 3.33433 7.98657C3.3403 7.67015 3.51642 7.38358 3.79701 7.23433C4.07761 7.08507 4.41493 7.1 4.6806 7.27313L6.52239 8.40149L9.17015 4.87015C9.4597 4.48507 10.006 4.40746 10.394 4.69701C10.7761 4.98358 10.8537 5.52985 10.5642 5.91493Z"
                      :fill="item.levelColor"
                    />
                  </svg>
                </div>
                <div class="container-vip-system-level-item-content-intro-item-desc">{{ item2 }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-vip-benefit">
      <div class="container-vip-system-title">{{ textData.wingame_vip__benefits }}</div>
      <div :class="'container-vip-benefit-content ' + (matchSM ? 'matchSM' : '')">
        <div class="container-vip-benefit-content-item" v-for="(item, index) in benefitsArr" :key="index">
          <div class="container-vip-benefit-content-item-left">
            <img :src="item.icon" />
          </div>
          <div class="container-vip-benefit-content-item-right">
            <div class="container-vip-benefit-content-item-right-title">{{ item.title }}</div>
            <div class="container-vip-benefit-content-item-right-desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="faqArr && faqArr.length > 0" class="container-vip-faq">
      <div class="container-vip-faq-title">{{ textData.faq }}</div>
      <div class="container-item-faq">
        <div class="container-item-faq-item" :class="currentOpenFAQ == index ? 'selected' : ''" v-for="(item, index) in faqArr" :key="index" @click="handleFAQClick(index)">
          <div class="container-item-faq-item-header">
            <div class="container-item-faq-item-header-title">{{ item.question }}</div>
            <div class="container-item-faq-item-header-arrow" :class="currentOpenFAQ == index ? 'selected' : ''">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                <path opacity="0.6" d="M0 0L8 9L0 18" fill="var(--icon-bg-color)" />
              </svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 3.33334L8.82501 4.50834L13.475 9.16668H3.33334V10.8333H13.475L8.82501 15.4917L10 16.6667L16.6667 10L10 3.33334Z" fill="#80A4DB"/>
              </svg>
            </div>
          </div>
          <div class="container-item-faq-item-desc" v-if="currentOpenFAQ == index">{{ item.answer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';

import pageLoading from '@/components/loading/pageLoading.vue';
import { toast } from 'vue3-toastify';
import benefit1 from '../../assets/images/vip/vip-benefit-1.svg';
import benefit2 from '../../assets/images/vip/vip-benefit-2.svg';
import benefit3 from '../../assets/images/vip/vip-benefit-3.svg';
import benefit4 from '../../assets/images/vip/vip-benefit-4.svg';
import benefit5 from '../../assets/images/vip/vip-benefit-5.svg';
import { getCookieUserId } from '@/assets/tools/CommonTool';
import { userInfoService, userVipQAService } from '@/assets/network/service/user';
import personInfoCard from '@/pages/profile/components/personInfoCard';
import wgButton from '@/components/common/wgButton.vue';
import { useAppStore } from '@/store/store';
import emitter from '@/assets/tools/eventBus';
import { onMounted } from 'vue';

const appStore = useAppStore();
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  welcome: t('welcome'),
  your_vip_progress: t('your_vip_progress'),
  none: t('none'),
  bronze: t('bronze'),
  silver: t('silver'),
  gold: t('gold'),
  platinum_1: t('platinum_1'),
  platinum_2: t('platinum_2'),
  platinum_3: t('platinum_3'),
  platinum_1_3: t('platinum_1_3'),
  wingame_vip__system: t('wingame_vip__system'),
  wager_amount: t('wager_amount'),
  the_support__choose: t('the_support__choose'),
  level_up_bonuses: t('level_up_bonuses'),
  rakeback: t('rakeback'),
  bonus_growth: t('bonus_growth'),
  weekly_bonuses: t('weekly_bonuses'),
  wingame_vip__benefits: t('wingame_vip__benefits'),
  boost: t('boost'),
  every_week__bonuses: t('every_week__bonuses'),
  dedicated_vip_host: t('dedicated_vip_host'),
  receive_your__needs: t('receive_your__needs'),
  recent_play_bonuses: t('recent_play_bonuses'),
  having_a__up: t('having_a__up'),
  level_ups: t('level_ups'),
  reach_a__go: t('reach_a__go'),
  bespoke: t('bespoke'),
  work_with__needs: t('work_with__needs'),
  unparalleled_vip_experience: t('unparalleled_vip_experience'),
  unlock_exclusive__attached: t('unlock_exclusive__attached'),
  start_game: t('start_game'),
  faq: t('faq'),
};

const levelArr = [
  {
    level: 1,
    levelColor: '#775124',
    levelName: textData.bronze,
    wagerAmount: '$10k',
    introArr: [textData.the_support__choose, textData.level_up_bonuses, textData.rakeback]
  },
  {
    level: 2,
    levelColor: '#94A0BF',
    levelName: textData.silver,
    wagerAmount: '$50-100k',
    introArr: [textData.the_support__choose, textData.level_up_bonuses, textData.rakeback, textData.bonus_growth]
  },
  {
    level: 3,
    levelColor: '#E6B86B',
    levelName: textData.gold,
    wagerAmount: '$100k',
    introArr: [textData.the_support__choose, textData.level_up_bonuses, textData.rakeback, textData.bonus_growth]
  },
  {
    level: 4,
    levelColor: '#4499E2',
    levelName: textData.platinum_1_3,
    wagerAmount: '$250k-$1M',
    introArr: [textData.the_support__choose, textData.level_up_bonuses, textData.rakeback, textData.weekly_bonuses]
  }
];
const benefitsArr = [
  {
    icon: benefit1,
    title: textData.boost,
    desc: textData.every_week__bonuses
  },
  {
    icon: benefit2,
    title: textData.dedicated_vip_host,
    desc: textData.receive_your__needs
  },
  {
    icon: benefit3,
    title: textData.recent_play_bonuses,
    desc: textData.having_a__up
  },
  {
    icon: benefit4,
    title: textData.level_ups,
    desc: textData.reach_a__go
  },
  {
    icon: benefit5,
    title: textData.bespoke,
    desc: textData.work_with__needs
  }
];

const isLoading = ref(false);
const userInfo = ref({
  email: 'lciuanaaa****@gmail.com',
  profile_image_url: '',
  registerTime: '2023/07/31',
  userName: '',
  profit: 8888.8888
});
const currentOpenFAQ = ref(-1);
const faqArr = ref([]);
const handleFAQClick = (index) => {
  if (index == currentOpenFAQ.value) {
    currentOpenFAQ.value = -1;
  } else {
    currentOpenFAQ.value = index;
  }
};

// const router = useRouter();
const handleStartGameClick = () => {
  emitter.emit('custom-login', 'sign_up');
};
const fetchUserInfo = async () => {
  try {
    const response = await userInfoService(getCookieUserId());
    userInfo.value = response.data;
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};
watch(
  () => appStore.isLogin,
  (newVal) => {
    if (newVal) {
      isLoading.value = true;
      fetchUserInfo();
    }
  },
  {
    immediate: true
  }
);
const fetchVIPQAData = async () => {
  try {
    const response = await userVipQAService();
    faqArr.value = response.data.data;
  } catch (error) {
    console.log(error)
  }
};

onMounted(() => {
  fetchVIPQAData();
})
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(90deg, #c0f4ff 0%, #27e1d6 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  position: relative;
  width: 100%;
  /* min-height: 16rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.header-content.matchSM {
  height: calc(100% - 4rem);
  margin: 2rem 0;
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-container-left-normal {
  width: calc(100% - 35.25rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(100% - 4rem);
  padding: 2rem 0.5rem;
}
.header-container-left-normal.matchSM {
  width: calc(100% - 1rem);
  height: 100%;
  justify-content: center;
  padding: 0 0.5rem;
}
.header-container-left-normal-title {
  width: 100%;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: left;
}
.header-container-left-normal-title.matchSM {
  font-size: 1.5rem;
}
.header-container-left-normal-desc {
  width: 100%;
  color: #8ea3c3;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
  text-align: left;
  margin-top: 1.125rem;
}
.header-container-left-normal-button {
  background-color: #1184fa;
  border: none;
  border-radius: 0.5rem;
  min-width: 7.375rem;
  height: 2.5rem;
  padding: 0 1.5625rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1.75rem;
  transition: all 0.2s;
}
.header-container-left-normal-button:hover {
  opacity: 0.8;
}
.header-container-left-normal-button:active {
  transform: scale(0.96);
}

.header-container-left-login {
  width: calc(100% - 35.25rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.header-container-left-login.matchSM {
  width: calc(100% - 1rem);
  height: 100%;
  justify-content: center;
  padding: 0 0.5rem;
}
.header-container-left-login-title {
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: left;
}
.header-container-left-login-progress-title {
  max-width: 22.625rem;
  min-width: 16.875rem;
  width: 100%;
  margin-top: 1.125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.375rem;
}
.header-container-left-login-progress-title.matchSM {
  max-width: 100%;
  min-width: 100%;
}
.header-container-left-login-progress-title-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
}
.header-container-left-login-progress-title-left-text {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
}
.header-container-left-login-progress-title-right {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: right;
}
.header-container-left-login-progress-bar {
  margin-top: 1rem;
  position: relative;
  max-width: 22.625rem;
  min-width: 16.875rem;
  width: 100%;
  height: 0.75rem;
  border-radius: 1rem;
  background-color: #55657e;
  overflow: hidden;
}
.header-container-left-login-progress-bar.matchSM {
  max-width: 100%;
  min-width: 100%;
}
.header-container-left-login-progress-bar-current {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-radius: 1rem;
  background-color: #43a0ff;
  transition: all 0.2s;
}
.header-container-left-login-progress-icon {
  margin-top: 0.5rem;
  max-width: 22.625rem;
  min-width: 16.875rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
}
.header-container-left-login-progress-icon.matchSM {
  max-width: 100%;
  min-width: 100%;
}
.header-container-left-login-progress-icon-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.header-container-left-login-progress-icon-item-text {
  color: #8ea3c3;
  font-size: 0.875rem;
  font-weight: 600;
}
.header-container-right {
  width: 34rem;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -4rem;
}
.container-vip-system {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 1.5rem;
}
.container-vip-system.matchSM {
  margin-top: 1rem;
}
.container-vip-system-title {
  width: 100%;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.25rem;
}
.container-vip-system-level {
  margin-top: 2.25rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.625rem;
  /* width: 100%; */
  box-sizing: border-box;
  /* max-width: 1200px; */
  /* display: flex; */
  /* max-width: 1100px; */
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
}
.container-vip-system-level.matchSM {
  width: 100vw;
  margin-top: 1.25rem;
  width: 1;
  overflow-x: auto;
  display: flex;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
}
.container-vip-system-level.matchSM::-webkit-scrollbar {
  display: none;
}
.container-vip-system-level-item {
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* width: 256px; */
}
.container-vip-system-level-item.matchSM {
  /* scroll-snap-align: start; */
  /* width: calc((100% - 0.625rem) * 4 / 5);
  min-width: calc((100% - 0.625rem) * 4 / 5); */
}
.container-vip-system-level-item-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}
.container-vip-system-level-item-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c394e;
  border-radius: 4rem;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
}
.container-vip-system-level-item-header-line {
  width: 100%;
  height: 2px;
  background-color: #2c394e;
}
.container-vip-system-level-item-content {
  margin-top: 2.25rem;
  background-color: #1e2736;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.container-vip-system-level-item-content-title {
  margin-top: 0.625rem;
  margin-left: 1.125rem;
  border-radius: 2px;
  height: 1.5rem;
  padding: 0px 0.8125rem;
  min-width: 2.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 0.75rem;
  font-weight: 700;
}
.container-vip-system-level-item-content-line {
  margin-top: 0.625rem;
  background-color: #55657e;
  width: 100%;
  height: 1px;
}
.container-vip-system-level-item-content-wager-amount {
  margin: 1.125rem;
  margin-bottom: 0;
  color: #fff;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.container-vip-system-level-item-content-wager {
  margin: 0 1.125rem;
  color: #8ea3c3;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.0625rem;
}
.container-vip-system-level-item-content-intro {
  margin: 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.container-vip-system-level-item-content-intro-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.container-vip-system-level-item-content-intro-item-desc {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.15rem;
  text-align: left;
}

.container-vip-benefit {
  margin-top: 1.75rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container-vip-benefit-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.75rem;
  margin-top: 1.75rem;
}
.container-vip-benefit-content.matchSM {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.125rem;
  margin-bottom: 2rem;
}
.container-vip-benefit-content-item {
  background-color: #1e2736;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5.25rem;
  padding: 0.5rem 1.125rem;
  gap: 1.125rem;
  width: calc(100% - 2.25rem);
}
.container-vip-benefit-content-item-left {
  background-color: #2c394e;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.25rem;
  height: 4.25rem;
  min-width: 4.25rem;
}
.container-vip-benefit-content-item-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.container-vip-benefit-content-item-right-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: left;
  width: 100%;
}
.container-vip-benefit-content-item-right-desc {
  color: #8ea3c3;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.15rem;
  text-align: left;
  width: 100%;
}

.container-vip-faq {
  margin-top: 1.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}
.container-vip-faq-title {
  width: 100%;
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: left;
  line-height: 0.9rem;
}
.container-item-faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.container-item-faq-item {
  background-color: #222942;
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: calc(100% - 1.5rem * 2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}
.container-item-faq-item:hover {
  opacity: 0.9;
}
.container-item-faq-item.selected {
  background-color: #2D3759;
}
.container-item-faq-item-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.125rem;
}
.container-item-faq-item-header-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 400;
  text-align: left;
  line-height: 1.75rem;
  width: 100%;
}
.container-item-faq-item-header-arrow {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}
.container-item-faq-item-header-arrow.selected {
  transform: rotate(90deg);
}
.container-item-faq-item-desc {
  color: #AAC4ED;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  white-space: pre-line;
}
</style>


