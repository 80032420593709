<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
// 传参
const props = defineProps({
  type: {
    type: String,
    default: 'Deposit'
  }
});
// 属性
const { t } = useI18n();
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="bg-[#072415] rounded-[4px] p-[10px]">
    <div class="text-[#00B555] text-[14px] font-[700] text-left">{{ t('NOTICE') }}</div>
    <ul v-if="props.type == 'Deposit'" class="my-[5px] pl-[22px] text-[#FFF] text-[12px] font-[400] text-left">
      <li>{{ t('NOTICE_Deposit_1') }}</li>
      <li>{{ t('NOTICE_Deposit_2') }}</li>
      <li>{{ t('NOTICE_Deposit_3') }}</li>
    </ul>
    <ul v-if="props.type == 'Withdraw'" class="my-[5px] pl-[22px] text-[#FFF] text-[12px] font-[400] text-left">
      <li>{{ t('NOTICE_Withdraw_1') }}</li>
    </ul>
  </div>
</template>

<style scoped></style>
