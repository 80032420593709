<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, onUnmounted } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';

const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['close', 'settingChoose']);
const settingChoose = (type) => {
  emit('settingChoose', type);
};
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  notification: t('notification'),
  mfa_or_w_pass_first: t('mfa_or_w_pass_first'),
  standard: t('standard'),
  more_safty: t('more_safty'),
  set_withdrawal_PIN: t('set_withdrawal_PIN'),
  bind_google_authenticator: t('bind_google_authenticator')
};

onUnmounted(() => {});
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title">{{ textData.notification }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <div class="content_main">
        <div class="tip">{{ textData.mfa_or_w_pass_first }}</div>

        <div class="setting-type" style="margin-top: 1.125rem">1、{{ textData.standard }}</div>

        <div class="bottom">
          <button :class="'bottom-button'" @click="settingChoose(1)">
            {{ textData.set_withdrawal_PIN }}
          </button>
        </div>
        <div class="setting-type">2、{{ textData.more_safty }}</div>
        <div class="bottom margin-bottom-6">
          <button :class="'bottom-button'" @click="settingChoose(2)">
            {{ textData.bind_google_authenticator }}
          </button>
        </div>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.125rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content_main {
  padding: 0.625rem;
  width: calc(100% - 1.25rem);
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: var(--green-color);
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0;
  text-align: left;
}
.setting-type {
  color: var(--white-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
}
.bottom-button-disable {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}
.margin-bottom-6 {
  margin-bottom: 6rem;
}
.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
