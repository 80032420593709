<script setup>
import { useI18n } from 'vue-i18n';
import { defineEmits, defineProps, ref } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { Vue3Lottie } from 'vue3-lottie';
import wgButton from '@/components/common/wgButton.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
// import wgTypeInput from '@/components/common/wgTypeInput.vue';
import wgInput from '@/components/common/wgInput';
import { bindInviteCodeService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';

const { t } = useI18n();
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close','bindSuccess'])

const confirmLoadingJson = ref(loadingAniJson);

const confirming = ref(false);
const inviteCode = ref('');

const bindInviteCode = () => {
  if (confirming.value) {
    toast(t('invite_code_binding'), { autoClose: 2000 });
    return;
  }
  let params = {
    invite_code: inviteCode.value
  };
  confirming.value = true;
  bindInviteCodeService(params)
    .then((res) => {
      confirming.value = false;
      toast(res.data.msg, { autoClose: 2000 });
      emit('bindSuccess');
      emit('close');
    })
    .catch((error) => {
      confirming.value = false;
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};

const handleValueChanged = (value) => {
  inviteCode.value = value;
};
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content !bg-[#121829] ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title text-[#fff]">{{ t('link_to_superior') }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <!-- <wg-type-input
        type="text"
        :value="inviteCode"
        :placeholder="t('enter_your___code')"
        @onValueChange="handleValueChanged"
      ></wg-type-input> -->
      <wg-input type="text" v-model="inviteCode" :placeholder="t('enter_your___code')" @onValueChange="handleValueChanged"></wg-input>
      <div class="bottom">
        <wgButton class="bottom-button !text-[#121829] !font-bold" @click="bindInviteCode" :disabled="confirming">
          {{ confirming ? '' : t('confirm').toLocaleUpperCase() }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
        </wgButton>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.125rem 1.1875rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: var(--green-color);
  font-size: 0.875rem;
  font-weight: 700;
  text-align: left;
}

.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
}
.bottom-button-disable {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  line-height: 2.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
