<!-- 统一弹窗管理 -->
<script setup>
import { onMounted } from 'vue';
// import { useAppStore } from '@/store/store';
import useDialogStore from '@/store/dialog';
import freeTurntableDialog from '@/components/freeTurntable';
import turntableDialog from '@/components/turntableDialog';
import freeSpinResDialog from '@/components/promotionDialog/components/freeSpinResDialog.vue';
import wgDialog from '@/components/promotionDialog/components/showPopupConfig.vue';
import tgEntry from '@/components/notice/tgEntry.vue';

// const appStore = useAppStore();
const dialogStore = useDialogStore();
// const bool = ref(true)
// const dialogCurrent = ref(0);
// const dialogList = ref([]);

// const isShowDialog = computed(() => {
//   if (dialogStore.dialogList.length > 0) {
//     if (dialogStore.dialogList[dialogStore.dialogCurrent].isShow && dialogStore.dialogList[dialogStore.dialogCurrent].sort == dialogStore.dialogCurrent) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// });

/**
 * 初始化弹窗
 * data 为数字时 1-首页 2-注册 3-登录 4-充值 5-提现
 * data 为对象时 手动push到数组
 */
// const initDialog = (param) => {
//   resetDialogData();
//   const dataType = getType(param);
//   if (dataType == 'Number') {
//     getAllDialogData(param);
//   } else if (dataType == 'Object') {
//     pushDialogData(param, 0);
//   } else {
//     console.log('init dialog failed');
//   }
// };

const nextDialog = () => {
  if (dialogStore.dialogCurrent < dialogStore.dialogList.length - 1) {
    dialogStore.dialogCurrent++;
    dialogStore.dialogList[dialogStore.dialogCurrent].isShow = true;
  }
};

const closeDialog = () => {
  dialogStore.dialogList[dialogStore.dialogCurrent].isShow = false;
  setTimeout(() => {
    nextDialog();
  }, 300);
};

// const isShowDialog = () => {
//   if (dialogStore.dialogList.length > 0) {
//     if (dialogStore.dialogList[dialogStore.dialogCurrent].isShow && dialogStore.dialogList[dialogStore.dialogCurrent].sort == dialogStore.dialogCurrent) {
//       return true;
//     } else {
//       return false;
//     }
//   } else {
//     return false;
//   }
// };

// const resetDialogData = () => {
//   dialogStore.dialogCurrent = 0;
//   dialogStore.dialogList = [];
// };

// const pushDialogData = (data, index) => {
//   dialogStore.dialogList.push({
//     sort: index,
//     isShow: true,
//     data: data
//   });
// };

// const getAllDialogData = (type) => {
//   getFreeSpinData();
//   getSpinData();
//   getPopupDialogData(type);
//   console.log('DialogListData', dialogStore.dialogList);
// };

// // 获取弹窗配置数据
// const getPopupDialogData = async (type) => {
//   try {
//     const res = await fetchPopupConfig({ type: type });
//     console.log('PopupDialogData', res.data);
//     const len = res.data.data.length;
//     let sort = dialogStore.dialogList.length;
//     for (let i = 0; i < len; i++) {
//       pushDialogData(res.data.data[i], sort);
//       sort++;
//     }
//   } catch (error) {
//     toast(error, { autoClose: 2000, type: 'error' });
//   }
// };

// // 获取免费转盘数据
// const getFreeSpinData = async () => {
//   try {
//     const res = await getFreePromotionsService();
//     console.log('FreeSpinData', res.data);
//     let sort = dialogStore.dialogList.length;
//     pushDialogData(res.data.data, sort);
//   } catch (error) {
//     toast(error, { autoClose: 2000, type: 'error' });
//   }
// };

// // 获取转盘数据
// const getSpinData = async () => {
//   try {
//     const res = await getTurntableList();
//     console.log('SpinData', res.data);
//     let sort = dialogStore.dialogList.length;
//     pushDialogData(res.data.data, sort);
//   } catch (error) {
//     toast(error, { autoClose: 2000, type: 'error' });
//   }
// };

// onMounted(() => {
//   emitter.on('custom-popupDialog', initDialog);
// });
// 初始化，页面挂载时启动弹窗逻辑
onMounted(() => {
  setTimeout(() => {
    dialogStore.initDialog(1);
  }, 2000);
});
</script>

<template>
  <div v-if="dialogStore.dialogList && dialogStore.dialogList.length > 0">
    <!-- 免费转盘 -->
    <freeTurntableDialog v-if="dialogStore.dialogList[dialogStore.dialogCurrent].type == 'freeSpin'" v-model:isShow="dialogStore.dialogList[dialogStore.dialogCurrent].isShow" :data="dialogStore.dialogList[dialogStore.dialogCurrent].data" @close="closeDialog" />
    <!-- 免费结果 -->
    <freeSpinResDialog @close="closeDialog" />
    <!-- 转盘 -->
    <turntableDialog v-if="dialogStore.dialogList[dialogStore.dialogCurrent].type == 'spin'" v-model="dialogStore.dialogList[dialogStore.dialogCurrent].isShow" :initiativeTab="true" :list="dialogStore.dialogList[dialogStore.dialogCurrent].data" @close="closeDialog" />
    <!-- 弹窗配置(中间) -->
    <div style="width: 100vw; height: 100vh; position: absolute" v-if="dialogStore.dialogList[dialogStore.dialogCurrent].type == 'popupCenter'">
      <wgDialog :show="dialogStore.dialogList[dialogStore.dialogCurrent].isShow" :notifyDates="dialogStore.dialogList[dialogStore.dialogCurrent].data" @close="closeDialog" />
    </div>
    <!-- 弹窗配置(侧滑) -->
    <tgEntry v-if="dialogStore.dialogList[dialogStore.dialogCurrent].type == 'popupSide'" :show="dialogStore.dialogList[dialogStore.dialogCurrent].isShow" :config="dialogStore.dialogList[dialogStore.dialogCurrent].data" @close="closeDialog" />
  </div>
</template>

<style scoped></style>
