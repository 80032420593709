<template>
  <div :class="props.isMainClass ? 'main-content' : ''">
    <div class="body profit">
      <div class="profit-header bg-[#191F33]">
        <div class="web font-bold text-[#80A4DB] left text-left ml-[1.125rem]" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'">
          {{ t('game_details_1_1_3') }}
        </div>
        <div class="web !font-bold !text-[#80A4DB] item" style="width: 21%">
          {{ t('game_details_1_1_5') }}
        </div>
        <div class="web !font-bold !text-[#80A4DB] item" :style="!props.isAll ? 'width: 12%;' : 'width: 16%;'">
          {{ t('game_details_1_1_6') }}
        </div>
        <div class="web !font-bold !text-[#80A4DB] item" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'">
          {{ t('game_details_1_1_7') }}
        </div>
        <div class="web !font-bold !text-[#80A4DB] item" :style="!props.isAll ? 'width: 10%;' : 'width: 14%;'">
          {{ t('game_details_1_1_8') }}
        </div>
        <div class="web !font-bold !text-[#80A4DB] item last" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'">
          {{ t('game_details_1_1_9') }}
        </div>
        <div class="phone !font-bold !text-[#80A4DB] item" style="width: 47%; display: flex; align-items: center; justify-content: left; margin-left: 3%">
          {{ t('game_details_1_1_3') }}
        </div>
        <div class="phone !font-bold !text-[#80A4DB] item last" style="width: 47%; display: flex; align-items: center; justify-content: right; margin-right: 3%">
          {{ t('game_details_1_1_9') }}
        </div>
      </div>
      <div class="profit-header" v-show="props.profitList.length == 0">
        <div style="display: block; width: 100%">
          <div style="width: 100%; height: 12rem; justify-content: center; align-items: center" v-show="props.profitList.length == 0">
            <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; margin-top: 5rem">
              <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
            </div>
            <div class="no-data">
              {{ t('no_data') }}
            </div>
          </div>
        </div>
      </div>
      <div ref="listContainer" class="h-[580px] md:h-[535px] relative max-h-[90rem] w-full overflow-hidden">
        <transition-group name="slide" tag="div">
          <div class="profit-header record_content !bg-[#222942] mt-2" v-for="profit in props.profitList" :key="profit.game_cycle_uid || profit">
            <div class="web item left" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'">
              <img :src="slotsImg1" v-if="profit.game_type == 'CasinoSlot'" />
              <img :src="pokerImg1" v-if="profit.game_type == 'CasinoLive' || profit.game_type == 'Live'" />
              <span class="text-[#fff]">{{ profit.game_name }}</span>
            </div>
            <div class="web item !text-[#fff]" style="width: 21%"><img :src="getHeaderImg(profit.player.profile_image_url)" />{{ profit.player.name }}</div>
            <div class="web item" :style="!props.isAll ? 'width: 12%;' : 'width: 16%;'">
              {{ timeDifference(profit.bet_time) }}
            </div>
            <div class="web item" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'"><img :src="getSymbolIconUrl(profit.symbol)" />{{ profit.bet_amount }}</div>
            <div class="web item" :style="!props.isAll ? 'width: 10%;' : 'width: 14%;'">{{ profit.multiplier }}x</div>
            <div class="web item last" :style="!props.isAll ? 'width: 19%;' : 'width: 23%;'">
              <img :src="getSymbolIconUrl(profit.symbol)" />
              <span>{{ profit.profit }}</span>
            </div>

            <div class="phone item" style="width: 47%; display: flex; align-items: center; justify-content: left; margin-left: 3%">
              <img :src="slotsImg1" v-if="profit.game_type == 'CasinoSlot'" />
              <img :src="pokerImg1" v-if="profit.game_type == 'CasinoLive' || profit.game_type == 'Live'" />
              <span>{{ profit.game_name }}</span>
            </div>
            <div class="phone item last" style="width: 47%; display: flex; align-items: center; justify-content: right; margin-right: 3%">
              <img :src="getSymbolIconUrl(profit.symbol)" />
              <span>{{ profit.profit }}</span>
            </div>
          </div>
        </transition-group>
        <div class="bg-custom-gradient h-[5rem] absolute bottom-0 w-full flex items-center justify-center text-white"></div>
      </div>

      <div style="clear: both"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import slotsImg1 from '../../assets/images/home/btn_qh_1_1.svg';
import pokerImg1 from '../../assets/images/home/btn_9_2.svg';
import headerImg1 from '@/assets/images/profile/avatar/profile-avatar1.png';
import headerImg2 from '@/assets/images/profile/avatar/profile-avatar2.png';
import headerImg3 from '@/assets/images/profile/avatar/profile-avatar3.png';
import headerImg4 from '@/assets/images/profile/avatar/profile-avatar4.png';
import headerImg5 from '@/assets/images/profile/avatar/profile-avatar5.png';
import headerImg6 from '@/assets/images/profile/avatar/profile-avatar6.png';
import headerImg7 from '@/assets/images/profile/avatar/profile-avatar7.png';
import headerImg8 from '@/assets/images/profile/avatar/profile-avatar8.png';
import headerImg9 from '@/assets/images/profile/avatar/profile-avatar9.png';
import headerImg10 from '@/assets/images/profile/avatar/profile-avatar10.png';
import headerImg11 from '@/assets/images/profile/avatar/profile-avatar11.png';
import headerImg12 from '@/assets/images/profile/avatar/profile-avatar12.png';
import headerImg13 from '@/assets/images/profile/avatar/profile-avatar13.png';
import headerImg14 from '@/assets/images/profile/avatar/profile-avatar14.png';
import headerImg15 from '@/assets/images/profile/avatar/profile-avatar15.png';
import headerImg16 from '@/assets/images/profile/avatar/profile-avatar16.png';
import headerImg from '@/assets/images/profile/avatar/profile-avatar4.png';
import useWalletStore from '@/store/wallet';

const { t } = useI18n();
const walletStore = useWalletStore();

const isPhone = ref(false);
const props = defineProps({
  showRank: {
    type: Boolean,
    default: false
  },
  isMainClass: {
    type: Boolean,
    default: true
  },
  isAll: {
    type: Boolean,
    default: false
  },
  profitList: Array,
  headers: Array
});

const getSymbolIconUrl = computed(() => (symbol) => {
  const wallet = walletStore.currencyTokens.find((wallet) => wallet.symbol === symbol);
  return wallet ? wallet.icon_url : '';
});

// const getCreatedTime = (time) => {
//   const date = new Date(time * 1000);
//   const hour = date.getHours();
//   const m = date.getMinutes();
//   const ss = date.getSeconds();
//   return `${hour >= 10 ? hour : '0' + hour}:${m >= 10 ? m : '0' + m}:${ss >= 10 ? ss : '0' + ss}`;
// };

const headerImages = {
  'profile-avatar1': headerImg1,
  'profile-avatar2': headerImg2,
  'profile-avatar3': headerImg3,
  'profile-avatar4': headerImg4,
  'profile-avatar5': headerImg5,
  'profile-avatar6': headerImg6,
  'profile-avatar7': headerImg7,
  'profile-avatar8': headerImg8,
  'profile-avatar9': headerImg9,
  'profile-avatar10': headerImg10,
  'profile-avatar11': headerImg11,
  'profile-avatar12': headerImg12,
  'profile-avatar13': headerImg13,
  'profile-avatar14': headerImg14,
  'profile-avatar15': headerImg15,
  'profile-avatar16': headerImg16
};

const getHeaderImg = (headerImageUrl) => {
  const header = headerImageUrl.split('//')[1];
  return headerImages[header] || headerImg;
};

const resize = () => {
  isPhone.value = window.innerWidth <= 768;
};

onMounted(() => {
  resize();
  window.addEventListener('resize', resize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', resize);
});

const timeDifference = (timestampInSeconds) => {
  const now = Date.now(); // 当前时间的时间戳（毫秒）
  const timestamp = Number(timestampInSeconds) * 1000; // 将秒级时间戳转换为毫秒级时间戳
  const diffInSeconds = Math.floor((now - timestamp) / 1000); // 计算时间差，单位为秒
  if (diffInSeconds < 0) {
    return `0s`;
  }

  if (diffInSeconds < 60) {
    return `${diffInSeconds}s`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}h`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) {
    return `${diffInDays}d`;
  }

  const diffInWeeks = Math.floor(diffInDays / 7);
  if (diffInWeeks <= 99) {
    return `${diffInWeeks}w`;
  }

  return '99+w';
};
</script>

<style scoped>
.profit .profit-header div.item {
  color: var(--profit-item-color);
  float: left;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
}
.profit .profit-header div.item.last {
  margin-left: 0rem;
  justify-content: right;
  margin-right: 1.125rem;
}
.profit .profit-header div.item span {
  max-width: calc(100% - 2rem);
  white-space: nowrap;
}
.record_content.single {
  background: var(--profit-single-bg-color);
}

.list-container {
  transition: all 0.3s ease;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-move {
  transition: transform 0.5s ease;
}
</style>
