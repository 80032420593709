<script setup>
import wingameAni from '@/assets/animation/export_sclogo_wingame.json';
import { Vue3Lottie } from 'vue3-lottie';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';

const animationLoad = ref(wingameAni);

onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
  document.body.style.overflow = 'auto';
});
</script>

<template>
  <div class="launch-animation-container">
    <vue3-lottie :animationData="animationLoad" :width="200" :height="200"></vue3-lottie>
  </div>
</template>

<style scoped>
.launch-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c131d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  padding: 0;
  margin: 0;
}
</style>
