<template>
  <div class="my-dialog">
    <div class="dialog-main">
      <div style="max-width: 30rem; background-color: rgb(30, 39, 54); margin: 0px auto" class="profit">
        <div style="display: flex; line-height: 2.5rem; padding: 0.5rem 1rem">
          <div style="justify-content: left; white-space: nowrap; width: 70%; text-align: left; overflow: hidden">{{ t('select_avatar') }}</div>
          <div style="display: flex; justify-content: right; width: 30%">
            <span style="font-size: 0.875rem; font-weight: 600; color: #6d728d; cursor: pointer" @click="close()">X</span>
          </div>
        </div>

        <div style="display: block; line-height: 2.5rem; padding: 0.5rem 1rem">
          <img @click="choose(image, index)" :src="image" v-for="(image, index) in images" :key="index" style="width: 20%; padding: 0 2.5%; cursor: pointer" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<script>
import profileAvatar1 from '../../assets/images/profile/avatar/profile-avatar1.png';
import profileAvatar2 from '../../assets/images/profile/avatar/profile-avatar2.png';
import profileAvatar3 from '../../assets/images/profile/avatar/profile-avatar3.png';
import profileAvatar4 from '../../assets/images/profile/avatar/profile-avatar4.png';
import profileAvatar5 from '../../assets/images/profile/avatar/profile-avatar5.png';
import profileAvatar6 from '../../assets/images/profile/avatar/profile-avatar6.png';
import profileAvatar7 from '../../assets/images/profile/avatar/profile-avatar7.png';
import profileAvatar8 from '../../assets/images/profile/avatar/profile-avatar8.png';
import profileAvatar9 from '../../assets/images/profile/avatar/profile-avatar9.png';
import profileAvatar10 from '../../assets/images/profile/avatar/profile-avatar10.png';
import profileAvatar11 from '../../assets/images/profile/avatar/profile-avatar11.png';
import profileAvatar12 from '../../assets/images/profile/avatar/profile-avatar12.png';
import profileAvatar13 from '../../assets/images/profile/avatar/profile-avatar13.png';
import profileAvatar14 from '../../assets/images/profile/avatar/profile-avatar14.png';
import profileAvatar15 from '../../assets/images/profile/avatar/profile-avatar15.png';
import profileAvatar16 from '../../assets/images/profile/avatar/profile-avatar16.png';
export default {
  name: 'App',
  props: {},
  watch: {},
  components: {
    // myI18n
  },
  data() {
    return {
      images: [profileAvatar1, profileAvatar2, profileAvatar3, profileAvatar4, profileAvatar5, profileAvatar6, profileAvatar7, profileAvatar8, profileAvatar9, profileAvatar10, profileAvatar11, profileAvatar12, profileAvatar13, profileAvatar14, profileAvatar15, profileAvatar16],
      localeShow: false,
      myShowMenu: true
    };
  },
  created() {},
  methods: {
    choose(img, index) {
      this.$emit('chooseImg', img, index);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
