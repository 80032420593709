<script setup>
import { ref, defineProps, defineEmits, onBeforeMount } from 'vue';
import { registerEmailSendCodeService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
// import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

// const router = useRouter();
const { t } = useI18n();
const props = defineProps({
  show: Boolean,
  email: String
});
const emit = defineEmits(['close', 'nextAfter']);
const inputCode = ref('');
const sendCodeText = ref('');
const sendCodeTimer = ref(null);

onBeforeMount(() => {
  init();
});

const init = () => {
  sendCodeTimer.value = null
  inputCode.value = '';
  sendCodeText.value = t('send_code');
};

const clickSendCode = () => {
  if (sendCodeTimer.value != null) {
    return;
  }
  registerEmailSendCodeService({ email: props.email })
    .then(() => {
      toast(t('sent_successfully'));
      startCountdown(60, () => {
        init();
      });
    })
    .catch((e) => {
      for (let key in e.response.data) {
        toast(e.response.data[key], { autoClose: 3000, type: 'error' });
      }
    });
};
const startCountdown = (duration, callback) => {
  let time = duration;
  sendCodeTimer.value = setInterval(() => {
    time--;
    sendCodeText.value = `${time}s`;
    if (time < 0) {
      clearInterval(sendCodeTimer.value);
      sendCodeTimer.value = null;
      callback();
    }
  }, 1000);
};

const closeSelf = () => {
  clearInterval(sendCodeTimer.value);
  init();
  emit('close');
};

const clickNext = () => {
  const par = inputCode.value
  emit('nextAfter', par);
};

defineExpose({
  closeSelf
});

</script>

<template>
  <div v-if="props.show" class="my-dialog verify-email-container">
    <div class="dialog-main">
      <div class="dialog-header">
        <div class="header-text">{{ t('bind_email') }}</div>
        <img class="header-close" src="../../assets/images/login-and-register/Vector.svg" @click="closeSelf" />
      </div>
      <div class="dialog-body">
        <div class="input-div">
          <input v-model="inputCode" type="text" :placeholder="t('please_input___code')" />
          <div class="append">
            <div class="append-btn" @click="clickSendCode">{{ sendCodeText }}</div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <button class="footer-btn" @click="clickNext">{{ t('next') }}</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.verify-email-container {
  .dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .header-text {
      color: #fff;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .header-close {
      background-color: transparent;
      cursor: pointer;
      border: none;
      padding: 0;
      width: 16px;
      height: 16px;
    }
  }
  .dialog-body {
    margin: 20px 0 30px;
    .input-div {
      position: relative;
      background-color: #0d101e;
      border-radius: 6px;
      border: 1px solid #0d101e;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      input {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        background-color: transparent;
        border: none;
        /* color: #aac4ed; */
        color: #acbecd;
        caret-color: #5b7bab;
        outline: 0;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0;
        text-align: left;
        padding: 11px 10px;
        box-sizing: border-box;
      }
      .append {
        margin: 0 10px;
        img {
          cursor: pointer;
          display: block;
          width: 20px;
        }
        .append-btn {
          cursor: pointer;
          background-color: #273854;
          color: #aac4ed;
          border-radius: 3px;
          text-wrap: nowrap;
          font-size: 12px;
          line-height: 1;
          font-weight: 400;
          padding: 10px 14px;
          min-width: 40px;
          text-align: center;
        }
      }
    }
  }
  .dialog-footer {
    .footer-btn {
      border: 0;
      cursor: pointer;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-weight: 700;
      line-height: normal;
      border-radius: 8px;
      background-color: #3aa1ff;
      color: #0c131d;
      box-shadow:
        0px 0px 10px 0px rgba(17, 132, 250, 0.4),
        0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
        0px 0px 12px 0px #0d52b2 inset;
    }
  }
}
</style>
