<template>
  <div>
    <wgCard :title="t('account_setting')">
      <div>
        <div class="flex justify-between p-[1rem] bg-[#222942]">
          <div class="flex">
            <img
              src="../../../assets/images/profile/profile/ico_profile_1-1.svg"
              class="w-[2rem] h-[2rem]"
            />
            <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
              {{ t('email') }} / {{ t('Telegram') }}
            </div>
          </div>
          <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
            {{
              props?.userInfo?.is_telegram_user
                ? obfuscateTG(props?.userInfo?.telegram_username)
                : obfuscateEmail(props?.userInfo?.user_email)
            }}
          </div>
        </div>
        <div class="flex justify-between p-[1rem] bg-[#222942] mt-[1rem]">
          <div class="flex">
            <img
              src="../../../assets/images/profile/profile/ico_profile_2-1.svg"
              class="w-[2rem] h-[2rem]"
            />
            <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
              {{ t('password') }}
            </div>
          </div>
          <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
            <wgButton
              class="!px-[1.5rem]"
              @click="resetPasswordEvent"
              >{{ t('reset').toLocaleUpperCase() }}</wgButton
            >
          </div>
        </div>
        <div class="flex justify-between p-[1rem] bg-[#222942] mt-[1rem]">
          <div class="flex">
            <img
              src="../../../assets/images/profile/profile/ico_profile_1-1.svg"
              class="w-[2rem] h-[2rem]"
            />
            <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
              {{ t('Email_Verification') }}
            </div>
          </div>
          <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
            <div v-if="props.userInfo.verify_email">
              <el-icon
                color="#00D66F"
                size="0.79rem"
                ><CircleCheckFilled
              /></el-icon>
              {{
                props?.userInfo?.is_telegram_user
                  ? obfuscateEmail(props?.userInfo?.user_email)
                  : t('Verifed')
              }}
            </div>
            <wgButton
              v-else
              class="!px-[1.5rem]"
              @click="handleOpenBindEmailClick"
              >{{ t('verify').toLocaleUpperCase() }}</wgButton
            >
          </div>
        </div>
        <div class="flex justify-between p-[1rem] bg-[#222942] mt-[1rem]">
          <div class="flex">
            <img
              src="../../../assets/images/profile/profile/ico_profile_5.svg"
              class="w-[2rem] h-[2rem]"
            />
            <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
              {{ t('2_Step_Verification') }}
            </div>
          </div>
          <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
            <div v-if="props.userInfo.enable_otp">
              <el-icon
                color="#00D66F"
                size="0.79rem"
                ><CircleCheckFilled
              /></el-icon>
              {{
                props?.userInfo?.is_telegram_user
                  ? obfuscateEmail(props?.userInfo?.user_email)
                  : t('Verifed')
              }}
            </div>
            <wgButton
              v-else
              class="!px-[1.5rem]"
              @click="handleOpenBindMfaClick"
              >{{ t('enable').toLocaleUpperCase() }}</wgButton
            >
          </div>
        </div>
        <div class="flex justify-between p-[1rem] bg-[#222942] mt-[1rem]">
          <div class="flex">
            <img
              src="../../../assets/images/profile/profile/ico_profile_7.svg"
              class="w-[2rem] h-[2rem]"
            />
            <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
              {{ t('link_to_superior') }}
            </div>
          </div>
          <div class="text-[0.875rem] text-[#AAC4ED] leading-[2.2425rem]">
            <div
              v-if="
                props.userInfo.parent_invite_code &&
                props.userInfo.parent_invite_code.length > 0
              "
            >
              <el-icon
                color="#00D66F"
                size="0.79rem"
                ><CircleCheckFilled
              /></el-icon>
              {{
                props?.userInfo?.is_telegram_user
                  ? obfuscateEmail(props?.userInfo?.user_email)
                  : props.userInfo.parent_invite_code
              }}
            </div>
            <wgButton
              v-else
              class="!px-[1.5rem]"
              @click="openBindSuperiorHandle"
              >{{ t('bind') }}</wgButton
            >
          </div>
        </div>
      </div>
    </wgCard>
    <reset-password
      v-if="showResetPassword"
      :is-begin="showResetPassword"
      @close="closeReset()"
    ></reset-password>
    <verified-email
      :show="openBindEmail"
      :bindEmail="props.userInfo.user_email"
      @close="handleCloseBindEmailClick"
      @bindSuccess="handleBindSuccess"
    ></verified-email>
    <bind-mfa
      :show="openBindMfa"
      v-if="openBindMfa"
      :email="props.userInfo.user_email"
      @close="handleCloseBindMfaClick"
      @bindSuccess="updateHandle"
    ></bind-mfa>
    <bind-superior
      :show="openBindSuperior"
      @close="closeBindSuperiorHandle"
      @bindSuccess="updateHandle"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import wgCard from '@/components/common/wgCard';
import wgButton from '@/components/common/wgButton';
import { obfuscateEmail, obfuscateTG } from '@/assets/tools/CommonTool';
import resetPassword from '@/components/password/resetpassword.vue';
import verifiedEmail from './verifiedEmail.vue';
import bindMfa from '../bindMfa.vue';
import bindSuperior from './bindSuperior.vue';

const { t } = useI18n();
const emit = defineEmits(['update']);
const showResetPassword = ref(false);
const openBindEmail = ref(false);
const openBindMfa = ref(false);
const openBindSuperior = ref(false);
const props = defineProps({
  userInfo: Object
});
const handleOpenBindEmailClick = () => {
  openBindEmail.value = true;
};

const resetPasswordEvent = () => {
  if(props.userInfo.verify_email) {
    showResetPassword.value = true;
  }else {
    handleOpenBindEmailClick()
  }
  
};

const closeReset = () => {
  showResetPassword.value = false;
};


const handleCloseBindEmailClick = () => {
  openBindEmail.value = false;
};

const handleBindSuccess = () => {
  openBindEmail.value = false;
  emit('update');
};

const handleCloseBindMfaClick = () => {
  openBindMfa.value = false;
};

const handleOpenBindMfaClick = () => {
  openBindMfa.value = true;
};

const updateHandle = () => {
  emit('update');
};

const openBindSuperiorHandle = () => {
  openBindSuperior.value = true;
};
const closeBindSuperiorHandle = () => {
  openBindSuperior.value = false;
};
</script>
