<script setup>
// 导入
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { useAppStore, useLoginStore } from '@/store/store';
import ButtonItem from '@/pages/promotion/module/button-item.vue';

const { t } = useI18n();
const appStore = useAppStore();
const loginStore = useLoginStore();
const walletStore = useWalletStore();
// const router = useRouter();
// 传参
const props = defineProps({
  promotionData: {
    type: Object,
    default: () => {}
  },
  promotionType: {
    type: String,
    default: ''
  },
  toRanPlay: {
    type: Function,
    default: () => {}
  }
});
// 属性
// 计算属性
const promotionSubTitle = computed(() => {
  if (props.promotionData.promotion_type == 'first_deposit') {
    return t('first_deposit');
  } else if (props.promotionData.promotion_type == 'second_deposit') {
    return t('second_deposit');
  } else if (props.promotionData.promotion_type == 'three_deposit') {
    return t('three_deposit');
  } else if (props.promotionData.promotion_type == 'total_deposit') {
    return t('accumulate_deposit');
  } else if (props.promotionData.promotion_type == 'single_deposit') {
    return t('single_deposit');
  } else if (props.promotionData.promotion_type == 'deposit') {
    return t('deposit_rank');
  } else {
    return '';
  }
});

// const totalPrize = computed(() => {
//   let res = 0;
//   const len = props.promotionData.promotion_prize_config.length;
//   for (let i = 0; i < len; i++) {
//     res += Number(props.promotionData.promotion_prize_config[i].amount);
//   }
//   return res;
// });

const btnText = computed(() => {
  if (props.promotionType == 'deposit') {
    return t('deposit').toLocaleUpperCase();
  } else if (props.promotionType == 'game') {
    return t('play_now').toLocaleUpperCase();
  } else {
    return '';
  }
});
// 生命周期
// 方法
const clickBtn = () => {
  if (props.promotionType == 'deposit') {
    goDeposit();
  } else if (props.promotionType == 'game') {
    goPlay();
  }
};

const goDeposit = () => {
  if (appStore.isLogin) {
    walletStore.showDialogDeposit = true;
  } else {
    loginStore.updateShow(true);
    loginStore.updateActType('login');
  }
};

const goPlay = () => {
  props.toRanPlay();
};
</script>

<template>
  <div class="relative">
    <div class="relative">
      <div class="text-[20px] text-[#ffffff] font-[700]">{{ props.promotionData.promotion_name }}</div>
      <div class="text-[16px] text-[#ffffff] font-[400]">{{ promotionSubTitle }}</div>
      <div class="text-[16px] text-[#AAC4ED] font-[400]">{{ `${t('Ends_at')} ${props.promotionData.end_time}` }}</div>
    </div>
    <div class="mt-[10px] md:flex md:justify-between md:items-center">
      <div v-if="props.promotionData.prize_show_type" class="flex justify-between items-center gap-[60px] bg-[#00000066] px-[16px] py-[8px]">
        <div class="text-[16px] text-[#ffffff] font-[700] leading-[16px] whitespace-pre-line">{{ t(props.promotionData.prize_show_type).toLocaleUpperCase() }}</div>
        <div class="text-[24px] text-[#FFB355] font-[700]">{{ `${Number(props.promotionData.prize_show_amount).toFixed(2)} ${props.promotionData.prize_show_symbol}` }}</div>
      </div>
      <div class="mt-[10px] md:mt-0">
        <ButtonItem class="!w-full" v-if="btnText != ''" :btnText="btnText" @click="clickBtn" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
