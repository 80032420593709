<script setup>
// 导入
import { ref, computed, defineProps } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { useAppStore } from '@/store/store';
import { getCookieUserId } from '@/assets/tools/CommonTool';
import { cryptoWalletWithdrawRequest, fiat_withdraw } from '@/assets/network/service/wallet';
import ButtonItem from '@/components/wallet/module/ButtonItem';
import wgTypePass from '@/components/common/wgTypeWithdrawPass.vue';
import { gaBeginCheckoutEvent } from '@/assets/tools/GoogleAnalyticsEvent';
import useDialogStore from '@/store/dialog';
const dialogStore = useDialogStore();

// 传参
const props = defineProps({
  tabSelect: String
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
const appStore = useAppStore();
const codePIN = ref('');
// 计算属性
const isDisabled = computed(() => {
  let res = false;
  if (codePIN.value.length != 6) {
    res = true;
  }
  return res;
});
// 生命周期
// 方法
const changePIN = (val) => {
  codePIN.value = val;
};
const clickConfirm = async () => {
  if (isDisabled.value) {
    return;
  }
  let par = {};
  if (props.tabSelect == 'Crypto') {
    const userId = getCookieUserId();
    par = {
      user_id: userId,
      platform: walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].platform,
      address: walletStore.cryptoWithdrawAddress,
      otp: codePIN.value,
      symbol: walletStore.cryptoSelectItem.symbol,
      amount: Number(walletStore.cryptoWithdrawAmount)
    };
    try {
      walletStore.isLoading = true;
      await cryptoWalletWithdrawRequest(par);
      walletStore.isLoading = false;
      toast(t('withdraw_successfully'), { autoClose: 2000, type: 'success' });
      walletStore.showDialogPIN = false;
      walletStore.getTokensInfo(2);
      appStore.reloadFlag = Math.random()
      dialogStore.initDialog(5)
      // emitter.emit('custom-withdraw-success', 'AFTER_WITHDRAW');
    } catch (error) {
      walletStore.isLoading = false;
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  } else if (props.tabSelect == 'Fiat') {
    par = {
      pay_id: walletStore.fiatWithdrawOptItem.pay_id,
      otp: codePIN.value,
      symbol: walletStore.fiatSelectItem.symbol,
      amount: Number(walletStore.fiatWithdrawAmount)
    };
    try {
      walletStore.isLoading = true;
      await fiat_withdraw(par);
      walletStore.isLoading = false;
      gaBeginCheckoutEvent({ currency: walletStore.fiatSelectItem.symbol, value: Number(walletStore.fiatWithdrawAmount) });
      toast(t('withdraw_successfully'), { autoClose: 2000, type: 'success' });
      walletStore.showDialogPIN = false;
      walletStore.getTokensInfo(2);
      appStore.reloadFlag = Math.random()
      dialogStore.initDialog(5)
      // emitter.emit('custom-withdraw-success', 'AFTER_WITHDRAW');
    } catch (error) {
      walletStore.isLoading = false;
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    }
  }
};
</script>

<template>
  <el-dialog v-model="walletStore.showDialogPIN" width="420" align-center :close-on-click-modal="false" class="bg-[#121829] text-left">
    <template #header>
      <div class="text-[#fff] text-[14px] font-[700]">{{ t('enter_MFA_PIN').toUpperCase() }}</div>
    </template>
    <div>
      <div>
        <wgTypePass @onValueChange="changePIN" pre-id="MFA-PIN" :text-title="t('PIN Code').toUpperCase()" />
      </div>
      <div class="w-full">
        <ButtonItem :btnText="t('confirm')" :disabled="isDisabled" @click="clickConfirm" />
      </div>
    </div>
  </el-dialog>
</template>

<style scoped></style>
