<template>
  <div class="main-content flex justify-center w-full">
    <div class="body flex justify-between ] w-full">
      <div class="flex text-[#fff] items-center">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.07545 10.4749C9.57596 9.90234 10.8976 11.3124 10.8139 13.2582C10.7252 15.3209 9.14007 16.9967 7.07545 16.9967C5.01083 16.9967 3.42566 15.3209 3.33699 13.2582C3.25335 11.3124 4.57494 9.90234 7.07545 10.4749ZM10.1924 10.4427C10.7941 9.97295 11.6873 9.79169 12.8444 10.057C15.2268 9.51184 16.4853 10.856 16.4056 12.709C16.3215 14.6752 14.8106 16.2717 12.8444 16.2717C12.0572 16.2748 11.2927 16.0082 10.6781 15.5162C11.0963 14.8433 11.3326 14.0734 11.364 13.2818C11.4089 12.2552 11.1015 11.3446 10.5013 10.7182C10.4053 10.6188 10.3021 10.5267 10.1924 10.4427ZM10.9098 3.69376C10.9995 3.56075 11.4494 3.8996 11.5548 4.02055C11.6054 4.0787 11.6308 4.15456 11.6254 4.23145C11.6201 4.30833 11.5844 4.37994 11.5263 4.43052C11.23 4.68829 10.949 4.95011 10.6801 5.21466C10.6795 5.2183 10.6797 5.22188 10.6789 5.22553C10.4088 6.51773 11.212 7.5026 11.9888 8.45499C12.2648 8.77837 12.5198 9.1191 12.7522 9.47509C12.4994 9.42618 12.2433 9.39659 11.9861 9.38656C11.8476 9.20181 11.6945 9.01367 11.5385 8.82233C10.8558 7.98537 10.1001 7.05495 10.0483 5.86958C8.87668 7.15159 8.02245 8.49353 7.52144 9.83629C7.37496 9.85496 7.22655 9.87836 7.07541 9.90979C7.0133 9.89688 6.95208 9.8866 6.89071 9.87584C7.40941 8.36715 8.34464 6.86211 9.65603 5.4363C9.78757 5.29454 9.90936 5.14405 10.0206 4.98583C10.3171 4.56005 10.6157 4.1297 10.9098 3.69376ZM11.6748 4.72382C11.9356 4.68179 13.7586 4.80201 14.5541 5.40188C15.5239 6.13315 15.4857 6.4132 15.8872 8.33377C14.7744 8.14023 13.8564 8.08455 12.6567 7.01873C12.0069 6.43601 11.6476 5.59616 11.6748 4.72382Z"
            fill="#FFFFFF"
          />
        </svg>
        <span class="pl-2 text-[#FFF] font-bold">{{ $t('game_details_1_1_2') }}</span>
      </div>
      <div class="tool-bar !w-auto tool-bar-search flex items-center">
        <div class="tool-bar-tabs width-auto">
          <div class="flex">
            <div :class="(indexProfit == 'all' ? 'bg-[#273854] !text-[#fff]' : '') + ' last cursor-pointer w-auto  h-[2.5rem]  text-[0.675rem] !px-[0.5rem]  md:text-[0.825rem] md:!px-[0.5rem]'" @click="profitChange('all')">
              {{ $t('all_beats') }}
            </div>

            <div :class="(indexProfit == 'highRollers' ? 'bg-[#273854] !text-[#fff]' : '') + ' last  cursor-pointer w-auto  h-[2.5rem]  text-[0.675rem] !px-[0.5rem]  md:text-[0.825rem] md:!px-[0.5rem]'" @click="profitChange('highRollers')">
              {{ $t('High_Rollers') }}
            </div>
            <div :class="(indexProfit == 'luckyBets' ? 'bg-[#273854] !text-[#fff]' : '') + ' last cursor-pointer w-auto  h-[2.5rem]  text-[0.675rem] !px-[0.5rem]  md:text-[0.825rem] md:!px-[0.5rem]'" @click="profitChange('luckyBets')">
              {{ $t('Lucky_Bets') }}
            </div>
            <div :class="(indexProfit == 'me' ? 'bg-[#273854] !text-[#fff]' : '') + ' first   h-[2.5rem] cursor-pointer w-auto   text-[0.675rem] !px-[0.5rem] md:text-[0.825rem] md:!px-[0.5rem]'" @click="profitChange('me')">
              {{ $t('my_beats') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <my-profit-list :profitList="profitList" :isAll="indexProfit == 'all'" :walletList="walletList"></my-profit-list> -->
  <my-profit-list v-show="indexProfit === 'me'" :profitList="myBetsList" :isAll="indexProfit == 'all'" :walletList="walletList"></my-profit-list>
  <my-profit-list v-show="indexProfit === 'all'" :profitList="allBetsList" :isAll="indexProfit == 'all'" :walletList="walletList"></my-profit-list>
  <my-profit-list v-show="indexProfit === 'highRollers'" :profitList="highRollersList" :isAll="indexProfit == 'all'" :walletList="walletList"></my-profit-list>
  <my-profit-list v-show="indexProfit === 'luckyBets'" :profitList="luckyBetsList" :isAll="indexProfit == 'all'" :walletList="walletList"></my-profit-list>
</template>

<script setup>
import myProfitList from '@/components/profit/profitList.vue';
</script>
<script>
import { useAppStore } from '@/store/store';
import { getCookieAccessToken, getCookieUserId } from '@/assets/tools/CommonTool';
import { getGameHistoryService } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';
const typeObj = {
  all: 'all_bets',
  highRollers: 'high_roller',
  luckyBets: 'lucky_bets'
};
export default {
  name: 'BetsList',
  components: {},
  props: {
    walletList: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'appStore.webSocketData.myBets': {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          // const uniqueData = this.removeDuplicatesById(this.myBetsList, newValue, 'game_cycle_uid');
          let resultArray = [...this.myBetsList];
          newValue.forEach((item2) => {
            const index = resultArray.findIndex((item1) => item1['game_cycle_uid'] === item2['game_cycle_uid']);
            if (index !== -1) {
              // 如果找到了相同的 key，则替换数据
              this.myBetsList[index] = item2;
            } else {
              // 如果没有找到相同的 key，则添加新数据
              this.myBetsList.unshift(item2);
            }
          });

          this.appStore.clearMyBets();
          if (this.myBetsList.length > 10) {
            this.myBetsList.pop();
          }
        }
      },
      immediate: true,
      deep: true
    },
    'appStore.webSocketData.publicBets': {
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          newValue.forEach((value) => {
            if (value.type === typeObj[this.indexProfit]) {
              switch (this.indexProfit) {
                case 'all':
                  if (this.allBetsList.length === 0) {
                    break;
                  }
                  this.allBetsList.unshift(value);
                  if (this.allBetsList.length > 10) {
                    this.allBetsList.pop();
                  }
                  break;
                case 'highRollers':
                  if (this.highRollersList.length === 0) {
                    break;
                  }
                  this.highRollersList.unshift(value);
                  if (this.highRollersList.length > 10) {
                    this.highRollersList.pop();
                  }
                  break;
                case 'luckyBets':
                  if (this.luckyBetsList.length === 0) {
                    break;
                  }
                  this.luckyBetsList.unshift(value);
                  if (this.luckyBetsList.length > 10) {
                    this.luckyBetsList.pop();
                  }
                  break;
              }
            }
          });
          this.appStore.clearPublicBets();
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      appStore: {},
      profitList: [],
      myBetsList: [],
      allBetsList: [],
      highRollersList: [],
      luckyBetsList: [],
      indexProfit: '',
      isLogin: false,
      showLogin: false,
      queryLast: null
    };
  },
  created() {
    let token = getCookieAccessToken();
    if (token) {
      this.isLogin = true;
      this.indexProfit = 'me';
    } else {
      this.indexProfit = 'all';
    }
    this.getGameHistoryService(this.indexProfit);
  },
  mounted() {
    this.appStore = useAppStore();
  },
  methods: {
    myCloseDialog() {
      this.showLogin = false;
      this.$emit('hideShowLogin');
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    getGameHistoryService(type) {
      let data = {};

      // 根据indexProfit来确定使用的列表
      let currentList = [];
      switch (type) {
        case 'me':
          currentList = this.myBetsList;
          break;
        case 'all':
          currentList = this.allBetsList;
          break;
        case 'highRollers':
          currentList = this.highRollersList;
          break;
        case 'luckyBets':
          currentList = this.luckyBetsList;
          break;
      }

      // 如果当前列表已有数据，则返回
      if (currentList.length > 0) {
        return;
      }

      // 根据indexProfit设置请求数据
      switch (type) {
        case 'me':
          data = {
            flag: false,
            user_id: Number(getCookieUserId()),
            page: 1,
            page_size: 10
          };
          break;
        case 'all':
          data = {
            flag: true,
            user_id: '',
            page: 1,
            page_size: 10
          };
          break;
        case 'highRollers':
          data = {
            flag: true,
            bet_type: 'high_roller',
            user_id: '',
            page: 1,
            page_size: 10
          };
          break;
        case 'luckyBets':
          data = {
            flag: true,
            bet_type: 'lucky_bets',
            user_id: '',
            page: 1,
            page_size: 10
          };
          break;
      }

      try {
        getGameHistoryService(data)
          .then((response) => {
            if (response.data && response.data.data) {
              switch (type) {
                case 'me':
                  this.myBetsList = response.data.data;
                  break;
                case 'all':
                  this.allBetsList = response.data.data;
                  break;
                case 'highRollers':
                  this.highRollersList = response.data.data;
                  break;
                case 'luckyBets':
                  this.luckyBetsList = response.data.data;
                  break;
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    },
    profitChange(indexProfit) {
      let token = getCookieAccessToken();
      if (!token && indexProfit == 'me') {
        toast(this.$t('please_login__account'), { autoClose: 1500 });
        return;
      }

      if (this.indexProfit == 'me' && !this.isLogin) {
        this.indexProfit = 'all';
        return;
      }

      this.indexProfit = indexProfit;
      this.getGameHistoryService(indexProfit);
    },
    removeDuplicatesById(array1, array2, key) {
      return array2.filter((item2) => {
        return !array1.some((item1) => item1[key] === item2[key]);
      });
    }
  }
};
</script>
<style scoped>
.width-auto {
  width: auto !important;
}
.tool-bar .tool-bar-tabs div {
  height: 2.5rem;
  /* background: var(--tab-bg-color); */
  border-radius: 0.375rem;
}
.tool-bar .tool-bar-tabs div.first {
  margin-left: 0;
}
.tool-bar .tool-bar-tabs div.last {
  margin-right: 0;
}
</style>
