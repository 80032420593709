<script setup>
// 导入
import { ref, onBeforeMount } from 'vue';

// 传参
const props = defineProps({
  tabIndex: {
    type: Object,
  },
  tabList: {
    type: Array,
  },
});

const emit = defineEmits(['changeTab']);
// 属性
const tabValue = ref(props.tabIndex.value)
// 计算属性
// 生命周期
onBeforeMount(async () => {

});
// 方法
const clickTab = (item) => {
  emit('changeTab', item);
};

const changeSelect = (val)=> {
  const item = props.tabList.find(obj => obj.value === val);
  console.log(123, item)
  emit('changeTab', item);
}

</script>

<template>
  <div class="relative md:bg-[#0a0c16] rounded-[8px] overflow-hidden p-[8px] flex gap-[8px]">
    <div v-for="(item, index) in props.tabList" :key="index" class="web" :class="props.tabIndex.value == item.value  ? 'tab-selected' : 'tab-unselected'" @click="clickTab(item)">
      {{ item.text }}
    </div>
    <div class="phone">
      <el-select v-model="tabValue" size="large" style="width: 14rem" @change="changeSelect">
        <el-option v-for="(item, index) in props.tabList" :key="index" :label="item.text" :value="item.value" />
      </el-select>
    </div>
  </div>
</template>

<style scoped>
.tab-selected {
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  padding: 16px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  background-color: #222942;
  color: #ffffff;
  text-shadow:
    0px 0px 8px #1184fa,
    0px 0px 12px #1184fa;
}
.tab-unselected {
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  padding: 16px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  background-color: transparent;
  color: #5b7bab;
  text-shadow: none
}
::v-deep .el-select__wrapper {
  background-color: #222942;
  border-color: #222942;
  box-shadow: none;
}
::v-deep .el-select__placeholder {
  color: #5b7bab;
}
</style>
