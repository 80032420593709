<template>
  <div>
    <wgCard class="bg-cover bg-no-repeat" :class="vipBgClass(userInfo.vip_level)">
      <div class="flex">
        <div class="relative hidden md:block">
          <img :src="getHeaderImg(userInfo.profile_image_url)" class="w-[3.625rem] h-[3.635rem]" />
          <div class="group hover:flex hover:bg-[rgba(0,0,0,0.6)] rounded-full overflow-hidden absolute cursor-pointer top-0 left-0 w-[3.625rem] h-[3.635rem] justify-center items-center" @click="editHeadUrl()" v-if="isEdit">
            <img class="w-[1.5rem] h-[1.5rem] hidden group-hover:block" src="../../../assets/images/profile/profile/btn_bianji.svg" />
          </div>
        </div>
        <div class="md:px-[1.5rem] md:w-[28.5rem] w-full">
          <div class="flex justify-between">
            <div>
              <div class="relative flex">
                <input v-if="userNameEdit" v-model="name" class="bg-[#0c131d] px-[0.5rem] rounded-xl w-full text-[#fff] leading-[2rem] h-[2rem] border border-solid border-[#55657e]" @keyup.enter="saveUserInfo('name', name)" />
                <div class="absolute top-2 right-2" v-show="userNameEdit">
                  <img
                    src="../../../assets/images/profile/profile-edit-close.svg"
                    class="w-[0.8rem] cursor-pointer"
                    @click="
                      () => {
                        userNameEdit = false;
                      }
                    "
                  />
                  <img class="w-[1.125rem] ml-2 cursor-pointer" src="../../../assets/images/profile/profile-edit-done.svg" @click="saveUserInfo('name', name)" />
                </div>

                <span v-if="!userNameEdit" class="text-[#fff] font-bold text-[1.25rem] h-[2rem]">{{ isHideInfo ? '****' : name }}</span>
                <img v-show="!userNameEdit" v-if="isEdit" class="ml-4" src="../../../assets/images/profile/profile/btn_bianji.svg" @click="userNameEdit = true" />
              </div>
              <div class="text-[#fff] text-[1rem] mt-[0.32rem]">
                {{ t('joined_on') }}
                <span style="margin-left: 0.5rem">{{ isHideInfo ? '****' : getJoinedTime(new Date(userInfo.joined_time * 1000)) }}</span>
              </div>
            </div>

            <hidden-button
              :isHide="isHideInfo"
              v-if="isEdit"
              @click="
                () => {
                  console.log(isHideInfo);
                  isHideInfo = !isHideInfo;
                  emit('hiddenStateChange', isHideInfo);
                }
              "
            ></hidden-button>
          </div>
          <div class="mt-[2.37rem]">
            <vip-com></vip-com>
          </div>
        </div>
      </div>
    </wgCard>
    <choose-img @chooseImg="chooseImgEvent" @close="chooseImgEvent" v-if="chooseImgFlag"></choose-img>
  </div>
</template>

<script setup>
import wgCard from '@/components/common/wgCard';
import { getHeaderImg } from '@/assets/tools/avatar.js';
import { defineProps, toRefs, ref, watch, defineEmits, defineModel } from 'vue';
import { updateUserInfoService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
import { setCookieUsername } from '@/assets/tools/CommonTool';
import vipCom from './vipCom';
import { useI18n } from 'vue-i18n';
import hiddenButton from './hiddenButton';
import chooseImg from '@/components/chooseHeaderImg/chooseImg.vue';

const { t } = useI18n();
const props = defineProps({
  userInfo: Object,
  isShowHiddenBtn: {
    type: Boolean,
    default: false
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update']);
const isHideInfo = defineModel({ isHideInfo: Boolean });
// const isHideInfo = ref(false);
const userNameEdit = ref(false);
const chooseImgFlag = ref(false);
const name = ref('');

watch(
  () => props.userInfo,
  (newVal) => {
    name.value = newVal.name;
  },
  {
    deep: true,
    immediate: true
  }
);

const saveUserInfo = (key, value) => {
  let userInfo = {
    userId: props.userInfo.user_id
  };
  userInfo[key] = value;
  updateUserInfoService(userInfo)
    .then(() => {
      if (userInfo.name) {
        setCookieUsername(userInfo.name);
      }
      chooseImgFlag.value = false;
      userNameEdit.value = false;
      emit('update');
    })
    .catch((error) => {
      const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};

const getJoinedTime = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  return year + '/' + (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) + '/' + day;
};

const editHeadUrl = () => {
  chooseImgFlag.value = true;
};

const chooseImgEvent = (img, index) => {
  if (img) {
    saveUserInfo('profile_image_url', 'avatars://profile-avatar' + (index + 1));
  } else {
    chooseImgFlag.value = false;
  }
};

const vipBgClass = (level) => {
  if (level === 0 || level === 1) {
    return 'bg-vip-1';
  } else if (level > 4) {
    return `bg-vip-4`;
  } else {
    return `bg-vip-${level + 1}`;
  }
};
const { userInfo, isEdit } = toRefs(props);
</script>
