<template>
  <el-input v-bind="attrs" v-model="proxyValue" @update:modelValue="updateModelValue" style="--el-input-bg-color: #0d101e; --el-color-primary: #0d101e; --el-input-border-color: #0d101e; --el-input-text-color: #fff; --el-input-hover-border-color: #0d101e">
    <template v-if="$slots.prefix" #prefix>
      <slot name="prefix"></slot>
    </template>
    <template v-if="$slots.suffix" #suffix>
      <slot name="suffix"></slot>
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend"></slot>
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append"></slot>
    </template>
  </el-input>
</template>

<script setup>
import { computed } from 'vue';
import { useAttrs, defineEmits, defineProps } from 'vue';
import { ElInput } from 'element-plus';

// 获取传递的所有属性
const attrs = useAttrs();

// 定义传递的 props 和 emits
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  }
});

const emit = defineEmits(['update:modelValue']);

// 使用 computed 创建一个代理 modelValue 的计算属性
const proxyValue = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
});

const updateModelValue = (value) => {
  emit('update:modelValue', value);
};
</script>

<style scoped>
::v-deep .el-input__wrapper {
  padding: 0.5rem;
}

/* 更改 placeholder 的字体颜色 */
::v-deep .el-input__inner::placeholder {
  color: #5B7BAB; /* 可以将 red 替换为你想要的颜色 */
}
</style>
