// const validator = require('@getdelta/wallet-address-validator');
var WAValidator = require('wallet-address-validator');

export const walletValid = (address, type) => {
  let walletType = '';
  if (type.toLowerCase() == 'bsc') {
    walletType = 'bsc';
  }
  if (type.toLowerCase() == 'tron') {
    walletType = 'tron';
  }
  if (type.toLowerCase() == 'eth') {
    walletType = 'ethereum';
  }
  const isValid = validator(address, walletType);
  return isValid;
};
function validator(address, walletType) {
  if (walletType.toLowerCase() == 'bsc') {
    return isValidBSCAddress(address);
  } else if (walletType.toLowerCase() == 'tron') {
    return isValidTRC20Address(address);
  } else if (walletType.toLowerCase() == 'ethereum') {
    return WAValidator.validate(address, 'ethereum');
  }
  return true;
}
function isValidTRC20Address(address) {
  // 检查地址是否为字符串类型
  if (typeof address !== 'string') {
    return false;
  }

  // 检查地址长度是否为 34 个字符
  if (address.length !== 34) {
    return false;
  }

  // 检查地址是否以 "T" 开头
  if (address[0] !== 'T') {
    return false;
  }

  // 检查地址是否只包含字母和数字
  const validCharacters = /^[A-Za-z0-9]+$/;
  if (!validCharacters.test(address)) {
    return false;
  }
  return true;
  //  // 检查地址的校验和是否正确
  //  const addressWithoutChecksum = address.slice(0, -4);
  //  const checksum = address.slice(-4);
  //  const hash = await sha256(addressWithoutChecksum);
  //  const calculatedChecksum = hash.slice(0, 4);
  //
  //  return checksum === calculatedChecksum;
}

//// SHA-256 哈希函数
//async function sha256(input) {
//  const utf8 = new TextEncoder().encode(input);
//
//  const hash = await crypto.subtle.digest("SHA-256", utf8);
//  const hashArray = Array.from(new Uint8Array(hash));
//  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
//  return hashHex;
//
////  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
////    const hashArray = Array.from(new Uint8Array(hashBuffer));
////    const hashHex = hashArray
////      .map((bytes) => bytes.toString(16).padStart(2, '0'))
////      .join('');
////
////    return hashHex;
////  });
//}

function isValidBSCAddress(address) {
  // 检查地址是否为字符串类型
  if (typeof address !== 'string') {
    return false;
  }

  // 检查地址是否以 "0x" 开头
  if (!address.startsWith('0x')) {
    return false;
  }

  // 去掉 "0x" 前缀后，检查地址长度是否为 40 个字符
  const addressWithoutPrefix = address.slice(2);
  if (addressWithoutPrefix.length !== 40) {
    return false;
  }

  // 检查地址是否只包含十六进制字符
  const hexRegex = /^[0-9A-Fa-f]+$/;
  if (!hexRegex.test(addressWithoutPrefix)) {
    return false;
  }
  // 地址通过所有校验规则
  return true;
}
