<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'email' // 类型 email、password、verifyCode、normal
  },
  value: {
    type: String,
    default: ''
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: 'Example@wingame.com'
  },
  preId: {
    type: String,
    default: ''
  },
  textTitle: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['onValueChange']);
const passWordVals = ref(['', '', '', '', '', '']);
const isActive = ref(false);
const inputValue = ref(null);
// const isSeePWD = ref(true);
let num = ref('0123456789');
const inputKeyUp = (e) => {
  if (e.keyCode == 8) {
    let inputV = '';
    for (let i in passWordVals.value) {
      if (passWordVals.value[i]) {
        inputV += passWordVals.value[i];
      }
      // 全部清空，后面在重新设置
    }
    if (inputV) {
      if (inputV.length > 0) {
        console.log(inputV.length - 1);
        let input = document.getElementById(props.preId + 'w_input_pass_' + (inputV.length - 1));
        if (input) {
          input.focus();
        }
      }
    } else {
      let input_0 = document.getElementById(props.preId + 'w_input_pass_0');
      input_0.focus();
    }
    emit('onValueChange', inputV);
  }
};
const handleInputOnValueChanged = (index) => {
  if (num.value.indexOf(passWordVals.value[index]) == -1) {
    passWordVals.value[index] = '';
  }
  let inputV = '';
  for (let i in passWordVals.value) {
    if (passWordVals.value[i]) {
      inputV += passWordVals.value[i];
    }
    // 全部清空，后面在重新设置
    passWordVals.value[i] = '';
  }
  if (inputV) {
    for (let i = 0; i < inputV.length; i++) {
      if (i >= passWordVals.value.length) {
        break;
      }
      passWordVals.value[i] = inputV[i];
    }

    if (inputV.length > 0) {
      let input = document.getElementById(props.preId + 'w_input_pass_' + inputV.length);
      if (input) {
        input.focus();
      }
    }
  } else {
    let input_0 = document.getElementById(props.preId + 'w_input_pass_0');
    input_0.focus();
  }

  emit('onValueChange', inputV);
};

onMounted(() => {
  inputValue.value = props.value;
  let input_0 = document.getElementById(props.preId + 'w_input_pass_0');
  input_0.focus();
});
</script>

<template>
  <div class="bg-content-pre">
    <div class="title">
      {{ props.textTitle }}
    </div>
    <!-- <button class="button" @click="isSeePWD = !isSeePWD">
      <div class="button-see" v-if="!isSeePWD">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path
            d="M16.0902 8.23706C10.9102 8.23706 6.45817 11.4431 4.70117 16.0001C6.45817 20.5571 10.9102 23.7631 16.0902 23.7631C21.2632 23.7631 25.7152 20.5571 27.4792 16.0001C25.7152 11.4431 21.2632 8.23706 16.0902 8.23706ZM16.0902 21.1731C13.1922 21.1731 10.9172 18.8981 10.9172 16.0001C10.9172 13.1021 13.1922 10.8271 16.0902 10.8271C18.9882 10.8271 21.2632 13.1021 21.2632 16.0001C21.2632 18.8981 18.9882 21.1731 16.0902 21.1731ZM16.0902 12.8921C14.3332 12.8921 12.9822 14.2361 12.9822 16.0001C12.9822 17.7571 14.3262 19.1081 16.0902 19.1081C17.8542 19.1081 19.1982 17.7641 19.1982 16.0001C19.1912 14.2431 17.8472 12.8921 16.0902 12.8921Z"
            fill="#55657E"
          />
        </svg>
      </div>
      <div class="button-hidden" v-if="isSeePWD">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M7.74209 20.3121L11.0059 17.0213C10.7878 16.4894 10.6753 15.9149 10.6753 15.2908C10.6753 12.6454 12.8206 10.4823 15.4443 10.4823C16.0633 10.4823 16.6331 10.6028 17.1606 10.8156L19.6155 8.34043C18.279 7.85816 16.8722 7.59574 15.4443 7.59574C10.4361 7.59574 5.61782 10.7943 4 15.2908C4.71747 17.2624 6.04689 18.9929 7.74209 20.3121Z" fill="#55657E" />
          <path
            d="M12.5815 15.2908V15.4326L15.585 12.4043H15.4443C13.8687 12.4043 12.5815 13.7021 12.5815 15.2908ZM23.1395 10.2695L28 5.36879L26.6424 4L4.18992 26.6312L5.54748 28L11.2943 22.2057C12.6166 22.7163 14.0234 22.9787 15.4443 22.9716C20.4244 22.9716 25.2849 19.773 26.9097 15.2837C26.1923 13.3121 24.8347 11.5816 23.1465 10.2624L23.1395 10.2695ZM15.4443 20.0993C14.8464 20.0993 14.2556 19.9787 13.728 19.766L15.3247 18.156H15.4654C17.041 18.156 18.3283 16.8582 18.3283 15.2695V15.1277L19.925 13.5177C20.0938 14.0922 20.2134 14.695 20.2134 15.2979C20.2134 17.9433 18.068 20.1064 15.4443 20.1064V20.0993Z"
            fill="#55657E"
          />
        </svg>
      </div>
    </button> -->
  </div>
  <div class="bg-content" :class="{ active: isActive }">
    <div style="display: flex">
      <div v-for="(item, index) in passWordVals" :key="index" style="display: flex">
        <input @keyup="inputKeyUp" class="input" :id="props.preId + 'w_input_pass_' + index" :readOnly="readOnly" v-model="passWordVals[index]" maxLength="1" @input="handleInputOnValueChanged(index)" :ref="'input_' + index" autocomplete="off" @focus="isActive = true" @blur="isActive = false" />
        <div class="input-div" v-if="index < passWordVals.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-content-pre {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.bg-content-pre .title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  color: var(--color-8ea3c3);
}
.bg-content {
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #55657e;
  /* height: 3.625rem;
  width: calc(100%);
  border-radius: 0.5rem;
  margin-bottom: 1.125rem; */
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 15px;
}
.bg-content.active {
  border-radius: 0.5rem;
}
.input {
  width: 100%;
  background-color: transparent;
  border: none;
  caret-color: #1184fa;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
}
.input-div {
  width: 1px;
  background-color: #2c394e;
  height: 2rem;
}
input::placeholder {
  color: #2c394e;
}
.input.email {
  padding-right: 1rem;
}
.input.password {
  padding-right: 0;
}
.input.verifyCode {
  padding-right: 0;
}
.button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  padding: 0;
  width: 10%;
}
</style>
