<template>
  <div :class="props.class" v-bind="attrs" class="bg-theme shadow-custom-combined px-[1rem] text-[#0C131D] rounded-md font-bold cursor-pointer text-center">
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps, useAttrs } from 'vue';
const props = defineProps({
  class: String
});

// 获取传递的所有属性
const attrs = useAttrs();
</script>
