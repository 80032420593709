<script setup>
// 导入
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import ButtonItem from '@/components/wallet/module/ButtonItem';
// 传参
const props = defineProps({
  userInfo: Object
});
const emit = defineEmits(['bindEmail','bindMfa']);
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
// 计算属性
// 生命周期
// 方法
const clickBindEmail = ()=> {
  emit('bindEmail');
}
const clickBindMfa = ()=> {
  emit('bindMfa');
}
</script>

<template>
  <el-dialog v-if="props.userInfo" v-model="walletStore.showDialogVerification" width="420" align-center :close-on-click-modal="false" class="bg-[#121829] text-left">
    <template #header>
      <div class="text-[#fff] text-[14px] font-[700]">{{ t('notifications') }}</div>
    </template>
    <div v-if="!props.userInfo.verify_email">
    <!-- <div> -->
      <div class="bg-[#1C243F] rounded-[4px] p-[15px] mb-[15px]">
        <div class="text-[#00B555] text-[12px] mb-[15px]">{{ t('Verify your Email') }}</div>
        <div class="flex justify-between items-center">
          <div class="text-[#fff] text-[14px] font-[700]">{{ t('Email_Verification') }}</div>
          <el-icon class="text-[#FF9F33] text-[14px]">
            <WarningFilled />
          </el-icon>
        </div>
      </div>
      <ButtonItem :btnText="t('verify')" @click="clickBindEmail" />
    </div>
    <div v-else-if="props.userInfo.verify_email && !props.userInfo.enable_otp">
    <!-- <div> -->
      <div class="bg-[#1C243F] rounded-[4px] p-[15px] mb-[15px]">
        <div class="text-[#00B555] text-[12px] mb-[15px]">{{ t('Bind 2FA') }}</div>
        <div class="flex justify-between items-center mb-[10px]">
          <div class="text-[#fff] text-[14px] font-[700]">{{ t('Email_Verification') }}</div>
          <el-icon class="text-[#00D66F] text-[14px]">
            <SuccessFilled />
          </el-icon>
        </div>
        <div class="flex justify-between items-center">
          <div class="text-[#fff] text-[14px] font-[700]">{{ t('2FA') }}</div>
          <el-icon class="text-[#FF9F33] text-[14px]">
            <WarningFilled />
          </el-icon>
        </div>
      </div>
      <ButtonItem :btnText="t('bind_google_authenticator')" @click="clickBindMfa"/>
    </div>
  </el-dialog>
</template>

<style scoped></style>
