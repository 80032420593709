<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
// 传参
const props = defineProps({
  titleText: {
    type: String
  },
  tabIndex: {
    type: String,
    default: 'Crypto'
  }
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="relative">
    <div class="text-[12px] text-[#5B7BAB] text-left font-[700] mb-[5px]">{{ props.titleText }}</div>
    <!-- 加密货币选择 -->
    <template v-if="props.tabIndex == 'Crypto'">
      <el-select v-model="walletStore.cryptoSelectItem" class="my-el-css" :placeholder="t('Please select')" size="large" :teleported="false" @change="walletStore.cryptoChainIndex = 0">
        <template #label>
          <div class="flex items-center gap-[5px]">
            <img class="w-[16px] h-[16px]" :src="walletStore.cryptoSelectItem.icon_url" />
            <span>{{ walletStore.cryptoSelectItem.name }}</span>
          </div>
        </template>
        <template #header>
          <el-input v-model="walletStore.cryptoSearchInput" :placeholder="t('search_symbol')" clearable>
            <template #prefix>
              <img src="@/assets/images/home/Frame 427320295.svg" />
            </template>
          </el-input>
        </template>
        <el-option v-for="(item, index) in walletStore.searchCryptoList" :key="index" :value="item">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-[5px]">
              <img class="w-[16px] h-[16px]" :src="item.icon_url" />
              <span>{{ item.name }}</span>
            </div>
            <div>
              {{ item.balance }}
            </div>
          </div>
        </el-option>
      </el-select>
    </template>
    <!-- 法币选择 -->
    <template v-if="props.tabIndex == 'Fiat'">
      <el-select v-model="walletStore.fiatSelectItem" class="my-el-css w-1/2" :placeholder="t('Please select')" size="large" :teleported="false" @change="walletStore.fiatWithdrawOptItem = null">
        <template #label>
          <div class="flex items-center gap-[5px]">
            <img class="w-[16px] h-[16px]" :src="walletStore.fiatSelectItem.icon_url" />
            <span>{{ walletStore.fiatSelectItem.name }}</span>
          </div>
        </template>
        <template #header>
          <el-input v-model="walletStore.fiatSearchInput" :placeholder="t('search_symbol')" clearable>
            <template #prefix>
              <img src="@/assets/images/home/Frame 427320295.svg" />
            </template>
          </el-input>
        </template>
        <el-option v-for="(item, index) in walletStore.searchFiatList" :key="index" :value="item">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-[5px]">
              <img class="w-[16px] h-[16px]" :src="item.icon_url" />
              <span>{{ item.name }}</span>
            </div>
            <div>
              {{ item.balance }}
            </div>
          </div>
        </el-option>
      </el-select>
    </template>
  </div>
</template>

<style scoped></style>
