import headerImg from '../../assets/images/profile/avatar/profile-avatar4.png';
import headerImg1 from '../../assets/images/profile/avatar/profile-avatar1.png';
import headerImg2 from '../../assets/images/profile/avatar/profile-avatar2.png';
import headerImg3 from '../../assets/images/profile/avatar/profile-avatar3.png';
import headerImg4 from '../../assets/images/profile/avatar/profile-avatar4.png';
import headerImg5 from '../../assets/images/profile/avatar/profile-avatar5.png';
import headerImg6 from '../../assets/images/profile/avatar/profile-avatar6.png';
import headerImg7 from '../../assets/images/profile/avatar/profile-avatar7.png';
import headerImg8 from '../../assets/images/profile/avatar/profile-avatar8.png';
import headerImg9 from '../../assets/images/profile/avatar/profile-avatar9.png';
import headerImg10 from '../../assets/images/profile/avatar/profile-avatar10.png';
import headerImg11 from '../../assets/images/profile/avatar/profile-avatar11.png';
import headerImg12 from '../../assets/images/profile/avatar/profile-avatar12.png';
import headerImg13 from '../../assets/images/profile/avatar/profile-avatar13.png';
import headerImg14 from '../../assets/images/profile/avatar/profile-avatar14.png';
import headerImg15 from '../../assets/images/profile/avatar/profile-avatar15.png';
import headerImg16 from '../../assets/images/profile/avatar/profile-avatar16.png';

export const getHeaderImg = (headerImageUrl) => {
  let header = headerImageUrl.split('//')[1];
  if (header == 'profile-avatar1') {
    return headerImg1;
  } else if (header == 'profile-avatar2') {
    return headerImg2;
  } else if (header == 'profile-avatar3') {
    return headerImg3;
  } else if (header == 'profile-avatar4') {
    return headerImg4;
  } else if (header == 'profile-avatar5') {
    return headerImg5;
  } else if (header == 'profile-avatar6') {
    return headerImg6;
  } else if (header == 'profile-avatar7') {
    return headerImg7;
  } else if (header == 'profile-avatar8') {
    return headerImg8;
  } else if (header == 'profile-avatar9') {
    return headerImg9;
  } else if (header == 'profile-avatar10') {
    return headerImg10;
  } else if (header == 'profile-avatar11') {
    return headerImg11;
  } else if (header == 'profile-avatar12') {
    return headerImg12;
  } else if (header == 'profile-avatar13') {
    return headerImg13;
  } else if (header == 'profile-avatar14') {
    return headerImg14;
  } else if (header == 'profile-avatar15') {
    return headerImg15;
  } else if (header == 'profile-avatar16') {
    return headerImg16;
  }
  return headerImg;
};
