<template>
  <el-switch v-bind="attrs" v-model="proxyValue" @update:modelValue="updateModelValue" :style="computedStyle" />
</template>

<script setup>
import { computed } from 'vue';
import { useAttrs, defineEmits, defineProps } from 'vue';
import { ElSwitch } from 'element-plus';

// 获取传递的所有属性
const attrs = useAttrs();

// 定义传递的 props 和 emits
const props = defineProps({
  modelValue: {
    type: [Boolean, String, Number],
    default: false
  }
});

const emit = defineEmits(['update:modelValue']);

// 使用 computed 创建一个代理 modelValue 的计算属性
const proxyValue = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
});

// 定义 updateModelValue 方法来更新 modelValue
const updateModelValue = (value) => {
  emit('update:modelValue', value);
};

const computedStyle = computed(() => {
  return {
    '--el-switch-on-color': '#13ce66',
    '--el-switch-off-color': '#0d101e',
    '--el-color-white': props.modelValue ? '#0E0F18' : '#80A4DB'
  };
});
</script>
