<script setup>
// import { ref, defineEmits } from 'vue';

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAppStore, useLoginStore } from '@/store/store';
import useWalletStore from '@/store/wallet';
import { onMounted, computed } from 'vue';
import { getHomeLargeImageService } from '@/assets/network/service/home';

const { t } = useI18n();
const router = useRouter();
const appStore = useAppStore();
const loginStore = useLoginStore();
const walletStore = useWalletStore();

const largeImageUrl = computed(() => {
  if(appStore.homeTopBanner) {
    if(appStore.homeTopBanner.image_url && appStore.homeTopBanner.image_url.length > 0) {
      return appStore.homeTopBanner.image_url
    }else {
      return require('@/assets/images/new-version/bigbanner-bg.webp');
    }
  }
  return null;
})

const clickLogin = () => {
  loginStore.updateShow(true);
  loginStore.updateActType('login');
};

const clickDeposit = () => {
  walletStore.showDialogDeposit = true;
};

const clickPlayNow = () => {
  window.sessionStorage.setItem('sortBy', JSON.stringify([]));
  router.push('/slots/allSlots');
};

const fetchLargeImage = async () => {
  try {
    const response = await getHomeLargeImageService();
    if(response.data.banner_config && response.data.banner_config.items.length > 0) {
      appStore.addHomeTopBanner(response.data.banner_config.items[0]);
    }
  } catch (error) {
    console.log(error)
  }
}

onMounted(() => {
  fetchLargeImage();
})
</script>

<template>
  <div class="body big-banner-container">
    <img v-if="largeImageUrl && largeImageUrl.length > 0" class="banner-bg-img" :src="largeImageUrl"/>
    <div class="banner-content">
      <template v-if="appStore.isLogin">
        <div class="content-div2">
          <div class="banner-title">{{ t('banner_title') }}</div>
          <div class="banner-desc">{{ t('banner_desc') }}</div>
          <div class="banner-btn">
            <button class="btn-button" @click="clickDeposit">{{ t('deposit') }}</button>
            <button class="btn-button" @click="clickPlayNow">{{ t('play_now') }}</button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="content-div">
          <div class="banner-title">{{ t('banner_title') }}</div>
          <div class="banner-desc">{{ t('banner_desc') }}</div>
          <div class="banner-btn">
            <button class="btn-button" @click="clickLogin">{{ t('join_us') }}</button>
            <div class="btn-hr">{{ t('or') }}</div>
            <div class="btn-other-login">
              <div class="other-button" @click="clickLogin">
                <div class="button-content">
                  <img class="w-[30px]"  src="@/assets/images/login-and-register/telegram.webp" />
                </div>
              </div>
              <div class="other-button" @click="clickLogin">
                <div class="button-content">
                  <img class="w-[30px]"  src="@/assets/images/login-and-register/google.webp" />
                </div>
              </div>
              <!-- <div class="other-button" @click="clickLogin">
                <div class="button-content">
                  <img class="w-[30px]" src="@/assets/images/login-and-register/facebook.webp" />
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.big-banner-container {
  position: relative;
  height: 18.4375rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .banner-bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border: none;
  }
  .banner-content {
    display: flex;
    z-index: 1;
    width: 100%;
    .content-div {
      overflow: hidden;
      width: 44%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      padding: 0 60px;
      box-sizing: border-box;
      gap: 10px;
      z-index: 1;
      .banner-title {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      .banner-desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      .banner-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .btn-hr {
          color: #aac4ed;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 115%;
        }
        .btn-other-login {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          .other-button {
            cursor: pointer;
            width: 60px;
            height: 40px;
            background-color: #273854;
            color: #aac4ed;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .button-content {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
            }
          }
        }
        .btn-button {
          border: 0;
          cursor: pointer;
          width: 100%;
          height: 40px;
          font-size: 14px;
          font-weight: 700;
          line-height: normal;
          border-radius: 8px;
          background-color: #3aa1ff;
          color: #0c131d;
          box-shadow:
            0px 0px 10px 0px rgba(17, 132, 250, 0.4),
            0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
            0px 0px 12px 0px #0d52b2 inset;
        }
      }
    }
    .content-div2 {
      overflow: hidden;
      width: 44%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #ffffff;
      text-align: left;
      padding: 0 30px;
      box-sizing: border-box;
      gap: 20px;
      z-index: 1;
      .banner-title {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      .banner-desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
      .banner-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 80%;
        .btn-button {
          border: 0;
          cursor: pointer;
          width: 100%;
          height: 40px;
          font-size: 14px;
          font-weight: 700;
          line-height: normal;
          border-radius: 8px;
          background-color: #3aa1ff;
          color: #0c131d;
          box-shadow:
            0px 0px 10px 0px rgba(17, 132, 250, 0.4),
            0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
            0px 0px 12px 0px #0d52b2 inset;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .big-banner-container {
    .banner-content {
      .content-div {
        width: 90%;
        padding: 10px 18px 18px 18px;
      }
      .content-div2 {
        width: 90%;
        padding: 10px 18px 18px 18px;
      }
    }
  }
}
</style>
