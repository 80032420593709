// 获取当前域名
const currentDomain = window.location.hostname;

const serverConfig = {
  baseURL: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_BASE_URL2 : process.env.VUE_APP_BASE_URL, // 请求基础地址,可根据环境自定义
  wsBaseURL: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_WS_BASE_URL2 : process.env.VUE_APP_WS_BASE_URL, // 请求基础地址,可根据环境自定义
  publicWsBaseURL: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_PUBLIC_WS_BASE_URL2 : process.env.VUE_APP_PUBLIC_WS_BASE_URL, // 请求基础地址,可根据环境自定义
  botId: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_BOT_ID2 : process.env.VUE_APP_BOT_ID,
  googleClientId: '',
  useTokenAuthorization: true, // 是否开启 token 认证
  withCredentials: false, // 跨域请求是否需要携带 cookie
  isGmail: false,
  breakPx: [1440, 780],
  googleAnalyticsId: currentDomain.includes('www.wingame.com') ? process.env.VUE_APP_GOOGLE_ANALYTICS_ID2 : process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  customerIntercomAppId: process.env.VUE_APP_CUSTOMER_INTERCOM_APP_ID
};

export default serverConfig;
