<template>
  <div class="main-content">
    <div class="body win_game_privacy_policy">
      <div class="title">
        {{ $t(win_game_privacy_policy_page_title) }}
      </div>
      <div v-for="index in win_game_privacy_policy_title.number" :key="index">
        <div class="content_title">
          {{ $t(win_game_privacy_policy_title.code_pre + index) }}
        </div>
        <div v-for="index2 in getNumber(win_game_privacy_policy, win_game_privacy_policy_title.code_pre + index)" :key="index2">
          <div class="content_desc">
            {{ $t('win_game_privacy_policy_' + index + '_' + index2) }}
          </div>
          <div v-if="win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren && win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren['win_game_privacy_policy_' + index + '_' + index2]">
            <div v-for="index3 in getNumber(win_game_privacy_policy, win_game_privacy_policy_title.code_pre + index, 'contentChildren', 'win_game_privacy_policy_' + index + '_' + index2)" :key="index3">
              <div
                v-if="win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren['win_game_privacy_policy_' + index + '_' + index2 + '_class']"
                :class="'content_desc ' + (win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren['win_game_privacy_policy_' + index + '_' + index2 + '_class'] ? win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren['win_game_privacy_policy_' + index + '_' + index2 + '_class'] : '')"
              ></div>
              <div :class="'content_desc'">
                {{ $t('win_game_privacy_policy_' + index + '_' + index2 + '_' + index3) }}
              </div>

              <div
                v-if="
                  win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren &&
                  win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren['win_game_privacy_policy_' + index + '_' + index2] &&
                  win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren.contentChildren &&
                  win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren.contentChildren['win_game_privacy_policy_' + index + '_' + index2 + '_' + index3]
                "
              >
                <div v-for="index4 in win_game_privacy_policy[win_game_privacy_policy_title.code_pre + index].contentChildren.contentChildren['win_game_privacy_policy_' + index + '_' + index2 + '_' + index3]" :key="index4" class="content_body">
                  <div :class="'content_desc'">
                    {{ $t('win_game_privacy_policy_' + index + '_' + index2 + '_' + index3 + '_' + index4) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const win_game_privacy_policy_page_title = ref('win_game_privacy_policy');

const win_game_privacy_policy_title = ref({
  code_pre: 'win_game_privacy_policy_title_',
  number: 10
});
const getNumber = (term, key1, key2, key3) => {
  let obj = term[key1];
  if (key2 && obj) {
    obj = obj[key2];
  }
  if (key3 && obj) {
    obj = obj[key3];
  }
  if (obj && obj.number) {
    return obj.number;
  }
  return obj ? obj : 0;
};
const win_game_privacy_policy = ref({
  win_game_privacy_policy_title_1: {
    code_pre: 'win_game_privacy_policy_1_',
    number: 3
  },
  win_game_privacy_policy_title_2: {
    code_pre: 'win_game_privacy_policy_2_',
    number: 2,
    contentChildren: {
      win_game_privacy_policy_2_1: 6,
      win_game_privacy_policy_2_2: 3,
      contentChildren: {
        win_game_privacy_policy_2_2_3: 1
      }
    }
  },
  win_game_privacy_policy_title_3: {
    code_pre: 'win_game_privacy_policy_3_',
    number: 2,
    contentChildren: {
      win_game_privacy_policy_3_1: 7
    }
  },
  win_game_privacy_policy_title_4: {
    code_pre: 'win_game_privacy_policy_4_',
    number: 1,
    contentChildren: {
      win_game_privacy_policy_4_1: 6
    }
  },
  win_game_privacy_policy_title_5: {
    code_pre: 'win_game_privacy_policy_5_',
    number: 1
  },
  win_game_privacy_policy_title_6: {
    code_pre: 'win_game_privacy_policy_6_',
    number: 2,
    contentChildren: {
      win_game_privacy_policy_6_1: 4
    }
  },
  win_game_privacy_policy_title_7: {
    code_pre: 'win_game_privacy_policy_7_',
    number: 1,
    contentChildren: {
      win_game_privacy_policy_7_1: 6,
      contentChildren: {
        win_game_privacy_policy_7_1_6: 1
      }
    }
  },
  win_game_privacy_policy_title_8: {
    code_pre: 'win_game_privacy_policy_8_',
    number: 3
  },
  win_game_privacy_policy_title_9: {
    code_pre: 'win_game_privacy_policy_9_',
    number: 2
  },
  win_game_privacy_policy_title_10: {
    code_pre: 'win_game_privacy_policy_10_',
    number: 2
  }
});
</script>

<style scoped>
.web .win_game_privacy_policy .title {
  margin-top: 3rem;
}
.web .win_game_privacy_policy .footer {
  margin-bottom: 3rem;
}
.phone .win_game_privacy_policy {
  padding: 1.5rem 1rem;
}
.win_game_privacy_policy div {
  width: 100%;
}
.win_game_privacy_policy .title {
  padding: 0.5rem 0;

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8125rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--term_of__title_color);
}
.win_game_privacy_policy .content_title {
  padding: 0.5rem 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875;
  letter-spacing: 0em;
  text-align: left;
  color: var(--term_of__title_color);
}
.win_game_privacy_policy .content_body {
  display: flex;
}
.win_game_privacy_policy .content_desc.li {
  width: 2rem;
  padding-left: 1.5rem;
}
.win_game_privacy_policy .content_desc {
  padding: 0.5rem 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9375rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
}
.win_game_privacy_policy .content_desc.li::before {
  content: '\2022'; /* Unicode编码，代表圆点 */
  margin-right: 0.5; /* 调整圆点与div之间的距离 */
}
</style>
