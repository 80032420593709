import api from '../api';
import httpManager from '../httpManager';

// 站内信列表
export const getNotificationMessagesService = (params) => {
  return httpManager({
    url: api.messages,
    method: 'get',
    params
  });
};

// 站内信标记已读
export const messageMakeAsReadService = (param) => {
  return httpManager({
    url: api.messagesRead,
    method: 'post',
    data: param
  });
};
