import api from '../api';
import httpManager from '../httpManager';

// 获取游戏url
export const getGameUrlService = (param) => {
  return httpManager({
    url: api.openBoxLogin,
    method: 'post',
    data: param
  });
};

// 获取下注 latest bets
export const getGameLatestBetsService = (param) => {
  const { gameName } = param;
  return httpManager({
    url: `${gameName}/${api.latestBets}`,
    method: 'get',
    data: {}
  });
};

// 获取下注 my bets
export const getGameMyBetsService = (param) => {
  const { gameName } = param;
  return httpManager({
    url: `${gameName}/${api.myBets}`,
    method: 'get',
    data: {}
  });
};

// 获取slot列表
export const getSlotsListService = (param) => {
  return httpManager({
    url: api.openBoxOnlineGames,
    method: 'post',
    data: param
  });
};

// 获取游戏记录
export const getGameHistoryService = (param) => {
  return httpManager({
    url: api.openBoxBetHistory,
    method: 'post',
    data: param
  });
};

// 获取liveCasino列表
export const getLiveCasinoListService = (param) => {
  return httpManager({
    url: api.openBoxOnlineGames,
    method: 'post',
    data: param
  });
};

// 获取收藏游戏列表
export const getFavoriteListService = (param) => {
  return httpManager({
    url: api.openBoxFavoriteGames,
    method: 'post',
    data: param
  });
};

// 收藏游戏
export const favoriteGameService = (param) => {
  return httpManager({
    url: api.openBoxFavoriteGame,
    method: 'post',
    data: param
  });
};

// 取消收藏游戏
export const unfavoriteGameService = (param) => {
  return httpManager({
    url: api.openBoxUnfavoriteGame,
    method: 'post',
    data: param
  });
};

// 获取最近玩游戏列表
export const getRecentListService = (param) => {
  return httpManager({
    url: api.openBoxRecentGames,
    method: 'post',
    data: param
  });
};

// 获取 popular 游戏
export const getCasinoPopularGamesService = (param) => {
  return httpManager({
    url: api.openBoxPopularGames,
    method: 'post',
    data: param
  });
};

// 获取 new 游戏
export const getCasinoNewGamesService = (param) => {
  return httpManager({
    url: api.openBoxNewGames,
    method: 'post',
    data: param
  });
};

// 获取 hot 游戏
export const getCasinoHotGamesService = (param) => {
  return httpManager({
    url: api.openBoxHotGames,
    method: 'post',
    data: param
  });
};

// 获取游戏列表接口
export const openboxSearchGames = (param) => {
  return httpManager({
    url: api.openboxSearchGames,
    method: 'post',
    data: param
  });
};

// 获取大赢家接口
export const getBigWinner = (param) => {
  return httpManager({
    url: api.getBigWinner,
    method: 'post',
    data: param
  });
};
// 获取幸运赢家接口
export const getLuckyWinner = (param) => {
  return httpManager({
    url: api.getLuckyWinner,
    method: 'post',
    data: param
  });
};
