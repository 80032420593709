<script setup>
import { defineProps, defineEmits, ref, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import wgDialog from '@/components/common/wgDialog.vue';
import wgTypeInput from '@/components/common/wgTypeInput.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { toast } from 'vue3-toastify';
import { createAllianceMemberService } from '@/assets/network/service/user';
import { walletValid } from '@/assets/tools/wattle';

const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['close']);

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  register: t('register'),
  name: t('name'),
  please_enter_name: t('please_enter_name'),
  traffic_geo: t('traffic_geo'),
  please_enter__ego: t('please_enter__ego'),
  email: t('email'),
  please_enter_email: t('please_enter_email'),
  skype: t('skype'),
  please_enter_skype: t('please_enter_skype'),
  wallet_address_trc20: t('wallet_address_trc20'),
  please_enter__trc20: t('please_enter__trc20'),
  receive_newsletters: t('receive_newsletters'),
  i_accept_the: t('i_accept_the'),
  affiliate_terms_conditions: t('affiliate_terms_conditions'),
  please_accept__conditions: t('please_accept__conditions'),
  confirm: t('confirm'),
  register_successfully: t('register_successfully')
};

const confirmLoadingJson = ref(loadingAniJson);
const confirming = ref(false);

const name = ref('');
const trafficGEO = ref('');
const email = ref('');
const skype = ref('');
const address = ref('');
const receiveNewsletters = ref(false);
const acceptTerms = ref(false);

const handleNameValueChanged = (value) => {
  name.value = value;
};
const handleTrafficGEOValueChanged = (value) => {
  trafficGEO.value = value;
};
const handleEmailValueChanged = (value) => {
  email.value = value;
};
const handleSkypeValueChanged = (value) => {
  skype.value = value;
};
const handleAddressValueChanged = (value) => {
  address.value = value;
};
const handleReceiveNewslettersClick = () => {
  receiveNewsletters.value = !receiveNewsletters.value;
};
const handleAcceptTermsClick = () => {
  acceptTerms.value = !acceptTerms.value;
};
const handleLookTermsClick = () => {
  window.open(window.location.origin + '/term_of_conditions', '_blank');
};
const handleConfirmClick = () => {
  if (name.value == null || name.value.length <= 0) {
    toast(textData.please_enter_name, { autoClose: 2000, type: 'error' });
    return;
  }
  if (trafficGEO.value == null || trafficGEO.value.length <= 0) {
    toast(textData.please_enter__ego, { autoClose: 2000, type: 'error' });
    return;
  }
  if (email.value == null || email.value.length <= 0) {
    toast(textData.please_enter_email, { autoClose: 2000, type: 'error' });
    return;
  }
  if (skype.value == null || skype.value.length <= 0) {
    toast(textData.please_enter_skype, { autoClose: 2000, type: 'error' });
    return;
  }
  if (address.value == null || address.value.length <= 0) {
    toast(textData.please_enter__trc20, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!walletValid(address.value, 'tron')) {
    toast(textData.please_enter__trc20, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!acceptTerms.value) {
    toast(textData.please_accept__conditions, { autoClose: 2000, type: 'error' });
    return;
  }

  confirming.value = true;
  handleConfirmToServer();
};

const handleCanNext = computed(() => {
  if (confirming.value) {
    return true;
  }
  if (acceptTerms.value && name.value != null && name.value.length > 0 && trafficGEO.value != null && trafficGEO.value.length > 0 && email.value != null && email.value.length > 0 && skype.value != null && skype.value.length > 0 && address.value != null && address.value.length > 0) {
    return true;
  }
  return false;
});

const handleConfirmToServer = async () => {
  const params = {
    name: name.value,
    traffix_geo: trafficGEO.value,
    email: email.value,
    skype: skype.value,
    trc20_address: address.value,
    receive_newsletters: receiveNewsletters.value
  };
  try {
    await createAllianceMemberService(params);
    confirming.value = false;
    toast(textData.register_successfully, { autoClose: 2000, type: 'success' });
    emit('close');
  } catch (error) {
    confirming.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

watchEffect(() => {
  if (props.show) {
    name.value = '';
    trafficGEO.value = '';
    email.value = '';
    skype.value = '';
    address.value = '';
    receiveNewsletters.value = false;
    acceptTerms.value = false;
  }
});
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content-affiliate-program-register ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title">{{ textData.register }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <wg-type-input type="normal" :placeholder="textData.name" @onValueChange="handleNameValueChanged"></wg-type-input>
      <wg-type-input type="normal" :placeholder="textData.traffic_geo" @onValueChange="handleTrafficGEOValueChanged"></wg-type-input>
      <wg-type-input type="normal" :placeholder="textData.email" @onValueChange="handleEmailValueChanged"></wg-type-input>
      <wg-type-input type="normal" :placeholder="textData.skype" @onValueChange="handleSkypeValueChanged"></wg-type-input>
      <div class="address">
        <div class="address-title">{{ textData.wallet_address_trc20 }}</div>
        <wg-type-input type="normal" :placeholder="textData.wallet_address_trc20" @onValueChange="handleAddressValueChanged"></wg-type-input>
      </div>
      <div class="agree" @click="handleReceiveNewslettersClick">
        <div class="agree-circle" v-if="!receiveNewsletters">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
            <circle cx="9.99998" cy="9.50001" r="8.9" fill="var(--agree-circle-un-selected-bg-color)" stroke="var(--agree-circle-un-selected-bg-color)" />
          </svg>
        </div>
        <div class="agree-circle" v-if="receiveNewsletters">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path
              d="M8.99998 0.100006C4.36117 0.100006 0.599976 3.8612 0.599976 8.50001C0.599976 13.1388 4.36117 16.9 8.99998 16.9C13.6388 16.9 17.4 13.1388 17.4 8.50001C17.4 3.8612 13.6388 0.100006 8.99998 0.100006ZM13.277 6.59792L9.52296 11.6021C9.32594 11.8636 9.01789 12.0176 8.69192 12.0212C8.49848 12.0212 8.30505 11.9675 8.14027 11.8636L5.12057 10.0081C4.7946 9.81463 4.59401 9.46359 4.60117 9.08389C4.60833 8.70418 4.81968 8.3603 5.15639 8.1812C5.49311 8.00209 5.89789 8.02001 6.21669 8.22777L8.42684 9.5818L11.6042 5.34418C11.9516 4.8821 12.6071 4.78896 13.0728 5.13642C13.5313 5.4803 13.6245 6.13583 13.277 6.59792Z"
              fill="var(--agree-circle-bg-color)"
            />
          </svg>
        </div>
        <div class="agree-title">{{ textData.receive_newsletters }}</div>
      </div>
      <div class="agree" @click="handleAcceptTermsClick">
        <div class="agree-circle" v-if="!acceptTerms">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
            <circle cx="9.99998" cy="9.50001" r="8.9" fill="var(--agree-circle-un-selected-bg-color)" stroke="var(--agree-circle-un-selected-bg-color)" />
          </svg>
        </div>
        <div class="agree-circle" v-if="acceptTerms">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
            <path
              d="M8.99998 0.100006C4.36117 0.100006 0.599976 3.8612 0.599976 8.50001C0.599976 13.1388 4.36117 16.9 8.99998 16.9C13.6388 16.9 17.4 13.1388 17.4 8.50001C17.4 3.8612 13.6388 0.100006 8.99998 0.100006ZM13.277 6.59792L9.52296 11.6021C9.32594 11.8636 9.01789 12.0176 8.69192 12.0212C8.49848 12.0212 8.30505 11.9675 8.14027 11.8636L5.12057 10.0081C4.7946 9.81463 4.59401 9.46359 4.60117 9.08389C4.60833 8.70418 4.81968 8.3603 5.15639 8.1812C5.49311 8.00209 5.89789 8.02001 6.21669 8.22777L8.42684 9.5818L11.6042 5.34418C11.9516 4.8821 12.6071 4.78896 13.0728 5.13642C13.5313 5.4803 13.6245 6.13583 13.277 6.59792Z"
              fill="var(--agree-circle-bg-color)"
            />
          </svg>
        </div>
        <div class="agree-title">
          {{ textData.i_accept_the + ' ' }}
          <span class="agree-title-link" @click.stop="handleLookTermsClick">{{ textData.affiliate_terms_conditions }}</span>
        </div>
      </div>
      <button :class="confirming ? 'bottom-button' : handleCanNext ? 'bottom-button' : 'bottom-button-disable'" @click="handleConfirmClick" :disabled="confirming || !handleCanNext">
        {{ confirming ? '' : textData.confirm }}
        <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
      </button>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content-affiliate-program-register {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.125rem;
  padding: 1.125rem 1.1875rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content-affiliate-program-register.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.address {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
}
.address-title {
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.agree {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding-left: 0.7875rem;
  width: calc(100% - 0.7875rem);
  cursor: pointer;
}
.agree-circle {
  width: 1.0625rem;
  height: 1.0625rem;
  min-width: 1.0625rem;
  min-height: 1.0625rem;
}
.agree-title {
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  width: 100%;
  text-align: left;
}
.agree-title-link {
  color: var(--bg-blue-color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.bottom-button {
  background-color: var(--bg-blue-color);
  border: none;
  padding: 0;
  color: var(--default-btn-color);
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.625rem 0;
}
.bottom-button-disable {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.625rem 0;
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}
</style>
