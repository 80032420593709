<template>
  <div>
    <div class="my-dialog" style="" v-if="reset_password">
      <div class="dialog-main !bg-[#0D101E]">
        <div class="dialog-header">
          <div class="dialog-title !text-[#fff]">{{ t('reset_password') }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="handleClose()" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="bottom-1rem !text-[#AAC4ED]">
            {{ t('you_are___continue') }}
          </div>
          <div style="display: flex; justify-content: center" class="input-div">
            <input :placeholder="t('please_input__email')" v-model="reset_password_data.email" />
          </div>
          <wg-button class="p-[1rem]"> {{ t('reset').toLocaleUpperCase() }}</wg-button>
        </div>
      </div>
    </div>
    <div class="my-dialog" style="" v-if="set_password">
      <div class="dialog-main !bg-[#0D101E]">
        <div class="dialog-header">
          <div class="dialog-title !text-[#fff]">{{ t('reset_password').toLocaleUpperCase() }}</div>
          <div class="close">
            <img src="../../assets/images/login-and-register/Vector.svg" @click="handleClose()" />
          </div>
        </div>
        <div class="dialog-body">
          <div class="bottom-1rem">
            <div class="text-left text-[#AAC4ED] text-[0.75rem] font-bold">{{ t('password') }}</div>
            <wg-type-input type="password" :placeholder="t('password')" @onValueChange="handlePasswordValueChanged"></wg-type-input>
          </div>

          <div style="width: 100%" class="">
            <ul class="reset_password_ul !text-[#AAC4ED]">
              <li>{{ t('Contanis_Tip1') }}</li>
              <li>{{ t('Contanis_Tip2') }}</li>
              <li>{{ t('Contanis_Tip3') }}</li>
            </ul>
          </div>
          <div class="bottom-1rem">
            <div class="text-left text-[#AAC4ED] text-[0.75rem] font-bold">{{ t('confirm_password') }}</div>
            <wg-type-input type="password" :placeholder="t('confirm_password')" @onValueChange="handlePassword2ValueChanged"></wg-type-input>
          </div>

          <div class="bottom-1rem">
            <div class="text-left text-[#AAC4ED] text-[0.75rem] font-bold">{{ t('verification') }}</div>
            <wg-type-input type="verifyCode" :placeholder="t('verification')" @onValueChange="handleVerifyValueChanged">
              <button class="verify-code-button" @click="resetSendCode" :disabled="sendCoding || registerT">
                {{ sendCoding ? '' : sendCodeText }}
                <vue3-lottie :animationData="confirmLoadingJson" :width="40" :height="40" v-if="sendCoding"></vue3-lottie>
              </button>
            </wg-type-input>
          </div>
          <wg-button class="p-[1rem]" @click="resetEmailPasswordServiceEvent"> {{ t('confirm').toLocaleUpperCase() }}</wg-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Vue3Lottie } from 'vue3-lottie';
import wgTypeInput from '@/components/common/wgTypeInput.vue';
import { useI18n } from 'vue-i18n';
import wgButton from '../common/wgButton.vue';
const { t } = useI18n();
</script>
<script>
import md5 from 'crypto-js/md5';
import { resetEmailPasswordSendCodeService, resetEmailPasswordService } from '@/assets/network/service/user';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { getCookieEmail } from '@/assets/tools/CommonTool';

import loadingAniJson from '@/assets/animation/expott_loading.json';

export default {
  name: 'App',
  components: {},
  props: {
    isBegin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmLoadingJson: loadingAniJson,
      sendCoding: false,
      errorShow: false,
      errorShowTime: -1,
      sendCodeText: '',
      userLogin: {
        password: '',
        email: ''
      },
      reset_password_data: {
        email: '',
        password1: '',
        password2: '',
        key: ''
      },
      registerData: {
        email: '',
        password: '',
        code: '',
        referralCode: ''
      },
      password: 'password',
      dialogVisible: false,
      reset_password: false,
      set_password: false,
      set_passwordData: {
        password: ''
      },
      isLogin: true,
      readFlag: false,
      classConfig: {
        email: false,
        password: false,
        code: false,
        referral_code: false
      },
      registerT: null,
      errorShowT: null,
      force_invite_code: false,
      default_invite_code: ''
    };
  },
  created() {
    const that = this;
    that.reset_password = that.isBegin;
    let email = getCookieEmail();
    if (email) {
      that.reset_password = false;
      that.set_password = true;
      that.reset_password_data.email = email;
    }
    console.log(that.reset_password);
    that.sendCodeText = that.$t('send_code');
    setInterval(function () {
      if (that.errorShowTime > -1) {
        that.errorShowTime--;
        that.errorShow = that.errorShowTime >= 0;
      }
    }, 1000);
    document.getElementsByTagName('body')[0].classList.add('hidden-overflow-y');
  },
  unmounted() {
    document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
    if (this.registerT) {
      clearInterval(this.registerT);
    }
    if (this.errorShowT) {
      clearInterval(this.errorShowT);
    }
  },
  methods: {
    handlePasswordValueChanged(value) {
      this.reset_password_data.password1 = value;
    },
    handlePassword2ValueChanged(value) {
      this.reset_password_data.password2 = value;
    },
    handleVerifyValueChanged(value) {
      this.reset_password_data.key = value;
    },
    resetEmailPasswordServiceEvent() {
      if (this.reset_password_data.password1 !== this.reset_password_data.password2) {
        toast(this.$t('the_password___inconsistent'), { autoClose: 2000, type: 'error' });
        return;
      }
      const saltPWD = md5(this.reset_password_data.password1 + '{PWD_Salt_CC_Poker}').toString();
      const saltPWD2 = md5(this.reset_password_data.password2 + '{PWD_Salt_CC_Poker}').toString();
      const isVerified = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(this.reset_password_data.password1);
      if (!isVerified) {
        toast(this.$t('the_new___invalid'), { autoClose: 2000, type: 'error' });
        return;
      }
      if (!this.reset_password_data.key) {
        toast(this.$t('please_input___code'), { autoClose: 2000, type: 'error' });
        return;
      }
      resetEmailPasswordService({
        key: this.reset_password_data.key,
        email: this.reset_password_data.email,
        password1: saltPWD,
        password2: saltPWD2
      })
        .then(() => {
          toast(this.$t('reset_successfully'), { autoClose: 2000 });
          this.reset_password = false;
          this.set_password = false;
          this.$emit('close');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetSendCode() {
      if (this.registerT) {
        return;
      }
      this.sendCoding = true;
      resetEmailPasswordSendCodeService({
        email: this.reset_password_data.email
      })
        .then(() => {
          const that = this;
          let time = 59;
          this.sendCoding = false;
          toast(this.$t('sent_successfully'));
          that.sendCodeText = '(' + time + ')';
          this.registerT = setInterval(function () {
            time--;
            if (time < 0) {
              that.sendCodeText = that.$t('send_code');
              clearInterval(that.registerT);
              that.registerT = null;
              return;
            } else {
              that.sendCodeText = '(' + time + ')';
            }
          }, 1000);
        })
        .catch((error) => {
          this.sendCoding = false;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    handlePasswordIsVerify(password) {
      return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(password);
    },
    inputFocus(name) {
      this.classConfig[name] = true;
    },
    inputBlur(name) {
      this.classConfig[name] = false;
    },
    handleClose() {
      this.reset_password = false;
      this.set_password = false;
      this.$emit('close');
    },
    resetPassword() {
      let error = false;
      if (!this.reset_password_data.email) {
        this.reset_password_data.emailCode = 'email_is_required';
        this.reset_password_data.emailError = true;
        error = true;
        // tip.loginEmailTip = emailFormatIncorrect;
      } else if (!this.reset_password_data.email.includes('@')) {
        this.reset_password_data.emailCode = 'email_format___incorrect';
        this.reset_password_data.emailError = true;
        error = true;
        // tip.loginEmailTip = emailFormatIncorrect;
      }
      if (error) {
        toast(this.$t(this.reset_password_data.emailCode), { autoClose: 2000, type: 'error' });
        return;
      }
      this.reset_password = false;
      this.set_password = true;
    }
  }
};
</script>

<style scoped>
::v-deep .bg-content.active {
  border: 2px solid var(--input-color);
}
::v-deep .input {
  caret-color: var(--input-color);
  text-align: left;
}
::v-deep input::placeholder {
  color: var(--placeholder-color);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
