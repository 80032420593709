import api from '../api';
import httpManager from '../httpManager';

// 获取转盘活动列表
export const getTurntableList = (param) => {
  return httpManager({
    url: api.getTurntableList,
    method: 'post',
    data: param
  });
};

// 获取转盘活动详情
export const getTurntableDetail = (param) => {
  return httpManager({
    url: api.getTurntableDetail,
    method: 'post',
    data: param
  });
};

// 获取转盘抽奖结果
export const getTurntableResult = (param) => {
  return httpManager({
    url: api.getTurntableResult,
    method: 'post',
    data: param
  });
};

// 获取转盘领奖列表
export const getTurntableResultList = (param) => {
  return httpManager({
    url: api.getTurntableResultList,
    method: 'post',
    data: param
  });
};

// 获取转盘领奖列表
export const getTurntableReceive = (param) => {
  return httpManager({
    url: api.getTurntableReceive,
    method: 'post',
    data: param
  });
};

// get free spin wheel list  
export const getFreePromotionsService = (params) => {
  return httpManager({
    url: api.get_free_promotions_for_index,
    method: 'get',
    params
  });
};


// 奖金手动领取接口
export const getReceivePromotionsPrizeService = (data) => {
  return httpManager({
    url: api.get_receive_promotions_prize,
    method: 'post',
    data
  });
};

