<template>
  <div style="" v-show="!showBindEmail && !showWithdrawSetting && !openBindMfa && !openSettingWithdrawPin && !showInputPinInfo && !showForgotPassword && !showAddAccount">
    <div class="my-dialog">
      <div class="dialog-main">
        <div style="" class="deposit-main profit">
          <div class="deposit-title">
            <div style="justify-content: left; white-space: nowrap; width: 70%; text-align: left; overflow: hidden">{{ t('my_wallet') }}</div>
            <div style="display: flex; justify-content: right; width: 30%">
              <img class="deposit_withdraw_img" @click="close()" src="../../assets/images/profile/profile-edit-close.svg" />
            </div>
          </div>
          <!-- div v-if="type=='withdraw' && thisSymbol2.is_fiat" -->
          <div class="deposit" style="cursor: default" v-if="type == 'withdraw' && thisSymbol2.is_fiat">
            <div class="flex-end brl-page-input" @click="showBrlPageSelected = true">
              <div class="flex-end">
                {{ $t(brlWithdrawPage) }}
              </div>
              <div class="flex-end" style="padding-left: 0.5625rem">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                  <path d="M-4.7903e-05 0.299706L5.59995 8.69971L11.2 0.299707" fill="var(--icon-bg-color)" />
                </svg>
              </div>
            </div>
          </div>

          <div class="close-div" style="z-index: 9" v-if="showBrlPageSelected" @click.stop="showBrlPageSelected = false"></div>
          <div class="deposit select-div2" v-if="showBrlPageSelected">
            <div class="select-div-main2">
              <div
                v-for="(code, index) in brlWithdrawPages"
                @click.stop="
                  brlWithdrawPage = code;
                  showBrlPageSelected = false;
                "
                :class="brlWithdrawPage == code ? 'brl-page-selected' : ''"
                :key="index"
              >
                {{ $t(code) }}
              </div>
            </div>
          </div>
          <div class="deposit tabs">
            <div :class="type == 'deposit' ? 'selected' : 'unselected'" @click="typeChange('deposit')">
              <img src="../../assets/images/wallet/wallet-deposit_2.svg" v-if="type == 'deposit'" />
              <img src="../../assets/images/wallet/wallet-deposit.svg" v-if="type !== 'deposit'" />
              {{ t('deposit') }}
            </div>
            <div :class="type == 'withdraw' ? 'selected' : 'unselected'" @click="typeChange('withdraw')">
              <img src="../../assets/images/wallet/wallet-withdraw_2.svg" v-if="type == 'withdraw'" />
              <img src="../../assets/images/wallet/wallet-withdraw.svg" v-if="type !== 'withdraw'" />
              {{ t('withdraw') }}
            </div>
          </div>

          <div v-if="type == 'deposit' && depositStep == 1">
            <div class="deposit web top-1rem">
              <img src="../../assets/images/wallet/wallet-deposit-banner.png" style="width: 100%" />
            </div>
            <div class="deposit phone top-1rem">
              <img src="../../assets/images/wallet/wallet-deposit-banner-mobile.png" style="width: 100%" />
            </div>
            <div class="deposit" style="color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default">
              {{ t('your_balance') }}
            </div>
            <div class="deposit input-selected-out">
              <div class="input-selected" @click="showSearchSymbolEvent()">
                <img src="../../assets/images/wallet/wallet-withdraw-arrow-down.svg" style="width: 0.875rem; position: relative; right: -90%" />
                {{ convertCurrency(thisSymbol.balance) }}
                <img :src="thisSymbol.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
              </div>
              <div class="transaction" @click="jump('deposit')">{{ t('transaction') }}</div>
            </div>
            <div class="deposit select-div" v-if="isSelectSymbol">
              <div class="select-div-main">
                <div :class="'option search_symbol'">
                  <div class="tool-bar-search" style="">
                    <div :class="'tool-bar-search bottom-1rem '">
                      <div :class="'input-zindex1550 line-height-2_5rem '">
                        <div :class="'line-height-2_5rem input-div'">
                          <div class="img-div line-height-2_5rem">
                            <img style="width: 1.25rem; height: 1.25rem; padding: 0 1.5rem" src="../../assets/images/home/Frame 427320295.svg" />
                          </div>
                          <div class="input line-height-2_5rem">
                            <input v-model="searchSymbol" type="input" :placeholder="t('search_symbol')" @keyup="searchSymbolEvent()" @keyup.enter="searchSymbolEvent()" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="'option ' + (thisSymbol.symbol == symbol.symbol ? 'selected' : '')" v-for="(symbol, index) in symbols" v-show="symbol.symbol !== 'Gold'" :key="index" @click="selectSymbol(symbol)">
                  <div class="option" style="width: 45%">
                    {{ convertCurrency(symbol.balance) }}
                  </div>
                  <div>
                    <img :src="symbol.icon_url" />
                  </div>
                  <div>
                    {{ symbol.symbol }}
                  </div>
                </div>
              </div>
            </div>
            <div class="deposit" style="color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default" v-if="thisSymbol.is_fiat">
              {{ t('amount') }}
            </div>
            <div class="brl-deposit" v-if="thisSymbol.is_fiat">
              <div :class="'brl-deposit-symbol' + (index % 4 == 0 ? ' first' : '') + ((index + 1) % 4 == 0 ? ' last' : '') + (index == brlDepositIndex ? ' brl-selected' : '')" @click="changBrlDepositIndex(index)" v-for="(money, index) in brlDepositList" :key="index">{{ money }} <img class="brl-deposit-symbol-icon" :src="thisSymbol.icon_url" /></div>
              <div style="clear: both"></div>
            </div>

            <div class="brl-deposit-symbol-amount" style="" v-if="thisSymbol.is_fiat">
              <img class="brl-deposit-symbol-icon2" :src="thisSymbol.icon_url" />
              <span v-if="thisSymbol.symbol == 'BRL'">R$</span>
              <span v-if="thisSymbol.symbol == 'MXN'">MX$</span>
              <input v-model="thisSymbol.amount" @change="thisSymbolAmountChange" />
            </div>
            <div class="deposit" style="color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default" v-if="!thisSymbol.is_fiat">
              {{ t('network') }}
            </div>

            <div class="net_work" style="" v-if="!thisSymbol.is_fiat">
              <div v-for="(net, index) in netWorks" @click="netWork = net" :key="index" :class="(index == 0 ? 'first ' : '') + (index == netWorks.length - 1 ? 'last ' : '') + 'symbol-block ' + (net.platform_name == netWork.platform_name ? 'symbol-selected' : '')">
                <div class="platform-top">
                  <img :src="thisSymbol.icon_url" style="width: 2.4rem" />
                </div>
                <div style="width: 100%">{{ thisSymbol.symbol + '-' }}{{ net.name || net.platform_name }}</div>
              </div>
            </div>

            <div style="clear: both"></div>
            <div class="deposit" style="cursor: default" v-if="!thisSymbol.is_fiat">
              <div class="btn line-height-2_5rem font-size-18" style="" @click="nextStep()">{{ t('next') }}</div>
            </div>
            <div class="deposit" style="cursor: default; padding: 1.75rem 0" v-if="thisSymbol.is_fiat">
              <div class="btn line-height-2_5rem font-size-18" style="" @click="fiatDeposit()">
                {{ depositing ? '' : t('deposit_now') }}
                <vue3-lottie :animationData="confirmLoadingJson" :width="40" :height="40" v-if="depositing"></vue3-lottie>
              </div>
            </div>

            <div style="clear: both"></div>
          </div>
          <div v-if="type == 'deposit' && depositStep == 2" class="deposit-main-out">
            <div class="deposit" style="cursor: default">
              <div style="width: 100%; text-align: center" class="top-1rem">
                <!--              <img src="../../assets/images/wallet/qr_code.png" class="qr_code"/>-->
                <qrcode-vue :value="netWork.address" level="H" :size="130"></qrcode-vue>
              </div>
            </div>
            <div class="deposit top-1rem" style="cursor: default">
              <div style="width: 100%; text-align: center" class="nft-text bottom-1rem">{{ t('the_address___depositing') }}{{ ' ' + thisSymbol.symbol + ' ' }}{{ t('it_does___deposit') }}</div>
            </div>

            <div class="deposit top-1rem warning" style="cursor: default; display: block">
              <!--  <div style="" class="warning-text bottom-1rem">
              {{t('before_withdrawing___correct')}}
            </div>-->
              <div class="deposit tool-bar-search" style="">
                <div :class="'tool-bar-search bottom-1rem '">
                  <div :class="'input-zindex1550 '">
                    <div :class="'input-div the_minimum_recharge'">
                      <div class="input the_minimum_recharge">
                        <span>{{ t('the_amount__than') }}</span>
                      </div>
                      <div class="img-div" style="width: auto; font-family: 'Poppins', sans-serif; font-size: 1.125rem; font-weight: 700; color: #ffffff; margin-right: 0.3rem; letter-spacing: 0em">
                        <img style="width: 1.25rem; height: 1.25rem" :src="thisSymbol.icon_url" />
                        <span style="color: var(--money-unit-color); margin: 0 0.3rem">{{ thisSymbol.symbol }}</span
                        >{{ netWork.min_deposit_amount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="deposit top-1rem warning" style="cursor: default; display: block">
              <div class="token">
                {{ t('token') }}
              </div>
              <div class="token-symbol"><img :src="thisSymbol.icon_url" />{{ thisSymbol.symbol }}</div>
              <div class="token">
                {{ t('network') }}
              </div>
              <div class="token-symbol">
                {{ netWork.platform_name }}
              </div>

              <div class="token">
                {{ thisSymbol.symbol }} {{ t('deposit_address') }}
                <div
                  @click="
                    () => {
                      copy(netWork.address);
                      toast(t('copy_successfully'), { autoClose: 1000 });
                    }
                  "
                  style="width: 1.375rem; float: right; cursor: pointer"
                >
                  <img src="../../assets/images/wallet/wallet-copy.svg" style="width: 1.375rem; float: right; cursor: pointer" />
                </div>
              </div>
              <div class="token-symbol token-symbol2">
                {{ netWork.address }}
              </div>
              <div style="clear: both"></div>
            </div>

            <div class="deposit top-1rem" style="cursor: default">
              <div style="width: 100%; text-align: left; line-height: 1.5rem" class="nft-text bottom-1rem">{{ t('you_can_only_deposit') }}{{ thisSymbol.symbol }}({{ netWork.platform_name }}){{ t('to__this___amount') }} {{ netWork.min_deposit_amount }}{{ t('the_minimum___leakage') }}</div>
            </div>
          </div>

          <div v-if="type == 'withdraw' && withdrawStep == 1" class="deposit-main-out">
            <div class="deposit" style="color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default">
              {{ t('your_balance') }}
            </div>
            <brl-withdraw :page="brlWithdrawPage" :show="thisSymbol2.is_fiat" v-if="thisSymbol2.is_fiat" :symbol="thisSymbol2" :symbols="walletList" :is_otp="enable_otp" :add-account-num="addAccountNum" @addAccount="addAccount" @withdrawSuccess="withdrawSuccess" @selectSymbol="selectSymbol" :network="netWork2" />
            <div v-show="!thisSymbol2.is_fiat">
              <div class="deposit input-selected-out">
                <div class="input-selected" @click="showSearchSymbolEvent()">
                  <img src="../../assets/images/wallet/wallet-withdraw-arrow-down.svg" style="width: 0.875rem; position: relative; right: -90%" />
                  {{ convertCurrency(thisSymbol2.balance) }}
                  <img :src="thisSymbol2.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
                </div>
                <div class="transaction" @click="jump('withdraw')">{{ t('transaction') }}</div>
              </div>
              <div class="deposit select-div" v-if="isSelectSymbol2">
                <div class="select-div-main">
                  <div :class="'option search_symbol'">
                    <div class="tool-bar-search" style="">
                      <div :class="'tool-bar-search bottom-1rem '">
                        <div :class="'input-zindex1550 line-height-2_5rem '">
                          <div :class="'line-height-2_5rem input-div'">
                            <div class="img-div line-height-2_5rem flex">
                              <img style="width: 1.25rem; height: 1.25rem" src="../../assets/images/home/Frame 427320295.svg" />
                            </div>
                            <div class="input line-height-2_5rem">
                              <input v-model="searchSymbol" type="input" :placeholder="t('search_symbol')" @keyup="searchSymbolEvent()" @keyup.enter="searchSymbolEvent()" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div :class="'option ' + (thisSymbol2.symbol == symbol.symbol ? 'selected' : '')" v-for="(symbol, index) in symbols2" :key="index" v-show="symbol.symbol !== 'Gold'" @click="selectSymbol(symbol)">
                    <div class="option" style="width: 45%">
                      {{ convertCurrency(symbol.balance) }}
                    </div>
                    <div>
                      <img :src="symbol.icon_url" />
                    </div>
                    <div>
                      {{ symbol.symbol }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="deposit" style="color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default">
                {{ t('network') }}
              </div>

              <div class="w_network">
                <div v-for="(net, index) in netWorks2" @click="netWork2 = net" :key="index" :class="'symbol-block ' + (net.platform_name == netWork2.platform_name ? 'symbol-selected' : '')">
                  <img v-if="netWork2.platform_name == net.platform_name" src="../../assets/images/wallet/withdraw_btn_selected.svg" style="width: 1.5rem" />
                  <img v-if="netWork2.platform_name != net.platform_name" src="../../assets/images/wallet/withdraw_btn_unselected.svg" style="width: 1.5rem" />
                  {{ net.platform_name }}
                </div>
              </div>

              <div class="deposit" style="font-size: 0.875rem; color: var(--color-8ea3c3); font-weight: 700; height: 2rem; line-height: 2rem; cursor: default">·{{ t('withdraw_address') }}</div>

              <div class="w_network border" style="line-height: 2.5rem; height: 2.5rem; padding: 0">
                <input :placeholder="'·' + t('withdraw_address')" style="background-color: #0c131d; width: calc(100% - 1rem); margin: auto; border: 0; outline: 0; color: #55657e; font-family: 'Poppins', sans-serif; font-size: 0.875rem; font-weight: 700; letter-spacing: 0em; text-align: left" v-model="netWorkAddress2" />
              </div>
              <div class="deposit" style="font-size: 0.875rem; color: var(--color-8ea3c3); font-weight: 700; height: 1.5rem; line-height: 1.5rem; cursor: default">·{{ t('withdraw_amount') }}</div>
              <div class="deposit" style="font-size: 0.875rem; color: var(--color-8ea3c3); font-weight: 700; height: 1.5rem; line-height: 1.5rem; cursor: default">
                ·{{ t('withdrawable_amount') }}
                <span style="padding-left: 0.3rem">{{ thisSymbol2.symbol }}</span>
                <span style="padding-left: 0.3rem">{{ thisSymbol2.balance }}</span>
                <img src="../../assets/images/wallet/ttbet_wallet-question.svg" style="width: 1.5rem; padding-left: 0.3rem" />
              </div>

              <div class="w_network border" style="line-height: 2.5rem; height: 2.5rem; padding: 0; margin-bottom: 0.5rem">
                <input
                  :placeholder="'·' + t('minimum_amount') + (netWork2.min_withdraw_amount || 0) + thisSymbol2.symbol"
                  style="background-color: #0c131d; width: calc(100% - 1rem); margin: auto; border: 0; outline: 0; color: #55657e; font-family: 'Poppins', sans-serif; font-size: 0.875rem; font-weight: 700; letter-spacing: 0em; text-align: left"
                  v-model="w_input_amount"
                  @change="w_thisSymbolChange(w_input_amount)"
                />
              </div>

              <div class="w_network">
                <div style="width: 100%; display: block">
                  <div class="w_network" style="margin: 0; width: 100%">
                    <div style="border-radius: 0.5rem" v-for="(rate, index) in rates" @click="defaultRate = rate.type" :key="index" :class="'symbol-block ' + (rate.type == defaultRate ? 'symbol-selected' : '')">{{ rate.number }}%</div>
                  </div>
                </div>
              </div>
              <div class="deposit" style="cursor: default; display: block; line-height: 2rem; color: var(--color-8ea3c3)">
                <div class="free">
                  {{ t('fee') }}:{{ thisSymbol2.symbol }}<span style="color: #ffffff">{{ thisSymbol2.freeInt }}</span
                  >{{ thisSymbol2.freeOther }}
                </div>
                <div class="free">
                  {{ t('estimated_amount') }}:<span style="color: var(--money-unit-color); margin: 0 0.3rem">{{ thisSymbol2.symbol }}</span
                  ><span style="color: #ffffff">{{ thisSymbol2.w_amountInt }}</span
                  >{{ thisSymbol2.w_amountOther }}
                </div>
              </div>
              <div class="deposit" style="cursor: default">
                <div class="btn line-height-2_5rem font-size-18" style="width: 100%; border-radius: 0.5rem; background-color: #1184fa" @click="nextStep()">{{ t('next') }}</div>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
          <div v-if="type == 'withdraw' && withdrawStep == 2" class="deposit-main-out">
            <div class="deposit top-1rem warning" style="cursor: default; display: block">
              <div style="" class="warning-text bottom-1rem">
                {{ t('before_withdrawing___correct') }}
              </div>
              <div class="deposit tool-bar-search" style="">
                <div :class="'tool-bar-search bottom-1rem '">
                  <div :class="'input-zindex1550 '">
                    <div :class="'input-div the_minimum_recharge'">
                      <div class="input the_minimum_recharge">
                        <span>{{ t('the_minimum_withdrawal') }}</span>
                      </div>
                      <div class="img-div" style="width: auto; font-family: 'Poppins', sans-serif; font-size: 1.125rem; font-weight: 700; color: #ffffff; margin-right: 0.3rem; letter-spacing: 0em">
                        <img style="width: 1.25rem; height: 1.25rem" :src="thisSymbol2.icon_url" />
                        <span style="color: var(--money-unit-color); margin: 0 0.3rem">{{ thisSymbol2.symbol }}</span
                        >{{ netWork2.min_withdraw_amount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="deposit top-1rem warning" style="cursor: default; display: block">
              <div class="token">
                {{ t('token') }}
              </div>
              <div class="token-symbol"><img :src="thisSymbol2.icon_url" />{{ thisSymbol2.symbol }}</div>
              <div class="token">
                {{ t('network') }}
              </div>
              <div class="token-symbol">
                {{ netWork2.platform_name }}
              </div>

              <div class="token">
                {{ t('address') }}
                <div
                  @click="
                    () => {
                      copy(netWorkAddress2);
                      toast(t('copy_successfully'), { autoClose: 1000 });
                    }
                  "
                  style="width: 1.375rem; float: right; cursor: pointer"
                ></div>
                <img src="../../assets/images/wallet/wallet-copy.svg" style="width: 1.375rem; float: right; cursor: pointer" />
              </div>
              <div class="token-symbol token-symbol3">
                {{ netWorkAddress2 }}
              </div>

              <div class="token">
                {{ t('amount') }}
              </div>
              <div class="token-symbol token-symbol3">
                {{ thisSymbol2.balanceAmount }}
              </div>

              <div class="token line-height-2_5rem">
                {{ t('balance') }}<span style="float: right; color: #ffffff">{{ subtract(thisSymbol2.balance, thisSymbol2.balanceAmount) }} {{ thisSymbol2.symbol }}</span>
              </div>
              <div class="token line-height-2_5rem">
                {{ t('gas_fee') }}<span style="float: right; color: #ffffff">{{ thisSymbol2.gasFee }} {{ thisSymbol2.symbol }}</span>
              </div>
              <div class="token line-height-2_5rem">
                {{ t('estimated_amount') }}<span style="float: right; color: #ffffff">{{ subtract(thisSymbol2.balanceAmount, thisSymbol2.gasFee) }} {{ thisSymbol2.symbol }}</span>
              </div>
            </div>

            <div class="deposit" style="cursor: default">
              <div class="btn line-height-2_5rem font-size-18" style="width: 100%; border-radius: 0.5rem; background-color: #1184fa" @click="confirmStep()">
                {{ t('confirm') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <bind-email :show="showBindEmail" @close="handleCloseBindEmailClick" @bindSuccess="handleBindSuccess" :bindEmail="userInfoEmail"></bind-email>
  <withdraw-setting :show="showWithdrawSetting" @close="handleCloseWithdrawSettingClick" @settingChoose="handleWithdrawSettingChoose"></withdraw-setting>
  <bind-mfa :show="openBindMfa" v-if="openBindMfa" :email="userInfoEmail" @close="handleCloseBindMfaClick" @bindSuccess="handleBindMfaSuccess"></bind-mfa>
  <pin-setting :show="openSettingWithdrawPin" @close="handleClosePinSettingClick" @settingSuccess="handleSettingPinSuccess"></pin-setting>
  <input-pin :show="showInputPinInfo" @forgotPassword="forgotPasswordEvent" @close="handleCloseInputPinClick" @settingSuccess="handleInputPinSuccess" :confirming="inputPinConfirming" :is_otp="isAddAccount ? false : enable_otp"></input-pin>
  <pin-re-setting :show="showForgotPassword" @close="processForgotPasswordAfter" @success="processForgotPasswordAfter"></pin-re-setting>
  <add-pix :show="showAddAccount" :pin-password="pinPassword" @addBlankSuccess="addBlankSuccess" @close="addPixClose" :person_id="person_id" :person_name="person_name" :account_name="account_name" :symbol="thisSymbol2.symbol"></add-pix>
</template>
<script></script>

<script setup>
import addPix from './addPix.vue';
import pinSetting from './pinSetting.vue';
import pinReSetting from './pinReSetting.vue';
import { ref } from 'vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { userInfoService } from '@/assets/network/service/user';
import { useI18n } from 'vue-i18n';
import { useClipboard } from '@vueuse/core';

const confirmLoadingJson = ref(loadingAniJson);
const { t } = useI18n();

const { copy } = useClipboard({ legacy: true });
</script>

<script>
import { BigNumber } from 'bignumber.js';
import profileAvatar1 from '../../assets/images/profile/avatar/profile-avatar1.png';
import profileAvatar2 from '../../assets/images/profile/avatar/profile-avatar2.png';
import profileAvatar3 from '../../assets/images/profile/avatar/profile-avatar3.png';
import profileAvatar4 from '../../assets/images/profile/avatar/profile-avatar4.png';
import profileAvatar5 from '../../assets/images/profile/avatar/profile-avatar5.png';
import profileAvatar6 from '../../assets/images/profile/avatar/profile-avatar6.png';
import profileAvatar7 from '../../assets/images/profile/avatar/profile-avatar7.png';
import profileAvatar8 from '../../assets/images/profile/avatar/profile-avatar8.png';
import profileAvatar9 from '../../assets/images/profile/avatar/profile-avatar9.png';
import profileAvatar10 from '../../assets/images/profile/avatar/profile-avatar10.png';
import profileAvatar11 from '../../assets/images/profile/avatar/profile-avatar11.png';
import profileAvatar12 from '../../assets/images/profile/avatar/profile-avatar12.png';
import profileAvatar13 from '../../assets/images/profile/avatar/profile-avatar13.png';
import profileAvatar14 from '../../assets/images/profile/avatar/profile-avatar14.png';
import profileAvatar15 from '../../assets/images/profile/avatar/profile-avatar15.png';
import profileAvatar16 from '../../assets/images/profile/avatar/profile-avatar16.png';
import usdtImg from '../../assets/images/home/unit-usdt.png';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { walletValid } from '@/assets/tools/wattle';
import { cryptoWalletWithdrawRequest, walletDepositInfoService, walletGenerateNewAddressService, getFiatPreDeposit, fiat_deposit } from '@/assets/network/service/wallet';
import QrcodeVue from 'qrcode.vue';
import { getCookieUserId, convertCurrency, getCookieEmail } from '@/assets/tools/CommonTool';
import { fpDepositViewAddressEvent, fpInitiateCheckout } from '@/assets/tools/FacebookPixelEvent';
import bindEmail from '@/pages/profile/bindEmail.vue';
import bindMfa from '@/pages/profile/bindMfa.vue';
import withdrawSetting from '@/components/symbol/withdrawSetting.vue';
import inputPin from './inputPin.vue';
import brlWithdraw from './brlWithdraw.vue';
import { kwaiEventService } from '@/assets/network/service/user';
import { useAppStore } from '@/store/store';
export default {
  name: 'App',
  props: {
    walletList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    wallet: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  watch: {
    netWork2: function () {
      this.w_thisSymbolChange();
    },
    walletList: function () {
      for (let i in this.walletList) {
        if (this.thisSymbol && this.thisSymbol.symbol == this.walletList[i].symbol) {
          for (let key in this.walletList[i]) {
            this.thisSymbol[key] = this.walletList[i][key];
          }
        }
        if (this.thisSymbol2 && this.thisSymbol2.symbol == this.walletList[i].symbol) {
          for (let key in this.walletList[i]) {
            this.thisSymbol2[key] = this.walletList[i][key];
          }
        }
      }
    },
    thisSymbol: function () {
      this.w_thisSymbolChange();
    },
    defaultRate: function () {
      if (this.defaultRate == 0) {
        return;
      }
      this.w_thisSymbolChange();
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      person_id: '',
      person_name: '',
      account_name: '',
      showAddAccount: false,
      isAddAccount: false,
      brlWithdrawPages: ['withdraw', 'withdraw_records', 'manage_account'],
      brlWithdrawPage: 'withdraw',
      inputPinConfirming: false,
      showInputPinInfo: false,
      showPinSetting: false,
      userInfoEmail: '',
      showBrlPageSelected: false,
      enable_withdraw_pass: false,
      enable_otp: false,
      showWithdrawSetting: false,
      openBindMfa: false,
      depositing: false,
      brlDepositList: [],
      brlDepositIndex: 0,
      showBindEmail: false,
      w_input_amount: 0,
      minAmount: 30,
      depositAmount: '',
      withdrawalAmount: '',
      searchSymbol: '',
      netWork: {
        platform_name: 'BSC'
      },
      netWork2: {
        platform_name: 'BSC'
      },
      netWorkDetail: 'BNB Smart Chain (BEP20)',
      netWorkAddress: '',
      netWorkAddress2: '',
      netWorks: [
        //     {
        //   name: 'USDT-ETH'
        // },{
        //   name: 'USDT-TRON'
        // },{
        //   name: 'USDT-BSC'
        // }
      ],
      netWorks2: [],
      defaultRate: '25',
      rates: [
        {
          type: '25',
          number: 25
        },
        {
          type: '50',
          number: 50
        },
        {
          type: '100',
          number: 100
        }
      ],
      isSelectSymbol: false,
      isSelectSymbol2: false,
      thisSymbol: {},
      thisSymbol2: {},
      symbols: [],
      symbols2: [],
      type: 'deposit',
      depositStep: 1,
      withdrawStep: 1,
      images: [profileAvatar1, profileAvatar2, profileAvatar3, profileAvatar4, profileAvatar5, profileAvatar6, profileAvatar7, profileAvatar8, profileAvatar9, profileAvatar10, profileAvatar11, profileAvatar12, profileAvatar13, profileAvatar14, profileAvatar15, profileAvatar16],
      localeShow: false,
      myShowMenu: true,
      configs: [],
      configs2: [],
      addAccountNum: 0,
      openSettingWithdrawPin: false
    };
  },
  created() {
    this.walletList.forEach((item) => {
      item.img = usdtImg;
    });
    this.thisSymbol = this.wallet;
    this.thisSymbol2 = this.wallet;
    this.symbols = this.walletList;
    this.symbols2 = this.walletList;
    this.walletDepositInfoService();
  },
  methods: {
    addBlankSuccess() {
      this.openSettingWithdrawPin = false;
      this.showAddAccount = false;
      this.isAddAccount = false;
      this.addAccountNum++;
      this.account_name = '';
      this.person_id = '';
      this.person_name = '';
    },
    async addAccount(params) {
      if (params && params.person_id) {
        this.person_id = params.person_id;
        this.person_name = params.person_name;
      }
      if (params && params.account_name) {
        this.account_name = params.account_name;
      }
      const userId = getCookieUserId();
      if (!this.enable_withdraw_pass) {
        try {
          const response = await userInfoService(userId);
          this.enable_otp = response.data.enable_otp;
          this.enable_withdraw_pass = response.data.has_withdraw_password;
          // 如果两个都没有设置，弹窗去设置绑定otp(MFA)或者设置提现密码
          if (!this.enable_withdraw_pass) {
            // this.openSettingWithdrawPin = true;
            this.showWithdrawSetting = true;
            return;
          }
        } catch (error) {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        }
      }
      this.isAddAccount = true;
      this.showInputPinInfo = true;
    },
    forgotPasswordEvent() {
      this.showInputPinInfo = false;
      this.showForgotPassword = true;
    },
    processForgotPasswordAfter() {
      this.showInputPinInfo = true;
      this.showForgotPassword = false;
    },
    handleWithdrawSettingChoose(type) {
      this.showWithdrawSetting = false;
      if (type == 2) {
        this.openBindMfa = true;
      } else {
        this.openSettingWithdrawPin = true;
      }
    },
    jump(type) {
      //      let url = '/profile/transaction?type='+type
      //      let path = '/profile/transaction'
      //      if (this.$route.path == path) {
      //        this.$router.push(url)
      //        window.location.reload()
      //      } else {
      //        this.$router.push(url)
      //      }
      let symbol = type == 'deposit' ? this.thisSymbol : this.thisSymbol2;
      this.$emit('transactionShow', type, symbol);
    },
    thisSymbolAmountChange() {
      this.brlDepositIndex = -1;
      var regex = /^\d+(\.\d{1,2})?$/;
      if (isNaN(this.thisSymbol.amount)) {
        this.thisSymbol.amount = '';
        return;
      }
      if (regex.test(this.thisSymbol.amount)) {
      } else {
        this.thisSymbol.amount = Number(this.thisSymbol.amount);
        this.thisSymbol.amount = this.thisSymbol.amount.toFixed(2);
      }
    },
    changBrlDepositIndex(index) {
      this.brlDepositIndex = index;
      this.thisSymbol.amount = this.brlDepositList[index];
    },
    reLoadNetWork(configs) {
      let thisSymbol = this.type == 'deposit' ? this.thisSymbol.symbol : this.thisSymbol2.symbol;
      let netWorks = [];
      let platform_name = this.type == 'deposit' ? this.netWork.platform_name : this.netWork2.platform_name;
      let netWork = {};
      for (let i = 0; i < configs.length; i++) {
        if (configs[i].name == thisSymbol) {
          netWorks = configs[i].chains;
          netWork = netWorks[0];
          configs[i].chains.forEach((item) => {
            if (item.platform_name == platform_name) {
              netWork = item;
            }
          });
          break;
        }
      }
      if (this.type == 'deposit') {
        this.configs = configs;
        this.netWork = netWork;
        this.brlDepositIndex = 0;
        this.thisSymbol.amount = this.brlDepositList[this.brlDepositIndex];
        if (!this.thisSymbol.is_fiat) {
          this.netWorks = netWorks;
        } else {
          this.netWorks = [];
        }
      } else {
        this.configs2 = configs;
        this.netWork2 = netWork;
        if (!this.thisSymbol2.is_fiat) {
          this.netWorks2 = netWorks;
        } else {
          this.netWorks2 = [];
        }
        // this.defaultRate
      }
      if (this.thisSymbol.is_fiat) {
        getFiatPreDeposit({ symbol: this.thisSymbol.symbol })
          .then((response) => {
            let brlDepositList = response.data.data ? response.data.data.options : [];
            this.brlDepositList = brlDepositList;
            if (this.brlDepositIndex > -1) {
              this.thisSymbol.amount = this.brlDepositList[this.brlDepositIndex];
            }
            console.log(brlDepositList);
          })
          .catch((error) => {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          });
      }
    },
    walletDepositInfoService() {
      walletDepositInfoService({ type: this.type == 'deposit' ? 1 : 2 })
        .then((response) => {
          let configs = response.data.data;
          this.reLoadNetWork(configs);
          console.log(response.data.data);
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    handleInputPinSuccess(pinPassword) {
      if (this.isAddAccount) {
        this.pinPassword = pinPassword;
        this.showAddAccount = true;
        this.showInputPinInfo = false;
      } else {
        this.walletWithdrawService(pinPassword);
      }
    },
    addPixClose() {
      this.account_name = '';
      this.person_id = '';
      this.person_name = '';
      this.showAddAccount = false;
      this.showInputPinInfo = true;
    },
    withdrawSuccess() {
      fpInitiateCheckout();
      this.$emit('success');
    },
    walletWithdrawService(pinPassword) {
      let userId = getCookieUserId();
      if (userId) {
        userId = Number(userId);
      }
      if (!pinPassword) {
        this.showInputPinInfo = true;
        return;
      }
      this.inputPinConfirming = true;
      let params = {
        user_id: userId,
        amount: this.thisSymbol2.balanceAmount,
        symbol: this.thisSymbol2.symbol,
        address: this.netWorkAddress2,
        platform: this.netWork2.platform
      };
      if (this.enable_otp) {
        params.otp = pinPassword;
      } else {
        params.withdraw_password = pinPassword;
      }
      cryptoWalletWithdrawRequest(params)
        .then(() => {
          this.inputPinConfirming = false;
          toast(this.$t('withdraw_successfully'), { autoClose: 2000, type: 'success' });
          this.$emit('success');
        })
        .catch((error) => {
          this.inputPinConfirming = false;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    // 减法
    subtract(a, b) {
      return new BigNumber(a).minus(b).toString();
    },
    w_thisSymbolChange(w_input_amount) {
      if (this.type == 'deposit') {
        return;
      }
      console.log('--------------------------', w_input_amount);
      let w_amount = 0;

      this.thisSymbol2.balance = new BigNumber(this.thisSymbol2.balance);
      if (w_input_amount >= 0) {
        if (isNaN(w_input_amount)) {
          this.w_input_amount = 0;
        }
        w_input_amount = new BigNumber(w_input_amount);
        if (w_input_amount.isGreaterThan(this.thisSymbol2.balance)) {
          w_input_amount = Number(this.thisSymbol2.balance);
        }
        w_amount = w_input_amount;
        this.defaultRate = 0;
      } else if (w_input_amount < 0) {
        this.w_input_amount = 0;
        w_amount = this.w_input_amount;
      } else {
        w_amount = this.thisSymbol2.balance * (Number(this.defaultRate) / 100);
      }
      w_amount = Number(w_amount);
      // let free = w_amount * (Number(this.netWork2.withdraw_fee)) / 100
      let free = Number(this.netWork2.withdraw_fee);
      let freeStrs = (convertCurrency(free) + '').split('.');
      this.thisSymbol2.freeInt = freeStrs[0];
      this.thisSymbol2.gasFee = free;
      if (freeStrs.length > 1) {
        this.thisSymbol2.freeOther = '.' + freeStrs[1];
      } else {
        this.thisSymbol2.freeOther = '.00000000';
      }
      this.w_input_amount = convertCurrency(w_amount);
      if (this.w_input_amount == 0) {
        this.w_input_amount = '';
      }
      w_amount = convertCurrency(w_amount) + '';

      this.thisSymbol2.balanceAmount = w_amount;
      w_amount = this.subtract(this.thisSymbol2.balanceAmount, this.thisSymbol2.gasFee);
      w_amount = w_amount > 0 ? w_amount : 0;
      w_amount = convertCurrency(w_amount) + '';
      let w_amountStrs = w_amount.split('.');

      this.thisSymbol2.w_amountInt = w_amountStrs[0];
      if (w_amountStrs.length > 1) {
        this.thisSymbol2.w_amountOther = '.' + w_amountStrs[1];
      } else {
        this.thisSymbol2.w_amountOther = '.0000';
      }
    },
    async copy2(text) {
      try {
        toast(this.$t('copy_successfully'), { autoClose: 1000 });
      } catch (e) {
        toast(this.$t('copy_failed'), { autoClose: 1000 });
      }
    },
    handleCloseBindEmailClick() {
      this.showBindEmail = false;
    },
    async typeChange(type) {
      if (this.type == type) {
        return;
      }
      if (type == 'deposit') {
        this.depositStep = 1;
        this.isSelectSymbol2 = false;
        this.brlDepositIndex = 0;
        this.thisSymbol.amount = this.brlDepositList[this.brlDepositIndex];
      }
      if (type == 'withdraw') {
        let email = getCookieEmail();
        this.userInfoEmail = email;
        // 设置邮箱
        if (!email) {
          this.showBindEmail = true;
          return;
        }

        let userId = getCookieUserId();

        if (!this.enable_otp && !this.enable_withdraw_pass) {
          try {
            const response = await userInfoService(userId);
            // 高分数，设置邮箱
            if (!response.data.verify_email) {
              this.showBindEmail = true;
              return;
            }
            this.enable_otp = response.data.enable_otp;
            this.enable_withdraw_pass = response.data.has_withdraw_password;
            // 如果两个都没有设置，弹窗去设置绑定otp(MFA)或者设置提现密码
            if (!this.enable_otp && !this.enable_withdraw_pass) {
              this.showWithdrawSetting = true;
              return;
            }
          } catch (error) {
            const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
            let errorMsg = msg.length > 0 ? msg[0] : error.message;
            if (error.response.status === 500) {
              errorMsg = error.message;
            }
            toast(errorMsg, { autoClose: 2000, type: 'error' });
          }
        }
        this.withdrawStep = 1;
        this.isSelectSymbol = false;
      }
      this.type = type;
      this.walletDepositInfoService();
    },
    handleBindSuccess() {
      this.showBindEmail = false;
    },
    handleBindMfaSuccess() {
      this.enable_otp = true;
    },
    handleSettingPinSuccess() {
      this.enable_withdraw_pass = true;
      this.openSettingWithdrawPin = false;
      this.withdrawStep = 1;
      this.isSelectSymbol = false;
      this.type = 'withdraw';
      this.walletDepositInfoService();
    },
    handleCloseBindMfaClick() {
      this.openBindMfa = false;
      if (this.enable_otp) {
        this.withdrawStep = 1;
        this.isSelectSymbol = false;
        this.type = 'withdraw';
        this.walletDepositInfoService();
      } else {
        this.showWithdrawSetting = true;
      }
    },
    handleCloseWithdrawSettingClick() {
      this.showWithdrawSetting = false;
    },
    handleClosePinSettingClick() {
      this.showWithdrawSetting = true;
      this.openSettingWithdrawPin = false;
    },
    handleCloseInputPinClick() {
      if (this.isAddAccount) {
        this.isAddAccount = false;
      }
      this.showInputPinInfo = false;
    },
    fiatDeposit() {
      if (this.depositing) {
        return;
      }
      if (!this.thisSymbol.amount) {
        toast(this.$t('deposit_amount_is_required'), { autoClose: 2000, type: 'error' });
        return;
      }
      if (this.thisSymbol.amount < Number(this.netWork.min_deposit_amount)) {
        toast(this.$t('the_minimum_recharge') + ' ' + this.netWork.min_deposit_amount + this.thisSymbol.symbol, { autoClose: 2000, type: 'error' });
        return;
      }
      if (this.netWork.max_deposit_amount && Number(this.netWork.max_deposit_amount) > 0 && this.thisSymbol.amount > Number(this.netWork.max_deposit_amount)) {
        toast(this.$t('the_maximum_recharge') + ' ' + this.netWork.max_deposit_amount + this.thisSymbol.symbol, { autoClose: 2000, type: 'error' });
        return;
      }
      this.depositing = true;
      fiat_deposit({ symbol: this.thisSymbol.symbol, amount: Number(this.thisSymbol.amount), pageUrl: this.$route.path })
        .then((response) => {
          this.depositing = false;
          console.log(response.data.data.cashierUrl);

          const appStore = useAppStore();
          const params = {
            kwai_pixel_id: appStore.kwaiPixelId,
            kwai_token: appStore.kwaiToken,
            click_id: appStore.kwaiClickId,
            event_name: 'deposit_now',
            symbol: this.thisSymbol.symbol,
            amount: this.thisSymbol.amount
          }
          kwaiEventService(params);

          window.open(response.data.data.cashierUrl, '_blank');
          this.order_id = response.data.data.order_id;
        })
        .catch((error) => {
          this.depositing = false;
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    nextStep() {
      if (this.type == 'deposit' && this.depositStep == 1) {
        // 在这里，调用新接口，获取地址
        if (!this.netWork.address) {
          const params = {
            symbol: this.thisSymbol.symbol,
            platform: this.netWork.platform
          };
          walletGenerateNewAddressService(params).then((res) => {
            this.netWork.address = res.data.data.address;
            this.depositStepNext();
          });
        } else {
          this.depositStepNext();
        }
      }
      if (this.type == 'withdraw' && this.withdrawStep == 1) {
        if (!this.netWorkAddress2) {
          toast(this.$t('withdraw_address_is_required'), { autoClose: 2000, type: 'error' });
          return;
        }
        let platform_name = this.netWork2.platform_name;
        let flag = walletValid(this.netWorkAddress2, platform_name);
        const that = this;
        if (platform_name && !flag) {
          toast(platform_name + that.$t('wallet_address_is_incorrect'), { autoClose: 2000, type: 'error' });
          return;
        }

        if (that.thisSymbol2.balanceAmount < Number(that.netWork2.min_withdraw_amount)) {
          toast(that.$t('the_minimum_withdrawal') + ' ' + that.netWork2.min_withdraw_amount + that.thisSymbol2.symbol, { autoClose: 2000, type: 'error' });
          return;
        }
        // 計算提現金額
        that.withdrawStepNext();
      }
    },
    confirmStep() {
      this.walletWithdrawService();
    },
    withdrawStepNext() {
      this.withdrawStep = 2;
    },
    depositStepNext() {
      const userId = getCookieUserId();
      fpDepositViewAddressEvent({ userId: userId, symbol: this.thisSymbol.symbol, network: this.netWork.platform_name });
      fpInitiateCheckout();

      const appStore = useAppStore();
      const params = {
        kwai_pixel_id: appStore.kwaiPixelId,
        kwai_token: appStore.kwaiToken,
        click_id: appStore.kwaiClickId,
        event_name: 'deposit_now',
        symbol: this.thisSymbol.symbol,
        amount: this.thisSymbol.amount
      }
      kwaiEventService(params);

      this.depositStep = 2;
    },
    selectSymbol(symbol) {
      if (this.type == 'deposit') {
        this.thisSymbol = symbol;
        this.isSelectSymbol = false;
        this.reLoadNetWork(this.configs);
      } else {
        if (symbol && symbol.symbol) {
          this.thisSymbol2 = symbol;
          this.isSelectSymbol2 = false;
          this.reLoadNetWork(this.configs2);
        }
      }
      if ((symbol && symbol.symbol == 'BRL') || symbol.symbol == 'MXN') {
        this.addAccountNum++;
      }
    },
    showSearchSymbolEvent() {
      if (this.type == 'deposit') {
        this.isSelectSymbol = !this.isSelectSymbol;
      } else {
        this.isSelectSymbol2 = !this.isSelectSymbol2;
      }
      this.searchSymbol = '';
      if (this.isSelectSymbol || this.isSelectSymbol2) {
        this.searchSymbolEvent();
      }
    },
    searchSymbolEvent() {
      let symbols = [];
      if (this.searchSymbol) {
        this.walletList.forEach((item) => {
          if (item.name.toLowerCase().indexOf(this.searchSymbol.trim().toLowerCase()) > -1) {
            symbols.push(item);
          }
        });
      } else {
        symbols = this.walletList;
      }
      if (this.type == 'deposit') {
        this.symbols = symbols;
      } else {
        this.symbols2 = symbols;
      }
      console.log(this.searchSymbol);
    },
    choose(img) {
      this.$emit('choose', img);
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>
<style scoped>
.selected {
  background-color: #1184fa;
  border-radius: 1.25rem;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0em;
  height: 2.5rem;
  line-height: 2.5rem;
}
.unselected {
  font-family: 'Poppins', sans-serif;
  border-radius: 1.25rem;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0em;
  color: var(--color-8ea3c3);
  height: 2.5rem;
  line-height: 2.5rem;
}
.deposit-title {
  display: flex;
  line-height: 2.5rem;
  padding: 0.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  color: var(--deposit-title-color);
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
}
.web .deposit-title {
  margin: 0 1.5rem;
}
.select-div {
  position: fixed;
  width: 100%;
  max-width: 30rem;
}
.phone .select-div {
  position: fixed;
}
.select-div-main {
  width: 65%;
  border-radius: 0.5rem;
  background-color: var(--deposit-withdraw-selected-div-bg-color);
  font-weight: 700;
  color: #000000;
  font-size: 0.875rem;
  line-height: 2.5rem;
  max-height: 25rem;
  overflow-y: auto;
}
.phone .select-div-main {
  width: 75%;
  max-height: 16rem;
}
.option {
  font-family: 'Poppins', sans-serif;
  line-height: 2rem;
  height: 2rem;
  margin: 0.25rem 0;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.875rem;
  border-radius: 0;
}
.option.search_symbol {
  line-height: 4rem;
  height: 4rem;
}
.option.search_symbol .input-div {
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 2px solid #1e2736;
}
.option.search_symbol .tool-bar-search {
  width: 95%;
}
.option.search_symbol .input-div input {
  background-color: #ffffff;
  color: #55657e;

  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0em;
}
.option img {
  width: 1rem;
  padding: 0 2rem 0;
}
.option.selected {
  background-color: var(--color-8ea3c3);
}
.option:hover {
  background-color: var(--color-8ea3c3);
}
.option.search_symbol:hover {
  background-color: #ffffff;
}
.phone .symbol-block {
  width: calc(33% - 0.2rem - 1rem);
}
.web .symbol-block.first {
  margin-left: 0;
}
.web .symbol-block.last {
  margin-right: 0;
}
.symbol-block {
  border: 2px solid var(--deposit-symbol-unselected-block-border-color);
  width: calc(33% - 0.9rem);
  float: left;
  margin: 0px 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  font-weight: 700;
  letter-spacing: 0em;
  color: var(--color-8ea3c3);
}
.symbol-block.symbol-selected {
  border: 2px solid var(--deposit-symbol-block-border-color);
  color: var(--deposit-symbol-block-border-color);
}
.qr_code {
  width: 8.125rem;
}
.web .deposit {
}
.phone .deposit {
  width: calc(100% - 1rem);
}
.phone .deposit.tabs {
  width: calc(100% - 2rem);
}
.phone .deposit.tool-bar-search .tool-bar-search {
  width: 90%;
}
.nft-text {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0px;
  text-align: center;
  color: var(--color-8ea3c3);
}
.warning {
  background-color: var(--deposit-withdraw-block-bg-color);
  border-radius: 0.5rem;
  padding: 0 2.5%;
}
.warning-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  color: #f15a5a;
}
.deposit .input-div .input input {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: #55657e;
  caret-color: #55657e;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--placeholder-color);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--placeholder-color);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--placeholder-color);
}
.input-div .input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}
.input-div .input input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}
.input-div .input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}
.input-div .input input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}
.deposit .input-div .input input::placeholder {
  font-size: 0.875rem; /* 设置所需的字体大小 */
}
::-webkit-input-placeholder {
  font-size: 0.875rem; /* 设置所需的字体大小 */
}

/* Firefox 19+ */
::-moz-placeholder {
  font-size: 0.875rem; /* 设置所需的字体大小 */
}

/* IE 10+ */
:-ms-input-placeholder {
  font-size: 0.875rem; /* 设置所需的字体大小 */
}
.token {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0px;
  color: var(--color-8ea3c3);
  text-align: left;
}

.token-symbol {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  height: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #1e2736;
}
.token-symbol img {
  height: 2rem;
  margin-right: 1rem;
}
.web .deposit .input-zindex1550 {
  display: block;
}
.token-symbol.token-symbol2 {
  font-size: 0.875rem;
  word-break: break-all;
  text-align: left;
  border-bottom: 0;
}
.token-symbol.token-symbol3 {
  word-break: break-all;
  text-align: left;
  color: var(--green-color);
  font-size: 1.125rem;
}

.w_network {
  color: var(--color-8ea3c3);
  font-weight: 700;
  line-height: 2rem;
  cursor: default;
  display: flex;
  width: calc(100% - 3rem);
  margin: 0 1.5rem;
  padding: 0.5rem 0;
  background-color: #0c131d;
  border-radius: 0.5rem;
  min-width: 8rem;
}
.w_network.border {
  border: 2px solid #55657e;
}
.phone .w_network {
  width: 90vw;
  margin: 0 0.5rem;
}
.w_network .symbol-block.symbol-selected {
  background-color: #55657e;
  color: #ffffff;
}
.w_network .symbol-block img {
  margin-right: 0.5rem;
}
.w_network .w_network .symbol-block {
  line-height: 3rem;
}
.w_network .symbol-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: none;
  background-color: #2c394e;
  color: var(--color-8ea3c3);
}
.deposit-main {
  color: #ffffff;
}
.phone .deposit-main-out {
  margin-bottom: 8rem;
}
.web .deposit-main-out {
  min-width: 30rem;
}
.web .net_work {
  width: calc(100% - 3rem);
  margin: 0 1.4rem;
}
.net_work {
  color: var(--color-8ea3c3);
  font-weight: 700;
  height: 2rem;
  line-height: 2rem;
  cursor: default;
  display: block;
}
.free {
  font-family: 'Poppins', sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: right;
}
.my-dialog .dialog-main {
  width: auto;
  padding: 0.3rem 0 0 0;
}
.the_minimum_recharge .the_minimum_recharge {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.the_minimum_recharge .the_minimum_recharge span {
  line-height: 1.3rem;
}
.phone .the_minimum_recharge .the_minimum_recharge span {
  line-height: 1rem;
  font-weight: normal;
  font-size: 0.8rem;
}
.web .btn {
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--bg-blue-color);
  margin-left: 0;
}
.phone .btn {
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--bg-blue-color);
  margin-left: 0;
}
.deposit_withdraw_img {
  width: 0.875rem;
}
.transaction {
  width: 25%;
  display: flex;
  justify-content: center;
  background-color: var(--deposit-withdraw-transaction-bg-color);
  border-radius: 0.4rem;
  text-align: center;
  margin-left: 2%;
  padding: 0 0.3rem;
}
.input-selected {
  width: 70%;
  display: flex;
  justify-content: left;
  border: 1px solid var(--deposit-withdraw-select-border-color);
  border-radius: 0.4rem;
  background-color: var(--input-default-bg-color);
  padding-left: 0.3rem;
  text-align: left;
  align-items: center;
}
.input-selected-out {
  font-weight: 700;
  color: var(--deposit-withdraw-select-color);
  font-size: 0.875rem;
  line-height: 2.5rem;
}
.platform-top {
  width: calc(100%);
  line-height: 3.625rem;
  height: 3.625rem;
  border-radius: 0.5rem;
  background-color: #2c394e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone .brl-deposit {
  width: calc(100%);
}
.brl-deposit {
  width: calc(100% - 42px);
  display: inline-grid;
  grid-template-columns: calc(25% - 0.4375rem) calc(25% - 0.4375rem) calc(25% - 0.4375rem) calc(25% - 0.4375rem);
  gap: 0.5rem;
}
.brl-deposit-symbol {
  padding: 17px 0;
  float: left;
  display: flex;
  justify-content: center;
  background: var(--brl-deposit-symbol-bg-color);
  margin: 0.25rem;
  border-radius: 0.5rem;
  font-size: 0.9375rem;
  font-weight: 700;
}
.brl-deposit-symbol.brl-selected {
  margin: 0;
  border: 0.25rem solid var(--bg-blue-color);
}
.brl-deposit-symbol-icon {
  width: 1.125rem;
  padding-left: 0.3rem;
  height: 1.125rem;
}
.brl-deposit-symbol-amount {
  display: flex;
  width: calc(100% - 48px - 1.5rem);
  align-items: center;
  padding: 0.625rem;
  background-color: var(--deposit-withdraw-block-bg-color);
  border-radius: 0.5rem;
  margin-left: 1.5rem;
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
}
.phone .brl-deposit-symbol-amount {
  width: calc(100% - 2.5rem);
  margin-left: 0;
}
.brl-deposit-symbol-amount input {
  margin-left: 1.125rem;
  height: 2rem;
  line-height: 2rem;
  background-color: var(--deposit-withdraw-block-bg-color);
  color: var(--white-color);
  font-weight: 700;
  font-size: 1.125rem;
  border: none;
}
.brl-deposit-symbol-icon2 {
  width: 2rem;
  padding-right: 0.5rem;
  height: 2rem;
}
.brl-page-input {
  display: flex;
  color: var(--color-8ea3c3);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 9px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #55657e;
  background: #000;
  cursor: pointer;
}

.deposit.select-div2 {
  position: fixed;
  margin: 0;
  z-index: 10;
  margin-left: 1.5rem;
  padding: 16px 0px;
  background-color: var(--menu-bg-color);
  border-radius: 0;
}
.select-div-main2 {
  width: auto;
  font-weight: 400;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  line-height: 2.5rem;
  text-align: left;
}
.select-div-main2 div {
  padding: 0 1.125rem;
  border-left: 4px solid var(--menu-bg-color);
}
.select-div-main2 div.brl-page-selected {
  color: var(--white-color);
  font-weight: 400;
  border-left: 4px solid var(--help_content_left_selected_color);
}
.select-div-main2 div:hover {
  color: var(--white-color);
  font-weight: 400;
  border-left: 4px solid var(--help_content_left_selected_color);
}
</style>
