import api from '../api';
import httpManager from '../httpManager';

// 获取游戏url
export const getBalanceTotal = (param) => {
  return httpManager({
    url: api.getBalanceTotal,
    method: 'Get',
    data: param
  });
};