import { ref, toValue, watchEffect } from 'vue';
import { useWindowSize } from './useWindowSize';

export function useScreenBreakpoint(breakPoint, isDown = true) {
  const match = ref(false);

  const { width } = useWindowSize();

  watchEffect(() => {
    if ((isDown && width.value <= toValue(breakPoint)) || (!isDown && width.value > toValue(breakPoint))) {
      match.value = true;
    } else {
      match.value = false;
    }
  });

  return match;
}
