<template>
  <div
    class="root_div"
    @click="
      providerShow = false;
      providerShow2 = false;
    "
  >
    <menus></menus>
    <div style="width: 100%" class="right_main slots" id="right_main">
      <my-header :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></my-header>

      <div class="main-content" style="background-color: var(--menu-item-bg-color)">
        <div class="body slots_title">
          <div class="left">
            <img src="../../assets/images/home/btn_qh_1_2.svg" />
            {{ t('slots') }}
          </div>
          <div class="right">
            <img src="../../assets/images/home/top_ico_slots_1.png" />
          </div>
        </div>
      </div>

      <my-search-game />

      <div
        v-if="providerShow || providerShow2"
        @click.stop="
          providerShow = false;
          providerShow2 = false;
        "
        class="search-overlay svelte-o7w8d3 background-none"
        style=""
      ></div>
      <div class="main-content" style="margin-top: 0">
        <div :class="'body slots_sort height-2_5rem' + (providerShow || providerShow2 ? ' showing' : '')">
          <div class="left">
            <div class="left">
              <div class="web">
                <img src="../../assets/images/home/sort.svg" />
                <span class="left-sort">{{ t('sort_by') }}</span>
              </div>
              <div class="game_providers" @click.stop="providerShow = !providerShow">
                {{ t('game_providers') }}
                <img src="../../assets/images/home/Frame 33764.svg" style="width: 0.5rem; height: 0.5rem; padding: 0 0 0 0.5rem" />
              </div>
            </div>

            <div class="providers_sort" v-show="providerShow" @click.stop="">
              <div v-for="(provider, index) in providerList" class="item" v-show="provider.name != 'PragmaticPlay LIVE'" @mouseover="provider.hover = true" @mouseleave="provider.hover = false" :key="index" @click="changeProviderChecked(provider)">
                <div v-if="!provider.isChecked" class="check-box" style=""></div>
                <img v-if="provider.isChecked" style="padding: 0; width: 1.425rem; height: 1.425rem" src="../../assets/images/home/Sign.svg" />

                <div :style="'padding:0 0.4rem;' + (provider.hover ? 'color:#FFFFFF;' : '')">{{ provider.name }}</div>
                <div class="number">{{ provider.number || 0 }}</div>
                <div style="clear: both"></div>
              </div>
            </div>
          </div>

          <div class="right">
            <div class="right">
              <div class="web">
                <img src="../../assets/images/home/sort.svg" />
                <span class="left-sort">{{ t('sort_by') }}</span>
              </div>
              <div class="game_providers" @click.stop="providerShow2 = !providerShow2">
                {{ t('popular') }}
                <img src="../../assets/images/home/Frame 33764.svg" style="width: 0.5rem; height: 0.5rem !important; padding: 0 0 0 0.5rem" />
              </div>
            </div>
            <div class="providers_sort2" v-show="providerShow2" @click.stop="">
              <div v-for="(slots, index) in slotsList" class="item" :key="index" @click="changeSlotsChecked(slots)" @mouseover="slots.hover = true" @mouseleave="slots.hover = false">
                <img style="padding: 0; width: 1.3rem; height: 1.3rem" :src="slots.img" v-show="!(slots.isChecked || slots.hover)" />
                <img style="padding: 0; width: 1.3rem; height: 1.3rem" :src="slots.selectedImg" v-show="slots.isChecked || slots.hover" />
                <div :style="slots.isChecked || slots.hover ? 'color:#FFFFFF;padding:0 0.4rem;' : 'padding:0 0.4rem;'">{{ t(slots.code) }}</div>
                <div style="clear: both"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <my-search-game-list :game-list="searchResult" @load-finish="loadFinish" :keyChange="keyChange"></my-search-game-list>

      <div class="main-content" v-show="total == 0">
        <div class="body">
          <div style="width: 100%; height: 12rem; justify-content: center; align-items: center">
            <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; margin-top: 5rem">
              <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
            </div>
            <div class="no-data">{{ t('no_data') }}</div>
          </div>
        </div>
      </div>

      <div class="main-content" v-show="total > 0">
        <div class="body">
          <div style="display: flex; justify-content: center; width: 100%; margin: 0.5rem 0" v-show="loadFinishFlag">
            <div style="background-color: #3a3e53; width: 12rem; border-radius: 0.5rem; height: 0.3rem">
              <div :style="'background-color: #43A0FF;width: ' + (total == 0 ? 12 : (searchResult.length / total) * 12) + 'rem;border-radius: 0.5rem;height: 0.3rem;'"></div>
            </div>
          </div>
          <div class="show-game-text" v-show="loadFinishFlag">{{ t('displaying') }}{{ searchResult.length }}/{{ total }}{{ t('games') }}</div>

          <div v-show="total > searchResult.length && loadFinishFlag" style="display: flex; justify-content: center; width: 100%; margin: 0.5rem 0">
            <div @click="next()" class="load-more">
              {{ t('load_more') }}
            </div>
          </div>
          <page-loading :animationData="jiazai" :container-height="'110px'" :show="!loadFinishFlag"></page-loading>
        </div>
      </div>
      <my-game-support></my-game-support>
      <bets-list @parentLogin="parentLogin"></bets-list>
      <div class="main-content">
        <my-testimonials-list :gameList="testimonialsList" :game="testimonials"></my-testimonials-list>
        <!--        <my-game-list :gameList="testimonialsList" :game="testimonials"></my-game-list>-->
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
</template>
<script setup>
import jiazai from '@/assets/animation/expott_jiazai.json';
import pageLoading from '@/components/loading/pageLoading.vue';
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import myGameSupport from '@/components/game/game_support.vue';
import betsList from '@/components/profit/betsList.vue';
import mySearchGameList from '@/components/game/searchGameList.vue';
import mySearchGame from '@/components/game/searchGame.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
// import mySearchGameList from "@/components/game/searchGameList.vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
<script>
import { getCookiesByKey, setCookiesByKey } from '@/assets/tools/CommonTool';
import icon from '@/assets/images/footer/footer-token-1.png';
import gameSupportImg from '@/assets/images/home/Frame 427320293.svg';
import testimonialsImg1 from '@/assets/images/home/money 2.png';
import testimonialsLevelImg from '@/assets/images/home/ico_b1.png';
import testimonialsImg2 from '@/assets/images/home/money 3.png';
import testimonialsImg3 from '@/assets/images/home/money 4.png';
import testimonialsImg4 from '@/assets/images/home/money 1.png';
import { openboxSearchGames } from '@/assets/network/service/game';
import { getGameProviders } from '@/assets/network/service/home';
// import {ref} from "vue";
import popularSlotsImg1 from '@/assets/images/home/btn_5.svg';
import popularSlotsImg2 from '@/assets/images/home/btn_5_2.svg';
import newSlotsImg1 from '@/assets/images/home/btn_6.svg';
import newSlotsImg2 from '@/assets/images/home/btn_6_2.svg';
import hotSlotsImg1 from '@/assets/images/home/btn_7.svg';
import hotSlotsImg2 from '@/assets/images/home/btn_7_2.svg';

export default {
  name: 'App',
  props: {
    game: {},
    gameList: []
  },
  watch: {
    providerList: function (newVal) {
      if (this.provider_counts.length == 0) {
        for (let i_i in newVal) {
          newVal[i_i].number = 0;
        }
        return;
      }
      for (let i in newVal) {
        for (let i_i in this.provider_counts) {
          if (newVal[i].provider_counts == this.provider_counts[i_i].name) {
            newVal[i].number = this.provider_counts[i_i].count;
            break;
          }
        }
      }
    },
    provider_counts: function (newVal) {
      if (this.providerList.length == 0) {
        return;
      }
      for (let i_i in this.providerList) {
        this.providerList[i_i].number = 0;
      }
      for (let i in newVal) {
        for (let i_i in this.providerList) {
          console.log(newVal[i].game_provider, this.providerList[i_i].name);
          if (newVal[i].game_provider == this.providerList[i_i].name) {
            this.providerList[i_i].number = newVal[i].count;
            break;
          }
        }
      }
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      keyChange: 0,
      loadFinishFlag: true,
      showLogin: false,
      slotsList: [
        {
          isChecked: false,
          name: 'popular',
          selectedImg: popularSlotsImg1,
          img: popularSlotsImg2,
          path: '/slots/popularSlots',
          code: 'popular_slots'
        },
        {
          isChecked: false,
          name: 'new',
          selectedImg: newSlotsImg1,
          img: newSlotsImg2,
          path: '/slots/newSlots',
          code: 'new_slots'
        },
        {
          isChecked: false,
          name: 'hot',
          selectedImg: hotSlotsImg1,
          img: hotSlotsImg2,
          path: '/slots/hotSlots',
          code: 'hot_slots'
        }
      ],
      provider_counts: [],
      providerShow: false,
      providerShow2: false,
      providerSearchList: [
        {
          isChecked: false,
          name: 'Pragmatic Play',
          code: 'PragmaticPlay',
          number: 0
        },
        {
          isChecked: false,
          name: 'PG Soft',
          code: 'PGSoft',
          number: 0
        }
      ],
      isGameSearch: false,
      searchResult: [],
      searchResult2: [],
      testimonialsList: [
        {
          image: testimonialsImg1,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg2,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg3,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg4,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        }
      ],
      providerList: [],
      gameSupport: {
        gameCode: 'game_support',
        gameImage: gameSupportImg,
        showBottom: false
      },
      testimonials: {
        gameCode: 'testimonials',
        showBottom: true
      },
      profitList: [
        {
          rank: 1,
          time: '2024-01-18',
          player: {
            img: icon,
            playId: 'Playerr123a231',
            email: 'Play****@gmail.com'
          },
          game: {
            name: 'Dingdong',
            img: icon
          },
          symbol: 'UDST',
          profitImg: icon,
          multiplier: 7.02,
          betAmount: 100.0,
          amount: 702.0
        }
      ],
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 0,
      indexProfit: 'me',
      searchText: '',
      searchParams: {
        game_type: '',
        name: '',
        start: 0,
        end: 20,
        page_name: '',
        game_provider: []
      },
      pageIndex: 0,
      total: 0,
      walletList: []
    };
  },
  created() {
    this.searchResult = [];
    setTimeout(this.getSlotsListService, 50);
    this.getGameProviders();
  },
  methods: {
    loginAfter(walletList) {
      this.walletList = walletList;
    },
    loadFinish() {
      this.loadFinishFlag = true;
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    parentLogin() {
      this.showLogin = true;
      this.$emit('parentLogin');
    },
    changeSlotsChecked(slots) {
      let page_name = '';
      for (let i in this.slotsList) {
        if (slots.code == this.slotsList[i].code) {
          this.slotsList[i].isChecked = !this.slotsList[i].isChecked;
          if (this.slotsList[i].isChecked) {
            page_name = this.slotsList[i].name;
          }
        } else {
          this.slotsList[i].isChecked = false;
        }
      }
      this.searchParams.page_name = page_name;
      this.pageIndex = 0;
      this.getSlotsListService();
    },
    changeProviderChecked(provider) {
      provider.isChecked = !provider.isChecked;
      let providers = [];
      for (let i in this.providerList) {
        if (this.providerList[i].isChecked) {
          providers.push(this.providerList[i].name);
        }
      }
      this.searchParams.game_provider = providers;
      window.sessionStorage.setItem('sortBy', JSON.stringify(providers));
      this.pageIndex = 0;
      this.getSlotsListService();
    },
    getGameProviders() {
      getGameProviders()
        .then((response) => {
          this.providerList = response.data.data;
          // 读取是否有勾选
          const sortBy = JSON.parse(window.sessionStorage.getItem('sortBy'));
          this.providerList.map((item) => {
            if (sortBy.includes(item.name)) {
              item.isChecked = true;
            }
            return item;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    enterSearch() {
      // console.log(this.searchText)
      // if(!this.searchText && this.searchText.trim().length <=3){
      //   this.searchText = this.searchText.trim()
      //   return
      // }
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.pageIndex = 0;
      this.getSlotsListService();
    },
    next() {
      this.pageIndex++;
      this.getSlotsListService();
    },
    getSlotsListService() {
      // 先获取在设置为空，是为了只在第一次加载数据的时候获取cookies中记录的页面大小
      let pageIndex = getCookiesByKey('new_games_page_size');
      setCookiesByKey('new_games_page_size', '');
      if (pageIndex) {
        this.pageIndex = pageIndex;
      }
      if (pageIndex) {
        this.searchParams.start = 0;
        this.searchParams.end = this.pageIndex * 20 + 20;
      } else {
        this.searchParams.start = this.pageIndex * 20;
        this.searchParams.end = this.searchParams.start + 20;
      }
      //读取是否有勾选
      const sortBy = JSON.parse(window.sessionStorage.getItem('sortBy'));
      if (sortBy && sortBy.length > 0) {
        this.searchParams.game_provider = sortBy;
      }
      this.loadFinishFlag = false;
      setCookiesByKey('games_page_size', this.pageIndex);
      openboxSearchGames(this.searchParams)
        .then((response) => {
          if (response.data.data.length == 0) {
            this.loadFinishFlag = true;
          }
          if (this.pageIndex != 0) {
            this.searchResult = this.searchResult.concat(response.data.data);
          } else {
            this.searchResult = response.data.data;
          }
          const that = this;
          setTimeout(function () {
            that.keyChange++;
          }, 200);
          this.provider_counts = response.data.provider_counts;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          this.loadFinishFlag = true;
          console.log(error);
        });
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    }
  }
};
</script>
<style scoped>
.slots_sort.showing {
  position: relative;
  z-index: var(--showing-z-index);
}
</style>
