<script setup>
import { useI18n } from 'vue-i18n';
import { defineEmits, defineProps, computed } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { toast } from 'vue3-toastify';
import { useClipboard } from '@vueuse/core';
import { useAppStore } from '@/store/store';

const { copy } = useClipboard({ legacy: true });
const { t } = useI18n();
const props = defineProps({
    show: {
        type: Boolean,
        default: false
    }
});
const appStore = useAppStore();

const emit = defineEmits(['close'])

// const link = ref('https://' + window.location.hostname + '/?referral_code=' + appStore.userInfo.invite_code)
const link = computed(() => {
    return 'https://' + window.location.hostname + '/?referral_code=' + appStore.userInfo.invite_code
})

</script>

<template>
    <wg-dialog :show="props.show"  myClass="!z-[9999]">
        <div :class="'content !bg-[#121829] box-border px-[20rem] ' + (matchSM ? 'matchSM' : '')">
            <div class="header">
                <div class="header-title text-[#fff]">{{ t('scheduleInfo_019') }}</div>


                <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                            d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z"
                            fill="var(--icon-bg-color)" />
                        <path
                            d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z"
                            fill="var(--icon-bg-color)" />
                    </svg>
                </button>
            </div>
            <div class="text-[1rem] text-[#fff] text-left w-full">{{ t('scheduleInfo_020') }}</div>
            <div class="bg-[#000] rounded-md flex justify-between w-full items-center p-[1rem]"
                style="margin-top: 0.525rem">
                <div class=" text-[#fff]">
                    {{ link }}
                </div>
                <div class="" @click="() => {
                    copy(link);
                    toast(t('copy_successfully'), { autoClose: 1000 });
                }
                    ">
                    <img src="../../../assets/images/wallet/wallet-copy.svg" style="width: 1.25rem" />
                </div>
            </div>
        </div>
    </wg-dialog>
</template>

<style scoped>
.content {
    border-radius: 0.5rem;
    width: 27.5rem;
    max-height: calc(100% - 5.75rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    padding: 1.125rem 1.1875rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.content.matchSM {
    width: calc(100vw - 3.5rem);
    padding: 1.125rem 0.875rem;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.75rem;
}

.header-title {
    font-size: 1.125rem;
    font-weight: 700;
    text-align: left;
}

.header-close-button {
    background-color: transparent;
    padding: 1.125rem 1.1875rem;
    margin-right: -1.1875rem;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-close-button.matchSM {
    padding: 1.125rem 0.875rem;
    margin-right: -0.875rem;
}

.header-close-button:hover {
    filter: brightness(0) invert(1);
}
</style>
