<script setup>
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import noLoginReferral from './index2.vue';
import myFooter from '@/components/footer/footer.vue';
import { useI18n } from 'vue-i18n';
import { useClipboard } from '@vueuse/core';
import { ref } from 'vue';

const { t } = useI18n();
const { copy } = useClipboard({ legacy: true });
const isSelect = ref(false);

const changeSelectEvent = () => {
  isSelect.value = !isSelect.value;
};
const tabs = ref([{ code: 'accumulated_profits' }, { code: 'sub_agents' }, { code: 'referral_data' }]);
const selectTab = ref({ code: 'accumulated_profits' });
</script>

<script>
import ttPokerIcon from '../../assets/images/referral/TTpoker-1.webp';
import ttPokerIcon2 from '../../assets/images/referral/referral-people.svg';
import goldIcon from '../../assets/images/referral/referral-gold.png';
import sliverIcon from '../../assets/images/referral/referral-sliver.png';
import bronzeIcon from '../../assets/images/referral/referral-bronze.png';
import token1 from '../../assets/images/footer/footer-token-1.png';
import { getCookieAccessToken, getCookieEmail, getCookieUserId, convertCurrency } from '@/assets/tools/CommonTool';
import { userInfoService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
import { getReferralGetReferralInfoService } from '@/assets/network/service/referral';
import { gaShareEvent } from '@/assets/tools/GoogleAnalyticsEvent';
export default {
  name: 'App',
  components: {},
  watch: {
    isLogin: function (newVal) {
      const that = this;
      if (newVal) {
        that.$nextTick(() => {
          if (that.$refs['body-main']) {
            let mainWidth = that.$refs['body-main'].offsetWidth;
            let tdWidth = mainWidth / 4;
            that.tdWidth = tdWidth > 5.06 * 16 ? tdWidth : 5.06 * 16;
          }
        });
      }
    }
  },
  data() {
    return {
      tokenConfig: {
        USDT: token1
      },
      symbolAmountList: [],
      isLogin: false,
      rankList: [],
      activeSubAgents: [
        {
          total: 0,
          code: 'total_number___agents',
          key: 'subordinate_cnt'
        },
        {
          total: 0,
          code: 'active_user_yesterday',
          key: 'yesterday_active'
        }
      ],
      sub_agents: [
        {
          // 下级人数
          total: 0,
          code: 'number_of_subordinates',
          key: 'subordinate_cnt'
        },
        {
          // 下级充值金额总计
          total: 0,
          code: 'total_recharge_amount_of_subordinates',
          key: 'sub_recharge_amount'
        },
        {
          // 下级投注金额总计
          total: 0,
          code: 'total_betting_amount_of_subordinates',
          key: 'sub_total_balance_minigame'
        },
        {
          // 下级返奖金额总计
          total: 0,
          code: 'total_prize_money_returned_by_subordinates',
          key: 'sub_total_betresult_minigame'
        }
      ],
      activeSubAgents2: [
        {
          total: 518,
          code: 'yesterday',
          key: 'yesterday_active'
        },
        {
          total: 8888,
          code: 'seven_day_average',
          key: 'last_7_day_active_avg'
        }
      ],
      someExamples: [
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'gold',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: goldIcon
        },
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'silver',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: sliverIcon
        },
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'bronze',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: bronzeIcon
        }
      ],
      gold: {
        min: 2000,
        max: 8500,
        rate: 50
      },
      sliver: {
        min: 500,
        max: 1999,
        rate: 40
      },
      bronze: {
        min: 50,
        max: 500,
        rate: 30
      },
      chooseImgFlag: false,
      userNameEdit: false,
      userInfo: {},
      tdWidth: 0,
      bets_selected: 'yesterday_commission',
      changesList: ['yesterday_commission', 'last_7_day_commission', 'last_30_day_commission'],
      refData: {
        total_commission: [],
        yesterday_commission: [],
        last_7_day_commission: [],
        last_30_day_commission: [],
        subordinate_cnt: 0,
        yesterday_active: 0,
        last_7_day_active_avg: 0,
        last_30_day_active_avg: 0
      }
    };
  },
  mounted() {
    if (this.$refs['body-main']) {
      let mainWidth = this.$refs['body-main'].offsetWidth;
      let tdWidth = mainWidth / 4;
      this.tdWidth = tdWidth > 5.06 * 16 ? tdWidth : 5.06 * 16;
    }
  },
  created() {
    let menuStr = localStorage.getItem('showMenu');
    if (menuStr) {
      this.showMenu = menuStr === 'true';
    } else {
      this.showMenu = true;
    }
    let token = getCookieAccessToken();
    if (token) {
      this.isLogin = true;
    }
    if (this.isLogin) {
      this.getBets();
      this.userInfoService();
    } else {
      this.getRankList();
    }
  },
  methods: {
    async copy2(text) {
      const { toClipboard } = useClipboard();
      await toClipboard(text);
      try {
        toast(this.$t('copy_successfully'), { autoClose: 1000 });
      } catch (e) {
        toast(this.$t('copy_failed'), { autoClose: 1000 });
      }
    },
    userInfoService() {
      let userId = getCookieUserId();
      userInfoService(userId)
        .then((response) => {
          this.userInfo = response.data;
          this.userInfo.email = getCookieEmail();
          this.name = this.userInfo.name;
          if (this.userInfo.referral_code) {
            this.isBind = true;
          }
          this.userInfo.link = 'https://' + window.location.hostname + '/?referral_code=' + this.userInfo.invite_code;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    getRankList() {},
    getBets() {
      getReferralGetReferralInfoService().then((response) => {
        let refData = response.data.data;
        for (let i in this.changesList) {
          let amountObjs = refData[this.changesList[i]];
          for (let j in amountObjs) {
            let amountObj = amountObjs[j];
            let amountStr = convertCurrency(amountObj.sum_amount) + '';
            amountStr = amountStr || '0.0000';
            let amountArrStr = amountStr.split('.');
            amountObj.amountInt = amountArrStr[0];
            amountObj.amountOther = amountArrStr[1] || '0000';
          }
        }
        this.refData = refData;
      });
    },
    phoneBetsChange(bets_selected) {
      this.bets_selected = bets_selected;
    },
    editHeadUrl() {
      this.chooseImgFlag = true;
    },
    privacySetting() {},
    chooseImgEvent(img) {
      if (img) {
        this.userInfo.headUrl = img;
      }
      this.chooseImgFlag = false;
    },
    loginAfter() {
      this.isLogin = true;
      this.getBets();
      this.userInfoService();
    }
  }
};
</script>
<template>
  <div class="root_div">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header @loginAfter="loginAfter" :showLogin="showLogin" @hideShowLogin="hideShowLogin"></my-header>
      <no-login-referral v-if="!isLogin"></no-login-referral>
      <div class="main-content" v-if="isLogin">
        <div class="body width-100">
          <div style="text-align: left">
            <div class="web-top md:pt-[1rem] md:pb-[1rem]" style="display: flex; line-height: 2.5rem; height: 2.5rem">
              <div class="transaction-title">
                <img src="../../assets/images/home/btn_4_2.svg" style="height: 1.25rem" />
                <span style="margin-left: 0.5rem">{{ t('referral') }}</span>
              </div>
            </div>
            <div class="phone deposit" style="color: var(--color-8ea3c3); font-weight: 700; color: var(--white-color); font-size: 0.875rem; line-height: 2.5rem">
              <div class="deposit select-input" @click="changeSelectEvent()">
                <img src="../../assets/images/wallet/wallet-withdraw-arrow-down.svg" style="width: 0.875rem; position: relative; right: -90%" />
                {{ $t(selectTab.code) }}
              </div>
            </div>
            <div class="deposit select-div" v-if="isSelect">
              <div class="select-div-main">
                <div
                  :class="'option ' + (tabs.code == selectTab.code ? 'selected' : '')"
                  v-for="(tab, index) in tabs"
                  :key="index"
                  @click="
                    selectTab = tab;
                    isSelect = false;
                  "
                >
                  {{ $t(tab.code) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content top-rem0" v-if="isLogin">
        <div class="body width-100 no-padding">
          <div style="width: 100%; display: flex; gap: 20px">
            <div class="left web">
              <div @click="selectTab = tab" v-for="(tab, index) in tabs" :key="index" :class="'item' + (selectTab.code == tab.code ? ' selected' : '')">
                {{ t(tab.code) }}
              </div>
            </div>
            <div class="right">
              <div class="main-content" v-if="isLogin && selectTab.code == 'accumulated_profits'">
                <div class="body width-100 profit referral_top bottom-2rem new_referral_level2 pb-[10px]">
                  <div class="top top2">
                    <div class="top-title">
                      <span>{{ t('accumulated_profits') }}</span>
                    </div>
                    <div class="referral_level2 mb-[0.7rem]">
                      <div style="width: 100%" class="symbol_block">
                        <div class="symbol_amount" v-show="symbolAmount.symbol != 'Gold'" v-for="(symbolAmount, index) in refData.total_commission" :key="index">
                          <div style="" class="name">
                            <img :src="tokenConfig[symbolAmount.symbol]" style="" v-if="symbolAmount.symbol == 'USDT'" />
                            <div style="display: block; line-height: 1rem">
                              <div class="text-[12px] text-[#AAC4ED] font-[700]">{{ symbolAmount.symbol }}</div>
                              <div class="text-[18px] text-[#ffffff] font-[400]">{{ symbolAmount.sum_amount }}</div>
                            </div>
                            <div v-if="index > 0 && index < refData.total_commission.length - 1" style="position: absolute; height: 2.25rem; width: 0; border-left: 1px solid var(--menu-item-line-color); margin-left: calc(45% - 1rem)"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="top top2 flex flex-col gap-[10px]">
                    <div :class="'new_referral_level2 referral_level2 active-sub-agents'" v-for="(activeSubAgent, index) in activeSubAgents" :key="index">
                      <div class="active-sub-agents-item">
                        <div style="display: flex; line-height: 3rem; width: 60%; align-items: center">
                          <div style="" class="text-[12px] text-[#AAC4ED] font-[700]">{{ t(activeSubAgent.code) }}</div>
                        </div>
                        <div :class="'right' + (index == 0 ? ' first' : '')" style="display: flex; line-height: 3rem; width: 40%; text-align: right; align-items: center; justify-content: right">
                          <div>
                            <span class="text-[14px] text-[#ffffff] font-[400]">{{ refData ? refData[activeSubAgent.key] : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </div>
              </div>

              <div class="main-content" v-if="isLogin && selectTab.code == 'accumulated_profits'">
                <div class="body width-100 profit referral_top bottom-2rem">
                  <div class="top top2">
                    <div class="top-title">
                      <span>{{ t('active_sub_agents') }}</span>
                    </div>
                    <div class="new_referral_level2 referral_level2 active-sub-agents !mb-[10px]" v-for="(activeSubAgent, index) in activeSubAgents2" :key="index">
                      <div class="active-sub-agents-item">
                        <div style="display: block; width: 70%; line-height: 1.5rem">
                          <div class="name text-[12px] text-[#AAC4ED] font-[700]">{{ t(activeSubAgent.code) }}</div>
                        </div>
                        <div :class="'right' + (index == 0 ? ' first' : '')" style="display: block; width: 30%; text-align: right; line-height: 1.5rem">
                          <div style="display: flex; align-items: center; justify-content: right">
                            <span class="gold text-[14px] text-[#ffffff] font-[400]" style="color: var(--white-color); margin-right: 0.5rem">{{ refData ? refData[activeSubAgent.key] : '' }}</span>
                            <img :src="ttPokerIcon2" style="width: 1.5rem" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both"></div>

                    <div class="referral_level display-block">
                      <div class="top-title web text-[12px] text-[#AAC4ED] font-[700] !pb-0">
                        <span style="margin-left: 0.5rem; text-transform: none">{{ t('your_code') }}</span>
                      </div>
                      <div class="top-title phone text-[12px] text-[#AAC4ED] font-[700]" style="padding-left: 0">
                        <span style="margin-left: 0.5rem; text-transform: none">{{ t('your_code') }}</span>
                      </div>
                      <div class="" style="margin-top: 0.525rem">
                        <div :class="'input-div '" style="width: 100%; margin: 0; height: 44px">
                          <div class="input">
                            <input type="input" v-model="userInfo.invite_code" readonly style="font-size: 14px; font-weight: 400; text-align: left; color: var(--white-color); height: 42px; background-color: transparent" />
                          </div>
                        </div>
                        <div
                          class="copy md:!mt-[-46px] h-[44px]"
                          @click="
                            () => {
                              copy(userInfo.invite_code);
                              gaShareEvent({ method: 'Copy Code' });
                              toast(t('copy_successfully'), { autoClose: 1000 });
                            }
                          "
                        >
                          <img src="../../assets/images/wallet/wallet-copy.svg" style="width: 1.25rem" />
                        </div>
                      </div>
                      <div class="top-title web text-[12px] text-[#AAC4ED] font-[700] !pb-0">
                        <span style="margin-left: 0.5rem; text-transform: none">{{ t('your_referral_link') }}</span>
                      </div>
                      <div class="top-title phone text-[12px] text-[#AAC4ED] font-[700]" style="padding-left: 0">
                        <span style="margin-left: 0.5rem; text-transform: none">{{ t('your_referral_link') }}</span>
                      </div>
                      <div class="" style="margin-top: 0.525rem">
                        <div :class="'input-div '" style="width: 100%; margin: 0; height: 44px">
                          <div class="input referral">
                            <input type="input" v-model="userInfo.link" readonly style="font-size: 14px; font-weight: 400; text-align: left; color: var(--white-color); height: 42px; background-color: transparent" />
                          </div>
                        </div>
                        <div
                          class="copy md:!mt-[-46px] h-[44px]"
                          @click="
                            () => {
                              copy(userInfo.link);
                              gaShareEvent({ method: 'Copy Link' });
                              toast(t('copy_successfully'), { autoClose: 1000 });
                            }
                          "
                        >
                          <img src="../../assets/images/wallet/wallet-copy.svg" style="width: 1.25rem" />
                        </div>
                      </div>
                      <div class="top-title top-1rem web !pb-[1rem]">
                        <span style="text-transform: none; margin-left: 0.5rem; text-align: left; color: var(--color-8ea3c3); font-size: 0.875rem">{{ t('copy_your___rebate') }}</span>
                      </div>
                      <div class="top-title phone !pt-0 !pb-[10px] w-full">
                        <span style="text-transform: none; text-align: left">{{ t('copy_your___rebate') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="main-content" v-if="isLogin && selectTab.code == 'sub_agents'">
                <div class="body width-100 profit referral_top bottom-2rem new_referral_level2">
                  <div class="top top2">
                    <div class="top-title">
                      <span style="margin-left: 0.5rem">{{ t('sub_agents') }}</span>
                    </div>
                  </div>
                  <div class="top top2 flex flex-col gap-[10px]">
                    <div class="sub_agents new_referral_level2 referral_level2 active-sub-agents" v-for="(activeSubAgent, index) in sub_agents" :key="index">
                      <div class="active-sub-agents-item">
                        <div style="display: flex; line-height: 2.5rem; width: 60%; align-items: center">
                          <div style="" class="name text-[14px] text-[#AAC4ED]">{{ t(activeSubAgent.code) }}</div>
                        </div>
                        <div :class="'right' + (index == 0 ? ' first' : '')" style="display: flex; line-height: 2.5rem; width: 40%; text-align: right; align-items: center; justify-content: right">
                          <div>
                            <span class="gold">{{ refData ? refData[activeSubAgent.key] : '' }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="clear: both" class="pt-[5px]"></div>
                  </div>
                </div>
              </div>

              <div class="main-content top-1rem" v-if="isLogin && selectTab.code == 'referral_data'">
                <div class="body width-100 profit referral_top bottom-1rem">
                  <div class="top top2 !pb-[15px]">
                    <div style="display: block">
                      <div class="text-[14px] text-[#AAC4ED] font-[700] h-[40px] leading-[40px] text-left pt-[5px]">
                        {{ t('referral_data') }}
                      </div>
                      <!-- <div class="top-title">
                        <div class="h-[50px]">
                          <span style="margin-right: 0.5rem">{{ t('referral_data') }}</span>
                        </div>
                      </div> -->
                      <div class="md:mb-[10px]">
                        <div class="tool-bar tool-bar-search web">
                          <div class="tool-bar-tabs !w-auto">
                            <div :class="bets_selected == 'yesterday_commission' ? 'first item selected' : 'first item '" @click="phoneBetsChange('yesterday_commission')">
                              {{ t('yesterday') }}
                            </div>
                            <div :class="bets_selected == 'last_7_day_commission' ? 'item selected' : 'item '" @click="phoneBetsChange('last_7_day_commission')">
                              {{ t('seven_day') }}
                            </div>
                            <div :class="bets_selected == 'last_30_day_commission' ? 'item selected' : 'item '" @click="phoneBetsChange('last_30_day_commission')">
                              {{ t('thirty_day') }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tool-bar tool-bar-search bottom-1rem phone">
                        <div class="tool-bar-tabs !w-auto">
                          <div :class="bets_selected == 'yesterday_commission' ? 'first item selected' : 'first item '" @click="phoneBetsChange('yesterday_commission')">
                            {{ t('yesterday') }}
                          </div>
                          <div :class="bets_selected == 'last_7_day_commission' ? 'item selected' : 'item '" @click="phoneBetsChange('last_7_day_commission')">
                            {{ t('seven_day') }}
                          </div>
                          <div :class="bets_selected == 'last_30_day_commission' ? 'item selected' : 'item '" @click="phoneBetsChange('last_30_day_commission')">
                            {{ t('thirty_day') }}
                          </div>
                        </div>
                      </div>
                      <div class="referral_level2" v-for="(referral, index) in refData[bets_selected]" :key="index" v-show="referral.symbol != 'Gold'">
                        <div class="flex justify-between items-center gap-[10px] bg-[#222942] rounded-[0.5rem] p-[10px]">
                          <div class="right name" style="display: block; width: 40%; text-align: left; line-height: 1.5rem">
                            <div style="display: flex; align-items: center; align-content: flex-start; float: left; color: #ffffff; font-size: 14px">
                              <img :src="tokenConfig[referral.symbol]" style="width: 1.125rem; height: 1.125rem" class="!pr-[8px]" />
                              {{ referral.symbol }}
                            </div>
                          </div>

                          <div class="phone" style="display: block; width: 60%; line-height: 1.5rem; text-align: right">
                            <div class="name">
                              <div style="display: flex; align-items: center; justify-content: flex-end">
                                {{ referral.time }}
                              </div>
                            </div>
                          </div>
                          <div class="" style="display: block; width: 60%; line-height: 1.5rem; text-align: right">
                            <div class="name">
                              <div style="display: flex; align-items: center; justify-content: flex-end; color: #ffffff; font-size: 16px">
                                <span class="white">{{ referral.amountInt }}.</span>{{ referral.amountOther }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="clear: both; margin-bottom: 10rem" class="web"></div>
      <my-footer :isHomeFooter="false"></my-footer>
    </div>
  </div>
</template>

<style scoped>
.referral_top .tool-bar .tool-bar-tabs {
  background-color: var(--referral-data-tab-bg-color);
  padding: 0.321rem 0.5rem;
}
.web .referral_top .top {
  padding: 0 1rem;
}
.web .referral_top .top-line {
  height: calc(100% - 2.5rem);
}
.phone .input-div .input input {
  /*width:calc(100% - 3rem);*/
}
.input-div .input {
  padding-left: 1.125rem;
  padding-right: 2.5rem;
}
.phone .input-div .input.referral {
  justify-content: left;
  text-align: center;
}
.tool-bar .tool-bar-tabs {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.5rem;
}
.tool-bar .tool-bar-tabs div {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.5rem;
}
.tool-bar .tool-bar-tabs .item.first {
  margin-left: 0;
}
.web .referral_level {
  background: #222942;
  padding: 0 1rem;
  margin-bottom: 1.125rem;
}
.referral_level2 .left {
  text-align: left;
}
.referral_level2.no-padding-bottom-1 {
  padding: 1rem 0;
}
.phone .referral_level2 .name {
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.referral_level2 .name2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  padding: 0.3rem;
}

.web .symbol_block {
  display: flex;
  white-space: nowrap;
  width: 100%;
  line-height: 4rem;
  min-height: 4rem;
  overflow-x: auto;
  border-radius: 0.5rem;
  background-color: #1c243f;
}
.web .symbol_block .symbol_amount {
  display: block;
  /*width: 23%;*/
  line-height: 4rem;
  height: 4rem;
  align-items: center;
  float: left;
}

.web .symbol_block .symbol_amount .name img {
  width: 2rem;
}
.symbol_block .symbol_amount .name {
  justify-content: left;
  padding: 0 2rem 0 1.125rem;
}
.active-sub-agents-name,
.referral_level2 .name.active-sub-agents-name {
  font-size: 1.125rem;
  font-weight: 700;
}
.phone .symbol_block {
  display: block;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  overflow-x: auto;
}
.phone .symbol_block .symbol_amount {
  display: inline-block;
  line-height: 4rem;
  height: 4rem;
  align-items: center;
  background-color: var(--profile-symbol-bg-color);
  margin: 0 1%;
  border-radius: 0.5rem;
}
.phone .symbol_block .symbol_amount .name img {
  width: 1.5rem;
}
.get_rebate {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: right;
  color: var(--color-8ea3c3) !important;
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
}
.phone .get_rebate {
  display: block;
}
.web .referral_top .top .top-body-referral .top-body-referral-header.top-body-referral-body {
  height: calc(100% - 2rem);
  white-space: normal;
}
.phone .main-content .body {
  padding: 0 0.5rem;
  width: calc(98% - 1rem);
}
.phone .referral_top {
  background: var(--referral-bg-color);
  border-radius: 0.5rem;
}
.phone .select-input {
  display: block;
  justify-content: left;
  border: 1px solid var(--search-border-color);
  border-radius: 0.4rem;
  background-color: var(--input-default-bg-color);
  text-align: left;
  color: var(--color-8ea3c3);
  width: 14rem;
}
.select-div {
  position: fixed;
  width: 14rem;
}
.phone .deposit {
  margin: 0;
}
.phone .select-div {
  position: absolute;
}
.select-div-main {
  margin-top: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--referral_level-code-link-bg-color);
  font-weight: 700;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  line-height: 2.5rem;
}
.phone .select-div-main {
}
.option {
  font-family: 'Poppins', sans-serif;
  line-height: 2rem;
  height: 2rem;
  margin: 0.25rem 0;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.875rem;
  border-radius: 0;
}
.option.search_symbol {
  line-height: 4rem;
  height: 4rem;
}
.option.search_symbol .input-div {
  background-color: var(--white-color);
  border-radius: 0.5rem;
  border: 2px solid var(--referral-bg-color);
}
.option.search_symbol .tool-bar-search {
  width: 95%;
}
.option.search_symbol .input-div input {
  background-color: var(--white-color);
  color: var(--search-border-color);

  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0em;
}
.option img {
  width: 1rem;
  padding: 0 2rem;
}
.option.selected {
  background-color: var(--color-8ea3c3);
}
.option:hover {
  background-color: var(--color-8ea3c3);
}
.option.search_symbol:hover {
  background-color: var(--white-color);
}
.sub_agents {
}
.phone .sub_agents.new_referral_level2.referral_level2.active-sub-agents {
  background-color: var(--profit-bg-color);
}
.web .new_referral_level2.referral_level2.active-sub-agents {
  background-color: #222942;
  border-radius: 0.5rem;
  padding: 0;
  line-height: 3.5rem;
}
.phone .new_referral_level2.referral_level2.active-sub-agents {
  background-color: var(--profit-bg-color);
  width: calc(100% - 1rem);
  float: left;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}

.active-sub-agents-item {
  display: flex;
  line-height: 3rem;
  height: 3rem;
  width: calc(100% - 2rem);
  margin: 0px 1rem;
  align-items: center;
  /* border-bottom: 1px solid var(--referral_level-code-link-bg-color); */
}
.phone .active-sub-agents-item {
  display: flex;
  line-height: 3rem;
  height: 3rem;
  width: 100%;
  padding: 0px;
}
.phone .main-content .body.new_referral_level2 {
  box-sizing: border-box;
  padding: 0 10px;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  margin-bottom: 0;
}
.name img {
  width: 1.5rem;
  padding-right: 1.125rem;
}
.web .new_referral_level2.referral_top {
  display: block;
}
.web .left {
  width: 16rem;
}
.web .right {
  width: calc(100% - 16rem);
}
.phone .right {
  width: 100%;
}
.phone .top-rem0 {
  padding-top: 0;
}
.phone .width-100.no-padding {
  width: 100%;
  padding: 0;
}
.web .right .main-content {
  padding: 0;
  display: flex;
}

.web .referral_top .top .top-title span {
  white-space: normal;
  color: #aac4ed;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14.4px;
  text-transform: uppercase;
}
.web .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 190px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}
.web .left .item {
  /* padding: 1rem 0 1rem 1rem; */
  box-sizing: border-box;
  min-height: 36px;
  padding: 6px 16px;
  border-radius: 8px;
  background-color: #191f33;
  color: #5b7bab;
  display: flex;
  justify-content: center;
  align-items: center;
}
.web .left .item.selected {
  background-color: #3e4b79;
  color: #cee2ff;
}
.web .left .item:hover {
  background-color: #3e4b79;
  color: #cee2ff;
  cursor: pointer;
}
.phone .referral_level2.active-sub-agents div.agents {
  padding-right: 0.5rem;
}
.web .web-top-1rem {
  margin-top: 1rem;
}
.active-sub-agents-right-line {
  display: block;
  border-right: 1px solid var(--active-sub-agents-right-line);
  height: 3.5rem;
  width: 1px;
}
</style>
