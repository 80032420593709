<!-- eslint-disable vue/no-dupe-keys -->
<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, ref, computed, onUnmounted, onMounted } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import wgTypeInput from '@/components/common/wgTypeInput.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { toast } from 'vue3-toastify';
import { sendBindOtpEmailCode, bindOtp, verifyOtpEmailCode } from '@/assets/network/service/user';
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';
import QrcodeVue from 'qrcode.vue';
import wgButton from '@/components/common/wgButton.vue';
import { useClipboard } from '@vueuse/core';

const { copy } = useClipboard({ legacy: true });

const props = defineProps({
  show: Boolean,
  email: {
    type: String,
    default: () => ''
  }
});
const emit = defineEmits(['close', 'bindSuccess']);
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  bind_mfa: t('2_Step_Verification'),
  verify_account: t('verify_account'),
  bind_mfa_code: t('2FA_code'),
  send_code: t('send_code'),
  please_input___code: t('please_input___code'),
  please_enter___the_security_code: t('please_enter___the_security_code'),
  security_code: t('security_code'),
  verification: t('6_digit_code'),
  bind_successfully: t('bind_successfully'),
  email_is_required: t('email_is_required'),
  next: t('next'),
  confirm: t('OK').toLocaleUpperCase(),
  please_use_authentication_to: t('please_use_authentication_to'),
  done: t('done'),
  please_download_it_first: t('please_download_it_first')
};
const currentStep = ref(0);
const mySteps = ref([textData.verify_account, textData.bind_mfa_code, textData.done]);
// const email = ref('');
const verificationCode = ref('');

// const handleOtpValueChanged = (value) => {
//   otp.value = value;
// };
const security_code = ref('');
const security_code_url = ref('');
const otp = ref('');
const otp_url = ref('');

const handleSecurityCodeChanged = (value) => {
  security_code.value = value;
};

const handleVerifyValueChanged = (value) => {
  verificationCode.value = value;
};

const timeValue = 60;
const intervalId = ref(null);
const countdownTime = ref(timeValue);
const sendCoding = ref(false);
const confirmLoadingJson = ref(loadingAniJson);
const confirming = ref(false);
const handleSendCodeClick = () => {
  if (props.email == null || props.email.length <= 0) {
    toast(textData.email_is_required, { autoClose: 2000, type: 'error' });
    return;
  }

  sendCoding.value = true;
  sendCodeToServer();
};
const handleBindClick = () => {
  if (currentStep.value == 0) {
    // 这里调用接口获取该值
    verifyCodeToServer();
    return;
  } else if (currentStep.value == 1) {
    confirming.value = true;
    bindOtpToServer();
    return;
  } else {
    emit('close');
  }
};

const handleCanNext = computed(() => {
  return true;
});

const verifyCodeToServer = async () => {
  try {
    const param = {
      key: verificationCode.value
    };
    const response = await verifyOtpEmailCode(param);
    console.log(response.data.data);
    otp.value = response.data.data.otp;
    otp_url.value = response.data.data.otp_url;
    console.log(security_code.value);
    console.log(security_code_url.value);
    sendCoding.value = false;
    currentStep.value = 1;
    intervalId.value = setInterval(() => {
      if (countdownTime.value <= 1) {
        clearInterval(intervalId.value);
        intervalId.value = null;
        countdownTime.value = timeValue;
      } else {
        countdownTime.value -= 1;
      }
    }, 1000);
  } catch (error) {
    sendCoding.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

const sendCodeToServer = async () => {
  try {
    const param = {
      email: props.email
    };
    await sendBindOtpEmailCode(param);
    sendCoding.value = false;
    intervalId.value = setInterval(() => {
      if (countdownTime.value <= 1) {
        clearInterval(intervalId.value);
        intervalId.value = null;
        countdownTime.value = timeValue;
      } else {
        countdownTime.value -= 1;
      }
    }, 1000);
  } catch (error) {
    sendCoding.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};
const bindOtpToServer = async () => {
  try {
    const param = {
      otp: security_code.value
    };
    await bindOtp(param);
    currentStep.value = 2;
    confirming.value = false;
    toast(textData.bind_successfully, { autoClose: 2000 });
    emit('bindSuccess');
  } catch (error) {
    confirming.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

onUnmounted(() => {
  clearInterval(intervalId.value);
  intervalId.value = null;
});

onMounted(() => {
  // email.value = props.email;
});
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content !bg-[#121829]  ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title">{{ textData.bind_mfa }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <div class="w-full p-[1rem] bg-[#1C243F] rounded-md">
        <div class="text-[#5B7BAB] text-[0.75rem] font-bold">
          {{ t('Enter_the_verification_code_we_send_to') }}
        </div>
        <div class="text-[#fff] text-[0.875rem] font-bold mt-[0.5rem]">
          {{ email }}
        </div>
      </div>
      <step-progress
        :styles="{
          progress__bridge: {
            backgroundColor: 'grey',
            height: '2px', // 修改进度条高度的地方
            flexGrow: '1',
            width: '45%'
          }
        }"
        :steps="mySteps"
        :class="currentStep == 2 ? 'step2' : ''"
        :current-step="currentStep"
        icon-class="fa fa-check"
        :active-color="'var(--green-color)'"
        :passive-color="'var(--passive-color)'"
        :line-thickness="2"
        :passive-thickness="2"
      ></step-progress>
      <div style="clear: both; margin-top: 1rem"></div>

      <div class="tip core !text-[#5B7BAB]" v-if="currentStep == 1">
        {{ textData.please_use_authentication_to }}
      </div>

      <div v-if="currentStep == 1" class="!bg-[#0D101E] w-full flex justify-center flex-col items-center !p-[1rem]">
        <div class="core-img w-[130px] h-[130px]] flex justify-center items-center rounded-md">
          <qrcode-vue class="" v-if="currentStep == 1" :value="otp_url" level="H" size="130"></qrcode-vue>
        </div>
        <div class="text-[#fff]">{{ otp }}</div>
        <wg-button
          @click="
            () => {
              copy(otp);
              toast($t('copy_successfully'), { autoClose: 1000 });
            }
          "
          class="!bg-[#1C243F] text-[#D6E3F6] mt-[0.5rem] py-[0.69rem] mx-[1rem] w-4/5"
        >
          {{ t('copy') }}
        </wg-button>
      </div>

      <div v-if="currentStep == 2">
        <img src="../../assets/images/profile/bind_success.svg" />
      </div>
      <div class="tip core align-center" v-if="currentStep == 2">
        {{ textData.bind_successfully }}
      </div>

      <!-- <wg-type-input
        v-if="currentStep == 1"
        type="text"
        :is-copy="true"
        :read-only="true"
        :value="otp"
        @onValueChange="handleOtpValueChanged"
      ></wg-type-input> -->
      <div class="text-[#5B7BAB] font-bold text-[0.75rem] w-full" v-if="currentStep == 1">
        {{ textData.security_code }}
      </div>
      <wg-type-input v-if="currentStep == 1" type="text" :placeholder="textData.please_enter___the_security_code" @onValueChange="handleSecurityCodeChanged"></wg-type-input>
      <wg-type-input v-if="currentStep == 0" type="verifyCode" :placeholder="textData.verification" @onValueChange="handleVerifyValueChanged">
        <button class="verify-code-button" @click="handleSendCodeClick" :disabled="sendCoding || intervalId != null">
          {{ sendCoding ? '' : intervalId != null ? `${countdownTime}s` : textData.send_code }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="40" :height="40" v-if="sendCoding"></vue3-lottie>
        </button>
      </wg-type-input>

      <div class="bottom">
        <wgButton :class="handleCanNext ? 'bottom-button' : 'bottom-button-disable'" @click="handleBindClick" :disabled="confirming || !handleCanNext">
          {{ confirming ? '' : currentStep == 2 ? textData.confirm : textData.next }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
        </wgButton>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.125rem 1.1875rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}
.security_code {
  color: var(--color-8ea3c3);
  padding-bottom: 0;
  border-bottom: 0;
}
.download_info {
  color: var(--color-8ea3c3);
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
}
.tip.core {
  color: var(--color-8ea3c3);
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
}
.tip.core.align-center {
  text-align: center;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  border: none;
  padding: 0;
  color: #121829;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-button-disable {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .bg-content {
  min-height: 3.125rem;
}

::v-deep .step-progress__step:after {
  width: 30px;
  height: 30px;
}
::v-deep .step-progress__step span {
  --passiveColor: #55657e;
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
}
::v-deep .step-progress__step:after {
  --activeBorder: 0px;
  --passiveBorder: 0px;
  --passiveColor: #55657e;
  background: var(--passiveColor);
}

::v-deep .step-progress__step.step-progress__step--valid:after {
  --activeColor: var(--green-color);
  --passiveColor: #55657e;
  --activeBorder: 0px;
  --passiveBorder: 0px;
  background: var(--activeColor);
  font-weight: 700;
}
::v-deep .step-progress__step--valid span {
  opacity: 1;
  transform: translateZ(0) scale(1) perspective(1000px);
}
::v-deep .step-progress__step--active:after,
.step-progress__step--valid:after {
  --activeColor: var(--green-color);
  --passiveColor: #55657e;
  --activeBorder: 0px;
  --passiveBorder: 0px;
  background: var(--activeColor);
  font-weight: 700;
}
::v-deep .step-progress__bar {
  margin-bottom: 0;
  height: 60px;
}

::v-deep .step-progress__step-label {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-8ea3c3);
}
::v-deep .step-progress__step--active .step-progress__step-label,
.step-progress__step--active span {
  color: #ffffff;
}
::v-deep .step-progress__bar > *:first-child .step-progress__step-label {
  transform: translateX(-20%) perspective(1000px);
}
::v-deep .input {
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}
.core-img {
  margin: 1rem 0;
  padding: 5px;
  background: #ffffff;
}
</style>
