<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
// 传参
const props = defineProps({
  titleText: {
    type: String,
    default: 'Crypto'
  }
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="relative">
    <div class="text-[12px] text-[#5B7BAB] text-left font-[700] mb-[5px]">{{ props.titleText }}</div>
    <!-- 网络选择 -->
    <el-select v-model="walletStore.cryptoChainIndex" class="my-el-css" :placeholder="t('Please select')" size="large" :teleported="false">
      <el-option v-for="(item, index) in walletStore.cryptoSelectItem.chains" :key="index" :label="item.platform_name" :value="index" />
    </el-select>
  </div>
</template>

<style scoped></style>
