<template>
  <div class="my-header2 bg main-content web" @click.stop="isShowProfileMenus = false">
    <div class="width-100" id="header_root">
      <div class="body display-flex width-100">
        <div class="header-item-first web"><img src="../assets/images/home/web_logo.svg" style="cursor: pointer" @click="clickTitleLogo" /></div>
        <div class="header-item web">
          <div class="flex h-[2.5rem]" v-if="isLogin && wallet.icon_url" @click="showBalance = !showBalance" ref="web_header_second">
            <div class="bg-[#000000] flex p-[0.69rem] items-center px-[0.5rem] rounded-lg">
              <div class="text-[0.75rem] text-[#fff] font-bold">{{ wallet.intBalance || '0.' }}{{ wallet.otherBalance || '0000' }}</div>
              <img class="w-[1rem] h-[1rem] ml-[0.5rem]" :src="wallet.icon_url" v-show="wallet.icon_url" />
              <img class="w-[1rem] h-[1rem] ml-[0.5rem] rotate-90" src="../assets/images/home/btn_cb_zk.svg" />
            </div>
            <wg-button @click.stop="showDepositEvent()" class="!ml-[0.75rem] leading-[2.5rem]">{{ t('wallet') }}</wg-button>
          </div>
          <div class="wallet_div" v-show="showBalance">
            <div class="close-div" @click="showBalance = false" v-if="showBalance"></div>
            <div class="cion-div !bg-[#191F33]">
              <div class="dia_balance !bg-[#191F33]">
                <div class="cion-search">
                  <div class="cion-search-content">
                    <img src="@/assets/images/home/Frame 427320295.svg" />
                    <input v-model="searchSymbol" type="input" class="!text-[#fff]" :placeholder="t('search_symbol')" />
                  </div>
                </div>
                <div v-show="w.symbol !== 'Gold'" :class="'dia_balance_item' + (index + 1 == walletList.length ? ' last' : '')" v-for="(w, index) in filterWalletList" :key="index" @click="chooseWallet(w)">
                  <span style="width: 70%">{{ convertCurrency(w.balance) || 0.0 }}</span>
                  <div style="width: 30%; text-align: left; display: flex; align-items: center">
                    <img :src="w.icon_url" class="token" style="margin-right: 0.3rem" />
                    <span class="unit">{{ w.symbol }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 30%; justify-content: right; white-space: nowrap" class="header-item web">
          <div :class="'btn gift-btn web ' + (isPhone ? ' phone' : '')" v-show="false"><img src="../assets/images/home/btn_ico_zhuanpan.svg" />{{ t('gift_code') }}</div>
          <div :class="'btn notify-btn web '" @click="showNotifyMessageEvent()" v-if="isLogin">
            <img src="../assets/images/header/notify.svg" />
            <span class="number" v-show="unread_count > 0"></span>
          </div>
          <div class="btn login-btn web" v-if="!isLogin" @click="login('login')">{{ t('login') }}</div>
          <div class="btn sign-up-btn web" v-if="!isLogin" @click="login('sign_up')">{{ t('sign_up') }}</div>
          <div class="btn login-btn web" v-if="isLogin" @click.stop="showProfileMenus()">
            <img :src="user.url" style="width: 1.125rem" />
            <span class="web" style="padding: 0 0.5rem">{{ userName || user.userNiceName }}</span>
            <!-- img src="../assets/images/header/header-arrow-right.svg" style="width: 0.333rem;"/ -->

            <svg width="6" height="12" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L8 9L0 18" fill="var(--icon-bg-color)" />
            </svg>
          </div>

          <div v-if="isShowProfileMenus" @click.stop="isShowProfileMenus = false" class="search-overlay svelte-o7w8d3 background-none z-index1450" style=""></div>
          <div class="header-profile-menu !bg-[#0D101E] !p-[1rem]" v-show="isShowProfileMenus" @mouseleave="isShowProfileMenus = false">
            <div :id="menu.code === 'live_support' ? 'custom_intercom_launcher_id' : ''" class="header-profile-menu-body !text-[#80A4DB] !text-[1.125rem] cursor-pointer hover:!text-[#fff]" @click="clickProfileMenu(menu)" v-for="(menu, index) in profileMenus" :key="index" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
              <!-- <img :src="menu.imgUrl" v-show="!menu.hover" class="w-[1.25rem] h-[1.25rem]" />
              <img :src="menu.hoverImgUrl" v-show="menu.hover" class="w-[1.25rem] h-[1.25rem]" /> -->
              <span :class="menu.icon" class="iconfont !text-[1.35rem]"></span>
              <span class="ml-[0.88rem]">{{ t(menu.code) }}</span>
            </div>
          </div>

          <div class="header-profile-menu no-background" v-if="!isMobile && (newMessageShow || notifyMessageShow)">
            <div class="header-profile-menu-body no-padding">
              <notification-popup :show="newMessageShow" :type="'newMessage'" @close="closeNewMessageEvent" @makeAsRead="makeAsRead()" :new-message-data="last_unpopup_message"> </notification-popup>
              <notification-popup :show="notifyMessageShow" :type="'notification'" @close="closeNotifyMessageEvent" @makeAsRead="makeAsRead()"> </notification-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-header2 bg phone width-100" id="header_root">
    <div class="main-content phone" style="display: flex; align-items: center; justify-content: center; padding-top: 0">
      <div class="header-item-first" style="width: 15%" @click="clickTitleLogo">
        <img v-if="isLogin" src="../assets/images/home/web_logo_w.svg" />
        <img v-else src="../assets/images/home/web_logo.svg" />
      </div>
      <div class="header-item" style="width: calc(65% - 4rem)" v-if="isLogin" ref="phone_header_second">
        <div class="flex h-[2.5rem]" v-if="isLogin && wallet.icon_url" @click="showBalance = !showBalance">
          <div class="bg-[#000000] flex p-[0.69rem] items-center px-[0.5rem] rounded-lg">
            <div class="text-[0.75rem] text-[#fff] font-bold">{{ wallet.intBalance || '0.' }}{{ wallet.otherBalance || '0000' }}</div>
            <img class="w-[1rem] h-[1rem] ml-[0.5rem]" :src="wallet.icon_url" v-show="wallet.icon_url" />
            <img class="w-[1rem] h-[1rem] ml-[0.5rem] rotate-90" src="../assets/images/home/btn_cb_zk.svg" />
          </div>
          <wg-button @click.stop="showDepositEvent()" class="!ml-[0.25rem] flex items-center h-[2.5rem] w-[1.5rem]">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#121829" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19 17.5457C19 17.7479 18.9662 17.9385 18.8987 18.1134C18.8311 18.2884 18.7373 18.444 18.6134 18.5762C18.4895 18.7084 18.3469 18.8134 18.1818 18.8872C18.0166 18.9611 17.8365 19 17.6413 19H6.44879C6.25362 19 6.06971 18.9611 5.8933 18.8872C5.71689 18.8134 5.563 18.7084 5.43164 18.5762C5.29651 18.444 5.19142 18.2884 5.11635 18.1134C5.03753 17.9385 5 17.7479 5 17.5457V9.57834C5 9.17395 5.13512 8.83177 5.40912 8.54791C5.68311 8.26406 6.0134 8.12407 6.40375 8.12407H17.5962C17.9866 8.12407 18.3169 8.26406 18.5909 8.54791C18.8649 8.83177 19 9.17395 19 9.57834V11.752H15.5019C15.1115 11.752 14.7812 11.892 14.5072 12.168C14.2332 12.4441 14.0981 12.7863 14.0981 13.1907C14.1094 13.4668 14.1657 13.7117 14.267 13.9256C14.3496 14.1084 14.4847 14.2717 14.6761 14.4194C14.8676 14.5672 15.1416 14.645 15.5019 14.645H19V17.5535V17.5457ZM16.9019 7.38916H9.90188C10.4574 7.08975 10.9828 6.80201 11.4745 6.52593C11.9062 6.29263 12.334 6.05543 12.7544 5.82213C13.1748 5.58882 13.5051 5.40606 13.7416 5.27775C14.1019 5.07944 14.4209 4.98611 14.7062 5.00167C14.9877 5.01722 15.2279 5.06777 15.4231 5.15332C15.6483 5.26997 15.8434 5.42551 16.0086 5.61604L16.9019 7.38916ZM14.8038 13.1868C14.8038 12.9846 14.8713 12.8135 15.0027 12.6774C15.1378 12.5374 15.2992 12.4713 15.4944 12.4713C15.6895 12.4713 15.8547 12.5413 15.9861 12.6774C16.1212 12.8174 16.185 12.9846 16.185 13.1868C16.185 13.389 16.1174 13.564 15.9861 13.7079C15.8509 13.8517 15.6895 13.9217 15.4944 13.9217C15.2992 13.9217 15.134 13.8517 15.0027 13.7079C14.8676 13.564 14.8038 13.3929 14.8038 13.1868Z"
                fill="#121829"
              />
            </svg>
          </wg-button>
        </div>
        <div class="wallet_div" v-show="showBalance">
          <div class="close-div" @click="showBalance = false" v-if="showBalance"></div>
          <div class="cion-div !bg-[#191F33]" style="position: absolute; z-index: 10; top: 3.5rem; margin-left: -7rem">
            <div class="dia_balance !bg-[#191F33]">
              <div class="cion-search">
                <div class="cion-search-content">
                  <img src="@/assets/images/home/Frame 427320295.svg" />
                  <input v-model="searchSymbol" type="input" :placeholder="t('search_symbol')" />
                </div>
              </div>
              <div style="display: flex; align-items: center" v-show="w.symbol !== 'Gold'" :class="'dia_balance_item' + (index == 0 ? ' first' : '') + (index + 1 == walletList.length ? ' last' : '')" v-for="(w, index) in filterWalletList" :key="index" @click="chooseWallet(w)">
                <span style="width: 70%">{{ w.balance || 0.0 }}</span>
                <div style="width: 30%; display: flex; align-items: center">
                  <img :src="w.icon_url" class="token" style="margin-right: 0.3rem" />
                  <span class="unit">{{ w.symbol }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: calc(15% + 4rem); justify-content: center; align-items: center; display: flex" class="header-item" v-if="isLogin">
        <div :class="'btn notify-btn '" @click="showNotifyMessageEvent()" v-if="isLogin">
          <img src="../assets/images/header/notify.svg" />
          <span class="number" v-show="unread_count > 0"></span>
        </div>
        <img :src="user.url" style="width: 2.325rem; margin-right: 0.3rem" @click="isShowProfileMenus = !isShowProfileMenus" />
      </div>
      <div style="width: 80%; justify-content: right" class="header-item" v-if="!isLogin">
        <div class="btn login-btn" v-if="!isLogin" @click="login('login')">{{ t('login') }}</div>
        <div class="btn sign-up-btn" v-if="!isLogin" @click="login('sign_up')">{{ t('sign_up') }}</div>
      </div>

      <div v-show="isShowProfileMenus" @click.stop="isShowProfileMenus = false" class="search-overlay svelte-o7w8d3 background-none z-index1450" style=""></div>
      <div class="header-profile-menu !bg-[#0D101E] !p-[1rem]" v-show="isShowProfileMenus" @mouseleave="isShowProfileMenus = false">
        <div :id="menu.code === 'live_support' ? 'custom_intercom_launcher_id' : ''" class="header-profile-menu-body !text-[#80A4DB] !text-[1.125rem] cursor-pointer" @click="clickProfileMenu(menu)" v-for="(menu, index) in profileMenus" :key="index" @mouseover="menu.hover = true" @mouseleave="menu.hover = false">
          <span :class="menu.icon" class="iconfont text-[1.35rem]"></span>
          <span class="ml-[0.88rem]">{{ t(menu.code) }}</span>
        </div>
      </div>

      <div class="header-profile-menu no-background max-index" v-if="isMobile && (newMessageShow || notifyMessageShow)">
        <div class="header-profile-menu-body no-padding">
          <notification-popup :show="newMessageShow" :type="'newMessage'" @close="closeNewMessageEvent" @makeAsRead="makeAsRead()" :new-message-data="last_unpopup_message"> </notification-popup>
          <notification-popup :show="notifyMessageShow" :type="'notification'" @close="closeNotifyMessageEvent" @makeAsRead="makeAsRead()"> </notification-popup>
        </div>
      </div>
    </div>
  </div>
  <!-- div class="main-content wallet_div" v-show="showBalance && showBalanceOpen" style="position:absolute;" -->
  <depositAndWithdraw @success="myReload()" @choose="closeDeposit" @close="closeDeposit()" v-if="appStore.showDeposit" :wallet="wallet" @transactionShow="transactionShow" :wallet-list="walletList" v-show="!transactionFlag" />
  <transaction-list v-if="transactionFlag" @closeTransaction="closeTransaction" :transaction-type="transactionType" :symbols="walletList" :symbol="symbol"></transaction-list>
  <div class="phone login-dialog" style="" v-if="myShowLogin">
    <div class="login-parent">
      <my-login :is-dialog="true" @closeDialog="myCloseDialog" @loginAfter="loginAfter"></my-login>
    </div>
  </div>
  <div style="clear: both; margin-bottom: 3.75rem"></div>
  <button v-if="(appStore.customerShowType === 'Intercom' || appStore.customerShowType === 'Original') && $route.path != '/betbySports'" class="zxkf" @click="clickProfileMenu({ code: 'live_support' })">
    <svg v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.50023 11.3641C4.30275 11.3641 4.11046 11.3862 3.92352 11.4225C4.20724 6.6724 8.17859 2.86438 13 2.86438C17.8214 2.86438 21.7927 6.6724 22.0765 11.4225C21.8895 11.3862 21.6972 11.3641 21.4997 11.3641H20.2912C20.0694 11.3641 19.8591 11.4098 19.6625 11.4831C19.413 8.03967 16.5071 5.28137 13 5.28137C9.49283 5.28137 6.58694 8.03967 6.33753 11.4831C6.14083 11.4098 5.9306 11.3641 5.70873 11.3641H4.50023ZM4.50024 19.8639H5.70874C6.04249 19.8639 6.31299 19.5934 6.31299 19.2596V13.1769C6.31299 12.8431 6.04249 12.5726 5.70874 12.5726H4.50024C3.49914 12.5726 2.6875 13.3843 2.6875 14.3854V18.0511C2.6875 19.0522 3.49914 19.8639 4.50024 19.8639ZM21.4998 12.5726H20.2913C19.9575 12.5726 19.687 12.8431 19.687 13.1769V20.4681C19.687 20.8016 19.4162 21.0724 19.0828 21.0724H15.9503C15.6999 20.3706 15.0356 19.8639 14.2488 19.8639H11.7915C10.792 19.8639 9.97876 20.6771 9.97876 21.6766C9.97876 22.6762 10.792 23.4894 11.7915 23.4894H14.2488C15.0356 23.4894 15.6999 22.9827 15.9503 22.2809H19.0828C20.0823 22.2809 20.8955 21.4677 20.8955 20.4681V19.8639H21.4998C22.5009 19.8639 23.3125 19.0522 23.3125 18.0511V14.3854C23.3125 13.3843 22.5009 12.5726 21.4998 12.5726Z"
        fill="white"
      />
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.63664 15.3633C5.3494 15.3633 5.0697 15.3953 4.79779 15.4482C5.21046 8.53894 10.987 3 17.9999 3C25.0129 3 30.7894 8.53894 31.2021 15.4482C30.9301 15.3953 30.6505 15.3633 30.3632 15.3633H28.6054C28.2827 15.3633 27.9769 15.4297 27.6908 15.5363C27.328 10.5277 23.1012 6.51562 17.9999 6.51562C12.8986 6.51562 8.67184 10.5277 8.30907 15.5363C8.02296 15.4297 7.71718 15.3633 7.39445 15.3633H5.63664ZM5.63672 27.7266H7.39453C7.87999 27.7266 8.27344 27.3331 8.27344 26.8477V18C8.27344 17.5145 7.87999 17.1211 7.39453 17.1211H5.63672C4.18057 17.1211 3 18.3017 3 19.7578V25.0898C3 26.546 4.18057 27.7266 5.63672 27.7266ZM30.3633 17.1211H28.6055C28.12 17.1211 27.7266 17.5145 27.7266 18V28.6055C27.7266 29.0905 27.3327 29.4844 26.8477 29.4844H22.2913C21.9272 28.4636 20.9608 27.7266 19.8164 27.7266H16.2422C14.7883 27.7266 13.6055 28.9094 13.6055 30.3633C13.6055 31.8171 14.7883 33 16.2422 33H19.8164C20.9608 33 21.9272 32.263 22.2913 31.2422H26.8477C28.3015 31.2422 29.4844 30.0593 29.4844 28.6055V27.7266H30.3633C31.8194 27.7266 33 26.546 33 25.0898V19.7578C33 18.3017 31.8194 17.1211 30.3633 17.1211Z"
        fill="white"
      />
    </svg>
  </button>
  <wg-activity-floating @needLogin="floatingActivityNeedLogin" @deposit="floatingActivityOpenDeposit" />
  <customer />
  <vip-popup :show="showVIP" @close="handleCloseVIP"></vip-popup>
  <deposit-result
    v-if="showDepositResult"
    @showDepositRecord="
      showDepositResult = false;
      showDepositRecordEvent();
    "
  ></deposit-result>
  <!-- 这里添加TG引流 -->
  <!-- <tg-entry :show="TGStore.show" :config="TGStore.config" @close="TGStore.updateShow(false)" /> -->
  <!-- 新版登录弹窗 -->
  <loginReg :show="loginStore.show" @close="loginStore.updateShow(false)" @loginAfter="loginAfter" :myClass="'login-Z-index'" />
  <!-- 新版钱包 -->
  <DialogDeposit v-if="walletStore.showDialogDeposit" />
</template>
<script setup>
import { onMounted } from 'vue';
import wgActivityFloating from '@/components/common/wgActivityFloating.vue';
import notificationPopup from '@/components/notification/notificationPopup.vue';
import depositResult from '@/pages/symbol/depositResult.vue';
import vipPopup from './VIP/vipPopup.vue';
import customer from '@/components/customer/customerPopup.vue';
import { useI18n } from 'vue-i18n';
import depositAndWithdraw from '@/components/symbol/depositAndWithdraw.vue';
import transactionList from '@/components/symbol/transactionList.vue';
import myLogin from '@/pages/login/myLogin.vue';
import { ref } from 'vue';
// import tgEntry from '@/components/notice/tgEntry.vue';
import loginReg from '@/components/login/loginReg.vue';
import { useLoginStore, useAppStore } from '@/store/store';
import wgButton from './common/wgButton.vue';
import useWalletStore from '@/store/wallet';
import DialogDeposit from '@/pages/wallet/DialogDeposit';
import { sendAdjustEventPurchase } from '@/assets/tools/adjustConfig';
import useDialogStore from '@/store/dialog';

const walletStore = useWalletStore();
// const TGStore = useTGStore();
const loginStore = useLoginStore();
const appStore = useAppStore();
const { t } = useI18n();
const transactionFlag = ref(false);
const transactionType = ref('');
const symbol = ref({});
const transactionShow = (type, sym) => {
  transactionType.value = type;
  symbol.value = sym;
  transactionFlag.value = true;
};
const closeTransaction = () => {
  transactionFlag.value = false;
  transactionType.value = '';
};
const showDepositRecordEvent = () => {
  transactionType.value = 'deposit';
  transactionFlag.value = true;
};
const floatingActivityNeedLogin = (isLogin) => {
  loginStore.updateActType(isLogin ? 'login' : 'sign_up');
  loginStore.updateShow(true);
};
const floatingActivityOpenDeposit = () => {
  appStore.showDeposit = true;
};
window.onload = function () {
  if (!appStore.isLogin) {
    const reloadLogin = window.sessionStorage.getItem('reloadLogin');
    window.sessionStorage.removeItem('reloadLogin');
    if (reloadLogin == 66) {
      setTimeout(() => {
        loginStore.updateActType('login');
        loginStore.updateShow(true);
      }, 1000);
    }
  }
};
onMounted(() => {
  walletStore.getCurrencyTokens();
});
</script>
<script>
import { useCookies } from 'vue3-cookies';
import headUrl from '../assets/images/profile/avatar/profile-avatar1.png';
import accountImg from '../assets/images/header/account.svg';
import accountImg2 from '../assets/images/header/account_2.svg';
import { clearUserCookies, getCookiesGmailLogin, getCookieAccessToken, getUserDefSymbol, setUserDefSymbol, getCookieUserNiceName, setCookieUserNiceName, getCookieUserId } from '@/assets/tools/CommonTool';
import { fpDepositSuccessEvent, fpDepositFirstSuccessEvent } from '../assets/tools/FacebookPixelEvent';

import { getNotifyInfo, getCustomerLinkService } from '@/assets/network/service/home';
import { kwaiEventService, logoutService, userInfoService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
import { userWalletInfosService } from '@/assets/network/service/wallet';
import { convertCurrency } from '@/assets/tools/CommonTool.js';
import serverConfig from '@/assets/network/serverConfig';
import { gaPurchaseEvent } from '@/assets/tools/GoogleAnalyticsEvent';
import { setupCustomerType } from '@/assets/tools/CustomerType';
import { UpdateIntercom, ShutdownIntercom, ShowIntercom, HideIntercom } from '@/assets/tools/CustomerType';
import emitter from '@/assets/tools/eventBus';

export default {
  name: 'HeaderCom',
  props: {
    symbol: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    showMenu: {
      type: Boolean,
      required: true
    },
    showLogin: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  watch: {
    symbol: function (newVal) {
      console.log(newVal);
      if (newVal) {
        this.currSymbol = newVal;
        setUserDefSymbol(this.currSymbol);
        for (let i in this.walletList) {
          if (this.walletList[i].symbol == newVal) {
            this.wallet = this.walletList[i];
            break;
          }
        }
      }
    },
    showMenu: function (newVal) {
      this.myShowMenu = newVal;
      this.localeShow = false;
    },
    showLogin: function (newVal) {
      this.myShowLogin = newVal;
      if (newVal) {
        document.getElementsByTagName('body')[0].classList.add('hidden-overflow-y');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
      }
    },
    wallet: function (newVal) {
      this.$emit('walletChange', newVal);
    },
    showDeposit: function (newVal) {
      //if(newVal){
      //  document.getElementsByTagName('html')[0].style = 'overflow-y:hidden;'
      //}else{
      //  document.getElementsByTagName('html')[0].style = ''
      //}
      if (newVal) {
        document.getElementsByTagName('body')[0].classList.add('hidden-overflow-y');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
      }
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      searchSymbol: '',
      brlWallet: null,
      showDepositRecord: false,
      showBalance: false,
      showDepositResult: false,
      myShowLogin: false,
      // showDeposit: false,
      profileMenus: [
        {
          hover: false,
          code: 'account',
          imgUrl: accountImg,
          hoverImgUrl: accountImg2,
          icon: 'icon-Icon-91'
        },
        {
          disabled: false,
          hover: false,
          code: 'balance',
          linkUrl: '/profile/balance',
          icon: 'icon-Icon-81'
        },
        {
          disabled: false,
          hover: false,
          code: 'deposit',
          linkUrl: '/profile/deposit',
          icon: 'icon-Icon-71'
        },
        {
          disabled: false,
          hover: false,
          code: 'withdraw',
          linkUrl: '/profile/withdraw',
          icon: 'icon-Icon-6'
        },
        {
          disabled: false,
          hover: false,
          code: 'transaction',
          linkUrl: '/profile/transaction',
          icon: 'icon-Icon-4'
        },
        {
          disabled: false,
          hover: false,
          code: 'my_beats',
          linkUrl: '/profile/myBeats',
          icon: 'icon-Icon-3'
        },
        {
          hover: false,
          code: 'VIP',
          linkUrl: '/profile/vip',
          icon: 'icon-Icon-2'
        },
        {
          hover: false,
          code: 'live_support',
          icon: 'icon-Icon-1'
        },
        {
          hover: false,
          code: 'logout',
          icon: 'icon-Icon1'
        }
      ],
      walletList: [],
      wallet: {},
      currSymbol: getUserDefSymbol(),
      user: {
        userNiceName: '',
        url: headUrl
      },
      isLogin: false,
      localeShow: false,
      myShowMenu: true,
      isPhone: false,
      isMobile: false,
      isShowProfileMenus: false,
      queryLast: null,
      notifyMessageShow: false,
      newMessageShow: false,
      last_unpopup_message: null,
      unread_count: 0,
      unpopup_count: 0,
      showVIP: false,
      socket: null,
      customer_service_link: '',
      publicWs: null
    };
  },
  computed: {
    filterWalletList() {
      return this.walletList.filter((item) => item.symbol.toLowerCase().includes(this.searchSymbol.toLowerCase()));
    }
  },
  created() {
    let referral_code = this.$route.query.referral_code;
    if (referral_code) {
      useAppStore.referralCode = referral_code;
    }
    let currSymbol = getUserDefSymbol();
    if (currSymbol) {
      // 过滤法币
      if (currSymbol == 'BRL' || currSymbol == 'MXN' || currSymbol == 'PHP' || currSymbol == 'KES') {
        setUserDefSymbol('');
      } else {
        this.currSymbol = currSymbol;
      }
    } else {
      setUserDefSymbol(this.currSymbol);
    }
    window.addEventListener('resize', this.resize);
    this.resize();
    this.loginAfter();
    this.user.userNiceName = getCookieUserNiceName();
    getCustomerLinkService()
      .then((response) => {
        // window.open(response.data.customer_service_link, '_blank');
        this.customer_service_link = response.data.customer_service_link;
      })
      .catch((error) => {
        const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
        let errorMsg = msg.length > 0 ? msg[0] : error.message;
        if (error.response.status === 500) {
          errorMsg = error.message;
        }
        toast(errorMsg, { autoClose: 2000, type: 'error' });
      });

    this.publicSocketInit();

    const appStore = useAppStore();
    this.$watch(
      () => appStore.reloadFlag,
      (newValue, oldValue) => {
        console.log('newValue', newValue);
        console.log('oldValue', oldValue);
        if (newValue != oldValue) {
          this.loginAfter();
        }
      }
    );
  },
  beforeUnmount() {
    if (this.queryLast) {
      clearInterval(this.queryLast);
    }

    window.removeEventListener('resize', this.resize);
    document.getElementsByTagName('body')[0].classList.remove('hidden-overflow-y');
    this.socketClose();
    this.closePublicSocket();
  },
  mounted() {
    setupCustomerType();
    // mount login method
    emitter.on('custom-login', (_type) => {
      this.login(_type);
    });
    // emitter.on('custom-withdraw-success', (_type) => {
      // this.getTGConfig(_type);
    // });
  },
  methods: {
    clickTitleLogo() {
      this.$router.push('/');
      // const par = {
      //   symbol: 'USDT',
      //   amount: '2.14'
      // }
      // sendAdjustEventPurchase(par);
    },
    myCloseDialog() {
      this.myShowLogin = false;
      this.$emit('hideShowLogin');
    },
    myReload() {
      const appStore = useAppStore();
      appStore.showDeposit = false;
      this.loginAfter();
      // this.getTGConfig('AFTER_WITHDRAW');
    },
    // getTGConfig(type) {
    //   const TGStore = useTGStore();
    //   TGStore.getConfigService(type);
    // },
    userInfoService() {
      let userId = getCookieUserId();
      const appStore = useAppStore();
      userInfoService(userId)
        .then((response) => {
          setCookieUserNiceName(response.data.name);
          this.user.userNiceName = response.data.name;
          this.user.enable_otp = response.data.enable_otp;
          this.user.enable_withdraw_pass = response.data.enable_withdraw_pass;
          appStore.addUserInfo(response.data);
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    processNotify() {
      getNotifyInfo()
        .then((response) => {
          this.unread_count = response.data.data.unread_count;
          this.unpopup_count = response.data.data.unpopup_count;
          if (response.data.data.unpopup_count > 0) {
            this.showNewMessageEvent();
            this.last_unpopup_message = response.data.data.last_unpopup_messages[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    socketClose() {
      if (this.socket) {
        this.socket.close();
        this.socket = null; // 防止内存泄漏
        // alert('关闭与WebSocket服务器的连接')
        console.log('关闭与WebSocket服务器的连接-userId');
      }
    },
    socketInit() {
      let userId = getCookieUserId();
      if (userId) {
        this.socket = new WebSocket(serverConfig.wsBaseURL + userId + '/');
        const appStore = useAppStore();
        const walletStore = useWalletStore();
        this.socket.onmessage = (event) => {
          let dataStr = event.data;
          if (dataStr) {
            // alert('WebSocket消息:'+dataStr)
            let data = JSON.parse(dataStr);
            let walletList = data.message;
            let deposit = null;
            let walletList2 = [];
            if (walletList && walletList.length === 0) return;
            for (let i in walletList) {
              if (walletList[i].type == 'deposit') {
                deposit = walletList[i];
              } else if (walletList[i].type == 'my_bets') {
                appStore.addMyBets(walletList[i]);
                return;
              } else {
                //过滤法币
                if (!walletList[i].is_fiat) {
                  walletList2.push(walletList[i]);
                }
              }
            }
            this.walletList = walletList2;
            this.processWallet();

            if (deposit != null) {
              deposit.userId = userId;
              deposit.referralCode = appStore.fpReferral;
              fpDepositSuccessEvent(deposit);

              gaPurchaseEvent(deposit);

              sendAdjustEventPurchase(deposit);
              walletStore.depositSuccess = true;

              const dialogStore = useDialogStore();
              dialogStore.initDialog(4)
            }
            if (deposit != null && deposit.is_first_deposit_record) {
              fpDepositFirstSuccessEvent(deposit);
            }
            console.log('WebSocket connection established.', this.walletList);
          }
        };

        this.socket.onopen = () => {
          // alert('开启与WebSocket服务器的连接-userId:'+userId)
          console.log('开启与WebSocket服务器的连接-userId' + userId);
        };
      }
    },
    closePublicSocket() {
      if (this.publicWs) {
        this.publicWs.close();
        this.publicWs = null; // 防止内存泄漏
        console.log('关闭与WebSocket服务器的连接-Public');
      }
    },
    publicSocketInit() {
      this.publicWs = new WebSocket(serverConfig.publicWsBaseURL);
      const appStore = useAppStore();
      this.publicWs.onmessage = (event) => {
        let dataStr = event.data;
        if (dataStr) {
          let data = JSON.parse(dataStr);
          let messageData = data.message;
          let betsTypeList = ['all_bets', 'high_roller', 'lucky_bets'];
          for (let i in messageData) {
            if (betsTypeList.includes(messageData[i].type)) {
              appStore.addPublicBets(messageData);
              break;
            }
          }
        }
      };
      this.publicWs.onopen = () => {
        console.log('开启与WebSocket服务器的连接-Public');
      };
    },
    loginAfter() {
      UpdateIntercom();

      const appStore = useAppStore();
      let accessToken = getCookieAccessToken();
      if (!accessToken) {
        this.isLogin = false;
        appStore.isLogin = false;
        return;
      }
      this.socketInit();
      this.isLogin = true;
      appStore.isLogin = true;
      this.userInfoService();
      this.processNotify();
      this.queryLast = setInterval(this.processNotify, 10000);
      userWalletInfosService()
        .then((response) => {
          //过滤法币
          const newWalletList = response.data.data.filter((item) => !item.is_fiat);
          const userSymbol = getUserDefSymbol();
          if (userSymbol) {
            this.currSymbol = userSymbol;
          } else {
            this.currSymbol = newWalletList[0].symbol;
          }
          setUserDefSymbol(this.currSymbol);
          this.walletList = newWalletList;
          this.processWallet(true);
        })
        .catch((error) => {
          console.log(error);
        });
      this.myShowLogin = false;
      this.$emit('loginAfter');
    },
    processWallet(isFirst) {
      let symbol = this.$route.query.depositResult;
      if (this.walletList && this.walletList.length > 0) {
        console.log(this.wallet.symbol || this.currSymbol);
        for (let i = 0; i < this.walletList.length; i++) {
          let wallet = this.walletList[i];
          let balances = wallet.balance.split('.');
          let intBalance = balances[0] + '.';
          wallet.intBalance = intBalance;
          if (balances.length > 1) {
            wallet.otherBalance = balances[1];
          } else {
            wallet.otherBalance = '0000';
          }
          if (this.walletList[i].symbol == symbol) {
            this.brlWallet = wallet;
          }
          if (this.walletList[i].symbol == (this.wallet.symbol || this.currSymbol)) {
            this.wallet = wallet;
            if (this.wallet.queryType == 'game_bets' && this.wallet.remind_amount > 0) {
              if (+this.wallet.rate_amount <= +this.wallet.remind_amount) {
                // this.getTGConfig('BETS');
              }
            }
          }
        }
      }
      this.$emit('loginAfter', this.walletList);

      if (symbol) {
        let order_id = localStorage.getItem('deposit_order_id');
        if (isFirst && order_id) {
          localStorage.setItem('deposit_order_id', '');
          this.depositResultSymbol = symbol;
          this.showDepositResult = true;
        }
      }
    },

    showDepositEvent() {
      // console.log('----------------');
      // const appStore = useAppStore();
      // appStore.showDeposit = true;
      const walletStore = useWalletStore();
      walletStore.showDialogDeposit = true;
    },
    makeAsRead() {
      if (this.unread_count > 0) {
        this.unread_count--;
      }
    },
    closeNotifyMessageEvent() {
      this.notifyMessageShow = false;
    },
    closeNewMessageEvent() {
      this.newMessageShow = false;
    },
    showNotifyMessageEvent() {
      if (this.isMobile) {
        // this.newMessageShow = true
        // this.last_unpopup_message = {
        //        "id": 1,
        //        "subject": "",
        //        "content": "You have met the eligibility criteria.",
        //        "is_read": false,
        //        "detail_link": "https://docs.google.com/forms/d/1WIK3goRv7XxzyHR2Eptcy3B2JYjfPdgCqp9KsIFZ1qs/edit",
        //        "created_at": 1552321716
        //     }
        // 跳转到手机版的消息中心页面
        this.$router.push('/notification-center');
        return;
      }
      this.notifyMessageShow = true;
    },
    showNewMessageEvent() {
      this.newMessageShow = true;
    },
    resize() {
      if (window.innerWidth <= 780) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }

      if (window.innerWidth >= 1155) {
        this.itemsToShow = 3;
        this.isPhone = false;
      }
      if (window.innerWidth <= 1155) {
        this.isPhone = true;
        return;
      }
    },
    login(_type) {
      const loginStore = useLoginStore();
      loginStore.updateShow(true);
      loginStore.updateActType(_type);
      // this.$router.push('/login?type=' + type);
    },
    showLoginEvent() {
      this.$emit('showLoginEvent');
    },
    showI18nMenuItem() {
      this.localeShow = !this.localeShow;
      if (!this.showMenu) {
        this.$emit('showMenuChange');
      }
    },
    chooseLanguage(locale, locale1) {
      console.log(locale1);
      locale.locale = locale1;
      localStorage.setItem('locale', locale.locale);
      this.localeShow = false;
    },
    phoneLogin(type) {
      this.$router.push('/login?type=' + type);
    },
    handleCloseVIP() {
      this.showVIP = false;
    },
    async openNewWindow(url) {
      const newWindow = window.open(url, '_blank');
      newWindow.focus();
    },
    loginOutAfter() {
      ShutdownIntercom();
      const that = this;
      if (this.queryLast) {
        clearInterval(this.queryLast);
        this.queryLast = null;
      }
      const appStore = useAppStore();
      appStore.fpInit = false;
      clearUserCookies();
      // dispatch(userLogout(response.data));
      // dispatch(walletLogout());
      this.isLogin = false;
      appStore.isLogin = false;
      toast(this.$t('login_out_successfully'), { autoClose: 1000 });
      const { cookies } = useCookies();
      cookies.remove('cookieName');
      for (let i in cookies) {
        console.log(cookies[i]);
      }
      if (this.$route.path == '/') {
        window.location.reload();
      } else {
        setTimeout(function () {
          that.$router.push('/');
        }, 1000);
      }
    },
    clickProfileMenu(menu) {
      if (menu.linkUrl && !menu.disabled) {
        this.$router.push(menu.linkUrl);
      } else if (menu.code == 'account') {
        // 跳转到我的账号页面
        this.$router.push('/profile/setting');
      } else if (menu.code == 'transaction') {
        // 跳转到我的账号页面
        this.$router.push('/profile/transaction');
      } else if (menu.code == 'VIP') {
        this.showVIP = true;
      } else if (menu.code == 'live_support') {
        // 跳转到我的账号页面
        // this.openNewWindow(this.customer_service_link)

        const appStore = useAppStore();
        if (!appStore.openCustomer) {
          if (appStore.customerShowType === 'Intercom') {
            ShowIntercom();
            const params = {
              kwai_pixel_id: appStore.kwaiPixelId,
              kwai_token: appStore.kwaiToken,
              click_id: appStore.kwaiClickId,
              event_name: 'contact'
            };
            kwaiEventService(params);
          }
          appStore.openCustomerPopup();
        } else {
          if (appStore.customerShowType === 'Intercom') {
            HideIntercom();
          }
          appStore.closeCustomerPopup();
        }
      } else if (menu.code == 'my_beats') {
        this.$router.push('/profile/myBeats');
      } else if (menu.code == 'logout') {
        const that = this;
        // TODO 退出登录
        try {
          logoutService()
            .then(() => {
              let isGmailLogin = getCookiesGmailLogin();
              let f = false;
              const appStore = useAppStore();
              appStore.clearUserInfo();
              if (isGmailLogin && isGmailLogin == 'true' && f) {
                this.$gAuth
                  .signOut()
                  .then(() => {
                    that.loginOutAfter();
                  })
                  .catch((error) => {
                    console.log(error);
                    that.loginOutAfter();
                  });
              } else {
                this.loginOutAfter();
              }
            })
            .catch((error) => {
              const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
              let errorMsg = msg.length > 0 ? msg[0] : error.message;
              if (error.response.status === 500) {
                errorMsg = error.message;
              }
              console.log('--------------------------5555555555555');
              console.log(error.response);
              toast(errorMsg, { autoClose: 2000, type: 'error' });

              if (error.response.status === 401) {
                clearUserCookies();
                setTimeout(function () {
                  if (that.$route.path == '/') {
                    window.location.reload();
                  } else {
                    that.$router.push('/');
                  }
                }, 1000);
              }
            });
        } catch (e) {
          console.error('Error fetching data:', e);
        }
      } else {
        toast('Coming soon', { autoClose: 1000, type: 'info' });
      }
      this.isShowProfileMenus = false;
    },
    showProfileMenus() {
      this.isShowProfileMenus = true;
    },
    closeDeposit() {
      const appStore = useAppStore();
      appStore.showDeposit = false;
    },
    chooseWallet(w) {
      this.currSymbol = w.symbol;
      setUserDefSymbol(this.currSymbol);
      this.wallet = w;
      this.showBalance = false;
      this.$emit('chooseWallet', this.wallet);
      // userWalletInfosService({ single_symbol: w.symbol }).then((res) => {
      //   if (res.data.code == 200 && res.data.data.length > 0) {
      //     const date = res.data.data[0];
      //     if (date.remind_amount > 0) {
      //       if (+date.rate_amount <= +date.remind_amount) {
      //         this.getTGConfig('BETS');
      //       }
      //     }
      //   }
      // });
      const appStore = useAppStore();
      if (this.$route.path.includes('/betbySports')) {
        appStore.btBool = true;
        emitter.emit('betbyUpdate');
      }
    }
  }
};
</script>
<style scoped>
.rotate-90 {
  transform: rotate(90deg);
}
.wallet_div {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--balance-z-index);
  top: 2.25rem;
  background: none;
}
.web .wallet_div {
  top: 3.25rem;
}
.wallet_div .body {
  display: flex;
  justify-content: let;
}
.wallet_div .dia_balance {
  display: block;
  background: #ffffff;
  text-align: left;
  line-height: 2.5rem;
}
.dia_balance_item {
  padding: 0 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.dia_balance_item:hover {
  background: var(--bg-blue-color);
}
.web.web-960 .login-btn span {
  max-width: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.web .login-btn span {
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.web .money-btn.btn .wallet {
  padding: 0 0.625rem;
}
.notify-btn {
  padding: 0 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--default-btn-bg-color);
}
.notify-btn:hover {
  opacity: 0.8;
}
.phone .notify-btn {
  margin-right: 0.5rem;
}
.notify-btn img {
  height: 0.875rem;
}
.notify-btn .number {
  position: absolute;
  margin-left: 2.6rem;
  margin-top: -1.625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background: #f15a5a;
}
.header-profile-menu-body.no-padding {
  padding: 0;
}
.no-background {
  background: none;
}
.header-profile-menu.max-index {
  z-index: 999999;
}
.search-overlay.svelte-o7w8d3.background-none.z-index1450 {
  z-index: 1450;
}
.zxkf {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 4rem;
  height: 4rem;
  z-index: 1450;
  background-color: #3e4b79;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4rem;
  padding: 0;
  transition: all 0.15s ease-out;
}
.zxkf:hover {
  transform: scale(1.1);
}
.zxkf:active {
  transform: scale(0.9);
}
.phone .zxkf {
  bottom: 6rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
}

.wallet_div {
  display: flex;
  line-height: 2.5rem;
  justify-content: center;
}
.dia_balance {
  background-color: var(--deposit-withdraw-selected-div-bg-color);
  border-radius: 0.5rem;
  font-weight: 700;
  max-height: 25rem;
  overflow-y: auto;
  color: var(--color-8ea3c3);
}
.dia_balance_item.first {
  border-radius: 0.5rem 0.5rem 0 0;
}
.dia_balance_item.last {
  border-radius: 0 0 0.5rem 0.5rem;
}
.dia_balance_item {
  padding: 0 1rem;
}
.dia_balance_item:hover {
  background-color: var(--color-8ea3c3);
  color: var(--white-color);
}
.token {
  width: 1rem;
  height: 1rem;
  padding: 0 0 0 0.5rem;
}
.select-div-main {
  max-height: 25rem;
  overflow-y: auto;
}
.cion-div {
  position: absolute;
  z-index: 10;
  top: 3.5rem;
  margin-left: -13rem;
  width: 310px;
  border-radius: 0.5rem;
  background-color: var(--deposit-withdraw-selected-div-bg-color);
  font-weight: 700;
  color: #000000;
  font-size: 0.875rem;
  line-height: 2.5rem;
  max-height: 25rem;
  overflow-y: auto;
}
.cion-search {
  padding: 0.5rem 1rem;

  .cion-search-content {
    border-radius: 8px;
    border: 1px solid #1e2736;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      padding: 10px;
    }
    input {
      /* background-color: var(--deposit-withdraw-block-bg-color); */
      background-color: transparent;
      /* color:var(--white-color); */
      border: none;
      width: 100%;
      padding-right: 10px;
    }
  }
}
</style>
