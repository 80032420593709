<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<script>
import { getHeaderImg } from '@/assets/tools/avatar.js';
//import referralLevel2 from '../../assets/images/referral/Group 33792.svg'
//import referralLevel3 from '../../assets/images/referral/Group 33791.svg'
import ttPokerIcon from '../../assets/images/referral/TTpoker-1.webp';
import ttPokerIcon2 from '../../assets/images/referral/referral-people.svg';
import goldIcon from '../../assets/images/referral/referral-gold.png';
import sliverIcon from '../../assets/images/referral/referral-sliver.png';
import bronzeIcon from '../../assets/images/referral/referral-bronze.png';
import token1 from '../../assets/images/footer/footer-token-1.png';
import { getCookieEmail, getCookieUserId, convertCurrency } from '@/assets/tools/CommonTool';
import { userInfoService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
import { getReferralGetReferralInfoService, getReferralReferralRankings } from '@/assets/network/service/referral';
export default {
  name: 'App',
  components: {},
  watch: {
    isLogin: function (newVal) {
      const that = this;
      if (newVal) {
        that.$nextTick(() => {
          if (that.$refs['body-main']) {
            let mainWidth = that.$refs['body-main'].offsetWidth;
            let tdWidth = mainWidth / 4;
            that.tdWidth = tdWidth > 5.06 * 16 ? tdWidth : 5.06 * 16;
          }
        });
      }
    }
  },
  data() {
    return {
      updateDate: 0,
      tokenConfig: {
        USDT: token1
      },
      symbolAmountList: [],
      isLogin: false,
      rankList: [],
      activeSubAgents: [
        {
          total: 0,
          code: 'total_number___agents',
          key: 'subordinate_cnt'
        },
        {
          total: 0,
          code: 'active_user_yesterday',
          key: 'yesterday_active'
        }
      ],
      activeSubAgents2: [
        {
          total: 518,
          code: 'yesterday',
          key: 'yesterday_active'
        },
        {
          total: 8888,
          code: 'seven_day_average',
          key: 'last_7_day_active_avg'
        }
      ],
      someExamples: [
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'gold',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: goldIcon
        },
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'silver',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: sliverIcon
        },
        {
          icon: ttPokerIcon,
          icon2: ttPokerIcon2,
          name1: 'Tom Smith',
          name2: 'Student',
          descCode: 'i_get___want',
          code: 'bronze',
          amount: 88888.88888,
          amountInt: 88888,
          amountOther: '.88888',
          symbol: 'USDT',
          codeIcon: bronzeIcon
        }
      ],
      gold: {
        min: 2000,
        max: 8500,
        rate: 50
      },
      sliver: {
        min: 500,
        max: 1999,
        rate: 40
      },
      bronze: {
        min: 50,
        max: 500,
        rate: 30
      },
      chooseImgFlag: false,
      userNameEdit: false,
      userInfo: {},
      tdWidth: 0,
      bets_selected: 'yesterday_commission',
      changesList: ['yesterday_commission', 'last_7_day_commission', 'last_30_day_commission'],
      refData: {
        total_commission: [],
        yesterday_commission: [],
        last_7_day_commission: [],
        last_30_day_commission: [],
        subordinate_cnt: 0,
        yesterday_active: 0,
        last_7_day_active_avg: 0,
        last_30_day_active_avg: 0
      }
    };
  },
  mounted() {},
  created() {
    this.getRankList();
  },
  methods: {
    getHeaderImg2(headerImageUrl) {
      return getHeaderImg(headerImageUrl);
    },
    userInfoService() {
      let userId = getCookieUserId();
      userInfoService(userId)
        .then((response) => {
          this.userInfo = response.data;
          this.userInfo.email = getCookieEmail();
          this.name = this.userInfo.name;
          if (this.userInfo.referral_code) {
            this.isBind = true;
          }
          this.userInfo.link = 'https://' + window.location.hostname + '/login?referral_code=' + this.userInfo.invite_code;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    getRankList() {
      getReferralReferralRankings().then((response) => {
        this.rankList = response.data.data;
        this.updateDate = response.data.last_update;
      });
    },
    getBets() {
      getReferralGetReferralInfoService().then((response) => {
        let refData = response.data.data;
        for (let i in this.changesList) {
          let amountObjs = refData[this.changesList[i]];
          for (let j in amountObjs) {
            let amountObj = amountObjs[j];
            let amountStr = amountObj.sum_amount + '' || '0.0000';
            let amountArrStr = amountStr.split('.');
            amountObj.amountInt = amountArrStr[0];
            amountObj.amountOther = amountArrStr[1];
          }
        }
        this.refData = refData;
      });
    },
    phoneBetsChange(bets_selected) {
      this.bets_selected = bets_selected;
    },
    editHeadUrl() {
      this.chooseImgFlag = true;
    },
    privacySetting() {},
    chooseImgEvent(img) {
      if (img) {
        this.userInfo.headUrl = img;
      }
      this.chooseImgFlag = false;
    },
    getCreatedTime(time) {
      let date = new Date(time * 1000);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      // let hour = date.getHours()
      // let m = date.getMinutes()
      // let ss = date.getSeconds()
      return year + '/' + (month >= 10 ? month : '0' + month) + '/' + (day >= 10 ? day : '0' + day);
    },
    loginAfter() {
      this.isLogin = true;
    }
  }
};
</script>
<template>
  <!--  -->
  <div class="main-content" v-if="!isLogin">
    <div class="body width-100 profit referral_top">
      <div class="top web flex">
        <!-- 分叉图 -->
        <div>
          <div class="top-title">
            <img src="../../assets/images/referral/notify.svg" style="height: 1.25rem" />
            <span style="margin-left: 0.5rem">{{ t('three_tier_friends') }}</span>
          </div>
          <div class="top-body-referral">
            <div class="top-body-referral-header">
              <div class="header" style="width: 28%">
                {{ t('you') }}
              </div>
              <div class="header" style="width: 30%">
                {{ t('level_one') }}
              </div>
              <div class="header" style="width: 21%">
                {{ t('level_two') }}
              </div>
              <div class="header" style="width: 21%">
                {{ t('level_three') }}
              </div>
            </div>
            <div class="top-body-referral-header top-body-referral-body" ref="body-main">
              <img src="../../assets/images/referral/referral_ral.webp" style="max-width: 100%" />
            </div>
          </div>
        </div>
        <div class="top-line web"></div>
        <!-- how_it_works & _examples -->
        <div style="display: block" class="web">
          <div class="top-title">
            <span style="margin-right: 0.5rem">{{ t('how_it_works') }}</span>
            <img src="../../assets/images/referral/shuoming.svg" style="height: 1.25rem" />
          </div>
          <div class="answer">1.{{ t('use_your___it') }}</div>
          <div class="answer">2.{{ t('new_users___code') }}</div>
          <div class="answer">3.{{ t('you_will___wish') }}</div>

          <div class="top-title">
            <span style="margin-right: 0.5rem">{{ t('some_examples___referrals') }}</span>
          </div>

          <div class="referral_level2 no-padding-bottom-1" v-for="(example, index) in someExamples" :key="index">
            <div style="display: flex; line-height: 3rem">
              <div class="left">
                <img :src="example.icon" style="width: 2.25rem" />
              </div>
              <div style="display: block; width: 40%; line-height: 1.5rem">
                <div style="" class="name">{{ example.name1 }}</div>
                <div class="name">
                  <div style="display: flex; align-items: center">
                    <img :src="example.icon2" style="width: 0.875rem" />
                    {{ example.name2 }}
                  </div>
                </div>
              </div>
              <div class="right" style="display: block; width: calc(60% - 3rem); text-align: right; line-height: 1.5rem">
                <div class="phone web">
                  <img :src="example.codeIcon" style="width: 1rem" />
                  <span :class="example.code">{{ t(example.code) }}</span>
                </div>
                <div class="get_rebate">
                  {{ t('get_rebate') }}
                  <span style="color: var(--amount-text-color)">{{ example.amountInt }}</span>
                  <span style="color: var(--amount-text-other-color)">{{ example.amountOther }}</span>
                  <span style="color: #34e6a8; margin-left: 5px">{{ example.symbol }}</span>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="desc">
                {{ t(example.descCode) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 广告位 -->
  <div class="main-content web">
    <div class="body width-100">
      <div style="">
        <div class="pt-[1.2rem]"></div>
        <img v-if="!isLogin" class="web" src="@/assets/images/referral/banner.webp" style="width: 100%" />
      </div>
    </div>
  </div>
  <!-- RANK -->
  <div class="main-content" v-if="!isLogin">
    <div class="body width-100 profit referral_top">
      <div class="top web record">
        <div style="display: block">
          <div class="top-title" style="padding: 0">
            <div style="float: left; width: 40%; text-align: left">
              <img src="../../assets/images/referral/referral-gold.png" style="width: 1rem" />
              <span style="margin-left: 0.5rem">{{ t('rank') }}</span>
            </div>
            <div style="float: right; width: 60%; text-align: right; font-size: 1.125rem; font-weight: 700; letter-spacing: 0em; color: var(--color-55657E)">{{ $t('updated_date') }}{{ getCreatedTime(updateDate) }}</div>
          </div>
          <div class="text-[#8ea3c3] text-[14px] font-[700] flex justify-between items-center px-[15px] bg-[#222942]">
            <div class="w-[35%] text-left">RANK</div>
            <div class="w-[40%] text-left">PLAYER</div>
            <div class="w-[35%] text-right">PAYOUT</div>
          </div>
          <div :class="'referral_level2 ' + (index % 2 == 0 ? ' single' : '')" v-for="(rank, index) in rankList" :key="index">
            <div class="text-[#8ea3c3] flex justify-between items-center px-[15px]">
              <div class="flex items-center w-[35%]">
                <img v-if="index == 0" src="@/assets/images/referral/referral-gold.png" class="w-[16px] h-[16px] mr-[5px]" />
                <img v-else-if="index == 1" src="@/assets/images/referral/referral-sliver.png" class="w-[16px] h-[16px] mr-[5px]" />
                <img v-else-if="index == 2" src="@/assets/images/referral/referral-bronze.png" class="w-[16px] h-[16px] mr-[5px]" />
                <img v-else src="" class="w-[16px] h-[16px] mr-[5px]" />
                <div class="" style="width: auto">
                  {{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}
                </div>
              </div>
              <div class="w-[40%]">
                <div class="name">
                  <div style="display: flex; align-items: center; justify-content: left">
                    <img :src="getHeaderImg2(rank.player.profile_image_url)" style="width: 1.125rem; height: 1.125rem" />
                    {{ rank.player.name }}
                  </div>
                </div>
              </div>
              <div class="w-[35%]">
                <div style="display: flex; align-items: center; justify-content: flex-end">
                  <img :src="token1" style="width: 1.125rem; height: 1.125rem; margin-right: 0.5rem" />
                  {{ convertCurrency(rank.total_earning, 4) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 手机端-分叉图 -->
  <div class="body width-100 profit referral_top phone">
    <div class="top">
      <div class="top-title">
        <img src="../../assets/images/referral/notify.svg" style="height: 1.25rem" />
        <span style="margin-left: 0.5rem">{{ t('three_tier_friends') }}</span>
      </div>
      <div class="top-body-referral">
        <div class="top-body-referral-header">
          <div class="header" style="width: 28%">
            {{ t('you') }}
          </div>
          <div class="header" style="width: 30%">
            {{ t('level_one') }}
          </div>
          <div class="header" style="width: 21%">
            {{ t('level_two') }}
          </div>
          <div class="header" style="width: 21%">
            {{ t('level_three') }}
          </div>
        </div>
        <div class="top-body-referral-header top-body-referral-body" ref="body-main">
          <img src="../../assets/images/referral/referral_ral.webp" style="max-width: 100%" />
        </div>
      </div>
      <div style="display: block" class="web">
        <div class="top-title">
          <span style="margin-right: 0.5rem">{{ t('how_it_works') }}</span>
          <img src="../../assets/images/referral/shuoming.svg" style="height: 1.25rem" />
        </div>
        <div class="answer">1.{{ t('use_your___it') }}</div>
        <div class="answer">2.{{ t('new_users___code') }}</div>
        <div class="answer">3.{{ t('you_will___wish') }}</div>

        <div class="top-title">
          <span style="margin-right: 0.5rem">{{ t('some_examples___referrals') }}</span>
        </div>

        <div class="referral_level2 no-padding-bottom-1" v-for="(example, index) in someExamples" :key="index">
          <div style="display: flex; line-height: 3rem">
            <div class="left">
              <img :src="example.icon" style="width: 2.25rem" />
            </div>
            <div style="display: block; width: 40%; line-height: 1.5rem">
              <div style="" class="name">{{ example.name1 }}</div>
              <div class="name">
                <div style="display: flex; align-items: center">
                  <img :src="example.icon2" style="width: 0.875rem" />
                  {{ example.name2 }}
                </div>
              </div>
            </div>
            <div class="right" style="display: block; width: calc(60% - 3rem); text-align: right; line-height: 1.5rem">
              <div class="phone web">
                <img :src="example.codeIcon" style="width: 1rem" />
                <span :class="example.code">{{ t(example.code) }}</span>
              </div>
              <div class="get_rebate">
                {{ t('get_rebate') }}
                <span style="color: var(--amount-text-color)">{{ example.amountInt }}</span>
                <span style="color: var(--amount-text-other-color)">{{ example.amountOther }}</span>
                <span style="color: var(--gold-color)">{{ example.symbol }}</span>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="desc">
              {{ t(example.descCode) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 手机端-how_it_works -->
  <div class="main-content phone">
    <div class="body width-100 profit referral_top">
      <div class="top phone">
        <div style="display: block">
          <div class="top-title">
            <span style="margin-right: 0.5rem">{{ t('how_it_works') }}</span>
            <img src="../../assets/images/referral/shuoming.svg" style="height: 1.25rem" />
          </div>
          <div class="answer">1.{{ t('use_your___it') }}</div>
          <div class="answer">2.{{ t('new_users___code') }}</div>
          <div class="answer">3.{{ t('you_will___wish') }}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 手机端-examples -->
  <div class="main-content">
    <div class="body width-100 referral_top">
      <div class="top phone">
        <div class="top-title">
          <span style="margin-right: 0.5rem">{{ t('some_examples___referrals') }}</span>
        </div>

        <div class="referral_level2 no-padding-bottom-1" v-for="(example, index) in someExamples" :key="index">
          <div style="display: flex; line-height: 3rem">
            <div class="left">
              <img :src="example.icon" style="width: 2.25rem" />
            </div>
            <div style="display: block; width: 70%; line-height: 1.5rem; padding-left: 0.5rem">
              <div style="" class="name">{{ example.name1 }}</div>
              <div class="name">
                <div style="display: flex; align-items: center">
                  <img :src="example.icon2" style="width: 0.875rem" />
                  {{ example.name2 }}
                </div>
              </div>
            </div>
            <div class="right" style="display: block; width: calc(60% - 3rem); text-align: right; line-height: 1.5rem">
              <div class="phone web">
                <img :src="example.codeIcon" style="width: 1rem" />
                <span :class="example.code">{{ t(example.code) }}</span>
              </div>
              <div class="get_rebate">
                <div style="" class="name">
                  {{ t('get_rebate') }}
                </div>
                <div class="name">
                  <div style="display: flex; align-items: center">
                    <span style="color: var(--amount-text-color)">{{ example.amountInt }}</span>
                    <span style="color: var(--amount-text-other-color)">{{ example.amountOther }}</span>
                    <span style="color: var(--gold-color)">{{ example.symbol }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="desc">
              {{ t(example.descCode) }}
            </div>
          </div>
        </div>
        <div style="display: block" v-if="false">
          <div class="top-title">
            <img src="../../assets/images/referral/referral-gold.png" style="height: 1.25rem" />
            <span style="margin-left: 0.5rem">{{ t('how_many___get') }}</span>
          </div>
          <div class="answer">
            {{ t('different_levels___promotion') }}
          </div>

          <div class="referral_level">
            <div class="level">
              <div class="left">
                <img src="../../assets/images/referral/referral-gold.png" style="width: 1.5rem" />
                <span class="gold">{{ t('gold') }}</span>
              </div>
              <div class="right">
                {{ t('rebate_ratio') }}<span class="gold">{{ gold.rate }}%</span>
              </div>
            </div>
            <div class="bottom">
              <div class="desc" style="line-height: 1.2rem; text-align: left; color: var(--color-8ea3c3)">{{ t('registration_reaches') }}{{ gold.min }}{{ t('people_rebate_reaches') }}USD {{ gold.max }}</div>
            </div>
          </div>

          <div class="referral_level">
            <div class="level">
              <div class="left">
                <img src="../../assets/images/referral/referral-sliver.png" style="width: 1.5rem" />
                <span class="gold">{{ t('sliver') }}</span>
              </div>
              <div class="right">
                {{ t('rebate_ratio') }}<span class="gold">{{ sliver.rate }}%</span>
              </div>
            </div>
            <div class="bottom">
              <div class="desc" style="line-height: 1.2rem; text-align: left; color: var(--color-8ea3c3)">{{ t('registration_reaches') }}{{ sliver.min }}{{ t('people_rebate_reaches') }}USD {{ sliver.max }}</div>
            </div>
          </div>

          <div class="referral_level">
            <div class="level">
              <div class="left">
                <img src="../../assets/images/referral/referral-bronze.png" style="width: 1.5rem" />
                <span class="gold">{{ t('space') }}</span>
              </div>
              <div class="right">
                {{ t('rebate_ratio') }}<span class="gold">{{ bronze.rate }}%</span>
              </div>
            </div>
            <div class="bottom">
              <div class="desc" style="line-height: 1.2rem; text-align: left; color: var(--color-8ea3c3)">{{ t('registration_reaches') }}{{ bronze.min }}{{ t('people_rebate_reaches') }}USD {{ bronze.max }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 手机端-广告位 -->
  <div class="main-content phone">
    <img v-if="!isLogin" class="w-full px-[0.25rem] box-border" src="@/assets/images/referral/banne_phone.webp" />
  </div>
  <!-- 手机端-RANK -->
  <div class="main-content top-1rem" v-if="!isLogin">
    <div class="body width-100 profit referral_top bottom-1rem">
      <div class="top top2 phone record">
        <div style="display: block">
          <div class="top-title line-height-2_5rem" style="padding-bottom: 0">
            <div style="float: left; width: 40%; text-align: left">
              <img src="../../assets/images/referral/referral-gold.png" style="width: 1rem" />
              <span style="margin-left: 0.5rem">{{ t('rank') }}</span>
            </div>
            <div style="float: right; width: 60%; text-align: right; font-family: 'Poppins', sans-serif; font-size: 0.875rem; font-weight: 600; letter-spacing: 0em; color: var(--color-8ea3c3)">{{ $t('updated_date') }}{{ getCreatedTime(updateDate) }}</div>
          </div>
          <div class="text-[#8ea3c3] text-[12px] font-[700] flex justify-between items-center px-[15px] bg-[#222942] p-[10px]">
            <div class="w-[35%] text-left">RANK</div>
            <div class="w-[40%] text-left">PLAYER</div>
            <div class="w-[35%] text-right">PAYOUT</div>
          </div>
          <div :class="'referral_level2 ' + (index % 2 == 0 ? ' single' : '')" v-for="(rank, index) in rankList" :key="index">
            <div style="display: flex; padding: 0px 1.125rem; justify-content: space-between; align-items: center; flex: 1 0 0; align-self: stretch">
              <img v-if="index == 0" src="@/assets/images/referral/referral-gold.png" class="w-[14px] h-[14px] mr-[5px]" />
              <img v-else-if="index == 1" src="@/assets/images/referral/referral-sliver.png" class="w-[14px] h-[14px] mr-[5px]" />
              <img v-else-if="index == 2" src="@/assets/images/referral/referral-bronze.png" class="w-[14px] h-[14px] mr-[5px]" />
              <img v-else src="" class="w-[14px] h-[14px] mr-[5px]" />
              <div class="left name" style="width: auto">
                {{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}
              </div>
              <div style="display: block; width: 60%; line-height: 1.5rem; text-align: left; padding-left: 3rem">
                <div class="name" style="justify-content: left">
                  <div style="display: flex; align-items: center; justify-content: left">
                    <img :src="getHeaderImg2(rank.player.profile_image_url)" style="width: 1.125rem; height: 1.125rem" />
                    {{ rank.player.name }}
                  </div>
                </div>
              </div>
              <div class="right name" style="justify-content: right; width: 40%; text-align: left; line-height: 1.5rem">
                {{ convertCurrency(rank.total_earning, 4) }}
                <img :src="token1" style="width: 1.125rem; height: 1.125rem; margin-left: 0.5rem" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.referral_top .tool-bar .tool-bar-tabs {
  background-color: var(--referral-data-tab-bg-color);
  padding: 0.321rem 0.5rem;
}
.web .referral_top .top {
  height: calc(100% - 3.5rem);
  width: calc(100% - 3.5rem);
  padding: 1rem;
}
.web .referral_top .top-line {
  height: auto;
}
.phone .input-div .input input {
  width: calc(100% - 3rem);
}
.phone .input-div .input.referral {
  justify-content: left;
  margin-left: 0.5rem;
  text-align: center;
}
.tool-bar .tool-bar-tabs {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 2.5rem;
}
.tool-bar .tool-bar-tabs div {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 2.5rem;
}
.tool-bar .tool-bar-tabs .item.first {
  margin-left: 0;
}
.referral_level2 {
  padding: 0.2rem 0;
}
.record .referral_level2 .left {
  height: 4.625rem;
  line-height: 4.625rem;
}
.record .referral_level2.single {
  background-color: var(--profit-bg-color);
}
.record .referral_level2 .name {
  height: 4.625rem;
  line-height: 4.625rem;
}
.referral_level2.no-padding-bottom-1 {
  padding: 1rem 0;
  /* border-bottom: 1px solid var(--some_examples-bottom-line-color); */
}
.phone .referral_level2 .name {
  text-align: left;
  color: var(--color-8ea3c3);
  leading-trim: both;
  text-edge: cap;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  align-items: center;
  justify-content: left;
  display: flex;
}
.phone .referral_level2 .name.right {
  justify-content: right;
}
.referral_level2 .name2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  padding: 0.3rem;
}
.web .symbol_block {
  display: flex;
  white-space: nowrap;
  width: 100%;
  line-height: 4rem;
  min-height: 4rem;
  overflow-x: auto;
  border-radius: 0.5rem;
  background-color: var(--profile-symbol-bg-color);
}
.web .symbol_block .symbol_amount {
  display: block;
  width: 23%;
  line-height: 4rem;
  height: 4rem;
  align-items: center;
  float: left;
  padding: 0 1%;
}

.web .symbol_block .symbol_amount .name img {
  width: 2rem;
}

.phone .symbol_block {
  display: block;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  overflow-x: auto;
}
.phone .symbol_block .symbol_amount {
  display: inline-block;
  width: 45%;
  line-height: 4rem;
  height: 4rem;
  align-items: center;
  background-color: var(--profile-symbol-bg-color);
  margin: 0 1%;
  border-radius: 0.5rem;
}
.phone .symbol_block .symbol_amount .name img {
  width: 1.5rem;
}
.get_rebate {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: right;
  color: var(--color-8ea3c3) !important;
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
}
.phone .get_rebate {
  display: block;
}
.web .referral_top .top .top-body-referral .top-body-referral-header.top-body-referral-body {
  height: calc(100% - 2rem);
  white-space: normal;
}
.phone .main-content .body {
  padding: 0 0.5rem;
  width: calc(98% - 1rem);
}
.phone .referral_top {
  background: var(--referral-bg-color);
  border-radius: 0.5rem;
}
.record .referral_level2 {
  padding: 0;
}
</style>
