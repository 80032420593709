<!-- eslint-disable vue/no-dupe-keys -->
<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, onUnmounted, ref, onMounted, watchEffect } from 'vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import wgTypePass from '@/components/common/wgTypeWithdrawPass.vue';
import symbolSearch from '@/components/symbol/symbolSearch.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { toast } from 'vue3-toastify';
import myPage from '@/components/page/myPage.vue';
import { convertCurrency } from '@/assets/tools/CommonTool.js';
import { fiat_withdraw, getFiatWithdrawList } from '@/assets/network/service/wallet';
// import { toast } from 'vue3-toastify';
const confirmLoadingJson = ref(loadingAniJson);

import { fiat_pre_withdraw } from '@/assets/network/service/wallet';
import { gaBeginCheckoutEvent } from '@/assets/tools/GoogleAnalyticsEvent';
const props = defineProps({
  show: Boolean,
  addAccountNum: {
    type: Number,
    default: () => 0
  },
  confirming: {
    type: Boolean,
    default: () => false
  },
  is_otp: {
    type: Boolean,
    default: () => false
  },
  symbols: {
    type: Array,
    default: () => []
  },
  symbol: {
    type: Object,
    default: () => {}
  },
  network: {
    type: Object,
    default: () => {}
  },
  page: {
    type: String,
    default: () => 'withdraw'
  }
});
const emit = defineEmits(['close', 'selectSymbol', 'withdrawSuccess', 'addAccount']);
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  enter_MFA_PIN: t('enter_MFA_PIN'),
  w_pin_pass_first: t('w_pin_pass_first'),
  set_withdrawal_PIN: t('set_withdrawal_PIN'),
  for_your_account_safety: t('for_your_account_safety'),
  input_google_authenticator: t('input_google_authenticator'),
  forgot_password: t('forgot_password'),
  confirm: t('confirm'),
  the_password___inconsistent: t('the_password___inconsistent'),
  withdraw_successfully: t('withdraw_successfully'),
  withdraw_PIN: t('withdraw_PIN'),
  the_remaining_wagering_amount: t('the_remaining_wagering_amount'),
  add_withdrawal_account: t('add_withdrawal_account'),
  amount: t('amount')
};

const passWord = ref('');

const passWordChange = (val) => {
  passWord.value = val;
};
const showAccount = ref(false);
const isSelectSymbol = ref(false);
const refreshData = () => {
  getPixList(true);
  getWithdrawList();
};
const pixList = ref([]);
const thisPix = ref({});
const amount = ref('');
const lastSymbol = ref('');
const closeSymbolSearch = (symbol) => {
  console.log(symbol);
  isSelectSymbol.value = false;
  if (symbol && symbol.symbol) {
    // 切换币种
    console.log(symbol);
    if (symbol.is_fiat) {
      getPixList();
    }
    emit('selectSymbol', symbol);
  }
};
const addAccount = () => {
  if (pixList.value.length > 0) {
    emit('addAccount', pixList.value[0]);
  } else {
    emit('addAccount');
  }
};
onUnmounted(() => {});
const getPixList = async (isCheck) => {
  if (!isCheck) {
    if (lastSymbol.value == props.symbol.symbol) {
      return;
    }
  }
  try {
    const response = await fiat_pre_withdraw({ symbol: props.symbol.symbol });
    lastSymbol.value = props.symbol.symbol;
    console.log(response);
    pixList.value = response.data.data.pay_info;
    if (pixList.value && pixList.value.length > 0) {
      thisPix.value = pixList.value[0];
    }
  } catch (error) {
    console.log(error);
  }
};
const w_list = ref([]);
const page_size = ref(4);
const page_num = ref(1);
const totalRow = ref(0);
const totalPage = ref(0);
const paginationChange = async (pageNum) => {
  page_num.value = pageNum;
  getWithdrawList();
};
const totalAmount = ref(0);
const getWithdrawList = async () => {
  try {
    const response = await getFiatWithdrawList({
      symbol: props.symbol.symbol,
      page_num: page_num.value,
      page_size: page_size.value
    });
    totalRow.value = response.data.data.total_count;
    totalPage.value = Math.ceil(totalRow.value / page_size.value);
    totalAmount.value = response.data.data.total_withdraw_amount;
    console.log(response);
    w_list.value = response.data.data.data;
  } catch (error) {
    console.log(error);
  }
};
const errorText = ref('');
const checkAmount = () => {
  errorText.value = '';
  if (isNaN(amount.value)) {
    return;
  }
  if (Number(amount.value) < props.network.min_withdraw_amount) {
    errorText.value = t('the_minimum_withdrawal') + ' ' + props.network.min_withdraw_amount + props.symbol.symbol;
  }
  if (!isNaN(props.network.max_withdraw_amount) && Number(amount.value) > Number(props.network.max_withdraw_amount)) {
    errorText.value = t('the_maximum_withdrawal') + ' ' + props.network.max_withdraw_amount + props.symbol.symbol;
  }
};
const handleCanNext = () => {
  if (!thisPix.value.pay_id) {
    return false;
  }
  if (isNaN(amount.value)) {
    return false;
  }
  if (Number(amount.value) < props.network.min_withdraw_amount) {
    return false;
  }
  if (!isNaN(props.network.max_withdraw_amount) && Number(amount.value) > Number(props.network.max_withdraw_amount)) {
    return false;
  }
  if (passWord.value.length !== 6) {
    return false;
  }
  return true;
};

const handleClick = async () => {
  let params = {
    pay_id: thisPix.value.pay_id,
    amount: Number(amount.value),
    symbol: props.symbol.symbol
  };
  if (props.is_otp) {
    params.otp = passWord.value;
  } else {
    params.withdraw_password = passWord.value;
  }
  try {
    await fiat_withdraw(params);
    gaBeginCheckoutEvent({ currency: props.symbol.symbol, value: Number(amount.value)});
    toast(textData.withdraw_successfully, { autoClose: 2000, type: 'success' });
    emit('withdrawSuccess');
  } catch (error) {
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 2000, type: 'error' });
  }
};
onMounted(() => {
  addAccountNum.value = props.addAccountNum;
  getPixList();
  getWithdrawList();
});
// eslint-disable-next-line vue/no-dupe-keys
const addAccountNum = ref(0);
watchEffect(() => {
  // 添加了新账户，和切换了币种的标识
  if (props.addAccountNum > addAccountNum.value) {
    addAccountNum.value = props.addAccountNum;
    getPixList(true);
    getWithdrawList();
  }
});
const getCreatedTime = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  return (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) + '/' + (day >= 10 ? day : '0' + day) + '/' + year;
};
const getStatusCode = (status) => {
  if (status == 1) {
    return 'withdraw_deposit_status_complete';
  } else if (status == 2 || status == 3) {
    return 'withdraw_deposit_status_in_processed';
  }
  return 'withdraw_deposit_status_failed';
};

const formatNumber = (account_id) => {
  if (!account_id || account_id.length < 4) {
    return account_id ? account_id : '';
  }
  return '*******' + account_id.substring(account_id.length - 4);
};
</script>

<template>
  <div v-show="props.show">
    <div :class="'content ' + (matchSM ? 'matchSM' : '')">
      <div class="content_main">
        <div class="balance-block">
          <div class="balance">
            <div class="balance" @click="isSelectSymbol = true">
              {{ convertCurrency(props.symbol.balance) }}
              <img :src="props.symbol.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
            </div>
            <div class="balance" @click="isSelectSymbol = true">
              <svg width="14" height="11" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.800001 0.6L6.4 9L12 0.600001" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="refresh">
            <svg @click.stop="refreshData" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
              <path
                d="M4.76065 19.9763L9.74801 17.2594L12.0312 19.7403C14.0721 19.7403 16.0364 18.9623 17.5415 17.5847C20.3095 15.0336 21.011 10.9646 19.4293 7.74391C19.1679 7.22094 19.3145 6.58317 19.7482 6.18775C20.4051 5.58187 21.483 5.74769 21.8784 6.5449C23.9894 10.8371 23.0965 16.2135 19.4038 19.6128C15.3094 23.382 8.93804 23.4139 4.76065 19.9827V19.9763ZM4.47366 3.38157C8.56176 -0.381272 14.9331 -0.41316 19.1168 3.01804L14.1295 5.73493L11.8463 3.25401C9.80541 3.25401 7.84108 4.03209 6.33594 5.40967C3.56802 7.96075 2.86648 12.0297 4.44814 15.2504C4.69687 15.7862 4.56932 16.4176 4.12926 16.813C3.47236 17.4189 2.39453 17.253 1.99911 16.4558C-0.111905 12.1636 0.780972 6.78088 4.47366 3.38157Z"
                fill="var(--brl-refresh-icon-bg-color)"
              />
            </svg>
          </div>
        </div>

        <div class="flex-start" v-if="isSelectSymbol">
          <symbol-search :isSelectSymbol="isSelectSymbol" :symbols="props.symbols" :symbol="props.symbol" @close="closeSymbolSearch"></symbol-search>
        </div>
        <div class="brl-withdraw-content" v-if="(props.page == 'manage_account' || showAccount) && props.page !== 'withdraw_records'">
          <div class="account-title">
            <span v-if="props.symbol.symbol == 'BRL'">PIX</span>
            <span v-if="props.symbol.symbol == 'MXN'">MX$ ACCOUNT</span>
            <span style="color: var(--color-8ea3c3)">({{ pixList.length }}/4)</span>
          </div>
          <div
            class="brl-input brl-input2"
            v-for="(pix_item, index) in pixList"
            :key="index"
            @click="
              thisPix = pix_item;
              showAccount = !showAccount;
            "
          >
            <div>
              <img :src="props.symbol.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
            </div>
            <div class="main">
              <span>{{ formatNumber(pix_item.account_id) }}</span
              >{{ pix_item.account_type }}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="brl-input brl-input2" v-if="pixList.length < 4" :key="index" @click="addAccount">
            <div>
              <img :src="props.symbol.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
            </div>
            <div class="main">
              <span v-if="props.symbol.symbol == 'BRL'">{{ $t('add_pix') }}</span>
              <span v-if="props.symbol.symbol == 'MXN'">{{ $t('add_bank_account') }}</span>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="brl-withdraw-content" v-if="props.page == 'withdraw' && !showAccount">
          <div class="tip">
            {{ textData.the_remaining_wagering_amount + ' ' }}
            <div v-if="props.symbol.symbol == 'BRL'">R$</div>
            <div v-if="props.symbol.symbol == 'MXN'">MX$</div>
            {{ props.network.min_withdraw_amount }}
          </div>
          <div class="brl-input" v-if="pixList.length == 0" @click="showAccount = true">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                <path
                  d="M15.3775 9.29518C17.9197 9.29518 19.9799 11.3554 19.9799 13.8976C19.9799 16.4398 17.9197 18.5 15.3775 18.5C12.8353 18.5 10.7751 16.4398 10.7751 13.8976C10.7751 11.3554 12.8353 9.29518 15.3775 9.29518ZM15.3775 11.1667C15.004 11.1667 14.6908 11.4398 14.6386 11.8092L14.6305 11.9096V13.1506H13.3896L13.2892 13.1586C12.9197 13.2108 12.6466 13.5241 12.6466 13.8976C12.6466 14.2711 12.9197 14.5843 13.2892 14.6365L13.3896 14.6446H14.6305V15.8855L14.6386 15.9859C14.6908 16.3554 15.004 16.6285 15.3775 16.6285C15.751 16.6285 16.0643 16.3554 16.1165 15.9859L16.1245 15.8855V14.6446H17.3655L17.4659 14.6365C17.8353 14.5843 18.1084 14.2711 18.1084 13.8976C18.1084 13.5241 17.8353 13.2108 17.4659 13.1586L17.3655 13.1506H16.1245V11.9096L16.1165 11.8092C16.0643 11.4398 15.751 11.1667 15.3775 11.1667ZM19.8434 7.44779V10.757C18.9116 8.94177 17.0482 7.79719 15.008 7.78916C12.9679 7.78112 11.0964 8.90964 10.1486 10.7169C9.20482 12.5241 9.34538 14.7088 10.5141 16.3755H2.9759C1.33333 16.3755 0 15.0422 0 13.3996V7.44779H19.8434ZM16.8675 0.5C18.51 0.5 19.8434 1.83333 19.8434 3.4759V5.45984H0V3.4759C0 1.83333 1.33333 0.5 2.9759 0.5H16.8675Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              {{ textData.add_withdrawal_account }}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="brl-input" v-if="pixList.length > 0" @click="showAccount = true">
            <div>
              <img :src="props.symbol.icon_url" style="width: 0.875rem; margin-left: 0.3rem" />
            </div>
            <div class="main">
              <span v-if="props.symbol.symbol == 'BRL'">PIX({{ formatNumber(thisPix.account_id) }})</span>
              <span v-if="props.symbol.symbol == 'MXN'">{{ formatNumber(thisPix.account_id) }}</span>
              {{ thisPix.account_type }}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="brl-input">
            <div class="money-unit" v-if="props.symbol.symbol == 'BRL'">R$</div>
            <div class="money-unit" v-else>MX$</div>
            <div class="main">
              <input v-model="amount" @keyup="checkAmount" :placeholder="textData.amount" />
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>

          <div style="display: flex; padding: 0.5rem 0" v-if="errorText">
            <img src="../../assets/images/login-and-register/login-and-register-input-tip.svg" style="width: 1.125rem" />
            <div style="" class="brl-error-tip">
              {{ errorText }}
            </div>
          </div>
          <div class="fee" v-if="amount">{{ $t('fee') }}≈{{ network.withdraw_fee }}</div>
          <div class="line"></div>
          <wgTypePass :text-title="textData.withdraw_PIN" @onValueChange="passWordChange" :pre-id="'w-pass-setting'"></wgTypePass>
          <div class="bottom">
            <button :class="handleCanNext() ? 'bottom-button' : 'bottom-button-disable'" @click="handleClick" :disabled="props.confirming || !handleCanNext()">
              {{ props.confirming ? '' : textData.confirm }}
              <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="props.confirming"></vue3-lottie>
            </button>
          </div>
        </div>

        <div class="brl-withdraw-content" v-if="props.page == 'withdraw_records'">
          <div class="brl-withdraw-title">
            <span>{{ $t('total_withdraw') }}</span>
            <div v-if="props.symbol.symbol == 'BRL'">R$</div>
            <div v-if="props.symbol.symbol == 'MXN'">MX$</div>
            {{ totalAmount }}
          </div>
          <div class="w-record title">
            <div>
              {{ $t('time') }}
            </div>
            <div>
              {{ $t('amount') }}
            </div>
            <div v-if="$i18n.locale == 'en'">
              Withdrawal<br />
              Method
            </div>
            <div v-if="$i18n.locale == 'es'">Método de<br />Retiro</div>
            <div v-if="$i18n.locale == 'ko' || $i18n.locale == 'zh'">
              {{ $t('withdrawal_method') }}
            </div>
            <div v-if="$i18n.locale == 'pt'">Método de<br />Retirada</div>
            <div v-if="$i18n.locale == 'tr'">Çekim<br />Yöntemi</div>
            <div>
              {{ $t('status') }}
            </div>
          </div>
          <div v-for="(w, index) in w_list" :key="index" :class="'w-record' + (index == w_list - 1 ? ' last' : '')">
            <div>
              {{ getCreatedTime(new Date(w.created_at * 1000)) }}
            </div>
            <div style="display: flex; align-items: center">
              <img :src="props.symbol.icon_url" style="width: 1.125rem; height: 1.125rem; margin-left: 0.3rem" />
              {{ w.amount }}
            </div>

            <div>
              {{ w.withdraw_method }}
            </div>
            <div>
              {{ $t(getStatusCode(w.status)) }}
            </div>
          </div>

          <div style="width: 100%; justify-content: center; align-items: center" v-if="w_list.length == 0">
            <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; margin-top: 6rem">
              <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
            </div>
            <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; color: var(--no-data-color); margin-bottom: 6rem">
              {{ $t('no_data') }}
            </div>
          </div>
          <my-page @pageChange="paginationChange" :total-count="totalRow" :page="page_num" :page-size="page_size" :all-page="totalPage"> </my-page>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1.125rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content_main {
  padding: 0 0.625rem;
  width: calc(100% - 1.5rem);
}
.content.matchSM {
  width: calc(100vw - 2.75rem);
  padding: 1.125rem 0.875rem;
}
.content.matchSM .brl-withdraw-content {
  width: calc(100vw - 3.5rem - 1.5rem);
}
.brl-withdraw-content {
  width: 25rem;
  padding: 0.625rem;
  border-radius: 0.5rem;
  background: var(--deposit-withdraw-block-bg-color);
  margin-top: 0.75rem;
  min-height: 15rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: left;
  display: flex;
  align-items: center;
}
.setting-type {
  color: var(--white-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-button-disable {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}
.margin-bottom-4_6 {
  margin-bottom: 4.6rem;
}
.verify-code-button {
  background-color: var(--send-code-btn);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  color: var(--default-btn-color);
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.balance-block {
  color: var(--brl-balance-block-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.balance {
  display: flex;
  align-items: center;
}
.balance img {
  padding-left: 0.625rem;
}
.refresh {
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
::v-deep .flex-start .deposit {
  justify-content: flex-start;
}
.brl-input {
  margin-top: 1.125rem;
  display: flex;
  height: 2.5rem;
  padding: 0px 1.125rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--color-55657E);
  background: var(--input-default-bg-color);
  cursor: pointer;
}

.brl-input.brl-input2 {
  height: 3.75rem;
  background: var(--deposit-symbol-unselected-block-border-color);
}
.brl-input.brl-input2:hover {
  border: 1px solid var(--bg-blue-color);
}
.brl-input div {
  display: flex;
  align-items: center;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.brl-input .money-unit {
  color: var(--brl-input-money-unit);
  font-size: 0.875rem;
  font-weight: 700;
}
.brl-input .main {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
}
.brl-input span {
  color: var(--brl-input-color);
  padding-right: 0.5rem;
}
.brl-input .main input {
  width: 100%;
  border: 0;
  color: var(--brl-input-color);
  font-size: 0.875rem;
  font-weight: 700;
}
input::placeholder {
  color: var(--placeholder-color);
  font-size: 0.875rem;
  font-weight: 700;
}
.line {
  margin-top: 1.125rem;
  height: 1.71px;
  align-self: stretch;
  background-color: var(--brl-line-bg-color);
}
.account-title {
  text-align: left;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.brl-error-tip {
  display: flex;
  padding-left: 0.5rem;
  text-align: left;
  color: var(--error-tip-color);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.fee {
  display: flex;
  padding: 0.5rem 0;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.w-record {
  color: var(--color-8ea3c3);
  padding: 1.125rem 0.5rem;
  display: flex;
  align-items: center;
  background-color: var(--profit-single-bg-color);

  align-self: stretch;

  display: flex;
  height: 3.75rem;
  padding: 0px 0.5rem;
  align-items: center;
}
.w-record.last {
  border-radius: 0 0 0.5rem 0.5rem;
}
.w-record div {
  white-space: nowrap;
  width: 25%;
}
.w-record.title {
  align-items: center;
  background-color: var(--header-bg-color);
}
.brl-withdraw-title {
  margin-bottom: 0.625rem;
  text-align: left;
  color: var(--white-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  justify-content: left;
  align-items: center;
}
.brl-withdraw-title span {
  color: var(--color-8ea3c3);
}
</style>
