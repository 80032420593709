<template>
    <div :class="['vue-lucky-wheel-item', { 'default-background': defaultBackground }]" :style="style">
        <div class="vue-lucky-wheel-item-background" :style="styleBackground">
        </div>
        <div class="vue-lucky-wheel-item-content flex justify-center items-center pb-[3.6rem]" :style="styleContent">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from 'vue'

const props = defineProps({
    defaultBackground: Boolean,
    bgColor: {
        type: String
    },
    total: {
        type: Number
    },
    index: {
        type: Number
    }
})

const parent = inject('parent', {})
const itemNumber = ref(0)
const size = ref(parent.size || 300)
const defaultBackground = ref(props.defaultBackground || parent.defaultBackground)
// const index = ref(0)
watch(() => props.total, (val) => {
    itemNumber.value = val
}, {
    immediate: true
})
const centerAngle = computed(() => 360 / itemNumber.value)

const style = computed(() => ({
    transform: `rotate(${centerAngle.value * props.index}deg)`,
    width: `${size.value / 2}px`,
    height: `${size.value / 2}px`,
}))

const styleBackground = computed(() => ({
    transform: `skewY(-${90 - centerAngle.value}deg)`,
    width: `${size.value / 2}px`,
    height: `${size.value / 2}px`,
    background: props.bgColor,
    'border-left': "3px solid #07022C",
}))

const styleContent = computed(() => ({
    transform: `translateX(-50%) rotate(${centerAngle.value / 2}deg)`,
    width: `${2 * (size.value / 2) * Math.sin(centerAngle.value / 2 * (Math.PI / 180))}px`,
    height: `${size.value / 2}px`,
}))

onMounted(() => {
    // itemNumber.value = parent.$children.length

    // You can determine the index by iterating through the parent's slots or items and match based on reference
    // index.value = parent.$children.indexOf(parent.$children.find(child => child === parent.$el.__vue__))
})
</script>

<style scoped>
@tailwind base;

.vue-lucky-wheel-item {
    position: absolute;
    top: 0;
    left: 50%;
    transform-origin: 0 100%;
}

/* .vue-lucky-wheel-item-background {
    background-color: #FFCC72;
} */

.vue-lucky-wheel-item-background {
    transform-origin: 0 100%;
}

.vue-lucky-wheel-item-content {
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: 50% 100%;
}
</style>