<script setup>
// 导入
import { defineProps } from 'vue';
// 传参
const props = defineProps({
  btnText: {
    type: String,
    default: 'Button'
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
// 属性
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="my-btn-item my-btn-shadow" :class="disabled ? 'my-btn-disabled' : ''">
    {{ props.btnText }}
  </div>
</template>

<style scoped>
.my-btn-item {
  display: flex;
  height: 40px;
  width: 126px;
  min-width: 126px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
}
.my-btn-shadow {
  border-radius: 8px;
  background: #1897FB;
  box-shadow: 0px 0px 10px 0px rgba(0, 10, 255, 0.60) inset, 0px -3px 0px 0px rgba(74, 78, 163, 0.55) inset, 0px 0px 10px 0px #0057FF;
  color: #FFFFFF;
}
.my-btn-disabled {
  cursor: not-allowed;
  background-color: #3aa1ffaa;
  color: #121829aa;
}
</style>
