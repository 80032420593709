<script setup>
import { ref, defineProps, defineEmits, watchEffect } from 'vue';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import pageLoading from '../loading/pageLoading.vue';

const props = defineProps({
  show: Boolean
});
const emit = defineEmits(['verified']);

const isLoading = ref(false);
watchEffect(() => {
  isLoading.value = props.show;
});

const handleOnVerify = (token, eKey) => {
  emit('verified', { token: token, eKey: eKey });
};
const handleOpened = () => {
  isLoading.value = false;
};

let language = 'en';
let languageLocale = localStorage.getItem('locale');
if (!languageLocale) {
  languageLocale = language;
}
if (languageLocale.toLowerCase().includes('ko')) {
  language = 'ko';
} else if (languageLocale.toLowerCase().includes('zh')) {
  language = 'zh';
} else if (languageLocale.toLowerCase().includes('pt')) {
  language = 'pt';
} else if (languageLocale.toLowerCase().includes('es')) {
  language = 'es';
} else if (languageLocale.toLowerCase().includes('tr')) {
  language = 'tr';
}
</script>

<template>
  <div class="bg-content">
    <page-loading :show="isLoading" containerHeight="4.7rem" containerHeightMobile="4.7rem" width="7rem" height="7rem" widthMobile="7rem" heightMobile="7asrem"></page-loading>

    <vue-hcaptcha v-if="props.show" sitekey="aebbb311-9485-4487-a968-225e765f332d" theme="dark" :language="language" @verify="handleOnVerify" @rendered="handleOpened"></vue-hcaptcha>
  </div>
</template>

<style scoped>
.bg-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
