<script setup>
import NewWithdraw from '@/components/wallet/NewWithdraw';
// import { useRoute } from 'vue-router';

// const route = useRoute();

const getTab = () => {
  return 'Crypto';
  // const languageLocale = localStorage.getItem('locale');
  //   if (languageLocale == 'pt') {
  //     return 'Fiat'
  //   } else {
  //     return 'Crypto'
  //   }
};
</script>

<template>
  <div class="bg-[#191F33] p-[15px]">
    <!-- <NewWithdraw :tabIndex="route.query.tab" :defSymbol="route.query.symbol" /> -->
    <NewWithdraw :tabIndex="getTab()" />
  </div>
</template>
