<template>
  <wgCard>
    <!-- <div class="mt-[1.66rem]">
      <div class="text-[0.75rem] font-bold text-[#fff] text-left">{{ t('Fiat_Currencies') }}</div>
      <div class="bg-[#222942] p-[1rem] mt-[1rem] flex justify-between" v-for="(item, index) in filteredFiatList" :key="index">
        <div class="flex">
          <img :src="item.icon_url" alt="" class="w-[1.5rem] h-[1.5rem]" />
          <div class="text-[0.875rem] font-bold text-[#fff] leading-[1.5rem] ml-[0.5rem]">{{ item.name }}</div>
        </div>
        <div class="flex items-center">
          <div class="text-[0.875rem] font-bold text-[#fff]">{{ formatNumberWithCommas(item.balance) }}</div>
          <div class="flex ml-[1rem] text-[#34E6A8] text-[0.75rem] font-bold md:flex-row flex-col">
            <div class="cursor-pointer" @click="depositHandle('Fiat', item.symbol)">{{ t('Deposit').toLocaleUpperCase() }}</div>
            <div class="cursor-pointer ml-[1rem] mt-[1rem] md:mt-0 md:ml-[1rem]" @click="withdrawHandle('Fiat', item.symbol)">{{ t('Withdraw').toLocaleUpperCase() }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex justify-between mt-[1.66rem]">
      <div>
        <span class="text-[0.75rem] text-[#fff] font-bold">{{ t('hide').toLocaleUpperCase() }} {{ hiddenCurrencyCount }} {{ t('balances').toLocaleUpperCase() }}</span>
        <wg-switch v-model="hidden" class="ml-2" />
      </div>
      <wg-input v-model="searchValue" class="w-[6.35rem] md:w-[13.755rem]" type="text">
        <template #prefix>
          <el-icon class="el-input__icon text-[#80A4DB]"><search /></el-icon>
        </template>
      </wg-input>
    </div>
    <div class="mt-[1rem]">
      <div class="text-[0.75rem] font-bold text-[#fff] text-left">{{ t('crypto_currencies') }}</div>
      <div class="bg-[#222942] p-[1rem] mt-[1rem] flex justify-between" v-for="(item, index) in filteredNonFiatList" :key="index">
        <div class="flex">
          <img :src="item.icon_url" alt="" class="w-[1.5rem] h-[1.5rem]" />
          <div class="text-[0.875rem] font-bold text-[#fff] leading-[1.5rem] ml-[0.5rem]">{{ item.name }}</div>
        </div>
        <div class="flex items-center">
          <div class="text-[0.875rem] font-bold text-[#fff]">{{ formatNumberWithCommas(item.balance) }}</div>
          <div class="flex ml-[1rem] text-[#34E6A8] text-[0.75rem] font-bold md:flex-row flex-col">
            <div class="cursor-pointer" @click="depositHandle('Crypto', item.symbol)">{{ t('Deposit').toLocaleUpperCase() }}</div>
            <div class="cursor-pointer ml-[1rem] mt-[1rem] md:mt-0 md:ml-[1rem]" @click="withdrawHandle('Crypto', item.symbol)">{{ t('Withdraw').toLocaleUpperCase() }}</div>
          </div>
        </div>
      </div>
    </div>
  </wgCard>
</template>

<script setup>
import { ref, onMounted, computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import wgCard from '@/components/common/wgCard';
import wgSwitch from '@/components/common/wgSwitch.vue';
import wgInput from '@/components/common/wgInput.vue';
import { userWalletInfosService } from '@/assets/network/service/wallet';
import { formatNumberWithCommas } from '@/assets/tools/CommonTool';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = useI18n();
const hidden = ref(false);
const hiddenCurrencyCount = ref(0);
const searchValue = ref('');
const balanceList = ref([]);

// get balance list data
const getBalanceData = () => {
  userWalletInfosService().then((response) => {
    balanceList.value = response.data.data;
  });
};

// filter
const filteredList = computed(() => {
  return balanceList.value.filter((item) => {
    const matchesSearchQuery = item.name.toLowerCase().includes(searchValue.value.toLowerCase());
    const isVisible = matchesSearchQuery && (Number(item.balance) !== 0 || ['USDT'].includes(item.name) || !hidden.value);
    return isVisible;
  });
});
// computed fiat list data
// const filteredFiatList = computed(() => {
//   return balanceList.value.filter((item) => item.is_fiat);
// });

// computed crypto currency list
const filteredNonFiatList = computed(() => {
  return filteredList.value.filter((item) => !item.is_fiat);
});

watchEffect(() => {
  let count = 0;
  balanceList.value.forEach((item) => {
    if (hidden.value && Number(item.balance) === 0 && !item.is_fiat) {
      count++;
    }
  });
  hiddenCurrencyCount.value = count;
});

const depositHandle = (type, symbol) => {
  router.push({
    path: '/profile/deposit',
    query: {
      tab: type,
      symbol: symbol
    }
  });
};
const withdrawHandle = (type, symbol) => {
  router.push({
    path: '/profile/withdraw',
    query: {
      tab: type,
      symbol: symbol
    }
  });
};

onMounted(() => {
  getBalanceData();
});
</script>

<style scoped lang="scss"></style>
