<template>
  <wgCard>
    <div class="grid grid-flow-row md:grid-flow-col gap-6 md:gap-4">
      <div
        class="flex"
        v-for="item in listData"
        :key="item.name"
      >
        <img
          :src="item.icon"
          class="w-[2.5rem] h-[2.5rem]"
        />
        <div class="text-left ml-[1rem]">
          <div class="text-[0.75rem] text-[#AAC4ED] font-bold">
            {{ item.text }}
          </div>
          <div class="text-[#fff] text-[1.125rem] mt-[0.35rem]">
            {{ defaultSymbol }}
            <span style="margin-left: 0.5rem">{{
              props.isHideInfo ? '****' : formatNumberWithCommas(item.num)
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </wgCard>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { toast } from 'vue3-toastify';
import { getBetInfo } from '@/assets/network/service/user';
import { useI18n } from 'vue-i18n';
import icon1 from '@/assets/images/profile/profile-total-bets.svg';
import icon2 from '@/assets/images/profile/profile/ico_profile_2.svg';
import icon3 from '@/assets/images/profile/profile-total-wagered.svg';
import icon4 from '@/assets/images/profile/profile-total-profit.svg';
import { isMobile, formatNumberWithCommas } from '@/assets/tools/CommonTool';
import wgCard from '@/components/common/wgCard';

const { t } = useI18n();
const defaultSymbol = ref(isMobile() ? '$' : 'evUSD');
const props = defineProps({
  isHideInfo: Boolean
});
const betInfo = ref({});
const listData = ref([]);
const getBetInfoHandle = () => {
  getBetInfo()
    .then((response) => {
      if (!response.data.error) {
        betInfo.value = response.data.data;
        listData.value = [
          {
            icon: icon1,
            text: t('total_profit').toLocaleUpperCase(),
            num: betInfo.value.total_win_amount || '0.0000'
          },
          {
            icon: icon2,
            text: t('total_bets').toLocaleUpperCase(),
            num: betInfo.value.total_bet_amount || '0.0000'
          },
          {
            icon: icon3,
            text: t('today_wagered').toLocaleUpperCase(),
            num: betInfo.value.today_total_bet_amount || '0.0000'
          },
          {
            icon: icon4,
            text: t('today_profit').toLocaleUpperCase(),
            num: betInfo.value.today_total_profile_amount || '0.0000'
          }
        ];
      }
    })
    .catch((error) => {
      const msg =
        error.response.data !== undefined
          ? Object.values(error.response.data)
          : '';
      let errorMsg = msg.length > 0 ? msg[0] : error.message;
      if (error.response.status === 500) {
        errorMsg = error.message;
      }
      toast(errorMsg, { autoClose: 2000, type: 'error' });
    });
};

onMounted(() => {
  getBetInfoHandle();
});
</script>
