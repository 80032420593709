<template>
  <my-search-game />

  <div class="phone clear"></div>
  <div class="slots">
    <my-search-game-list :game-list="providerList" :game="gameSupport"></my-search-game-list>
  </div>
  <div class="web clear" style="margin-top: 1rem"></div>
</template>
<script setup>
import { ref } from 'vue';

import gameSupportImg from '@/assets/images/home/Frame 427320293.svg';
import mySearchGameList from '@/components/game/searchGameList.vue';
import mySearchGame from '@/components/game/searchGame.vue';
const gameSupport = ref({
  gameCode: 'game_support',
  gameImage: gameSupportImg,
  showBottom: false,
  toUrl: '/providers-list'
});
</script>
<script>
import { getGameProviders } from '@/assets/network/service/home';
import { openboxSearchGames } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';

export default {
  name: 'App',
  props: {
    providerShow: {
      type: Boolean,
      default: false
    },
    gameSearch: {
      type: Boolean,
      default: false
    },
    page_name: {
      type: String,
      default: ''
    },
    game_type: {
      type: String,
      default: ''
    },
    game_sub_type: {
      type: String,
      default: ''
    }
  },
  watch: {
    gameSearch: function (newVal) {
      this.isGameSearch = newVal;
    }
  },
  components: {
    // myI18n
  },
  beforeUnmount() {},
  data() {
    return {
      searchText: '',
      isGameSearch: false,
      searchResult: [],
      pageIndex: 0,
      total: 0,
      provider_counts: [],
      isProviderShow: false,
      providerShow2: false,
      providerList: [],
      searchParams: {
        game_type: '',
        name: '',
        start: 0,
        end: 20,
        page_name: '',
        game_provider: [],
        game_sub_type: ''
      }
    };
  },
  created() {
    this.searchParams.game_type = this.game_type;
    this.searchParams.page_name = this.page_name;
    this.searchParams.game_sub_type = this.game_sub_type;
    this.getGameProviders();
    // this.getSlotsListService()
  },
  mounted() {},
  methods: {
    getCounts(provider) {
      for (let i_i in this.provider_counts) {
        if (provider.name == this.provider_counts[i_i].provider_counts) {
          provider.number = this.provider_counts[i_i].count;
          break;
        }
      }
    },
    getGameProviders() {
      getGameProviders()
        .then((response) => {
          this.providerList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    next() {
      this.pageIndex++;
      this.getSlotsListService();
    },
    getSlotsListService() {
      const that = this;
      this.searchParams.start = this.pageIndex * 20;
      this.searchParams.end = this.searchParams.start + 20;
      openboxSearchGames(that.searchParams)
        .then((response) => {
          if (this.pageIndex !== 0) {
            this.searchResult = this.searchResult.concat(response.data.data);
          } else {
            this.searchResult = response.data.data;
          }
          this.provider_counts = response.data.provider_counts;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    enterSearch() {
      console.log(this.searchText);
      if (!this.searchText && this.searchText.trim().length <= 3) {
        this.searchText = this.searchText.trim();
        return;
      }
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.getSlotsListService();
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    }
  }
};
</script>

<style spaced>
.slots_sort.showing {
  position: relative;
  z-index: var(--showing-z-index);
}
</style>
