<script setup>
import NewDeposit from '@/components/wallet/NewDeposit';

import useWalletStore from '@/store/wallet';

const walletStore = useWalletStore();

const getTab = () => {
  return 'Crypto';
  // const languageLocale = localStorage.getItem('locale');
  // if (languageLocale == 'pt') {
  //   return 'Fiat';
  // } else {
  //   return 'Crypto';
  // }
};
</script>

<template>
  <el-dialog v-model="walletStore.showDialogDeposit" :close-on-click-modal="false" class="bg-[#191F33] text-left w-full md:w-[500px]">
    <!-- <NewDeposit :tabIndex="route.query.tab" :defSymbol="route.query.symbol" /> -->
    <NewDeposit :tabIndex="getTab()" />
  </el-dialog>
</template>
