<template>
    <div class="flex  gap-2 overflow-auto" :class="direction === 'vertical' ? 'flex-col' : 'w-[100vw]'">
        <template v-for="(item, index) in menuData">
            <div :key="item.path" v-if="!item.hidden"
                class="bg-[#222942] text-[#5B7BAB] whitespace-nowrap text-ellipsis overflow-hidden text-[0.875rem] px-[1rem] py-[0.375rem] mt-[0.5rem] cursor-pointer rounded-md min-w-[4rem] text-center"
                :class="item.isSel ? 'bg-[#3E4B79] text-shadow-custom' : ''" @click="menuLinkHandle(item, index)">
                {{ item.name }}
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';


defineOptions({
    name: 'wg-tab'
})
const props = defineProps({
    tab: {
        type: Array,
        default: () => []
    },
    direction: {
        type: String,
        default: 'vertical'
    }
})

const emits = defineEmits(['change'])
const menuData = ref([]);

watch(props.tab, (val) => {
    menuData.value = val.map((item, index) => {
        return {
            isSel: index === 0 ? true : false,
            ...item
        }
    })
}, {
    immediate: true,

})


const menuLinkHandle = (info, index) => {
    emits('change', info, index)
    menuData.value.forEach((item, itemIndex) => {
        if (itemIndex === index) {
            item.isSel = true
        } else {
            item.isSel = false
        }
    })
};
</script>

<style scoped>
/* @tailwind base; */
</style>