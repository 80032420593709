<script setup>
import myGameList from '@/components/game/gameList.vue';
import { ref, onMounted } from 'vue';
import gameSupportImg from '@/assets/images/new-version/menu/Providers-1.svg';
import { getGameProviders } from '@/assets/network/service/home';

let providerList = ref([]);
const gameSupport = ref({
  gameCode: 'game_support',
  gameImage: gameSupportImg,
  showBottom: false,
  toUrl: '/providers-list'
});
const myGetGameProviders = () => {
  getGameProviders()
    .then((response) => {
      providerList.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
onMounted(() => {
  myGetGameProviders();
});
</script>

<template>
  <my-game-list @reload="reload" :gameList="providerList" :game="gameSupport" v-show="providerList.length > 0"></my-game-list>
</template>

<style scoped></style>
