<!-- eslint-disable vue/no-dupe-keys -->
<script setup>
import { useRouter, useRoute } from 'vue-router';
const router = useRouter();
const route = useRoute();
let path = route.query.pageUrl;
let jumpPath = path;
if (!jumpPath) {
  jumpPath = '/';
}
if (path) {
  localStorage.setItem('deposit_order_id', 'true');
  router.push({
    path: jumpPath,
    query: {
      depositResult: 'BRL'
    }
  });
}
import { useI18n } from 'vue-i18n';
import { ref, computed, onUnmounted, onMounted, defineEmits } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';

// const props = defineProps({
//   show: Boolean,
//   email: {
//     type: String,
//     default: () => ''
//   }
// });

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  deposit_records: t('deposit_records'),

  finish: t('finish')
};
const currentStep = ref(2);
// const email = ref('');

const intervalId = ref(null);
const confirmLoadingJson = ref(loadingAniJson);
const confirming = ref(false);

const handleCanNext = computed(() => {
  return true;
});
const emit = defineEmits(['showDepositRecord']);
const handleClick = () => {
  emit('showDepositRecord');
};
onUnmounted(() => {
  clearInterval(intervalId.value);
  intervalId.value = null;
});

onMounted(() => {
  // email.value = props.email;
});
</script>

<template>
  <wg-dialog :show="true" :my-class="'dialog-no-bg'">
    <div :class="'content ' + (matchSM ? 'matchSM' : '')">
      <div class="tip">{{ $t('deposit_done') }}</div>

      <div style="clear: both; margin-top: 1rem"></div>

      <div v-if="currentStep == 2">
        <img src="../../assets/images/profile/bind_success.svg" />
      </div>
      <div class="tip" v-if="currentStep == 2">
        <img src="../../assets/images/profile/profile/ico_profile_confirm.svg" />
        {{ textData.finish }}
      </div>

      <div class="bottom">
        <button :class="handleCanNext ? 'bottom-button' : 'bottom-button-disable'" @click="handleClick" :disabled="confirming || !handleCanNext">
          {{ confirming ? '' : textData.deposit_records }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
        </button>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
::v-deep .dialog {
  background-color: var(--body-bg-color) !important;
}
.content {
  background-color: var(--body-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.125rem 1.1875rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  display: flex;
  align: center;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.1875rem solid #2c394e;
  align-items: center;
  justify-content: center;
}
.tip img {
  margin-right: 0.5rem;
}
.security_code {
  color: var(--color-8ea3c3);
  padding-bottom: 0;
  border-bottom: 0;
}
.download_info {
  color: var(--color-8ea3c3);
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
}
.tip.core {
  color: var(--color-8ea3c3);
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 0;
  border-bottom: 0;
}
.tip.core.align-center {
  text-align: center;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  border: none;
  padding: 0;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-button-disable {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .bg-content {
  min-height: 3.125rem;
}

::v-deep .step-progress__step:after {
  width: 30px;
  height: 30px;
}
::v-deep .step-progress__step span {
  --passiveColor: var(--passive-color);
  font-size: 0.875rem;
  font-weight: 700;
  color: #ffffff;
}
::v-deep .step-progress__step:after {
  --activeBorder: 0px;
  --passiveBorder: 0px;
  --passiveColor: var(--passive-color);
  background: var(--passiveColor);
}

::v-deep .step-progress__step.step-progress__step--valid:after {
  --activeColor: var(--green-color);
  --passiveColor: var(--passive-color);
  --activeBorder: 0px;
  --passiveBorder: 0px;
  background: var(--activeColor);
  font-weight: 700;
}
::v-deep .step-progress__step--valid span {
  opacity: 1;
  transform: translateZ(0) scale(1) perspective(1000px);
}
::v-deep .step-progress__step--active:after,
.step-progress__step--valid:after {
  --activeColor: var(--green-color);
  --passiveColor: var(--passive-color);
  --activeBorder: 0px;
  --passiveBorder: 0px;
  background: var(--activeColor);
  font-weight: 700;
}
::v-deep .step-progress__bar {
  margin-bottom: 0;
  height: 60px;
}
::v-deep .step-progress__wrapper-after {
  background: linear-gradient(90deg, rgba(40, 178, 170, 0) 0%, rgba(40, 178, 170, 0.79) 100%) !important;
  width: calc(100% - 8rem) !important;
  height: 2px !important;
  left: 2rem;
}
::v-deep .step-progress__wrapper-before {
  background: linear-gradient(90deg, rgba(85, 101, 126, 0) 2rem, var(--passive-color) calc(50% - 1rem), #1e2736 calc(50% - 1rem + 1px), #1e2736 calc(50% + 1rem - 1px), rgba(85, 101, 126, 0) calc(50% + 2rem), var(--passive-color) 100%) !important;
  width: calc(100% - 2rem) !important;
}
::v-deep .step2 .step-progress__wrapper-after {
  background: linear-gradient(90deg, rgba(40, 178, 170, 0) 2rem, rgba(40, 178, 170, 0.79) calc(50% - 1rem), #1e2736 calc(50% - 1rem + 1px), #1e2736 calc(50% + 1rem - 1px), rgba(40, 178, 170, 0) calc(50% + 2rem), rgba(40, 178, 170, 0.79) 100%) !important;
  width: calc(100% - 2rem) !important;
  left: 0;
}
::v-deep .step-progress__step-label {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-8ea3c3);
}
::v-deep .step-progress__step--active .step-progress__step-label,
.step-progress__step--active span {
  color: #ffffff;
}
::v-deep .step-progress__bar > *:first-child .step-progress__step-label {
  transform: translateX(-20%) perspective(1000px);
}
::v-deep .input {
  font-family: 'Poppins', sans-serif;
  color: var(--input-color);
  font-size: 0.875rem;
  font-weight: 500;
}
.core-img {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 1rem;
  background: #ffffff;
}
</style>
