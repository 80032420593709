<script setup>
// 导入
import { onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import Cookies from 'js-cookie';
import menus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import { getCookieAccessToken, getUserDefSymbol } from '@/assets/tools/CommonTool';
import { fetchBetbyToken } from '@/assets/network/service/user';
import { useAppStore, useLoginStore } from '@/store/store';
import useWalletStore from '@/store/wallet';
import emitter from '@/assets/tools/eventBus';

// const router = useRouter();
const appStore = useAppStore();
const loginStore = useLoginStore();
const walletStore = useWalletStore();
const instance = getCurrentInstance();

const brandId = process.env.VUE_APP_BRAND_ID;

let bt = null;
emitter.on('betbyUpdate', () => {
  if (appStore.btBool) {
    appStore.btBool = false;
    killInit();
  }
});
// emitter.on('betbyLogin', () => {
//   router.push('/');
// });
// 生命周期
onMounted(() => {
  btInit();
});

onUnmounted(async () => {
  bt.kill();
});

onBeforeRouteLeave((to, from, next) => {
  const search = window.location.search;
  Cookies.set('betby-search', search);
  next();
});

// 方法
const btInit = async () => {
  const betbyToken = await getBetbyToken();
  const isMobile = instance.proxy.$isMobile();
  const opts = {
    brand_id: brandId,
    token: betbyToken,
    onTokenExpired: refreshToken,
    themeName: 'default',
    lang: getBetbyLang(),
    target: document.getElementById('betby'),
    stickyTop: isMobile ? 53 : 60,
    betSlipOffsetTop: isMobile ? 53 : 60,
    betSlipOffsetBottom: isMobile ? 70 : 0,
    betslipZIndex: 999,
    // basename: '/betbySports',
    onLogin: onLogin,
    onRegister: onRegister,
    onSessionRefresh: killInit,
    onRecharge: onRecharge
  };
  if (bt == null) {
    setTimeout(() => {
      bt = new window.BTRenderer();
      bt.initialize(opts);
    }, 500);
  } else {
    bt.initialize(opts);
  }
};

const killInit = () => {
  bt.kill();
  btInit();
};

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    fetchBetbyToken({ currency: getUserDefSymbol() })
      .then((res) => {
        if (res.data.token) {
          resolve(res.data.token);
        } else {
          reject(null);
        }
      })
      .catch(() => {
        reject(null);
      });
  });
};

const getBetbyToken = async () => {
  try {
    const accessToken = getCookieAccessToken();
    if (accessToken !== undefined && accessToken.length > 0) {
      const betbyToken = await refreshToken();
      return betbyToken;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

const getBetbyLang = () => {
  let language = '';
  let languageLocale = localStorage.getItem('locale');
  if (languageLocale) {
    language = languageLocale.toLocaleLowerCase();
  } else {
    language = 'en';
  }
  if (language == 'sw') {
    language = 'en';
  }
  return language;
};

const onLogin = () => {
  loginStore.updateActType('login');
  loginStore.updateShow(true);
};

const onRegister = () => {
  loginStore.updateActType('sign_up');
  loginStore.updateShow(true);
};

const onRecharge = () => {
  walletStore.showDialogDeposit = true;
};
</script>

<template>
  <div class="root_div home" @click="isGameSearch = false">
    <menus @menuChange="menuChange"></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <myHeader :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></myHeader>
      <div class="main-content" style="padding: 0">
        <div id="betby"></div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
