<template>
  <div  class="md:bg-[#D6E3F6]/[0.1] cursor-pointer rounded h-[2.5rem] flex justify-around text-[#D6E3F6] text-[0.75rem] items-center px-[0.75rem] font-bold">
    <img :src="props.isHide ? VisibilityEyeOff : ico_chakan" class="w-[1.25rem] h-[1.25rem] mr-[0.5rem]" />
    <span class="md:block hidden">
      {{ props.isHide ? t('HIDE_INFO') : t('SHOW_INFO') }}
    </span>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import ico_chakan from '@/assets/images/profile/profile/ico_chakan.svg';
import VisibilityEyeOff from '@/assets/images/profile/VisibilityEyeOff.svg';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  isHide: Boolean
});
</script>
