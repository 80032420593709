<template>
  <div class="bg-[#191F33] p-[1rem] md:p-[1.5rem] rounded-md">
    <div v-if="props.title" class="text-[#fff] text-[0.75rem] font-bold text-left pb-[1rem]">{{ props.title }}</div>
    <slot></slot>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props =  defineProps({
  title: String
})

</script>
