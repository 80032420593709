<template>
  <div>
    <div class="mt-4 relative w-full h-[0.3125rem] rounded-full bg-[#273854]/[0.5]">
      <div class="absolute top-0 left-0 w-0 h-full rounded-full bg-[#CEE2FF] transition-all duration-200" :style="levelProgressStyle"></div>
    </div>
    <div class="flex w-full justify-between mt-[0.5rem]">
      <div class="flex justify-start items-center gap-2">
        <!-- <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="19"
          viewBox="0 0 20 19"
          :fill="levelColor"
        >
          <path
            d="M13.5999 10.3522V18.0667H15.2212V15.2652C17.8122 15.3036 19.1261 15.1884 19.4898 12.9772C19.6053 12.3306 19.3744 11.6048 19.0874 11.2068C18.2326 10.0492 15.662 10.3362 13.5999 10.3522ZM15.2212 13.9541V11.7045C16.424 11.6661 17.697 11.5327 17.9072 12.5049C18.2533 14.1306 16.5732 13.9925 15.2212 13.9541ZM10.4775 10.3906C10.4957 12.9432 10.4957 15.514 10.5159 18.0667H12.0985V10.3522C11.5649 10.3749 11.0291 10.3749 10.4772 10.3906H10.4775ZM13.5458 0.0666504C11.0473 2.56043 8.55574 5.03625 6.03931 7.53473V0.0666504H0C0.018229 0.561871 0.018229 1.07504 0.0383913 1.57247H1.50582V18.0553C7.50674 12.05 13.4892 6.06729 19.4901 0.0666504H13.5458Z"
            :fill="levelColor"
          />
        </svg> -->
        <span class="text-[#fff] text-bases" >{{ t('vip')+ (level) }}</span>
        <div class="text-[#fff] text-base font-semibold">{{ levelName }}</div>
      </div>
      <div class="flex justify-start items-center gap-2">
        <span class="text-[#fff] text-base">{{ t('next') }}:</span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="#55657E">
          <path
            d="M13.5999 10.3522V18.0667H15.2212V15.2652C17.8122 15.3036 19.1261 15.1884 19.4898 12.9772C19.6053 12.3306 19.3744 11.6048 19.0874 11.2068C18.2326 10.0492 15.662 10.3362 13.5999 10.3522ZM15.2212 13.9541V11.7045C16.424 11.6661 17.697 11.5327 17.9072 12.5049C18.2533 14.1306 16.5732 13.9925 15.2212 13.9541ZM10.4775 10.3906C10.4957 12.9432 10.4957 15.514 10.5159 18.0667H12.0985V10.3522C11.5649 10.3749 11.0291 10.3749 10.4772 10.3906H10.4775ZM13.5458 0.0666504C11.0473 2.56043 8.55574 5.03625 6.03931 7.53473V0.0666504H0C0.018229 0.561871 0.018229 1.07504 0.0383913 1.57247H1.50582V18.0553C7.50674 12.05 13.4892 6.06729 19.4901 0.0666504H13.5458Z"
            fill="#55657E"
          />
        </svg> -->
        <span class="text-[#fff] text-bases" >{{ t('vip')+ (level + 1) }}</span>
        <div class="text-[#fff] text-base font-semibold">{{ nextLevelName }}</div>
        <div class="text-base text-[#fff]">{{ `${new BigNumber(progress * 100).toFixed(2, 1)}%` }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import BigNumber from 'bignumber.js';
import { useI18n } from 'vue-i18n';
import { userInfoService, userBetInfoService } from '@/assets/network/service/user';
import { toast } from 'vue3-toastify';
import { getCookieUserId } from '@/assets/tools/CommonTool';

const { t } = useI18n();
const textData = {
  vip: 'VIP',
  progress: t('progress'),
  benefit: t('benefit'),
  welcome: t('welcome'),
  your_vip_progress: t('your_vip_progress'),
  none: '',
  bronze: t('bronze'),
  silver: t('silver'),
  gold: t('gold'),
  platinum_1: t('platinum_1'),
  platinum_2: t('platinum_2'),
  platinum_3: t('platinum_3'),
  platinum_1_3: t('platinum_1_3'),
  want_to__level: t('want_to__level'),
  wager_on_casino: t('wager_on_casino'),
  play_wager__games: t('play_wager__games'),
  learn_more__vip: t('learn_more__vip'),
  support_will__choice: t('support_will__choice'),
  enable_rebate_function: t('enable_rebate_function')
};
const progress = ref(0.0);
const level = ref(0);
const nickName = ref('');
const levelProgressStyle = computed(() => {
  let result = new BigNumber(progress.value * 100).toFixed(2, 1);
  if (result.endsWith('.00')) {
    result = result.slice(0, -3);
  }
  return { width: `${result}%` };
});

// const levelColor = computed(() => {
//   return level.value == 0 ? '#55657E' : level.value == 1 ? '#775124' : level.value == 2 ? '#94A0BF' : level.value == 3 ? '#E6B86B' : '#68BFB9';
// });

const levelName = computed(() => {
  return level.value == 0 ? textData.none : level.value == 1 ? textData.bronze : level.value == 2 ? textData.silver : level.value == 3 ? textData.gold : level.value == 4 ? textData.platinum_1 : level.value == 5 ? textData.platinum_2 : textData.platinum_3;
});
const nextLevelName = computed(() => {
  return level.value == 0 ? textData.bronze : level.value == 1 ? textData.silver : level.value == 2 ? textData.gold : level.value == 3 ? textData.platinum_1 : level.value == 4 ? textData.platinum_2 : textData.platinum_3;
});

const fetchUserBetInfo = async (nextLevelAmount) => {
  try {
    const response = await userBetInfoService();
    const total_bet_amount = new BigNumber(response.data.data.total_symbols_bet_amount);
    const progressExperience = new BigNumber(nextLevelAmount);

    if (total_bet_amount.isGreaterThan(progressExperience)) {
      progress.value = 1;
    } else {
      if (progressExperience.isEqualTo(BigNumber(0))) {
        progress.value = 0;
      } else {
        progress.value = total_bet_amount.dividedBy(progressExperience);
      }
    }
  } catch (error) {
    // const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    // let errorMsg = msg.length > 0 ? msg[0] : error.message;
    // if (error.response.status === 500) {
    //   errorMsg = error.message;
    // }
    // toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

const fetchUserInfo = async () => {
  try {
    const response = await userInfoService(getCookieUserId());

    nickName.value = response.data.name;
    level.value = response.data.vip_level;

    fetchUserBetInfo(response.data.user_next_vip_level_amount);
  } catch (error) {
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

onMounted(() => {
  fetchUserInfo();
});
</script>
