<!-- eslint-disable vue/no-dupe-keys -->
<template>
  <div class="close-div" @click="selectSymbol" v-if="props.isSelectSymbol"></div>
  <div class="deposit select-div" v-if="props.isSelectSymbol">
    <div class="select-div-main">
      <div :class="'option search_symbol'">
        <div class="tool-bar-search" style="">
          <div :class="'tool-bar-search bottom-1rem '">
            <div :class="'input-zindex1550 line-height-2_5rem '">
              <div :class="'line-height-2_5rem input-div'">
                <div class="img-div line-height-2_5rem">
                  <img style="width: 1.25rem; height: 1.25rem; padding: 0 1rem" src="../../assets/images/home/Frame 427320295.svg" />
                </div>
                <div class="input line-height-2_5rem">
                  <input v-model="searchSymbol" type="input" :placeholder="$t('search_symbol')" @keyup="searchSymbolEvent()" @keyup.enter="searchSymbolEvent()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :class="'option ' + (thisSymbol.symbol == symbol.symbol ? 'selected' : '')" v-for="(symbol, index) in symbols" v-show="symbol.symbol !== 'Gold'" :key="index" @click="selectSymbol(symbol)">
        <div class="option" style="width: 45%">
          {{ convertCurrency(symbol.balance) }}
        </div>
        <div>
          <img :src="symbol.icon_url" />
        </div>
        <div>
          {{ symbol.symbol }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { convertCurrency } from '@/assets/tools/CommonTool.js';
import { defineProps, defineEmits, onMounted, ref } from 'vue';
import usdtImg from '../../assets/images/home/unit-usdt.png';
const props = defineProps({
  symbols: {
    type: Array,
    default: () => []
  },
  symbol: {
    type: Object,
    default: () => {}
  },
  isSelectSymbol: {
    type: Boolean,
    default: () => false
  }
});
const thisSymbol = ref({});
// eslint-disable-next-line vue/no-dupe-keys
const symbols = ref([]);
const searchSymbol = ref('');
const emit = defineEmits(['close']);
const selectSymbol = (sy) => {
  if (sy) {
    thisSymbol.value = sy;
    emit('close', thisSymbol.value);
  } else {
    emit('close', {});
  }
};
onMounted(() => {
  props.symbols.forEach((item) => {
    item.img = usdtImg;
  });
  thisSymbol.value = props.symbol;
  symbols.value = props.symbols;
});
const searchSymbolEvent = () => {
  let searchSymbols = [];
  if (searchSymbol.value) {
    props.symbols.forEach((item) => {
      if (item.name.toLowerCase().indexOf(searchSymbol.value.trim().toLowerCase()) > -1) {
        searchSymbols.push(item);
      }
    });
  } else {
    searchSymbols = props.symbols;
  }
  symbols.value = searchSymbols;
  console.log(searchSymbol.value);
};
</script>

<style scoped>
.deposit.select-div {
  position: fixed;
  width: 30rem;
  margin: 0;
  z-index: 10;
}
.phone .deposit.select-div {
}
.select-div-main {
  width: 65%;
  border-radius: 0.5rem;
  background-color: var(--deposit-withdraw-selected-div-bg-color);
  font-weight: 700;
  color: #000000;
  font-size: 0.875rem;
  line-height: 2.5rem;
  max-height: 25rem;
  overflow-y: auto;
}
.phone .select-div-main {
  width: 75%;
}
.deposit {
  margin: 0;
  justify-content: flex-end;
}
.option {
  font-family: 'Poppins', sans-serif;
  line-height: 2rem;
  height: 2rem;
  margin: 0.25rem 0;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.875rem;
  border-radius: 0;
}
.option.search_symbol {
  line-height: 4rem;
  height: 4rem;
}
.option.search_symbol .input-div {
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 2px solid #1e2736;
}
.option.search_symbol .tool-bar-search {
  width: 95%;
}
.option.search_symbol .input-div input {
  background-color: #ffffff;
  color: #55657e;

  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0em;
}
.option img {
  width: 1rem;
  padding: 0 2rem 0 0;
}
.option div {
  display: flex;
  align-items: center;
  justify-content: left;
}
.option.selected {
  background-color: var(--color-8ea3c3);
}
.option:hover {
  background-color: var(--color-8ea3c3);
}
.option.search_symbol:hover {
  background-color: #ffffff;
}
.tran-rows.flex-end {
  align-items: baseline;
}
.flex-end {
  align-items: baseline;
}
</style>
