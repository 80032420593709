<script setup>
// 导入
import { ref, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { getDrainageGameConfigService } from '@/assets/network/service/home';
import { setGameInfo } from '@/assets/tools/CommonTool';
import headerImg1 from '@/assets/images/profile/avatar/profile-avatar1.png';
import headerImg2 from '@/assets/images/profile/avatar/profile-avatar2.png';
import headerImg3 from '@/assets/images/profile/avatar/profile-avatar3.png';
import headerImg4 from '@/assets/images/profile/avatar/profile-avatar4.png';
import headerImg5 from '@/assets/images/profile/avatar/profile-avatar5.png';
import headerImg6 from '@/assets/images/profile/avatar/profile-avatar6.png';
import headerImg7 from '@/assets/images/profile/avatar/profile-avatar7.png';
import headerImg8 from '@/assets/images/profile/avatar/profile-avatar8.png';
import headerImg9 from '@/assets/images/profile/avatar/profile-avatar9.png';
import headerImg10 from '@/assets/images/profile/avatar/profile-avatar10.png';
import headerImg11 from '@/assets/images/profile/avatar/profile-avatar11.png';
import headerImg12 from '@/assets/images/profile/avatar/profile-avatar12.png';
import headerImg13 from '@/assets/images/profile/avatar/profile-avatar13.png';
import headerImg14 from '@/assets/images/profile/avatar/profile-avatar14.png';
import headerImg15 from '@/assets/images/profile/avatar/profile-avatar15.png';
import headerImg16 from '@/assets/images/profile/avatar/profile-avatar16.png';
const router = useRouter();
// 属性
const dataList = ref([]);
// 生命周期
onBeforeMount(() => {
  getLiveWinsList();
});
onMounted(() => {});
onBeforeUnmount(() => {});
// 方法
const clickItem = (game) => {
  // if (!game.game_api_provider) {
  //   return;
  // }
  setGameInfo(JSON.stringify(game));
  router.push('/gameDetail');
};
const getLiveWinsList = () => {
  getDrainageGameConfigService()
    .then((response) => {
      dataList.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};
const getHeaderImg = (headerImageUrl) => {
  let header = (headerImageUrl + '').split('//')[1];
  if (header == 'profile-avatar1') {
    return headerImg1;
  } else if (header == 'profile-avatar2') {
    return headerImg2;
  } else if (header == 'profile-avatar3') {
    return headerImg3;
  } else if (header == 'profile-avatar4') {
    return headerImg4;
  } else if (header == 'profile-avatar5') {
    return headerImg5;
  } else if (header == 'profile-avatar6') {
    return headerImg6;
  } else if (header == 'profile-avatar7') {
    return headerImg7;
  } else if (header == 'profile-avatar8') {
    return headerImg8;
  } else if (header == 'profile-avatar9') {
    return headerImg9;
  } else if (header == 'profile-avatar10') {
    return headerImg10;
  } else if (header == 'profile-avatar11') {
    return headerImg11;
  } else if (header == 'profile-avatar12') {
    return headerImg12;
  } else if (header == 'profile-avatar13') {
    return headerImg13;
  } else if (header == 'profile-avatar14') {
    return headerImg14;
  } else if (header == 'profile-avatar15') {
    return headerImg15;
  } else if (header == 'profile-avatar16') {
    return headerImg16;
  } else {
    return headerImg4;
  }
};
</script>

<template>
  <div class="live-wins-container scrollbar">
    <div v-for="(item, index) in dataList" :key="index" class="live-wins-item" @click="clickItem(item)">
      <div class="item-cover">
        <img :src="item.game_image" />
      </div>
      <div class="item-user">
        <img :src="getHeaderImg(item.user_info.profile_image_url)" />
        <div>{{ item.user_info.user_name }}</div>
      </div>
      <div class="item-profit">{{ `$${item.user_info.win_amount}` }}</div>
    </div>
  </div>
</template>

<style scoped>
.live-wins-container {
  width: 100%;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  .live-wins-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    .item-cover {
      width: 80px;
      /* height: 80px; */
      border-radius: 6px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    .item-user {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      img {
        width: 16px;
      }
      div {
        color: #ffffff;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .item-profit {
      color: #00b555;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .live-wins-item:hover {
    .item-cover {
      opacity: 0.7;
    }
  }
}
</style>
