import { useAppStore } from "@/store/store";
import { getCookieAccessToken, getCookieUserId } from "./CommonTool";
import { getCustomerListService } from "../network/service/home";
import { userInfoService } from "../network/service/user";
import serverConfig from "../network/serverConfig";
import Intercom from '@intercom/messenger-js-sdk';
import { update as IntercomUpdate } from "@intercom/messenger-js-sdk";
import { shutdown as IntercomShutdown } from "@intercom/messenger-js-sdk";
import { show as IntercomShow } from "@intercom/messenger-js-sdk";
import { hide as IntercomHide } from "@intercom/messenger-js-sdk";
import { onShow as IntercomOnShow } from "@intercom/messenger-js-sdk";
import { onHide as IntercomOnHide } from "@intercom/messenger-js-sdk";

const updateUserIntercom = async () => {
  const token = getCookieAccessToken();
  const userId = getCookieUserId();
  if(token) {
    const userInfoResponse = await userInfoService(userId);
    const param = {
      user_id: userId,
      name: userInfoResponse.data.name,
      email: userInfoResponse.data.user_email,
      created_at: userInfoResponse.data.joined_time,
      user_hash: userInfoResponse.data.user_hash,
    };
    console.log('====> Intercom update param:'+JSON.stringify(param));
    IntercomUpdate(param);
  }
}

export const initCustomerIntercom = () => {
  const param = {
    app_id: serverConfig.customerIntercomAppId,
    hide_default_launcher: true,
    // custom_launcher_selector:'#custom_intercom_launcher_id',
  };
  console.log('====> Intercom init');
  Intercom(param);
}

export const setupCustomerType = async () => {
  console.log('====> setupCustomerType');
  const appStore = useAppStore();
  try {
    const response = await getCustomerListService();
    if (response.data.code == 200 && response.data.customer_service_type == 2) {
      appStore.switchCustomerShowType('Intercom');
      console.log('====> switchCustomerShowType Intercom');
    } else {
      console.log('====> switchCustomerShowType Original');
      appStore.switchCustomerShowType('Original');
    }
  } catch (error) {
    appStore.switchCustomerShowType('Original');
    console.log(error);
  }
}

export const UpdateIntercom = async () => {
  const appStore = useAppStore();

  let intercomInterval = setInterval(() => {
    // console.log('====> Intercom window.booted:',window.Intercom.booted);
    if(window.Intercom.booted) {
      clearInterval(intercomInterval)
      intercomInterval = null;

      updateUserIntercom();

      IntercomOnShow(() => {
        console.log('====> Intercom onShow');
        appStore.openCustomerPopup();
      });
      IntercomOnHide(() => {
        console.log('====> Intercom onHide');
        appStore.closeCustomerPopup();
      });
    }
  }, 1000);
}

export const ShutdownIntercom = () => {
  IntercomShutdown();
}

export const ShowIntercom = () => {
  IntercomShow();
}

export const HideIntercom = () => {
  IntercomHide();
}
