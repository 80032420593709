<template>
  <div class="main-content tool-bar-search top-1rem height-0" style="" v-show="show">
    <div :class="'body tool-bar-search bottom-1rem search search-div ' + (isGameSearch ? 'searching' : '')">
      <div v-show="isGameSearch" @click="isGameSearch = false" class="search-overlay svelte-o7w8d3" style=""></div>
      <div :class="'input-zindex1550 line-height-2_5rem ' + (isGameSearch ? 'focus' : '')">
        <div :class="'line-height-2_5rem input-div'" @click.stop="searchFocus()">
          <div class="img-div line-height-2_5rem">
            <img style="width: 1.25rem; height: 1.25rem" src="../../assets/images/home/Frame 427320295.svg" />
          </div>
          <div class="input line-height-2_5rem">
            <input v-model="searchText" type="input" @keyup.enter="enterSearch" :placeholder="$t('input_search')" @blur.stop="searchBlur()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-content search-game" v-show="isGameSearch">
    <div class="body search-body" v-show="isGameSearch" @click.stop="isGameSearch = true">
      <div class="search-history">
        <div v-show="searchResult.length > 0">
          <my-search-game-list :game-list="searchResult" :style="'padding:0 0 1rem 0;border-radius:1rem;'"></my-search-game-list>
        </div>
        <div v-show="searchText.length < 3" style="width: 100%; text-align: center" class="search-remind">
          <div style="color: var(--color-8ea3c3)">{{ $t('search_text') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import mySearchGameList from '@/components/game/searchGameList.vue';
</script>
<script>
import { getGameProviders } from '@/assets/network/service/home';
import { openboxSearchGames } from '@/assets/network/service/game';
import { toast } from 'vue3-toastify';
import { gaSearchEvent } from '@/assets/tools/GoogleAnalyticsEvent';
import { useAppStore } from '@/store/store';

export default {
  name: 'App',
  props: {
    show: {
      type: Boolean,
      default: true
    },
    providerShow: {
      type: Boolean,
      default: false
    },
    gameSearch: {
      type: Boolean,
      default: false
    },
    page_name: {
      type: String,
      default: ''
    },
    game_type: {
      type: String,
      default: ''
    },
    game_sub_type: {
      type: String,
      default: ''
    }
  },
  watch: {
    gameSearch: function (newVal) {
      this.isGameSearch = newVal;
    }
  },
  components: {
    // myI18n
  },
  beforeUnmount() {},
  data() {
    return {
      searchText: '',
      isGameSearch: false,
      searchResult: [],
      pageIndex: 0,
      total: 0,
      provider_counts: [],
      isProviderShow: false,
      providerShow2: false,
      providerList: [],
      searchParams: {
        game_type: '',
        name: '',
        start: 0,
        end: 20,
        page_name: '',
        game_provider: [],
        game_sub_type: ''
      }
    };
  },
  created() {
    this.searchParams.game_type = this.game_type;
    this.searchParams.page_name = this.page_name;
    this.searchParams.game_sub_type = this.game_sub_type;
    this.getGameProviders();
    // this.getSlotsListService()
  },
  mounted() {},
  methods: {
    getCounts(provider) {
      for (let i_i in this.provider_counts) {
        if (provider.name == this.provider_counts[i_i].provider_counts) {
          provider.number = this.provider_counts[i_i].count;
          break;
        }
      }
    },
    getGameProviders() {
      getGameProviders()
        .then((response) => {
          this.providerList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    next() {
      this.pageIndex++;
      this.getSlotsListService();
    },
    getSlotsListService() {
      const that = this;
      this.searchParams.start = this.pageIndex * 20;
      this.searchParams.end = this.searchParams.start + 20;
      const appStore = useAppStore();
      if (appStore.kwaiPixelId) {
        this.searchParams.kwai_pixel_id = appStore.kwaiPixelId;
      }
      if (appStore.kwaiToken) {
        this.searchParams.kwai_token = appStore.kwaiToken;
      }
      if (appStore.kwaiClickId) {
        this.searchParams.click_id = appStore.kwaiClickId;
      }
      openboxSearchGames(that.searchParams)
        .then((response) => {
          gaSearchEvent({ content: this.searchParams.name });
          if (this.pageIndex !== 0) {
            this.searchResult = this.searchResult.concat(response.data.data);
          } else {
            this.searchResult = response.data.data;
          }
          this.provider_counts = response.data.provider_counts;
          this.total = response.data.total_count;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    },
    enterSearch() {
      console.log(this.searchText);
      if (!this.searchText && this.searchText.trim().length <= 3) {
        this.searchText = this.searchText.trim();
        return;
      }
      this.searchResult = [];
      this.searchParams.name = this.searchText;
      this.getSlotsListService();
    },
    searchBlur() {},
    searchFocus() {
      this.isGameSearch = true;
    }
  }
};
</script>

<style spaced>
.phone .main-content.search-game {
  padding: 0rem 0 0 0 !important;
}
.slots_sort.showing {
  position: relative;
  z-index: var(--showing-z-index);
}
.main-content.search-game .body.search-body {
  height: 0;
}
.web .search-history {
  height: 2rem;
  line-height: 2rem;
}
</style>
