import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';
import { getType } from '@/assets/tools/CommonTool';
import { fetchPopupConfig } from '@/assets/network/service/home';
import { getTurntableList, getFreePromotionsService } from '@/assets/network/service/turntable';

const useDialogStore = defineStore('Dialog', {
  state: () => ({
    dialogCurrent: 0,
    dialogList: []
  }),
  getters: {
    // isShowDialog() {
    //   if (this.dialogList.length > 0) {
    //     if (this.dialogList[this.dialogCurrent].isShow && this.dialogList[this.dialogCurrent].sort == this.dialogCurrent) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     return false;
    //   }
    // }
  },
  actions: {
    initDialog(param) {
      this.resetDialogData();
      const dataType = getType(param);
      if (dataType == 'Number') {
        this.getAllDialogData(param);
      } else if (dataType == 'Function') {
        this.pushDialogData(null, 0, 'freeSpinRes');
        this.getAllDialogData(param);
        setTimeout(() => {
          param();
        }, 666);
      } else {
        console.log('init dialog failed');
      }
    },
    resetDialogData() {
      this.dialogCurrent = 0;
      this.dialogList = [];
    },
    pushDialogData(data, index, type) {
      let bool = false;
      if (index == 0) {
        bool = true;
      }
      this.dialogList.push({
        sort: index,
        type: type,
        isShow: bool,
        data: data
      });
    },
    async getAllDialogData(type) {
      // 1-首页 2-注册 3-登录 4-充值 5-提现
      if (type == 1) {
        await this.getFreeSpinData(type);
        await this.getSpinData(type);
        await this.getPopupDialogData(type);
      } else if (type == 2) {
        await this.getPopupDialogData(type);
      } else if (type == 3) {
        await this.getSpinData(type);
        await this.getPopupDialogData(type);
      } else if (type == 4) {
        await this.getPopupDialogData(type);
      } else if (type == 5) {
        await this.getPopupDialogData(type);
      }
      console.log('DialogListData', this.dialogList);
    },
    // 获取弹窗配置数据
    async getPopupDialogData(type) {
      try {
        let sort = this.dialogList.length;
        const res = await fetchPopupConfig({ type: type });
        const len = res.data.data.length;
        for (let i = 0; i < len; i++) {
          if (res.data.data[i].style == 2) {
            this.pushDialogData(res.data.data[i], sort, 'popupSide');
          } else {
            this.pushDialogData([res.data.data[i]], sort, 'popupCenter');
          }
          sort++;
        }
      } catch (error) {
        toast(error, { autoClose: 2000, type: 'error' });
      }
    },
    // 获取免费转盘数据
    async getFreeSpinData() {
      try {
        let sort = this.dialogList.length;
        const res = await getFreePromotionsService();
        if (res.data.data && res.data.data.exists && res.data.data.register_dialog_open) {
          this.pushDialogData(res.data.data, sort, 'freeSpin');
        }
      } catch (error) {
        toast(error, { autoClose: 2000, type: 'error' });
      }
    },
    // 获取转盘数据
    async getSpinData() {
      try {
        let sort = this.dialogList.length;
        const res = await getTurntableList();
        const records = res.data.data.records;
        const isHomeTab = records.some((item) => item.home_tab === true);
        if (isHomeTab && records.length > 0) {
          // activeInitiativeTab.value = true;
          this.pushDialogData(records, sort, 'spin');
        }
      } catch (error) {
        toast(error, { autoClose: 2000, type: 'error' });
      }
    }
  }
});

export default useDialogStore;
