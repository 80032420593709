const screenBreakpoint = {
  matchXS: 0,
  matchSM: 780,
  matchMD: 850,
  matchLG: 1440,
  matchXL: 1536
};

export const designSize = {
  width: 375,
  height: 667
};

export default screenBreakpoint;
