<script setup>
// 导入
import { defineProps } from 'vue';
// 传参
const props = defineProps({
  btnText: {
    type: String,
    default: 'Button'
  },
  disabled: {
    type: Boolean,
    default: false
  }
});
// 属性
// 计算属性
// 生命周期
// 方法
</script>

<template>
  <div class="my-btn-item text-[14px] font-[700] text-center rounded-[4px] py-[10px]" :class="disabled ? 'my-btn-disabled' : ''">
    {{ props.btnText }}
  </div>
</template>

<style scoped>
.my-btn-item {
  cursor: pointer;
  background-color: #3aa1ff;
  color: #121829;
  box-shadow:
    0px 0px 10px 0px rgba(17, 132, 250, 0.4),
    0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
    0px 0px 12px 0px #0d52b2 inset;
}
.my-btn-disabled {
  cursor: not-allowed;
  background-color: #3aa1ffaa;
  color: #121829aa;
}
</style>
