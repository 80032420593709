/* eslint-disable no-unused-vars */
export const fpInit = (pixelId) => {
  if (window.fbq !== undefined && window.fbq !== null) {
    console.log('fb init');
    window.fbq('init', pixelId);
    window.fbq('track', 'PageView');
  } else {
    console.log('fbq null init failed');
  }
};

export const fpCompleteRegistrationEvent = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   window.fbq(
  //     'track',
  //     'CompleteRegistration',
  //     {
  //       content_name: param.userId
  //     },
  //     { eventID: `${param.userId}` }
  //   );
  // } else {
  //   console.log('fbq null');
  // }
};

export const fpDepositViewAddressEvent = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   window.fbq('track', 'ViewContent', {
  //     content_ids: [param.userId],
  //     content_name: 'deposit',
  //     content_type: 'product',
  //     currency: param.symbol,
  //     value: param.network
  //   });
  // } else {
  //   console.log('fbq null');
  // }
};

export const fpInitiateCheckout = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   if (param !== undefined && param !== null) {
  //     window.fbq('track', 'InitiateCheckout', param);
  //   } else {
  //     window.fbq('track', 'InitiateCheckout');
  //   }
  // } else {
  //   console.log('fbq null');
  // }
};

export const fpDepositSuccessEvent = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   if (param.event_id) {
  //     window.fbq(
  //       'track',
  //       'Purchase',
  //       {
  //         value: param.fiat_amount,
  //         currency: param.fiat_coin
  //       },
  //       { eventID: param.event_id }
  //     );
  //   } else {
  //     window.fbq('track', 'Purchase', {
  //       value: param.fiat_amount,
  //       currency: param.fiat_coin
  //     });
  //   }
  // } else {
  //   console.log('fbq null');
  // }
};

export const fpDepositFirstSuccessEvent = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   if (param.event_id) {
  //     window.fbq(
  //       'track',
  //       'Purchase',
  //       {
  //         value: param.fiat_amount,
  //         currency: param.fiat_coin,
  //         content_type: 'FIRST_DEPOSIT'
  //       },
  //       { eventID: param.event_id }
  //     );
  //   } else {
  //     window.fbq('track', 'Purchase', {
  //       value: param.fiat_amount,
  //       currency: param.fiat_coin,
  //       content_type: 'FIRST_DEPOSIT'
  //     });
  //   }
  // } else {
  //   console.log('fbq null');
  // }
};

export const fpPCustomEvent = (param) => {
  // if (window.fbq !== undefined && window.fbq !== null) {
  //   window.fbq(
  //     'trackCustom',
  //     param.event_name,
  //     {
  //       content_name: param.content_name,
  //       content_type: param.content_type
  //     },
  //     { eventID: param.event_id }
  //   );
  // } else {
  //   console.log('fbq null');
  // }
};
