<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, watch, defineExpose } from 'vue';
import Luckywheel from './LuckyWheel/Luckywheel.vue';
import LuckywheelItem from './LuckyWheel/LuckywheelItem.vue';
import iconHp from '@/assets/images/spin/icon_hp.webp'
// import { getTokensPriceService } from '@/assets/network/service/home';

defineOptions({
    name: 'Turntable'
})
const bgColorList = [
    '#F4F4F4',
    'linear-gradient(60deg, #2D5ABF 20%, #407FCE 30%)',
    '#F4F4F4',
    'linear-gradient(60deg, #254DB8 20%, #1C3EB0 30%)',
    '#F4F4F4',
    'linear-gradient(60deg, #2449B6 20%, #2037B3 30%)',
    '#F4F4F4',
    'linear-gradient(60deg, #264EB9 20%, #1839AE 30%)',
    '#F4F4F4',
    'linear-gradient(60deg, #305FC0 20%, #2E5BBF 30%)',
    '#F4F4F4',
]

const emits = defineEmits(['beforeStartCallback', 'endCallback'])

const props = defineProps({
    sections: {
        type: [Array],
        default: () => []
    },
    cryptoList: {
        type: [Array],
        default: () => []
    }
});

const prizeIndex = ref(0)
const prizes = ref([])

// const cryptoList = ref([])

// 监听 sections 的变化
watch(() => props.sections, (val) => {
    prizes.value = []
    prizes.value = val.map((item, index) => {
        return {
            text: item,
            background: bgColorList[index % 12],
            ...item
        }
    });
}, {
    immediate: true,
    deep: true
})

// 创建对抽奖组件的引用
const myLucky = ref(null)


// 点击抽奖按钮会触发star回调
const startCallback = () => {
    emits('beforeStartCallback')
}

// 抽奖结束会触发end回调
const endCallback = () => {
    emits('endCallback', prizes.value[prizeIndex.value])
}

const play = (index) => {
    prizeIndex.value = index
    myLucky.value.draw()
};

const stop = (index) => {
    // myLucky.value.stop(index)
};



const init = () => {
    myLucky.value.init()
};


// const getCoinPriceList = () => {
//     getTokensPriceService()
//         .then((response) => {
//             cryptoList.value = response.data.data;
//         })
//         .catch((error) => {
//             console.log(error);
//         });
// };

const getCryptoIcon = (symbol) => {
    const obj = props.cryptoList.find(item => {
        return item.symbol === symbol
    })
    return obj ? obj.icon_url : iconHp
}





defineExpose({
    play,
    stop,
    init
});


</script>

<template>
    <div class="flex justify-center">
        <div id="myLucky" class="relative">
            <img class="absolute top-[-20px] right-[50%] w-[3rem] z-50"
                style="right: 50%; transform: translateX(50%) rotate(-90deg);"
                src="../../../assets//images/spin/zp_02.png" alt="">
            <Luckywheel ref="myLucky" :prize-index="prizeIndex" @get-prize="startCallback" @game-over="endCallback"
                :total="prizes.length">
                <LuckywheelItem v-for="(prize, index) in prizes" :key="index" :total="prizes.length" :index="index"
                    :bgColor="prize.background">
                    <div class="flex !rotate-[270deg] transform"
                        :class="index % 2 === 0 ? 'text-[#000]' : 'text-[#fff]'">
                        <span class="text-[1rem] mr-[8px] font-medium">{{ prize.text }}</span>
                        <img :src="getCryptoIcon(prize.prize_symbol)" alt="" class="w-[1.2rem] !h-[1.2rem]" />
                    </div>
                </LuckywheelItem>
            </Luckywheel>
        </div>
    </div>
</template>

<style scoped>
@tailwind base;

.rotate-90 {
    transform: rotate(90deg);
}
</style>
