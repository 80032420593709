<template>
    <div>
      <span v-if="remainingTime > 0">{{ formattedTime }}</span>
      <span v-else>Time's up!</span>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch, onBeforeUnmount } from 'vue';
  
  // props
  const props = defineProps({
    targetTimestamp: {
      type: Number,
      required: true
    }
  });
  
  // 剩余时间的 ref
  const remainingTime = ref(0);
  
  // 倒计时的 interval ID
  let intervalId = null;
  
  // 开始倒计时
  const startCountdown = () => {
    clearInterval(intervalId);  // 清除之前的倒计时
    const now = Date.now();
    const targetTime = props.targetTimestamp * 1000; // 将时间戳转换为毫秒
  
    remainingTime.value = Math.floor((targetTime - now) / 1000); // 剩余秒数
    console.log(props.targetTimestamp ,remainingTime.value ,'remainingTime.value')
    if (remainingTime.value > 0) {
      intervalId = setInterval(() => {
        remainingTime.value--;
  
        if (remainingTime.value <= 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  };
  
  // 格式化时间的计算属性
  const formattedTime = computed(() => {
    const days = Math.floor(remainingTime.value / 86400);
    const hrs = Math.floor((remainingTime.value % 86400) / 3600);
    const mins = Math.floor((remainingTime.value % 3600) / 60);
    const secs = remainingTime.value % 60;
    
    return `${days === 0 ? '' : pad(days)+'d:'}${pad(hrs)}h:${pad(mins)}m:${pad(secs)}s`;
  });
  
  // 时间填充函数
  const pad = (num) => {
    return String(num).padStart(2, '0');
  };
  
  // 监听 targetTimestamp 的变化
  watch(() => props.targetTimestamp, () => {
    startCountdown();  // 每次 targetTimestamp 变化时重新启动倒计时
  }, { immediate: true });
  
  // 生命周期钩子
  onBeforeUnmount(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });
  </script>