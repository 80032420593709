<template>
  <div class="main-content">
    <div class="body term_of_service">
      <div class="title">
        {{ $t(term_of_service_page_title) }}
      </div>
      <div v-for="index in term_of_service_title.number" :key="index">
        <div class="content_title">
          {{ $t(term_of_service_title.code_pre + index) }}
        </div>
        <div class="content_desc" v-if="index == 3">
          {{ $t('term_of_service_title_3_1') }}
        </div>
        <div v-for="index2 in getNumber(term_of_service, term_of_service_title.code_pre + index)" :key="index2">
          <div class="content_desc">
            {{ $t('term_of_service_' + index + '_' + index2) }}
          </div>
          <div v-if="term_of_service[term_of_service_title.code_pre + index].contentChildren && term_of_service[term_of_service_title.code_pre + index].contentChildren['term_of_service_' + index + '_' + index2]">
            <div v-for="index3 in getNumber(term_of_service, term_of_service_title.code_pre + index, 'contentChildren', 'term_of_service_' + index + '_' + index2)" :key="index3" class="content_body">
              <div
                v-if="term_of_service[term_of_service_title.code_pre + index].contentChildren['term_of_service_' + index + '_' + index2 + '_class']"
                :class="'content_desc ' + (term_of_service[term_of_service_title.code_pre + index].contentChildren['term_of_service_' + index + '_' + index2 + '_class'] ? term_of_service[term_of_service_title.code_pre + index].contentChildren['term_of_service_' + index + '_' + index2 + '_class'] : '')"
              ></div>
              <div :class="'content_desc'">
                {{ $t('term_of_service_' + index + '_' + index2 + '_' + index3) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
const term_of_service_page_title = ref('term_of_service');

const term_of_service_title = ref({
  code_pre: 'term_of_service_title_',
  number: 26
});
const getNumber = (term, key1, key2, key3) => {
  let obj = term[key1];
  if (key2 && obj) {
    obj = obj[key2];
  }
  if (key3 && obj) {
    obj = obj[key3];
  }
  if (obj && obj.number) {
    return obj.number;
  }
  return obj ? obj : 0;
};
const term_of_service = ref({
  term_of_service_title_1: {
    code_pre: 'term_of_service_1_',
    number: 2
  },
  term_of_service_title_2: {
    code_pre: 'term_of_service_2_',
    number: 1
  },
  term_of_service_title_3: {
    title_desc: 'term_of_service_title_3_1',
    code_pre: 'term_of_service_3_',
    number: 19,
    contentChildren: {
      term_of_service_3_3: 1
    }
  },
  term_of_service_title_4: {
    code_pre: 'term_of_service_4_',
    number: 4,
    contentChildren: {
      term_of_service_4_1: 14
    }
  },
  term_of_service_title_5: {
    code_pre: 'term_of_service_5_',
    number: 19
  },
  term_of_service_title_6: {
    code_pre: 'term_of_service_6_',
    number: 14
  },
  term_of_service_title_7: {
    code_pre: 'term_of_service_7_',
    number: 2
  },
  term_of_service_title_8: {
    code_pre: 'term_of_service_8_',
    number: 6
  },
  term_of_service_title_9: {
    code_pre: 'term_of_service_9_',
    number: 15
  },
  term_of_service_title_10: {
    code_pre: 'term_of_service_10_',
    number: 3
  },
  term_of_service_title_11: {
    code_pre: 'term_of_service_11_',
    number: 3
  },
  term_of_service_title_12: {
    code_pre: 'term_of_service_12_',
    number: 13
  },
  term_of_service_title_13: {
    code_pre: 'term_of_service_13_',
    number: 4
  },
  term_of_service_title_14: {
    code_pre: 'term_of_service_14_',
    number: 1
  },
  term_of_service_title_15: {
    code_pre: 'term_of_service_15_',
    number: 2
  },
  term_of_service_title_16: {
    code_pre: 'term_of_service_16_',
    number: 3
  },
  term_of_service_title_17: {
    code_pre: 'term_of_service_17_',
    number: 3,
    contentChildren: {
      term_of_service_4_2: 3
    }
  },
  term_of_service_title_18: {
    code_pre: 'term_of_service_18_',
    number: 4
  },
  term_of_service_title_19: {
    code_pre: 'term_of_service_19_',
    number: 3
  },
  term_of_service_title_20: {
    code_pre: 'term_of_service_20_',
    number: 6,
    contentChildren: {
      term_of_service_20_3: 14,
      term_of_service_20_3_class: 'li'
    }
  },
  term_of_service_title_21: {
    code_pre: 'term_of_service_21_',
    number: 1
  },
  term_of_service_title_22: {
    code_pre: 'term_of_service_22_',
    number: 6
  },
  term_of_service_title_23: {
    code_pre: 'term_of_service_23_',
    number: 1
  },
  term_of_service_title_24: {
    code_pre: 'term_of_service_24_',
    number: 1
  },
  term_of_service_title_25: {
    code_pre: 'term_of_service_25_',
    number: 2
  },
  term_of_service_title_26: {
    code_pre: 'term_of_service_26_',
    number: 7
  }
});
</script>

<style scoped>
.web .term_of_service .title {
  margin-top: 3rem;
}
.web .term_of_service .footer {
  margin-bottom: 3rem;
}
.phone .term_of_service {
  padding: 1.5rem 1rem;
}
.term_of_service div {
  width: 100%;
}
.term_of_service .title {
  color: var(--color-8ea3c3);
  padding: 0.5rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8125rem;
  letter-spacing: 0em;
  text-align: left;
}
.term_of_service .content_title {
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875;
  letter-spacing: 0em;
  text-align: left;
  color: var(--term_of__title_color);
}
.term_of_service .content_body {
  display: flex;
}
.term_of_service .content_desc.li {
  width: 2rem;
  padding-left: 1.5rem;
}
.term_of_service .content_desc {
  padding: 0.5rem 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.9375rem;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-8ea3c3);
}
.term_of_service .content_desc.li::before {
  content: '\2022'; /* Unicode编码，代表圆点 */
  margin-right: 0.5; /* 调整圆点与div之间的距离 */
}
</style>
