<template>
  <div class="locale-changer">
    <div :class="'menu-item ' + (localeShow ? 'menu-selected' : '')" @click="showI18nMenuItem()">
      <div class="menu-item-img i18n">
        <img :src="chooseLanguageImage($i18n.locale)" />
      </div>
      <div class="menu-item-text" v-if="myShowMenu" style="width: 55%">
        {{ chooseLanguageText($i18n.locale) }}
      </div>
      <div class="menu-item-text last" v-if="myShowMenu" style="float: right">
        <div style="display: flex; align-items: center; height: 2.8rem; float: right">
          <img src="../assets/images/home/Frame 33764.svg" style="width: 1.125rem; height: 0.5rem; transform: rotate(-90deg)" v-if="!localeShow" />
          <img src="../assets/images/home/Frame 33764.svg" style="height: 0.5rem; width: 1.125rem" v-if="localeShow" />
        </div>
      </div>
    </div>
    <div :class="'menu-item ' + ($i18n.locale == locale ? 'menu-selected' : '')" v-show="!($i18n.locale == locale) && localeShow && myShowMenu" @click="chooseLanguage($i18n, locale)" v-for="locale in $i18n.availableLocales" :key="'locale-' + locale">
      <div class="menu-item-img i18n">
        <img :src="chooseLanguageImage(locale)" />
      </div>
      <div class="menu-item-text">{{ chooseLanguageText(locale) }}</div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>
<script setup>
import languageEN from '../assets/images/drawer/language-en.png';
import languageKO from '../assets/images/drawer/language-ko.png';
import languageSG from '../assets/images/drawer/language-sg.png';
import languageBR from '../assets/images/drawer/language-br.png';
import languageES from '../assets/images/drawer/language-es.png';
import languageTR from '../assets/images/drawer/language-trq.png';
import languageSW from '../assets/images/drawer/language-sw.webp';
// import {ref} from "vue";
// let localeShow = ref(false)
const chooseLanguageImage = (locale) => {
  if (locale.toLowerCase().includes('ko')) {
    return languageKO;
  } else if (locale.toLowerCase().includes('zh')) {
    return languageSG;
  } else if (locale.toLowerCase().includes('pt')) {
    return languageBR;
  } else if (locale.toLowerCase().includes('es')) {
    return languageES;
  } else if (locale.toLowerCase().includes('tr')) {
    return languageTR;
  } else if (locale.toLowerCase().includes('sw')) {
    return languageSW;
  } else {
    return languageEN;
  }
};
// const showI18nMenuItem = (localeShow)=>{
//   localeShow = true
//   console.log(localeShow)
// // }
// const chooseLanguage = (locale, locale1,localeShow) => {
//   locale.locale = locale1
//   localeShow = false
// }
const chooseLanguageText = (locale) => {
  if (locale.toLowerCase().includes('ko')) {
    return '한국인';
  } else if (locale.toLowerCase().includes('zh')) {
    return '繁體中文';
  } else if (locale.toLowerCase().includes('pt')) {
    return 'Português';
  } else if (locale.toLowerCase().includes('es')) {
    return 'Español';
  } else if (locale.toLowerCase().includes('tr')) {
    return 'Türkçe';
  } else if (locale.toLowerCase().includes('sw')) {
    return 'Kiswahili';
  } else {
    return 'English';
  }
};
</script>

<script>
// import myI18n from "@/components/myI18n.vue";

export default {
  name: 'App',
  props: {
    showMenu: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    showMenu: function (newVal) {
      this.myShowMenu = newVal;
      this.localeShow = false;
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      localeShow: false,
      myShowMenu: true
    };
  },
  created() {
    let locale = localStorage.getItem('locale');
    if (locale) {
      this.$i18n.locale = locale;
    }
    this.myShowMenu = this.showMenu;
  },
  methods: {
    showI18nMenuItem() {
      this.localeShow = !this.localeShow;
      if (!this.showMenu) {
        this.$emit('showMenuChange');
      }
    },
    chooseLanguage(locale, locale1) {
      if (locale.locale == locale1) {
        return;
      }
      locale.locale = locale1;
      localStorage.setItem('locale', locale.locale);
      this.localeShow = false;
      window.location.reload();
    }
  }
};
</script>
