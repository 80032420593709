<template>
  <div class="main-content" :style="style ? style : ''">
    <div class="body game-list-content">
      <div style="clear: both"></div>
      <div class="games-list search-result" ref="gamesList" :key="config.setupSize" :style="config.style">
        <div class="game-main" ref="gameMain" :id="'game_' + index" v-for="(myGame, index) in pageGameList" :key="index" v-show="gameImgLoads[myGame.game_uid]">
          <div class="game-main-image" @click="toPlay(myGame)">
            <a
              v-if="myGame.game_uid"
              :href="'/gameDetail?id=' + myGame.game_uid"
              @mousedown.left="
                () => {
                  myGame.leftDown = true;
                }
              "
              :class="myGame.leftDown ? 'events_none' : ''"
            >
              <img :src="myGame.image || myGame.game_image || myGame.image_url" @load="onImageLoad(myGame, index)" />
            </a>
            <img v-else :src="myGame.image || myGame.game_image || myGame.image_url" @load="onImageLoad(myGame, index)" />
          </div>
          <div style="clear: both"></div>
          <div class="game-main-bottom" v-if="(game && game.show_bottom) || !game">
            <div class="left" v-if="myGame.playing_count || !myGame.is_maintain">
              <div :class="!myGame.is_maintain ? 'dot' : 'no'"></div>
              <div class="text" v-show="!myGame.is_maintain">{{ myGame.playing_count }}</div>
              <div style="padding-left: 0.3rem; max-width: calc(100% - 7px - 0.3rem)">
                {{ !myGame.is_maintain ? $t('playing') : $t('in_maintenance') }}
              </div>
            </div>
            <div class="left" v-else>{{ ' ' }}</div>
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</template>
<script setup>
// import { useI18n } from 'vue-i18n';
// const { t } = useI18n();
import { ref, onUnmounted, onMounted } from 'vue';
import { gameResize, searchGameListBreakPx } from '@/assets/tools/CommonTool';
const config = ref({
  breakPx: 1490,
  setupSize: 8,
  style: 'grid-template-columns: repeat(8, 1fr);gap: 20px 10px;display:grid;'
});
const loadConfig = () => {
  config.value = gameResize(searchGameListBreakPx);
};
onUnmounted(() => {
  window.removeEventListener('resize', loadConfig);
});
onMounted(() => {
  loadConfig();
  window.addEventListener('resize', loadConfig);
});
</script>
<script>
import { setGameInfo, getCookiesByKey, setCookiesByKey } from '@/assets/tools/CommonTool';

export default {
  name: 'App',
  props: {
    keyChange: {
      type: Number,
      default: 0
    },
    style: {
      type: String,
      default: ''
    },
    game: {},
    gameList: []
  },
  watch: {
    keyChange: function () {
      let loadFinish = true;
      for (let key in this.gameImgLoads) {
        if (!this.gameImgLoads[key]) {
          loadFinish = false;
          break;
        }
      }
      if (loadFinish) {
        this.$emit('loadFinish');
      }
    },
    gameList: function (newVal) {
      this.allSize = newVal.length;
      this.loadIndex = 0;
      for (let i = 0; i < newVal.length; i++) {
        let item = newVal[i];
        this.gameImgLoads[item.game_uid] = this.gameImgLoads[item.game_uid] ? true : false;
        newVal[i].myLoad = this.gameImgLoads[item.game_uid];
      }
      this.lastPageSize = newVal.length - this.lastPageSize > 0 ? newVal.length - this.lastPageSize : newVal.length;
      this.pageGameList = newVal;
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      gameImgLoads: {},
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 0,
      loadIndex: 0,
      lastPageSize: 0
    };
  },
  created() {
    this.pageGameList = this.gameList;
    this.allSize = this.gameList.length;
    this.lastPageSize = this.gameList.length;
    // this.isPhone = this.$isMobile()
    // window.addEventListener('resize',this.resize)
  },
  methods: {
    onImageLoad(myGame) {
      myGame.isLoad = true;
      this.gameImgLoads[myGame.game_uid] = true;
      let loadFinish = true;
      for (let key in this.gameImgLoads) {
        if (!this.gameImgLoads[key]) {
          loadFinish = false;
          break;
        }
      }
      if (loadFinish) {
        this.$emit('loadFinish');
        let top_px = getCookiesByKey('new_top_px')
        if(top_px){
          // 滚动到元素所在位置
          window.scrollTo({
            top: top_px,
            behavior: 'smooth' //  smooth 代表平滑滚动
          });
        }
        setCookiesByKey('new_top_px', '')
      }
    },
    toPlay(game) {
      if (game.isAll && game.allUrl) {
        this.$router.push(game.allUrl);
        return;
      }
      if (this.game && this.game.gameCode && this.game.gameCode == 'game_support') {
        this.$router.push(this.game.toUrl + '?providerName=' + game.name);
        return;
      }
      if (!game.game_uid) {
        return;
      }
      setGameInfo(JSON.stringify(game));
      if (this.$route.path == '/gameDetail') {
        window.location.reload();
      } else {
        setCookiesByKey('top_px', window.scrollY)
        this.$router.push('/gameDetail');
      }
    }
  }
};
</script>

<style scoped>
.phone .main-content {
  padding: 0rem 0 0 0 !important;
}
::v-deep .slots .game-main {
  max-width: 12.5%;
  margin-right: 1.4% !important;
}
::v-deep .web .game-list-content .games-list .game-main .game-main-image img {
  width: 100% !important;
  max-width: 100% !important;
}
.web .game-list-content .games-list .game-main {
  /*width: calc(14.2857% - 0.857rem);
    padding-right: 1rem;*/
  margin-right: 0;
}
.left {
  align-items: center;
}
.text {
  padding: 0 0.3rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.0625;
  letter-spacing: 0em;
  text-align: left;
  color: var(--game-list-text-color);
}

.dot {
  width: 0.415rem;
  height: 0.415rem;
  margin-left: 2px;
  background-color: var(--positive-colors);
  border-radius: 50%;
  animation: blink 1s infinite ease-out;
  margin-left: 2px;
}

.no {
  width: 0.415rem;
  height: 0.415rem;
  margin-left: 2px;
  background-color: var(--no-positive-colors);
  border-radius: 50%;
  animation: blink2 1s infinite ease-out;
  margin-left: 2px;
}
@keyframes blink {
  0% {
    box-shadow: 0 0 var(--positive-colors-box);
  }
  30% {
    box-shadow: 0 0 2px 2px var(--positive-colors-box);
  }
  70% {
    box-shadow: 0 0 2px 2px var(--positive-colors-box);
  }
  100% {
    box-shadow: 0 0 var(--positive-colors-box);
  }
}
@keyframes blink2 {
  0% {
    box-shadow: 0 0 var(--no-positive-colors-box);
  }
  30% {
    box-shadow: 0 0 2px 2px var(--no-positive-colors-box);
  }
  70% {
    box-shadow: 0 0 2px 2px var(--no-positive-colors-box);
  }
  100% {
    box-shadow: 0 0 var(--no-positive-colors-box);
  }
}
.events_none {
  pointer-events: none;
}
</style>
