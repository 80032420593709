<template>
    <div class="relative">
        <img :src="zp01" class="absolute top-[-25px] left-[-25px] w-[350px]" alt="">
        <div class="">
            <div class="vue-lucky-wheel bg-spin-bg" :style="style">
                <div class="vue-lucky-wheel-main" :style="mainStyle">
                    <slot />
                </div>
                <div class="vue-lucky-wheel-button" @click="getPrize">
                    <slot name="button">
                        <img :src="defaultButton" :width="300 / size * 85" :height="300 / size * 85">
                    </slot>
                </div>
            </div>
        </div>
    </div>


</template>

<script setup>
import { ref, computed, watch } from 'vue'
import defaultButton from '@/assets/images/spin/zp_03.png'
import zp01 from '@/assets/images/spin/zp_01.webp'
const props = defineProps({
    defaultBackground: {
        type: Boolean,
        default: true
    },
    size: {
        type: Number,
        default: 300
    },
    rotateTime: {
        type: Number,
        default: 6
    },
    prizeIndex: {
        type: Number
    },
    total: {
        type: Number
    }
})

const emit = defineEmits(['get-prize', 'game-over'])

const itemNumber = ref(0)
const rotateAngle = ref(0)
const startAngle = ref(0)
const isDrawing = ref(false)

const style = computed(() => ({
    width: `${props.size}px`,
    height: `${props.size}px`,
}))

const mainStyle = computed(() => ({
    ...style.value,
    transform: `rotate(${rotateAngle.value}deg)`,
    transition: `transform ${props.rotateTime}s ease-in-out`,
}))

const prizeAngleList = computed(() => {
    let itemNum = itemNumber.value
    const onePrizeAngle = 360 / itemNum / 2
    const angles = []

    while (itemNum > 0) {
        angles.push(onePrizeAngle + --itemNum * onePrizeAngle * 2)
    }

    return angles
})


const getPrize = () => {
    if (isDrawing.value) return

    isDrawing.value = true
    emit('get-prize')
}

watch(() => props.prizeIndex, () => {
    if (props.prizeIndex !== undefined) {
        draw()
    }
})

watch(() => props.total, (val) => {
    itemNumber.value = val
}, {
    immediate: true
})



const draw = () => {
    rotateAngle.value = startAngle.value + 6 * 360 + prizeAngleList.value[props.prizeIndex] - startAngle.value % 360
    startAngle.value = rotateAngle.value
    setTimeout(() => {
        isDrawing.value = false
        emit('game-over')
    }, props.rotateTime * 1000)
}

defineExpose({
    draw
})

</script>

<style scoped>
.vue-lucky-wheel {
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    margin: 0 auto;
}

.vue-lucky-wheel-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>