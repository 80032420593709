<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, ref, computed, reactive, onUnmounted } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../../tools/screenBreakpoint';
import wgTypeInput from '@/components/common/wgTypeInput.vue';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { toast } from 'vue3-toastify';
import { bindEmailSendCodeService, bindEmailService } from '@/assets/network/service/user';
import md5 from 'crypto-js/md5';
import wgButton from '@/components/common/wgButton.vue';

const props = defineProps({
  show: Boolean,
  bindEmail: String,
  isReadOnly: {
    type: Boolean,
    default: true
  }
});
const emit = defineEmits(['close', 'bindSuccess']);

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  bind_email: t('Email_Verification').toLocaleUpperCase(),
  once_the__account: t('It is strongly recommended that you complete email verification to enhance the security of your account.'),
  set_email__password: t('set_email__password'),
  confirm_email__password: t('confirm_email__password'),
  verification: t('verification'),
  send_code: t('send_code'),
  bind: t('confirm').toLocaleUpperCase(),
  bind_successfully: t('bind_successfully'),
  email_is_required: t('email_is_required'),
  email_format___incorrect: t('email_format___incorrect'),
  password_is_required: t('password_is_required'),
  please_input___again: t('please_input___again'),
  characters_in___numbers: t('characters_in___numbers'),
  the_password___inconsistent: t('the_password___inconsistent'),
  please_input___code: t('please_input___code')
};

const email = ref('');
const password = ref('');
// const confirmPassword = ref('');
const verificationCode = ref('');

const handleEmailValueChanged = (value) => {
  email.value = value;
};
const handlePasswordValueChanged = (value) => {
  password.value = value;
};
// const handleConfirmPasswordValueChanged = (value) => {
//   confirmPassword.value = value;
// };
const handleVerifyValueChanged = (value) => {
  verificationCode.value = value;
};

const timeValue = 60;
const intervalId = ref(null);
const countdownTime = ref(timeValue);
const sendCoding = ref(false);
const confirmLoadingJson = ref(loadingAniJson);
const confirming = ref(false);
const handleSendCodeClick = () => {
  if (props.bindEmail) {
    handleEmailValueChanged(props.bindEmail);
  }
  if (email.value == null || email.value.length <= 0) {
    toast(textData.email_is_required, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!email.value.includes('@')) {
    toast(textData.email_format___incorrect, {
      autoClose: 2000,
      type: 'error'
    });
    return;
  }
  if ((password.value == null || password.value.length <= 0) && !props.bindEmail) {
    toast(textData.password_is_required, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!handlePasswordIsVerify(password.value) && !props.bindEmail) {
    toast(textData.characters_in___numbers, { autoClose: 2000, type: 'error' });
    return;
  }

  sendCoding.value = true;
  sendCodeToServer();
};
const handleBindClick = () => {
  if (props.bindEmail) {
    handleEmailValueChanged(props.bindEmail);
  }
  if (email.value == null || email.value.length <= 0) {
    toast(textData.email_is_required, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!email.value.includes('@')) {
    toast(textData.email_format___incorrect, {
      autoClose: 2000,
      type: 'error'
    });
    return;
  }
  if ((password.value == null || password.value.length <= 0) && !props.bindEmail) {
    toast(textData.password_is_required, { autoClose: 2000, type: 'error' });
    return;
  }
  if (!handlePasswordIsVerify(password.value) && !props.bindEmail) {
    toast(textData.characters_in___numbers, { autoClose: 2000, type: 'error' });
    return;
  }
  
  if (verificationCode.value == null || verificationCode.value.length <= 0) {
    toast(textData.please_input___code, { autoClose: 2000, type: 'error' });
    return;
  }

  confirming.value = true;
  bindEmailToServer();
};

const handleCanNext = computed(() => {
  if (props.bindEmail) {
    if ((reactive(email).value != null && reactive(email).value.length > 0 && reactive(verificationCode).value != null && reactive(verificationCode).value.length > 0) || reactive(confirming).value) {
      return true;
    }
  } else {
    if ((reactive(email).value != null && reactive(email).value.length > 0 && reactive(password).value != null && reactive(password).value.length > 0 && reactive(verificationCode).value != null && reactive(verificationCode).value.length > 0) || reactive(confirming).value) {
      return true;
    }
  }
  return false;
});
const handlePasswordIsVerify = (password) => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{6,12}$/.test(password);
};

const sendCodeToServer = async () => {
  try {
    const param = {
      email: email.value
    };
    await bindEmailSendCodeService(param);
    sendCoding.value = false;
    intervalId.value = setInterval(() => {
      if (countdownTime.value <= 1) {
        clearInterval(intervalId.value);
        intervalId.value = null;
        countdownTime.value = timeValue;
      } else {
        countdownTime.value -= 1;
      }
    }, 1000);
  } catch (error) {
    sendCoding.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};
const bindEmailToServer = async () => {
  try {
    const param = {
      email: email.value,
      key: verificationCode.value
      // password1: md5(password.value + '{PWD_Salt_CC_Poker}').toString(),
      // password2: md5(confirmPassword.value + '{PWD_Salt_CC_Poker}').toString()
    };
    if (!props.bindEmail) {
      param.password1 = md5(password.value + '{PWD_Salt_CC_Poker}').toString();
      param.password2 = md5(password.value + '{PWD_Salt_CC_Poker}').toString();
    }
    await bindEmailService(param);
    confirming.value = false;
    toast(textData.bind_successfully, { autoClose: 2000 });
    emit('bindSuccess', email.value);
  } catch (error) {
    confirming.value = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};

onUnmounted(() => {
  clearInterval(intervalId.value);
  intervalId.value = null;
});
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content !bg-[#121829] ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        <div class="header-title text-[#fff]">{{ textData.bind_email }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <div class="tip p-[1rem] bg-[#222942] rounded-md">
        {{ textData.once_the__account }}
      </div>
      <wg-type-input type="email" :placeholder="t('YourEmailAddress')" @onValueChange="handleEmailValueChanged" :value="props.bindEmail" :readOnly="props.bindEmail.length > 0"></wg-type-input>
      <wg-type-input v-if="!props.bindEmail" type="password" :placeholder="textData.set_email__password" @onValueChange="handlePasswordValueChanged"></wg-type-input>
      <wg-type-input type="verifyCode" :placeholder="textData.verification" @onValueChange="handleVerifyValueChanged">
        <button class="verify-code-button" @click="handleSendCodeClick" :disabled="sendCoding || intervalId != null">
          {{ sendCoding ? '' : intervalId != null ? `${countdownTime}s` : textData.send_code }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="40" :height="40" v-if="sendCoding"></vue3-lottie>
        </button>
      </wg-type-input>
      <div class="bottom">
        <wgButton :class="handleCanNext ? 'bottom-button' : 'bottom-button-disable'" @click="handleBindClick" :disabled="confirming || !handleCanNext">
          {{ confirming ? '' : textData.bind }}
          <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="confirming"></vue3-lottie>
        </wgButton>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 1.125rem 1.1875rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip {
  color: var(--green-color);
  font-size: 0.875rem;
  text-align: left;
}

.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
}
.bottom-button-disable {
  border: none;
  padding: 0;
  font-size: 1.125rem;
  line-height: 2.5rem;
  font-weight: 500;
  border-radius: 0.5rem;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}

.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
