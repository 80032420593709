<script setup>
// 导入
import { defineProps } from 'vue';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import QrcodeVue from 'qrcode.vue';
import { useClipboard } from '@vueuse/core';
import { toast } from 'vue3-toastify';
// 传参
const props = defineProps({
  titleText: {
    type: String,
    default: 'Crypto'
  }
});
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
const { copy } = useClipboard({ legacy: true });
// 计算属性
// 生命周期
// 方法
const clickCopy = () => {
  copy(walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address);
  toast(t('copy_successfully'), { autoClose: 1000 });
};
</script>

<template>
  <div class="relative">
    <div class="text-[12px] text-[#5B7BAB] text-left font-[700] mb-[5px]">{{ props.titleText }}</div>
    <div class="bg-[#0D101E] rounded-[4px] p-[10px]">
      <div class="flex gap-[10px] mb-[10px]">
        <div class="flex rounded-[4px] border-4 border-solid border-[#ffffff]">
          <QrcodeVue :value="walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address" level="H" :size="130" />
        </div>
        <div class="w-full flex flex-col justify-evenly">
          <div class="text-[#fff] text-[13px] font-[400] text-left break-all">{{ walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].address }}</div>
          <div class="copy-btn text-[12px] font-[700] rounded-[4px] py-[8px]" @click="clickCopy">
            {{ t('Copy Address').toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center bg-[#000] rounded-[4px] text-[#AAC4ED] text-[14px] font-[400] p-[5px]">
        <div class="flex items-center gap-[px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M13.3987 4.31184C13.7775 4.52621 14.0906 4.83934 14.305 5.21809L20.6225 16.3812C21.275 17.5343 20.8694 18.9981 19.7162 19.6512C19.3556 19.855 18.9488 19.9625 18.5344 19.9625H5.89937C4.57437 19.9625 3.5 18.8881 3.5 17.5631C3.5 17.1487 3.60687 16.7418 3.81125 16.3812L10.1287 5.21809C10.7812 4.06496 12.2456 3.65871 13.3987 4.31184ZM12.2056 15.7137C11.6313 15.7137 11.165 16.19 11.165 16.7781C11.165 17.3662 11.6306 17.8425 12.2056 17.8425C12.78 17.8425 13.2463 17.3662 13.2463 16.7781C13.2463 16.19 12.7806 15.7137 12.2056 15.7137ZM12.2056 8.61809C11.6313 8.61809 11.165 9.08371 11.165 9.65871V13.9637C11.165 14.5381 11.6306 15.0043 12.2056 15.0043C12.7806 15.0043 13.2463 14.5387 13.2463 13.9637V9.65871C13.2463 9.08434 12.7806 8.61809 12.2056 8.61809Z"
              fill="#80A4DB"
            />
          </svg>
          {{ t('Minimum Deposit') }}
        </div>
        <div>{{ walletStore.cryptoSelectItem.chains[walletStore.cryptoChainIndex].min_deposit_amount }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.copy-btn {
  cursor: pointer;
  text-align: center;
  background-color: #222942;
  color: #fff;
  box-shadow:
    0px -3px 0px 0px rgba(0, 0, 0, 0.15) inset,
    0px 0px 12px 0px #142034 inset;
}
</style>
