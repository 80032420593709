<template>
  <div :class="'main-content ' + (!isHomeFooter ? 'other-home-footer footer' : 'footer')">
    <div class="body">
      <footer-content />
    </div>
  </div>
</template>
<script setup>
import footerContent from './footerContent.vue';
// import { useI18n } from 'vue-i18n';
// const { t } = useI18n();
</script>
<script>
export default {
  name: 'footerCom',
  props: {
    isHomeFooter: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    // myI18n
  },
  data() {
    return {
      menuCode: 'home',

      onlineNum: 0,
      isPhone: false,
      phone_show_menu: false
    };
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
  },
  created() {
    this.isPhone = this.$isMobile();
    window.addEventListener('resize', this.resize);
    this.resize();
  },
  methods: {
    resize() {
      this.isPhone = this.$isMobile();
    }
  }
};
</script>
<style>
.phone .footer {
  margin-bottom: 4rem;
}
.main-content.footer {
  background-color: var(--foot-body-bg-color);
}
</style>
