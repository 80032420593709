<!-- eslint-disable vue/no-dupe-keys -->
<script setup>
import { useI18n } from 'vue-i18n';
import { defineProps, defineEmits, ref, onMounted, watchEffect } from 'vue';
import wgDialog from '@/components/common/wgDialog.vue';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import loadingAniJson from '@/assets/animation/expott_loading.json';

import { toast } from 'vue3-toastify';

import { add_bank_card, add_bank_account } from '@/assets/network/service/wallet';

import { Vue3Lottie } from 'vue3-lottie';
const confirmLoadingJson = ref(loadingAniJson);
const props = defineProps({
  show: Boolean,
  confirming: {
    type: Boolean,
    default: () => false
  },
  is_otp: {
    type: Boolean,
    default: () => false
  },
  symbol: {
    type: String,
    default: ''
  },
  pinPassword: {
    type: String,
    default: ''
  },
  person_name: {
    type: String,
    default: ''
  },
  person_id: {
    type: String,
    default: ''
  },
  account_name: {
    type: String,
    default: ''
  }
});
const emit = defineEmits(['close', 'addBlankSuccess']);
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  add_pix: t('add_pix'),
  add_bank_account: t('add_bank_account'),
  please_enter_the_name: t('please_enter_the_name'),
  please_enter_the_11digit: t('please_enter_the_11digit'),
  please_choose_account_type: t('please_choose_account_type'),
  success: t('success'),
  confirm: t('confirm'),
  bank_name: t('bank_name'),
  please_enter_your_pix_account: t('please_enter_your_pix_account'),
  the_16_digit_account: t('the_16_digit_account'),
  the_18_digit_account: t('the_18_digit_account')
};

// const account_types  = ref(['CPF','PHONE','EMAIL'])
const account_types = ref(['CPF', 'EMAIL']);
const mxn_account_types = ref(['CLABE', 'BANK_CARD']);
const selected_account_type = ref(false);
const selected_bank_name = ref(false);
const bankNames = ref([]);
const account_type = ref('');
// eslint-disable-next-line vue/no-dupe-keys
const person_name = ref('');
// eslint-disable-next-line vue/no-dupe-keys
const person_id = ref('');
// eslint-disable-next-line vue/no-dupe-keys
const account_name = ref('');
const account_id = ref('');

const bank_name = ref('');
const all_bankNames = ref([
  'BANCOMEXT',
  'BANJERCITO',
  'BANSEFI',
  'BANAMEX',
  'SANTANDER',
  'BAJIO',
  'MIFEL',
  'BANREGIO',
  'BANSI',
  'BANORTE',
  'MUFG',
  'BMONEX',
  'CREDIT SUISSE',
  'AUTOFIN',
  'COMPARTAMOS',
  'ACTINVER',
  'BANCOPPEL',
  'CONSUBANCO',
  'CIBANCO',
  'BANKAOOL',
  'INMOBILIARIO',
  'BANCREA',
  'ICBC',
  'SHINHAN',
  'BANCO S3',
  'GBM',
  'VALUE',
  'MULTIVA CBOLSA',
  'VALMEX',
  'CB INTERCAM',
  'FINCOMUN',
  'REFORMA',
  'EVERCORE',
  'KUSPIT',
  'ASP INTEGRA OPC',
  'CAJA POP MEXICA',
  'CAJA TELEFONIST',
  'FONDO (FIRA)',
  'FOMPED',
  'CoDi Valida',
  'BANAMEX2',
  'ACTINVER',
  'AKALA',
  'ASP INTEGRA OPC',
  'AZTECA',
  'BANAMEX',
  'BANCO FINTERRA',
  'BANCOMEXT',
  'BANCREA',
  'BANK OF AMERICA',
  'BANREGIO',
  'BANSI',
  'BBASE',
  'BMONEX',
  'CAJA TELEFONIST',
  'CI BOLSA',
  'CoDi Valida',
  'CONSUBANCO',
  'CREDIT SUISSE',
  'DONDE',
  'FINAMEX',
  'FOMPED',
  'GBM',
  'HSBC',
  'INBURSA',
  'INMOBILIARIO',
  'INVERCAP',
  'JP MORGAN',
  'LIBERTAD',
  'MIFEL',
  'MONEXCB',
  'MULTIVA BANCO',
  'NAFIN',
  'PROFUTURO',
  'SABADELL',
  'SCOTIABANK',
  'STP',
  'TRANSFER',
  'VALMEX',
  'VE POR MAS',
  'VOLKSWAGEN',
  'BANORTE2',
  'SANTANDER2',
  'BANOBRAS',
  'NAFIN',
  'HIPOTECARIA FED',
  'BBVA MEXICO',
  'HSBC',
  'INBURSA',
  'SCOTIABANK',
  'INVEX',
  'AFIRME',
  'BANK OF AMERICA',
  'JP MORGAN',
  'VE POR MAS',
  'AZTECA',
  'BARCLAYS',
  'MULTIVA',
  'INTERCAM BANCO',
  'ABC CAPITAL',
  'VOLKSWAGEN',
  'BBASE',
  'PAGATODO',
  'DONDE',
  'BANCO FINTERRA',
  'SABADELL',
  'MIZUHO BANK',
  'MONEXCB',
  'MASARI',
  'VECTOR',
  'FINAMEX',
  'PROFUTURO',
  'CI BOLSA',
  'AKALA',
  'STP',
  'CREDICAPITAL',
  'UNAGRA',
  'LIBERTAD',
  'CRISTOBAL COLON',
  'TRANSFER',
  'INVERCAP',
  'INDEVAL',
  'ARCUS',
  'ABC CAPITAL',
  'AFIRME',
  'ARCUS',
  'AUTOFIN',
  'BAJIO',
  'BANAMEX2',
  'BANCO S3',
  'BANCOPPEL',
  'BANJERCITO',
  'BANOBRAS',
  'BANSEFI',
  'BARCLAYS',
  'BBVA MEXICO',
  'CAJA POP MEXICA',
  'CB INTERCAM',
  'CIBANCO',
  'COMPARTAMOS',
  'CREDICAPITAL',
  'CRISTOBAL COLON',
  'EVERCORE',
  'FINCOMUN',
  'FONDO (FIRA)',
  'HIPOTECARIA FED',
  'ICBC',
  'INDEVAL',
  'INTERCAM BANCO',
  'INVEX',
  'KUSPIT',
  'MASARI',
  'MIZUHO BANK',
  'MUFG',
  'MULTIVA CBOLSA',
  'PAGATODO',
  'REFORMA',
  'SANTANDER',
  'SHINHAN',
  'TACTIV CB',
  'UNAGRA',
  'VALUE',
  'VECTOR',
  'AZTECA2',
  'HSBC2',
  'BBVA BANCOMER2'
]);

const selectAccountType = (type) => {
  account_type.value = type;
  selected_account_type.value = false;
};
const oldBank_name = ref('');
const selectBankNames = (name) => {
  bank_name.value = name;
  selected_bank_name.value = false;
  oldBank_name.value = name;
};
const bankNameBlur = () => {
  if (bank_name.value) {
    if (all_bankNames.value.indexOf(bank_name.value) == -1) {
      bank_name.value = oldBank_name.value;
    }
  }
};
const filterBankNames = () => {
  bankNames.value = [];
  console.log(bank_name.value);
  if (bank_name.value) {
    all_bankNames.value.forEach((item) => {
      if (item.indexOf(bank_name.value) > -1 && bankNames.value.indexOf(item) == -1) {
        bankNames.value.push(item);
      }
    });
  } else {
    all_bankNames.value.forEach((item) => {
      if (bankNames.value.indexOf(item) == -1) {
        bankNames.value.push(item);
      }
    });
  }
};
const all_number = /^\d+$/;
const handleCanNext = () => {
  if (props.symbol == 'BRL') {
    if (!account_type.value) {
      return false;
    }
    if (!person_name.value) {
      return false;
    }
    if (!person_id.value || person_id.value.length != 11 || isNaN(person_id.value)) {
      return false;
    }
    if ((account_type.value == 'PHONE' || account_type.value.value == 'EMAIL') && !account_id.value) {
      return false;
    }
  } else {
    console.log(!all_number.test(account_id.value) || account_id.value.length !== 18);
    if (!account_name.value) {
      return false;
    }
    if (!bank_name.value) {
      return false;
    }
    if (!account_type.value) {
      return false;
    }
    if (!account_id.value) {
      return false;
    }
    // const mxn_account_types  = ref(['CLABE','BANK_CARD'])
    if (account_type.value == 'CLABE' && (!all_number.test(account_id.value) || account_id.value.length !== 18)) {
      return false;
    }
    if (account_type.value == 'BANK_CARD' && (!all_number.test(account_id.value) || account_id.value.length !== 16)) {
      return false;
    }
  }
  return true;
};
const handleClick = async () => {
  try {
    let params = {
      account_type: account_type.value,
      person_name: person_name.value,
      person_id: person_id.value,
      account_id: account_id.value,
      withdraw_password: props.pinPassword
    };
    if (props.symbol != 'BRL') {
      params = {
        withdraw_password: props.pinPassword,
        account_name: account_name.value,
        bank_name: bank_name.value,
        account_type: account_type.value,
        account_id: account_id.value
      };
      await add_bank_account(params);
    } else {
      await add_bank_card(params);
    }
    clearAll();
    toast(textData.success, { autoClose: 2000, type: 'success' });
    emit('addBlankSuccess');
  } catch (error) {
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 2000, type: 'error' });
  }
};
const is_read_only = ref(false);
watchEffect(() => {
  if (props.person_name) {
    person_name.value = props.person_name;
    person_id.value = props.person_id;
  }
  if (props.account_name) {
    account_name.value = props.account_name;
  }

  if (props.person_name && props.symbol == 'BRL') {
    is_read_only.value = true;
  } else if (props.account_name && props.symbol == 'MXN') {
    is_read_only.value = true;
  }
});
onMounted(() => {
  person_name.value = props.person_name;
  person_id.value = props.person_id;
  account_name.value = props.account_name;
  if (props.person_name && props.symbol == 'BRL') {
    is_read_only.value = true;
  } else if (props.account_name && props.symbol == 'MXN') {
    is_read_only.value = true;
  }
  all_bankNames.value.forEach((item) => {
    if (bankNames.value.indexOf(item) == -1) {
      bankNames.value.push(item);
    }
  });
});
const clearAll = () => {
  is_read_only.value = false;

  bankNames.value = [];
  account_type.value = '';
  person_name.value = '';
  person_id.value = '';
  account_id.value = '';

  account_name.value = '';
  bank_name.value = '';
};
</script>

<template>
  <wg-dialog :show="props.show">
    <div :class="'content ' + (matchSM ? 'matchSM' : '')">
      <div class="header">
        {{ props.symbol }}
        <div class="header-title">{{ props.symbol == 'BRL' ? textData.add_pix : textData.add_bank_account }}</div>
        <button :class="'header-close-button ' + (matchSM ? 'matchSM' : '')" @click="emit('close')">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
            <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
          </svg>
        </button>
      </div>
      <div class="content_main">
        <div class="brl-withdraw-content" v-if="props.symbol == 'MXN'">
          <div class="brl-input">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="account_name" :readonly="is_read_only" :placeholder="textData.please_enter_the_name" />
            </div>
          </div>

          <div class="brl-input" @click="selected_bank_name = true">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="bank_name" :placeholder="textData.bank_name" @blur="bankNameBlur" @keyup="filterBankNames" />
            </div>
          </div>
          <div v-show="selected_bank_name">
            <div class="close-div" @click="selected_bank_name = false" v-if="selected_bank_name"></div>
            <div class="deposit select-div flex-start">
              <div class="select-div-main">
                <div :class="'option ' + (bank_name == name ? 'selected' : '')" v-for="(name, index) in bankNames" :key="index" @click="selectBankNames(name)">
                  {{ name }}
                </div>
              </div>
            </div>
          </div>

          <div class="brl-input" @click="selected_account_type = true">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
                <path
                  d="M12.836 11.7853C12.836 12.5128 12.6146 13.0885 12.1717 13.5124C11.7289 13.9363 11.1405 14.145 10.4004 14.145H2.43561C1.70176 14.145 1.11342 13.9363 0.664257 13.5124C0.221419 13.0885 0 12.5128 0 11.7853C0 11.4627 0.0126525 11.1464 0.0316313 10.8427C0.05061 10.5327 0.0948938 10.2038 0.158156 9.84949C0.221419 9.49522 0.30366 9.16626 0.398554 8.8626C0.493448 8.55894 0.626299 8.2616 0.790782 7.9706C0.955264 7.67959 1.14505 7.43286 1.35382 7.23042C1.56891 7.02798 1.82829 6.8635 2.13195 6.7433C2.44193 6.6231 2.77723 6.55984 3.15047 6.55984C3.20741 6.55984 3.33394 6.6231 3.53638 6.75595C3.73882 6.88881 3.96656 7.03431 4.21328 7.19247C4.46634 7.35062 4.7953 7.50245 5.20018 7.62898C5.60506 7.76183 6.00994 7.82509 6.41482 7.82509C6.8197 7.82509 7.22458 7.76183 7.62946 7.62898C8.03434 7.49613 8.36331 7.35062 8.61636 7.19247C8.86941 7.03431 9.09715 6.88248 9.29327 6.75595C9.49571 6.6231 9.62223 6.55984 9.67917 6.55984C10.0524 6.55984 10.3877 6.6231 10.6977 6.7433C11.0077 6.8635 11.2671 7.02798 11.4758 7.23042C11.6909 7.43286 11.8744 7.67959 12.0389 7.9706C12.2033 8.2616 12.3362 8.55261 12.4311 8.8626C12.526 9.16626 12.6082 9.49522 12.6715 9.84949C12.7347 10.2038 12.779 10.5391 12.798 10.8427C12.817 11.1527 12.8296 11.4627 12.8296 11.7853H12.836ZM8.89471 1.16987C9.57795 1.85311 9.91957 2.68185 9.91957 3.64344C9.91957 4.60503 9.57795 5.43377 8.89471 6.117C8.21148 6.80024 7.38274 7.14186 6.42115 7.14186C5.45956 7.14186 4.63082 6.80024 3.94758 6.117C3.26435 5.43377 2.92273 4.60503 2.92273 3.64344C2.92273 2.68185 3.26435 1.85311 3.94758 1.16987C4.63082 0.486637 5.45956 0.14502 6.42115 0.14502C7.38274 0.14502 8.21148 0.486637 8.89471 1.16987Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="account_type" :placeholder="textData.please_choose_account_type" readonly />
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div v-show="selected_account_type">
            <div class="close-div" @click="selected_account_type = false" v-if="selected_account_type"></div>
            <div class="deposit select-div flex-start">
              <div class="select-div-main">
                <div :class="'option ' + (account_type == type ? 'selected' : '')" v-for="(type, index) in mxn_account_types" :key="index" @click="selectAccountType(type)">PIX-{{ type }}</div>
              </div>
            </div>
          </div>

          <div class="brl-input">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="account_id" :placeholder="account_type == 'BANK_CARD' ? textData.the_16_digit_account : textData.the_18_digit_account" />
            </div>
          </div>
        </div>
        <div class="brl-withdraw-content" v-if="props.symbol == 'BRL'">
          <div class="brl-input">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="person_name" :readonly="is_read_only" :placeholder="textData.please_enter_the_name" />
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="brl-input" @click="selected_account_type = true">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
                <path
                  d="M12.836 11.7853C12.836 12.5128 12.6146 13.0885 12.1717 13.5124C11.7289 13.9363 11.1405 14.145 10.4004 14.145H2.43561C1.70176 14.145 1.11342 13.9363 0.664257 13.5124C0.221419 13.0885 0 12.5128 0 11.7853C0 11.4627 0.0126525 11.1464 0.0316313 10.8427C0.05061 10.5327 0.0948938 10.2038 0.158156 9.84949C0.221419 9.49522 0.30366 9.16626 0.398554 8.8626C0.493448 8.55894 0.626299 8.2616 0.790782 7.9706C0.955264 7.67959 1.14505 7.43286 1.35382 7.23042C1.56891 7.02798 1.82829 6.8635 2.13195 6.7433C2.44193 6.6231 2.77723 6.55984 3.15047 6.55984C3.20741 6.55984 3.33394 6.6231 3.53638 6.75595C3.73882 6.88881 3.96656 7.03431 4.21328 7.19247C4.46634 7.35062 4.7953 7.50245 5.20018 7.62898C5.60506 7.76183 6.00994 7.82509 6.41482 7.82509C6.8197 7.82509 7.22458 7.76183 7.62946 7.62898C8.03434 7.49613 8.36331 7.35062 8.61636 7.19247C8.86941 7.03431 9.09715 6.88248 9.29327 6.75595C9.49571 6.6231 9.62223 6.55984 9.67917 6.55984C10.0524 6.55984 10.3877 6.6231 10.6977 6.7433C11.0077 6.8635 11.2671 7.02798 11.4758 7.23042C11.6909 7.43286 11.8744 7.67959 12.0389 7.9706C12.2033 8.2616 12.3362 8.55261 12.4311 8.8626C12.526 9.16626 12.6082 9.49522 12.6715 9.84949C12.7347 10.2038 12.779 10.5391 12.798 10.8427C12.817 11.1527 12.8296 11.4627 12.8296 11.7853H12.836ZM8.89471 1.16987C9.57795 1.85311 9.91957 2.68185 9.91957 3.64344C9.91957 4.60503 9.57795 5.43377 8.89471 6.117C8.21148 6.80024 7.38274 7.14186 6.42115 7.14186C5.45956 7.14186 4.63082 6.80024 3.94758 6.117C3.26435 5.43377 2.92273 4.60503 2.92273 3.64344C2.92273 2.68185 3.26435 1.85311 3.94758 1.16987C4.63082 0.486637 5.45956 0.14502 6.42115 0.14502C7.38274 0.14502 8.21148 0.486637 8.89471 1.16987Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              {{ account_type || textData.please_choose_account_type }}
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div v-show="selected_account_type">
            <div class="close-div" @click="selected_account_type = false" v-if="selected_account_type"></div>
            <div class="deposit select-div flex-start">
              <div class="select-div-main">
                <div :class="'option ' + (account_type == type ? 'selected' : '')" v-for="(type, index) in account_types" :key="index" @click="selectAccountType(type)">PIX-{{ type }}</div>
              </div>
            </div>
          </div>
          <div class="brl-input" v-if="account_type == 'EMAIL' || account_type == 'PHONE'">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="account_id" :placeholder="textData.please_enter_your_pix_account" />
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="brl-input">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                <path
                  d="M17.2319 2.29962V11.9904C17.2319 12.5633 17.0066 13.1118 16.6002 13.5133C16.1987 13.9149 15.6502 14.145 15.0773 14.145H2.1546C1.58167 14.145 1.03323 13.9198 0.631689 13.5133C0.225254 13.1118 0 12.5633 0 11.9904V2.29962C0 1.10969 0.964673 0.14502 2.1546 0.14502H15.0773C15.6502 0.14502 16.1987 0.370273 16.6002 0.776709C17.0066 1.17825 17.2319 1.72669 17.2319 2.29962ZM11.8454 3.91557C11.5467 3.91557 11.3068 4.15551 11.3068 4.45422C11.3068 4.75292 11.5467 4.99287 11.8454 4.99287H14.5387C14.8374 4.99287 15.0773 4.75292 15.0773 4.45422C15.0773 4.15551 14.8374 3.91557 14.5387 3.91557H11.8454ZM11.8454 6.60882C11.5467 6.60882 11.3068 6.84876 11.3068 7.14747C11.3068 7.44617 11.5467 7.68612 11.8454 7.68612H14.5387C14.8374 7.68612 15.0773 7.44617 15.0773 7.14747C15.0773 6.84876 14.8374 6.60882 14.5387 6.60882H11.8454ZM11.8454 9.30207C11.5467 9.30207 11.3068 9.54201 11.3068 9.84072C11.3068 10.1394 11.5467 10.3794 11.8454 10.3794H14.5387C14.8374 10.3794 15.0773 10.1394 15.0773 9.84072C15.0773 9.54201 14.8374 9.30207 14.5387 9.30207H11.8454ZM5.92515 3.37692C5.03393 3.37692 4.3092 4.10165 4.3092 4.99287V6.07017C4.3092 6.96139 5.03393 7.68612 5.92515 7.68612C6.81637 7.68612 7.5411 6.96139 7.5411 6.07017V4.99287C7.5411 4.10165 6.81637 3.37692 5.92515 3.37692ZM4.11333 8.05828C3.28576 8.42064 2.60021 9.02295 2.1595 9.76726V9.84072C2.1595 10.4332 2.63938 10.918 3.2368 10.918H8.6233C9.21581 10.918 9.70059 10.4381 9.70059 9.84072V9.76726C9.25988 9.02295 8.57433 8.42064 7.74676 8.05828C7.25219 8.50878 6.60581 8.76342 5.93494 8.75852C5.26408 8.75852 4.6177 8.50878 4.12312 8.05338L4.11333 8.05828Z"
                  fill="var(--icon-bg-color)"
                />
              </svg>
            </div>
            <div class="main">
              <input v-model="person_id" :readonly="is_read_only" maxLength="11" :placeholder="textData.please_enter_the_11digit" />
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <path d="M0.600001 12.0999L9 6.4999L0.6 0.899902" fill="var(--icon-bg-color)" />
              </svg>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="bottom margin-bottom-4_6">
          <button :class="handleCanNext() ? 'bottom-button' : 'bottom-button-disable'" @click="handleClick" :disabled="props.confirming || !handleCanNext()">
            {{ props.confirming ? '' : textData.confirm }}
            <vue3-lottie :animationData="confirmLoadingJson" :width="50" :height="50" v-if="props.confirming"></vue3-lottie>
          </button>
        </div>
      </div>
    </div>
  </wg-dialog>
</template>

<style scoped>
.content {
  background-color: var(--my-dialog-main-bg-color);
  border-radius: 0.5rem;
  width: 27.5rem;
  max-height: calc(100% - 5.75rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.125rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.content_main {
  width: 27.5rem;
  padding: 0.625rem;
}
.content.matchSM .content_main {
  width: 100%;
  padding: 0.625rem;
}
.content.matchSM {
  width: calc(100vw - 3.5rem);
  padding: 1.125rem 0.875rem;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
}
.header-title {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
}
.header-close-button {
  background-color: transparent;
  padding: 1.125rem 1.1875rem;
  margin-right: -1.1875rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header-close-button.matchSM {
  padding: 1.125rem 0.875rem;
  margin-right: -0.875rem;
}
.header-close-button:hover {
  filter: brightness(0) invert(1);
}

.tip2 {
  color: var(--pin-setting-note-color);
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0;
  text-align: center;
  cursor: pointer;
}
.tip {
  color: var(--white-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 2.5rem;
  text-align: center;
}
.setting-type {
  color: var(--white-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.bottom {
  width: 100%;
  padding: 1.75rem 0;
}
.bottom-button {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  transition: all 0.25s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-blue-color);
  color: var(--default-btn-color);
}
.bottom-button-disable {
  padding: 0.27rem 0.9rem;
  border: none;
  padding: 0;
  font-size: 1.125rem;
  font-weight: 700;
  border-radius: 0.5rem;
  width: 100%;
  height: 3rem;
  cursor: pointer;
  background-color: var(--btn-disable-bg-color);
  color: var(--btn-disable-color);
}
.bottom-button:hover {
  opacity: 0.8;
}
.bottom-button:active {
  transform: scale(0.95);
}
.margin-bottom-4_6 {
  margin-bottom: 4.6rem;
}
.verify-code-button {
  background-color: var(--send-code-btn);
  color: var(--default-btn-color);
  border: none;
  border-radius: 0.5rem;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 0.5rem;
  min-width: 4rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brl-input {
  margin-top: 1.125rem;
  display: flex;
  height: 2.5rem;
  padding: 0px 1.125rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--color-55657E);
  background: var(--input-default-bg-color);
  cursor: pointer;
  margin-bottom: 1.75rem;
}

.brl-input.brl-input2 {
  height: 3.75rem;
  background: var(--deposit-symbol-unselected-block-border-color);
}
.brl-input.brl-input2:hover {
  border: 1px solid var(--bg-blue-color);
}
.brl-input div {
  display: flex;
  align-items: center;
  color: var(--color-8ea3c3);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.brl-input .money-unit {
  color: var(--brl-input-money-unit);
  font-size: 0.875rem;
  font-weight: 700;
}
.brl-input .main {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  color: var(--brl-input-color);
}
.brl-input span {
  color: var(--brl-input-color);
  padding-right: 0.5rem;
}
.brl-input .main input {
  width: 100%;
  border: 0;
  color: var(--brl-input-color);
  font-size: 0.875rem;
  font-weight: 700;
}
input::placeholder {
  color: var(--placeholder-color);
  font-size: 0.875rem;
  font-weight: 700;
}
.line {
  margin-top: 1.125rem;
  height: 1.71px;
  align-self: stretch;
  background-color: var(--brl-line-bg-color);
}
.account-title {
  text-align: left;
}

.deposit.select-div {
  position: fixed;
  width: 30rem;
  margin: 0;
  z-index: 10;
}
.phone .deposit.select-div {
}
.select-div-main {
  padding: 0.5rem 0px;
  width: 85%;
  border-radius: 0.5rem;
  font-weight: 700;
  color: var(--white-color);
  font-size: 0.875rem;
  line-height: 2.5rem;
  margin-top: -1.1rem;

  border: 1px solid var(--add-pix-select-div-main-border-color);
  background: var(--add-pix-select-div-main-bg-color);
  max-height: 18rem;
  overflow-y: auto;
}
.phone .select-div-main {
  width: 85%;
}
.deposit {
  margin: 0;
  justify-content: flex-end;
}
.option {
  font-family: 'Poppins', sans-serif;
  line-height: 2rem;
  height: 2rem;
  margin: 0.25rem 0;
  text-align: left;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.875rem;
  border-radius: 0;
}
.option img {
  width: 1rem;
  padding: 0 2rem 0 0;
}
.option div {
  display: flex;
  align-items: center;
  justify-content: left;
}
.option.selected {
  background-color: var(--bg-blue-color);
}
.option:hover {
  background-color: var(--bg-blue-color);
}
.flex-start {
  justify-content: flex-start;
  align-items: baseline;
}
</style>
