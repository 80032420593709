<script setup>
// 导入
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { useI18n } from 'vue-i18n';
import useWalletStore from '@/store/wallet';
import { add_ken_bank_card } from '@/assets/network/service/wallet';
import ButtonItem from '@/components/wallet/module/ButtonItem';
// 传参
// 属性
const { t } = useI18n();
const walletStore = useWalletStore();
const info_KES = ref({
  type: 'KES',
  phone_number: '',
  bank_name: '',
  notes: ''
});
// 计算属性
watch([() => walletStore.fiatWithdrawOpt], () => {
  walletStore.getFiatWithdrawOpt();
});
// 生命周期
// 方法
const clickConfirm = async () => {
  if(!info_KES.value.phone_number) {
    toast(t('please_enter_the_phone_number'), { autoClose: 2000, type: 'error' });
    return;
  }
  if (!/^7\d{8}$/.test(info_KES.value.phone_number)) {
    toast(t('please_enter__seven'), { autoClose: 2000, type: 'error' });
    return;
  }
  const par = {
    phone_number: '254' + info_KES.value.phone_number,
    bank_name: info_KES.value.bank_name,
    notes: info_KES.value.notes
  };
  const res = await add_ken_bank_card(par);
  if (res.data.code == 200) {
    toast(t('success'), { autoClose: 2000, type: 'success' });
    walletStore.showDialogAddBank = false
    walletStore.getFiatWithdrawOpt();
  } else {
    toast(res.data.msg, { autoClose: 2000, type: 'error' });
  }
};
</script>

<template>
  <el-dialog v-model="walletStore.showDialogAddBank" width="420" align-center :close-on-click-modal="false" class="bg-[#121829] text-left">
    <template #header>
      <div class="text-[#fff] text-[14px] font-[700]">{{ t('add_account').toUpperCase() }}</div>
    </template>
    <div class="relative text-[12px] text-[#5B7BAB] text-left font-[700]">
        <div class="mb-[15px]">
            <div class="mb-[5px]">{{ t('currency') }}</div>
            <el-input v-model="info_KES.type" class="my-el-css" size="large" disabled clearable style="color: white !important;"> </el-input>
        </div>
      <div class="mb-[15px] flex justify-between items-center">
        <div class="mb-[5px]">{{ t('payment_method') }}</div>
        <div class="text-[#fff] text-[14px] font-[400] text-end">Bank</div>
      </div>
      <div class="mb-[15px]">
        <div class="mb-[5px]">{{ t('phone_number') }}</div>
        <el-input v-model="info_KES.phone_number" class="my-el-css" size="large" clearable :placeholder="t('please_enter_the_phone_number')">
            <template #prefix>
                <div class="text-[#fff] text-[14px] font-[400] text-end">+254</div>
            </template>
            <template #append>
                <div class="w-[24px] h-[24px] min-w-[24px] min-h-[24px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.2035 13.636L15.3346 14.9276L12.8297 13.2211L12.9159 16H11.2485L11.3112 13.2916L8.85323 14.9354L8 13.636L10.7789 12L8.00783 10.3483L8.85323 9.0411L11.3033 10.7084L11.2485 8H12.908L12.8454 10.7162L15.3268 9.06458L16.1957 10.364L13.3933 11.9922L16.2035 13.636Z" fill="#C10031"/>
                    </svg>
                </div>
            </template>
        </el-input>
      </div>
      <div class="mb-[15px]">
        <div class="mb-[5px]">{{ t('bank_name') }}</div>
        <el-input v-model="info_KES.bank_name" class="my-el-css" size="large" :max="50" clearable>
            <template #append>
                <div class="text-[#5B7BAB] text-[14px] font-[400] text-end">{{ t('optional') }}</div>
            </template>
        </el-input>
      </div>
      <div class="mb-[15px]">
        <div class="mb-[5px]">{{ t('note') }}</div>
        <el-input v-model="info_KES.notes" class="my-el-css" size="large" :max="50" clearable>
            <template #append>
                <div class="text-[#5B7BAB] text-[14px] font-[400] text-end">{{ t('optional') }}</div>
            </template>
        </el-input>
      </div>
      <div class="w-full">
        <ButtonItem :btnText="t('confirm')" @click="clickConfirm" />
      </div>
    </div>
  </el-dialog>
</template>

<style scoped></style>
