<template>
  <div>
    <template v-for="item in menuData">
      <div :key="item.path" v-if="!item.hidden" class="bg-[#222942] text-[#5B7BAB] text-[0.875rem] px-[1rem] py-[0.375rem] mt-[0.5rem] cursor-pointer rounded-md min-w-[4rem]" :class="item.isSel ? 'bg-[#3E4B79] text-shadow-custom' : ''" @click="menuLinkHandle(item)">
        {{ item.name }}
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@/store/store';

const appStore = useAppStore();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const menuData = ref([
  {
    name: t('account'),
    path: '/profile/setting',
    isSel: false,
    hidden: !appStore.isLogin
  },
  {
    name: t('balance'),
    path: '/profile/balance',
    isSel: false,
    hidden: !appStore.isLogin
  },
  {
    name: t('deposit'),
    path: '/profile/deposit',
    isSel: false,
    hidden: !appStore.isLogin
  },
  {
    name: t('withdraw'),
    path: '/profile/withdraw',
    isSel: false,
    hidden: !appStore.isLogin
  },
  {
    name: t('transaction'),
    path: '/profile/transaction',
    isSel: false
  },
  {
    name: t('my_beats'),
    path: '/profile/myBeats',
    isSel: false,
    hidden: !appStore.isLogin
  },
  {
    name: t('vip_club'),
    path: '/profile/vip',
    isSel: false,
    hidden: false
  }
]);

watch(
  () => appStore.isLogin,
  () => {
    menuData.value = menuData.value.map((item) => {
      if (item.path === '/profile/vip') {
        return {
          name: item.name,
          path: item.path,
          isSel: item.isSel,
          hidden: false
        };
      } else {
        return {
          name: item.name,
          path: item.path,
          isSel: item.isSel,
          hidden: !appStore.isLogin
        };
      }
    });
  },
  {
    immediate: true
  }
);

watch(
  () => route.fullPath,
  (newPath) => {
    menuData.value.forEach((item) => {
      if (newPath.includes(item.path)) {
        item.isSel = true;
      } else {
        item.isSel = false;
      }
    });
  },
  {
    immediate: true
  }
);

const menuLinkHandle = (menuData) => {
  router.push(menuData.path);
};
</script>

<style scoped>
/* @tailwind base; */
</style>
