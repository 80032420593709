<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import myMenus from '@/pages/menus/myMenus.vue';
import myHeader from '@/components/myHeader.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
import myFooter from '@/components/footer/footer.vue';
import affiliateProgramRegister from './affiliateProgramRegister.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import headerBg from '@/assets/images/affiliate-program/header-bg.webp';
import whyUsIcon1 from '@/assets/images/affiliate-program/why_us_1.svg';
import whyUsIcon2 from '@/assets/images/affiliate-program/why_us_2.svg';
import whyUsIcon3 from '@/assets/images/affiliate-program/why_us_3.svg';
import whyUsIcon4 from '@/assets/images/affiliate-program/why_us_4.svg';
import kitIcon from '@/assets/images/affiliate-program/kit.webp';
import banner1 from '@/assets/images/affiliate-program/new_banner_1.webp';
import banner2 from '@/assets/images/affiliate-program/new_banner_2.webp';
import banner3 from '@/assets/images/affiliate-program/new_banner_3.webp';
// import banner4 from '@/assets/images/affiliate-program/banner_4.webp';
// import banner5 from '@/assets/images/affiliate-program/banner_5.webp';

const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);
const { t } = useI18n();
const textData = {
  earn_passive__share: t('earn_passive__share'),
  here_at__partnerships: t('here_at__partnerships'),
  wingame_leverage__crypto: t('wingame_leverage__crypto'),
  join_now: t('join_now'),
  why_us: t('why_us'),
  crypto_supported_payment: t('crypto_supported_payment'),
  crypto_supported__gaming: t('crypto_supported__gaming'),
  fast_commission_distribution: t('fast_commission_distribution'),
  experience_lightning__up: t('experience_lightning__up'),
  attractive_cpa_share: t('attractive_cpa_share'),
  not_only__soar: t('not_only__soar'),
  best_customer_support: t('best_customer_support'),
  with_a__finest: t('with_a__finest'),
  commission: t('commission'),
  first_level: t('first_level'),
  second_level: t('second_level'),
  third_level: t('third_level'),
  fourth_level: t('fourth_level'),
  revenue_share: t('revenue_share'),
  min: t('min'),
  total_deposit: t('total_deposit'),
  cpa: t('cpa'),
  ready_to__needs: t('ready_to__needs'),
  affiliate_kit: t('affiliate_kit'),
  faq: t('faq'),
  why_should__join: t('why_should__join'),
  the_question__guaranteed: t('the_question__guaranteed'),
  how_do__join: t('how_do__join'),
  simple_hit__time: t('simple_hit__time'),
  i_have__website: t('i_have__website'),
  so_you__ready: t('so_you__ready'),
  when_will__paid: t('when_will__paid'),
  we_process__ditto: t('we_process__ditto'),
  is_there__over: t('is_there__over'),
  nope_why__here: t('nope_why__here'),
  i_forgot__do: t('i_forgot__do'),
  it_happens__reset: t('it_happens__reset'),
  register: t('register'),
  name: t('name'),
  please_enter_name: t('please_enter_name'),
  traffic_geo: t('traffic_geo'),
  please_enter__ego: t('please_enter__ego'),
  email: t('email'),
  please_enter_email: t('please_enter_email'),
  skype: t('skype'),
  please_enter_skype: t('please_enter_skype'),
  wallet_address_trc20: t('wallet_address_trc20'),
  please_enter__trc20: t('please_enter__trc20'),
  receive_newsletters: t('receive_newsletters'),
  i_accept_the: t('i_accept_the'),
  affiliate_terms_conditions: t('affiliate_terms_conditions'),
  confirm: t('confirm')
};

const whyUsArr = [
  {
    icon: whyUsIcon1,
    title: textData.crypto_supported_payment,
    desc: textData.crypto_supported__gaming
  },
  {
    icon: whyUsIcon2,
    title: textData.fast_commission_distribution,
    desc: textData.experience_lightning__up
  },
  {
    icon: whyUsIcon3,
    title: textData.attractive_cpa_share,
    desc: textData.not_only__soar
  },
  {
    icon: whyUsIcon4,
    title: textData.best_customer_support,
    desc: textData.with_a__finest
  }
];
const commissionArr = [
  {
    title: textData.first_level,
    percent: 25,
    percentDesc: textData.revenue_share,
    amount: textData.min + ' $0',
    totalDeposit: textData.total_deposit,
    cpa: textData.cpa + '$22.5'
  },
  {
    title: textData.second_level,
    percent: 35,
    percentDesc: textData.revenue_share,
    amount: textData.min + ' $2000',
    totalDeposit: textData.total_deposit,
    cpa: textData.cpa + '$22.5'
  },
  {
    title: textData.third_level,
    percent: 45,
    percentDesc: textData.revenue_share,
    amount: textData.min + ' $5000',
    totalDeposit: textData.total_deposit,
    cpa: textData.cpa + '$22.5'
  },
  {
    title: textData.fourth_level,
    percent: 55,
    percentDesc: textData.revenue_share,
    amount: textData.min + ' $8000',
    totalDeposit: textData.total_deposit,
    cpa: textData.cpa + '$22.5'
  }
];
const affiliateArr = [banner1, banner2, banner3];
const faqArr = [
  {
    title: textData.why_should__join,
    desc: textData.the_question__guaranteed
  },
  {
    title: textData.how_do__join,
    desc: textData.simple_hit__time
  },
  {
    title: textData.i_have__website,
    desc: textData.so_you__ready
  },
  {
    title: textData.when_will__paid,
    desc: textData.we_process__ditto
  },
  {
    title: textData.is_there__over,
    desc: textData.nope_why__here
  },
  {
    title: textData.i_forgot__do,
    desc: textData.it_happens__reset
  }
];

const fadeAni = ref(false);
const circleRef = ref(null);
const currentOpenFAQ = ref(-1);
const openRegister = ref(false);

const handleJoinNowClick = () => {
  openRegister.value = !openRegister.value;
};
const handleFAQClick = (index) => {
  if (index == currentOpenFAQ.value) {
    currentOpenFAQ.value = -1;
  } else {
    currentOpenFAQ.value = index;
  }
};

const handleIntersect = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      fadeAni.value = true;
    } else {
      fadeAni.value = false;
    }
  });
};

onMounted(() => {
  const observer = new IntersectionObserver(handleIntersect, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2
  });
  observer.observe(circleRef.value);
});
</script>

<template>
  <div class="root_div">
    <affiliate-program-register :show="openRegister" @close="handleJoinNowClick" />

    <my-menus></my-menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header></my-header>
      <div class="header">
        <img class="header-image" :src="headerBg" />
        <div :class="'header-content ' + (matchSM ? 'matchSM' : '')">
          <div class="main-content" style="background-color: transparent; padding-top: 0">
            <div class="body" style="background-color: transparent">
              <div :class="'header-container ' + (matchSM ? 'matchSM' : '')">
                <div :class="'header-container-title ' + (matchSM ? 'matchSM' : '')">{{ textData.earn_passive__share }}</div>
                <div :class="'header-container-desc ' + (matchSM ? 'matchSM' : '')">{{ textData.here_at__partnerships }}</div>
                <div :class="'header-container-desc ' + (matchSM ? 'matchSM' : '')">{{ textData.wingame_leverage__crypto }}</div>
                <button class="header-container-button" @click="handleJoinNowClick">{{ textData.join_now }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-content" style="background-color: transparent" :style="matchSM ? { marginBottom: '1.25rem' } : ''">
        <div class="body" style="background-color: transparent">
          <div class="container">
            <div class="container-item">
              <div class="container-title">{{ textData.why_us }}</div>
              <div :class="'container-item-why-us ' + (matchSM ? 'matchSM' : '')">
                <div class="container-item-why-us-item" :class="matchSM ? 'matchSM' : ''" v-for="(item, index) in whyUsArr" :key="index">
                  <div class="container-item-why-us-item-header">
                    <div class="container-item-why-us-item-header-icon">
                      <img :src="item.icon" />
                    </div>
                    <div class="container-item-why-us-item-header-title">{{ item.title }}</div>
                  </div>
                  <div class="container-item-why-us-item-desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>

            <div class="container-item" style="background: linear-gradient(180deg, var(--affiliateProgram-commission-color-1) 0%, var(--affiliateProgram-commission-color-2) 44%, var(--affiliateProgram-commission-color-3) 65%, var(--affiliateProgram-commission-color-4) 100%); border-radius: 8px">
              <div class="container-title">{{ textData.commission }}</div>
              <div class="container-item-commission">
                <div class="container-item-commission-level" :class="matchSM ? 'matchSM' : ''" ref="circleRef">
                  <div class="circle" v-for="(item, index) in commissionArr" :key="index" :style="{ '--mod': 75, '--proc': 25 + 10 * index }">
                    <div class="circle-level">{{ item.title }}</div>
                    <div class="circle-center circle-diagram" :class="fadeAni ? 'in-view' : ''" :style="matchSM ? { width: '9.3125rem', height: '9.3125rem', marginTop: '0.94rem' } : ''">
                      <svg class="circle-image" :style="matchSM ? { width: '9.3125rem', height: '9.3125rem' } : ''" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle class="c1" :style="matchSM ? { r: '4.15625rem' } : ''"></circle>
                        <circle class="c2" :class="fadeAni ? 'in-view' : ''" pathLength="100" :style="matchSM ? { r: '4.15625rem' } : ''"></circle>
                      </svg>
                      <div class="circle-percent" :class="fadeAni ? 'in-view' : ''" :style="matchSM ? { fontSize: '1.625rem' } : ''"></div>
                      <div class="circle-subtitle" :style="matchSM ? { fontSize: '0.625rem' } : ''">{{ item.percentDesc }}</div>
                    </div>
                    <div class="circle-bottom" :style="matchSM ? { marginTop: '0.75rem' } : ''">
                      <div class="circle-bottom-amount" :style="matchSM ? { fontSize: '1.25rem' } : ''">{{ item.amount }}</div>
                      <div class="circle-bottom-total" :style="matchSM ? { fontSize: '0.71rem' } : ''">{{ item.totalDeposit }}</div>
                      <div class="circle-bottom-cpa" :style="matchSM ? { fontSize: '0.71rem' } : ''">{{ item.cpa }}</div>
                    </div>
                  </div>
                </div>
                <img class="container-item-commission-img" :class="matchSM ? 'matchSM' : ''" :src="kitIcon" />
              </div>
              <div class="container-item-commission-desc" :class="matchSM ? 'matchSM' : ''">{{ textData.ready_to__needs }}</div>
            </div>

            <div class="container-item">
              <div class="container-title">{{ textData.affiliate_kit }}</div>
              <div class="container-item-kit" :class="matchSM ? 'matchSM' : ''">
                <swiper
                  :slidesPerView="matchSM ? 'auto' : 3"
                  :spaceBetween="'18px'"
                  :pagination="{
                    clickable: true
                  }"
                  :autoplay="{
                    delay: 5000,
                    disableOnInteraction: false
                  }"
                  :loop="true"
                  :modules="[Pagination, Autoplay]"
                  class="mySwiper"
                  :class="matchSM ? 'matchSM' : ''"
                >
                  <swiper-slide class="mySwiper-slide" :class="matchSM ? 'matchSM' : ''" v-for="(item, index) in affiliateArr" :key="index">
                    <img class="mySwiper-slide-img" :class="matchSM ? 'matchSM' : ''" :src="item" />
                  </swiper-slide>
                </swiper>
              </div>
            </div>

            <div class="container-item">
              <div class="container-title">{{ textData.faq }}</div>
              <div class="container-item-faq">
                <div class="container-item-faq-item" :class="currentOpenFAQ == index ? 'selected' : ''" v-for="(item, index) in faqArr" :key="index" @click="handleFAQClick(index)">
                  <div class="container-item-faq-item-header">
                    <div class="container-item-faq-item-header-title">{{ item.title }}</div>
                    <div class="container-item-faq-item-header-arrow" :class="currentOpenFAQ == index ? 'selected' : ''">
                      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                        <path opacity="0.6" d="M0 0L8 9L0 18" fill="var(--icon-bg-color)" />
                      </svg>
                    </div>
                  </div>
                  <div class="container-item-faq-item-desc" v-if="currentOpenFAQ == index">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-content">
        <my-testimonials-list></my-testimonials-list>
      </div>
      <my-footer :isHomeFooter="false"></my-footer>
    </div>
  </div>
</template>

<style scoped>
.header {
  position: relative;
  width: 100%;
  min-height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 3rem 1.5rem;
}
.header-content.matchSM {
  padding: 1.75rem 0.5rem;
}
.header-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.75rem;
  max-width: 46.75rem;
}
.header-container.matchSM {
  gap: 1.125rem;
  max-width: 100%;
}
.header-container-title {
  color: var(--page-title-color);
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.28rem;
  text-align: left;
  white-space: pre-line;
}
.header-container-title.matchSM {
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.header-container-desc {
  color: var(--color-8ea3c3);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  white-space: pre-line;
  max-width: 33.0625rem;
  text-align: left;
  white-space: pre-line;
}
.header-container-desc.matchSM {
  max-width: 100%;
}
.header-container-button {
  background-color: var(--bg-blue-color);
  border: none;
  border-radius: 0.5rem;
  min-width: 10.3125rem;
  height: 2.3125rem;
  padding: 0 2.25rem;
  color: var(--default-btn-color);
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}
.header-container-button.matchSM {
  font-size: 1.05rem;
}
.header-container-button:hover {
  opacity: 0.8;
}
.header-container-button:active {
  transform: scale(0.96);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 1.75rem;
  margin-top: 1.75rem;
  margin-bottom: -4rem;
}
.container-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.75rem;
}
.container-title {
  color: var(--page-title-color);
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  line-height: 1.6rem;
}
.container-item-why-us {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.125rem;
}
.container-item-why-us.matchSM {
  grid-template-columns: repeat(1, 1fr);
  gap: 0.75rem;
}
.container-item-why-us-item {
  background-color: var(--affiliateProgram-why-us-bg-color);
  border-radius: 0.44rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 2.25rem);
  padding: 1.75rem 1.125rem;
  gap: 1.125rem;
}
.container-item-why-us-item.matchSM {
  padding: 1.125rem;
}
.container-item-why-us-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.125rem;
}
.container-item-why-us-item-header-icon {
  width: 2.25rem;
  height: 2.25rem;
  min-width: 2.25rem;
  min-height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-item-why-us-item-header-title {
  color: var(--affiliateProgram-why-us-title-color);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  width: 100%;
  text-align: left;
  white-space: pre-line;
}
.container-item-why-us-item-desc {
  color: var(--affiliateProgram-why-us-desc-color);
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  width: 100%;
  text-align: left;
  white-space: pre-line;
}

.container-item-commission {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.container-item-commission-level {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.125rem;
  /* gap: 6.68rem; */
  margin: 0 1.125rem;
}
.container-item-commission-level.matchSM {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.125rem;
  row-gap: 2.5rem;
  margin: 0 1.75rem;
}
@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
.circle {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  --proc: 0;
  --mod: 75;
  /*  --proc + --mod = 100 */
  --animDuration: 2.5s;
}
.circle-level {
  color: var(--affiliateProgram-circle-level-color);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.56rem;
}
.circle-level.matchSM {
  font-size: 1.125rem;
}
.circle-center {
  width: 11.5rem;
  height: 11.5rem;
  margin-top: 1.125rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circle-image {
  width: 11.5rem;
  height: 11.5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle-percent {
  color: var(--affiliateProgram-circle-percent-color);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}
.circle-percent.in-view {
  animation: counter var(--animDuration) ease-in-out both;
  counter-reset: num var(--num);
}
.circle-percent::after {
  content: counter(num) '%';
}
.circle-subtitle {
  color: var(--affiliateProgram-circle-subtitle-color);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.circle-diagram.in-view {
  animation: fadeInUp 1s;
}
.circle-diagram svg circle {
  cx: 50%;
  cy: 50%;
  r: 5.25rem;
  fill: none;
  /* stroke-linecap: round; */
  stroke-width: 1rem;
}
.circle-diagram .c1 {
  stroke: var(--affiliateProgram-circle-diagram-c1-bg-color);
}
.circle-diagram .c2 {
  stroke: var(--affiliateProgram-circle-diagram-c2-bg-color);
  stroke-dashoffset: 0;
  stroke-dasharray: var(--proc) var(--mod);
}
.circle-diagram .c2.in-view {
  animation: gline_anim var(--animDuration) ease-in-out both;
}
.circle-bottom {
  margin-top: 1.125rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.circle-bottom-amount {
  color: var(--affiliateProgram-circle-bottom-amount-color);
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.circle-bottom-total {
  color: var(--affiliateProgram-circle-bottom-total-color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.circle-bottom-cpa {
  color: var(--affiliateProgram-circle-bottom-total-color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.container-item-commission-img {
  width: 35.56rem;
  height: 19.125rem;
  margin-bottom: -1.75rem;
}
.container-item-commission-img.matchSM {
  width: 20.125rem;
  height: 10.875rem;
}
.container-item-commission-desc {
  color: var(--affiliateProgram-container-item-commission-desc-color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.0625rem;
  width: 100%;
  max-width: 47.56rem;
  text-align: center;
  white-space: pre-line;
  margin-bottom: 1.125rem;
}
.container-item-commission-desc.matchSM {
  max-width: calc(100% - 2.25rem);
  font-size: 0.625rem;
}

.container-item-kit {
  width: 100%;
  /* height: 19.625rem; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}
.container-item-kit.matchSM {
  height: calc((100vw - 20px) * 270 / 355);
  margin: 0;
}
.mySwiper {
  width: 100%;
  height: 100%;
  min-height: 0;
  --swiper-pagination-bullet-size: 0.5rem;
  --swiper-pagination-bottom: -1.5rem;
  --swiper-pagination-color: var(--affiliateProgram-swiper-pagination-color);
  --swiper-pagination-bullet-inactive-color: var(--affiliateProgram-swiper-pagination-bullet-inactive-color);
  --swiper-pagination-bullet-inactive-opacity: 1;
}
.mySwiper.matchSM {
  --swiper-pagination-bottom: 0rem;
  height: calc((100vw - 20px) * 270 / 355);
}
.mySwiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 18rem; */
}
.mySwiper-slide.matchSM {
  width: calc(100vw - 84px);
  height: calc((100vw - 84px) * 234 / 291);
}
.mySwiper-slide-img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}

.container-item-faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
}
.container-item-faq-item {
  background-color: #1e2736;
  border-radius: 0.5rem;
  padding: 1.75rem;
  width: calc(100% - 1.75rem * 2);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}
.container-item-faq-item:hover {
  opacity: 0.9;
}
.container-item-faq-item.selected {
  background-color: #2c394e;
}
.container-item-faq-item-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.125rem;
}
.container-item-faq-item-header-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: left;
  line-height: 1.5rem;
  width: 100%;
}
.container-item-faq-item-header-arrow {
  width: 0.5rem;
  height: 100%;
  min-width: 0.5rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}
.container-item-faq-item-header-arrow.selected {
  transform: rotate(90deg);
}
.container-item-faq-item-desc {
  color: #8ea3c3;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
  white-space: pre-line;
}

@keyframes counter {
  from {
    --num: 0;
  }

  to {
    --num: var(--proc);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes gline_anim {
  0% {
    stroke-dasharray: 1 99;
  }

  100% {
    stroke-dasharray: var(--proc) var(--mod);
  }
}
</style>
