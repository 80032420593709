export const loadGtagScript = (id, callback) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.appendChild(script);
  
    script.onload = () => {
        console.log('======>>>>ga loadGtagScript onload');
        window.dataLayer = window.dataLayer || [];
        window.gtag = function() { window.dataLayer.push(arguments); };
            window.gtag('js', new Date());
            window.gtag('config', id);
    
        if (typeof callback === 'function') {
            callback();
        }
    };
}

export const gaScreenAppViewEvent = () => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga screen_name');
        window.gtag('event', 'screen_view', {
            'app_name': 'WinGame',
            'screen_name': 'App'
          });
    } else {
      console.log('======>>>>ga gtag null');
    }
};
// begin_checkout 此事件表示用户已开始结账流程
export const gaBeginCheckoutEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga begin_checkout currency:' + params.currency + ', value:' + params.value);
        window.gtag('event', 'begin_checkout', {
            'currency': params.currency,
            'value': params.value
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// login 发送此事件即表示用户登录了您的网站或应用
export const gaLoginEvent = () => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga login');
        window.gtag('event', 'login');
    } else {
      console.log('======>>>>ga gtag null');
    }
}
// purchase 此事件表示用户已购买一件或多件商品
export const gaPurchaseEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga purchase currency:' + params.fiat_coin + ', value:' + params.fiat_amount + ', transaction_id:' + params.event_id);
        if (params.event_id) {
            window.gtag('event', 'purchase', {
                'currency': params.fiat_coin,
                'value': params.fiat_amount,
                'transaction_id': params.event_id
            });
        } else {
            window.gtag('event', 'purchase', {
                'currency': params.fiat_coin,
                'value': params.fiat_amount
            });
        }
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// search 记录此事件，以表明用户何时执行了搜索
export const gaSearchEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga search search_term:' + params.content);
        window.gtag('event', 'search', {
            'search_term': params.content
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// select_content 此事件表示用户选择了某个类型的某些内容
export const gaSelectContentEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga select_content content_type:' + params.type + ', content_id:' + params.name);
        window.gtag('event', 'select_content', {
            'content_type': params.type,
            'content_id': params.name
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// select_promotion 此事件表示从列表中选择了推广活动
export const gaSelectPromotionEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga select_promotion promotion_name:' + params.name);
        window.gtag('event', 'select_promotion', {
            'promotion_name': params.name
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// share 在用户分享了内容时使用此事件
export const gaShareEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga share method:' + params.method);
        window.gtag('event', 'share', {
            'method': params.method
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}
// sign_up 此事件表示用户已注册账号
export const gaSignUpEvent = (params) => {
    if (window.gtag !== undefined && window.gtag !== null) {
        console.log('======>>>>ga sign_up method:' + params.method);
        window.gtag('event', 'sign_up', {
            'method': params.method
        });
    } else {
        console.log('======>>>>ga gtag null');
    }
}

