<script setup>
import { useI18n } from 'vue-i18n';
import statisticsCom from './components/statisticsCom';
import accountSetting from './components/accountSetting';
// import wgCard from '@/components/common/wgCard';
import { ref } from 'vue';

const { t } = useI18n();
const isHideInfo = ref(false);

// const hiddenStateChange = (state) => {
//   isHideInfo.value = state;
// };
</script>

<script>
import { getHeaderImg } from '@/assets/tools/avatar.js';
import { updateUserInfoService, userInfoService as userInfoServiceApi, getBetInfo } from '@/assets/network/service/user';
import { getCookieEmail, getCookieUserId, setCookieUsername } from '@/assets/tools/CommonTool';
import { toast } from 'vue3-toastify';
import personInfoCard from './components/personInfoCard';
export default {
  name: 'settingPage',
  components: {},
  data() {
    return {
      chooseImgFlag: false,
      userNameEdit: false,
      betInfo: {},
      userInfo: {
        email: 'lciuanaaa****@gmail.com',
        profile_image_url: '',
        registerTime: '2023/07/31',
        userName: '',
        profit: 8888.8888
      },
      name: '',
      user_email: '',
      telegram_username: ''
    };
  },
  created() {
    let menuStr = localStorage.getItem('showMenu');
    if (menuStr) {
      this.showMenu = menuStr === 'true';
    } else {
      this.showMenu = true;
    }
    this.userInfoService();
    this.getBetInfo();
  },
  methods: {
    getBetInfo() {
      getBetInfo()
        .then((response) => {
          console.log(response.data);
          if (!response.data.error) {
            this.betInfo = response.data.data;
          }
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    getHeaderImg2(headerImageUrl) {
      return getHeaderImg(headerImageUrl);
    },
    getJoinedTime(date) {
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      return year + '/' + (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) + '/' + day;
    },
    saveUserInfo(key, value) {
      let userInfo = {
        userId: this.userInfo.user_id
      };
      userInfo[key] = value;
      console.log(value);
      updateUserInfoService(userInfo)
        .then(() => {
          if (userInfo.name) {
            setCookieUsername(userInfo.name);
          }
          this.userInfo[key] = value;
          this.chooseImgFlag = false;
          this.userNameEdit = false;
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    userInfoService() {
      let userId = getCookieUserId();
      userInfoServiceApi(userId)
        .then((response) => {
          this.userInfo = response.data;
          this.userInfo.email = getCookieEmail();
          this.name = this.userInfo.name;
          this.user_email = response.data.user_email;
          this.telegram_username = response.data.telegram_username;
          if (this.userInfo.parent_invite_code) {
            this.isBind = true;
          }
        })
        .catch((error) => {
          const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
          let errorMsg = msg.length > 0 ? msg[0] : error.message;
          if (error.response.status === 500) {
            errorMsg = error.message;
          }
          toast(errorMsg, { autoClose: 2000, type: 'error' });
        });
    },
    editHeadUrl() {
      this.chooseImgFlag = true;
    },
    privacySetting() {
      toast('Coming soon', { autoClose: 1000, type: 'info' });
    },
    chooseImgEvent(img, index) {
      if (img) {
        this.saveUserInfo('profile_image_url', 'avatars://profile-avatar' + (index + 1));
        // this.userInfo.profile_image_url = img
      } else {
        this.chooseImgFlag = false;
      }
    },
    closeReset() {
      this.showResetPassword = false;
    }
  }
};
</script>
<template>
  <div>
    <div class="flex web py-[0.8rem]">
      <span class="text-[0.75rem] text-left text-[#fff] font-bold">{{ t('account') }}</span>
    </div>
    <personInfoCard :isEdit="true" :userInfo="userInfo" v-model.isHideInfo="isHideInfo" @update="userInfoService"></personInfoCard>
    <div class="mt-[0.87rem]">
      <statistics-com :isHideInfo="isHideInfo"></statistics-com>
    </div>
    <div class="mt-[0.87rem]">
      <accountSetting :userInfo="userInfo" @update="userInfoService"></accountSetting>
    </div>
  </div>
</template>

<style scoped>
.web .profile-title .header {
  width: 19rem;
}
.phone .profile-title .header {
  display: block;
  width: 100%;
}
.phone .profile-title div .profile-title-top img.edit.close {
  right: 4.125rem;
}
.phone .profile-title div .profile-title-top img.edit {
  margin-left: 1.125rem;
  right: 1.625rem;
}
.web .profile-title div .profile-title-top img.edit.close {
  margin-left: 14rem;
}
.web .profile-title div .profile-title-top img.edit {
  margin-left: 16.4rem;
}
.profile-title div .profile-title-top img.edit {
  height: 1.125rem;
  width: auto;
  position: absolute;
}

.profile-title div .profile-title-top span.name {
  max-width: calc(80% - 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
