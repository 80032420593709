<template>
  <div class="w-full bg-[#fff] font-google-sans pb-[10rem]">
    <div class="h-[4rem] flex items-center">
      <!-- <img src="../../assets/images/pwa/top.png" alt="" /> -->
    </div> 
    <div class="py-[0.875rem] px-[1.5rem] flex justify-between items-center">
      <img class="w-[116px] h-[26px]" src="../../assets/images/pwa/google-play.webp" alt="" />
      <img class="w-[80px] h-[25px]" src="../../assets/images/pwa/google-play-level.webp" alt="" />
    </div>
    <div class="flex px-[1.37rem] mt-[3rem]">
      <div class="relative">
        <div class="spinner absolute left-0 top-8" v-if="loading"></div>
        <img class="w-[72px] h-[72px]" src="../../assets//images/pwa/app-logo.webp" alt="" />
      </div>
      <div class="ml-[1.5rem] text-left">
        <div class="text-black leading-none text-2xl font-semibold capitalize">{{ in8n.text1 }}</div>
        <div class="mt-[0.62rem] text-[#01875F] leading-none text-[15px] font-semibold capitalize">{{ in8n.text2 }}</div>
        <div class="mt-[0.5rem] text-[#5F6368] leading-none text-[11px] font-normal">{{ in8n.text3 }}</div>
      </div>
    </div>
    <div class="mt-[3rem] px-[1.37rem] grid grid-cols-3">
      <div class="relative flex flex-col items-center justify-center">
        <img class="w-[50px] h-[18px]" src="../../assets//images/pwa/type_1.webp" alt="" />
        <div class="text-[#5F6368] text-center text-[12px] font-medium leading-none">{{ in8n.text4 }}</div>
        <div class="absolute right-0 top-1/2 w-px h-[18px] bg-[#E8EAED] transform -translate-y-1/2"></div>
      </div>
      <div class="relative flex flex-col items-center justify-center">
        <img class="w-[61px] h-[18px]" src="../../assets//images/pwa/type_2.webp" alt="" />
        <div class="text-[#5F6368] text-center text-[12px] font-medium leading-none">{{ 'Downloads' }}</div>
        <div class="absolute right-0 top-1/2 w-px h-[18px] bg-[#E8EAED] transform -translate-y-1/2"></div>
      </div>
      <div class="flex flex-col items-center justify-center">
        <img class="w-[18px] h-[18px]" src="../../assets//images/pwa/type_3.webp" alt="" />
        <div class="text-[#5F6368] text-center text-[12px] font-medium leading-none">{{ in8n.text5 }}</div>
      </div>
    </div>
    <button class="!mt-[1.875rem] !bg-[#01875F] !mx-[1.375rem] h-[3.625rem] rounded-[0.43rem] flex flex-col justify-center items-center gap-[5px] " style="width: calc(100% - 1.375rem * 2);" @click="installPwa">
      <div class="flex items-center gap-[5px] text-[#FE0] text-center text-[14px] font-medium leading-none capitalize">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74526 0.0250244H10.3674L7.84641 5.12531L12.125 5.13102L3.15938 15.775L5.73762 8.9924H0.875L3.74526 0.0250244Z" fill="#FFEE00"/>
        </svg>
        {{ in8n.text8 }}
      </div>
      <div class="text-white text-center text-[12px] font-medium leading-none capitalize">{{ in8n.text10 }}</div>
    </button>
    <div class="flex mt-[1.75rem] justify-center gap-[1.75rem] mx-[3.125rem]">
      <div class="flex py-[6px] px-0 justify-center items-center gap-[2px]">
        <img class="min-w-[20px] min-h-[20px] w-[20px] h-[20px]" src="../../assets//images/pwa/share.svg" alt="" />
        <span class="text-[#01875F] text-center text-[14px] font-medium leading-none">{{ 'Compartilhar' }}</span>
      </div>
      <div class="flex py-[6px] px-0 justify-center items-center gap-[6px]">
        <img class="min-w-[22px] min-h-[22px] w-[22px] h-[22px]" src="../../assets//images/pwa/plus.svg" alt="" />
        <span class="text-[#01875F] text-left text-[14px] font-medium leading-none">{{ 'Adicionar à lista de desejos' }}</span>
      </div>
    </div>
    <div class="mt-[2rem] overflow-x-auto mx-[1.3125rem] whitespace-nowrap" style="width: calc(100% - 1.3125rem * 2);">
      <div class="flex gap-[10px] h-[148px]">
        <img class="w-[85px] h-[148px]" :src="item" v-for="(item, index) in affiliateArr" :key="index" />
      </div>
      <!-- <swiper :slides-per-view="'auto'" :spaceBetween="'10px'" :modules="[]" :loop="true" class="mySwiper">
        <swiper-slide class="!w-[5.3125rem] !h-[9.25rem] !min-w-[5.3125rem] !min-h-[9.25rem]" v-for="(item, index) in affiliateArr" :key="index">
          <img class="!w-[5.3125rem] !h-[9.25rem] !min-w-[5.3125rem] !min-h-[9.25rem]" :src="item" />
        </swiper-slide>
      </swiper> -->
    </div>
    <div class="mt-[2rem]">
      <img src="../../assets//images/pwa/explain_pu.webp" alt="" />
    </div>
    <div class="fixed bottom-0"><img src="../../assets//images/pwa/bottom.png" alt="" /></div>
    <wg-dialog :show="dialogShow">
      <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white w-[300px] px-4 pb-4 pt-5 sm:p-6 sm:pb-4 rounded">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Deactivate account</DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 flex justify-end">
          <button type="button" class="inline-flex px-2 mr-6 py-2 text-sm text-gray-900 shadow-sm" @click="dialogShow = false">{{ t('Cancel') }}</button>
          <button type="button" class="inline-flex px-2 py-2 text-sm text-gray-900 shadow-sm" @click="installHandle">{{ t('Install') }}</button>
        </div>
      </DialogPanel>
    </wg-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
// import { Swiper, SwiperSlide } from 'swiper/vue';
import { fpPCustomEvent } from '@/assets/tools/FacebookPixelEvent';
// import { Pagination, Autoplay } from 'swiper/modules';
import game0 from '@/assets/images/pwa/game_0.webp';
import game1 from '@/assets/images/pwa/game_1.webp';
import game2 from '@/assets/images/pwa/game_2.webp';
import game3 from '@/assets/images/pwa/game_3.jpeg';
import wgDialog from '@/components/common/wgDialog.vue';

const deferredPrompt = ref(null);
const { t } = useI18n();
const loading = ref(false);
// const loadingNum = ref(0)
const in8n = ref({
  text1: 'WinGame',
  text2: 'Loucura Do Produto',
  text3: 'Contém anúncios · Compras no aplicativo',
  text4: '46K avaliações',
  text5: 'Rated for 18+',
  test6: 'compartilhar',
  text7: 'Adicionar à lista de desejos',
  text8: 'Rapid Instalar',
  text9: 'Instalar...',
  text10: 'Baixe dentro de 15 segundos'
});
const affiliateArr = ref([game0, game1, game2, game3]);
const dialogShow = ref(false);
const installPwa = async () => {
  const params = JSON.parse(localStorage.getItem('pwaParams'));
  let url = '/download1';
  if (params) {
    let newParams = new URLSearchParams(params);
    let newUrl = url + '?' + newParams.toString();
    url = newUrl.toString();
  }
  window.open(url, '_self');
};
const installHandle = () => {
  dialogShow.value = false;
  installPwa();
};

const getCurrentQueryParams = () => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  let queryParams = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
};

onMounted(() => {
  localStorage.setItem('pwaParams', JSON.stringify(getCurrentQueryParams()));

  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    deferredPrompt.value = event;
    console.log('beforeinstallprompt event captured.');
  });
  window.addEventListener('appinstalled', () => {
    console.log('PWA was installed');
    loading.value = true;
    setTimeout(() => {
      loading.value = false;
    }, 3000);
    // 添加埋点
    fpPCustomEvent({
      event_name: 'DownloadWinGamePwa',
      content_name: 'DownloadWinGamePwa',
      content_type: 'PWA'
    });
  });

  // 添加埋点
  fpPCustomEvent({
    event_name: 'ViewGooglePlayPage',
    content_name: 'ViewGooglePlayPage',
    content_type: 'PWA'
  });
});
</script>

<style scoped>
@tailwind base;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: relative;
}

.spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.01);
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
}
/* Add your styles here */
</style>
