<template>
  <div class="root_div" @click="providerShow = false">
    <menus></menus>
    <div style="width: 100%" class="right_main" id="right_main">
      <my-header :showLogin="showLogin" @loginAfter="loginAfter" @hideShowLogin="hideShowLogin"></my-header>

      <div class="main-content" style="background-color: var(--menu-item-bg-color)">
        <div class="body slots_title">
          <div class="left">
            <img src="../../assets/images/home/top_ico_slots_3.svg" />
            {{ t('new_release') }}
          </div>
          <div class="right">
            <img src="../../assets/images/home/top_ico_slots_1.png" />
          </div>
        </div>
      </div>
      <slots-list :game_type="searchParams.game_type" :page_name="searchParams.page_name" :provider-show="providerShow" :game_code="'new_release'" :game-search="isGameSearch"></slots-list>
      <my-game-support></my-game-support>

      <bets-list @parentLogin="parentLogin"></bets-list>
      <div class="main-content">
        <my-testimonials-list :gameList="testimonialsList" :game="testimonials"></my-testimonials-list>
        <!--        <my-game-list :gameList="testimonialsList" :game="testimonials"></my-game-list>-->
      </div>
      <my-footer :isHomeFooter="true"></my-footer>
    </div>
  </div>
</template>
<script setup>
import menus from '../menus/myMenus.vue';
import myHeader from '../../components/myHeader.vue';
import myFooter from '@/components/footer/footer.vue';
import myGameSupport from '@/components/game/game_support.vue';
import betsList from '@/components/profit/betsList.vue';
import slotsList from '@/components/game/slotsList.vue';
import myTestimonialsList from '@/components/testimonials/testimonialsList.vue';
// import mySearchGameList from "@/components/game/searchGameList.vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
<script>
import icon from '@/assets/images/footer/footer-token-1.png';
import gameSupportImg from '@/assets/images/home/Frame 427320293.svg';
import testimonialsImg1 from '@/assets/images/home/money 2.png';
import testimonialsLevelImg from '@/assets/images/home/ico_b1.png';
import testimonialsImg2 from '@/assets/images/home/money 3.png';
import testimonialsImg3 from '@/assets/images/home/money 4.png';
import testimonialsImg4 from '@/assets/images/home/money 1.png';

export default {
  name: 'App',
  props: {
    game: {},
    gameList: []
  },
  components: {
    // myI18n
  },
  data() {
    return {
      showLogin: false,
      providerShow: false,
      providerShow2: false,
      providerSearchList: [
        {
          isChecked: false,
          name: 'Pragmatic Play',
          code: 'PragmaticPlay',
          number: 0
        },
        {
          isChecked: false,
          name: 'PG Soft',
          code: 'PGSoft',
          number: 0
        }
      ],
      isGameSearch: false,
      searchResult: [],
      searchResult2: [],
      testimonialsList: [
        {
          image: testimonialsImg1,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg2,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg3,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        },
        {
          image: testimonialsImg4,
          levelImage: testimonialsLevelImg,
          infoCode: 'testimonials_info',
          morePath: '/'
        }
      ],
      providerList: [],
      gameSupport: {
        gameCode: 'game_support',
        gameImage: gameSupportImg,
        showBottom: false
      },
      testimonials: {
        gameCode: 'testimonials',
        showBottom: true
      },
      profitList: [
        {
          rank: 1,
          time: '2024-01-18',
          player: {
            img: icon,
            playId: 'Playerr123a231',
            email: 'Play****@gmail.com'
          },
          game: {
            name: 'Dingdong',
            img: icon
          },
          symbol: 'UDST',
          profitImg: icon,
          multiplier: 7.02,
          betAmount: 100.0,
          amount: 702.0
        }
      ],
      gameIndex: 0,
      maxGameIndex: 0,
      pageGameList: [],
      allSize: 0,
      setupSize: 0,
      indexProfit: 'me',
      searchText: '',
      searchParams: {
        game_type: '',
        start: 0,
        end: 20,
        name: '',
        page_name: 'new',
        pageIndex: 0
      },
      pageSize: 20,
      total: 0,
      walletList: []
    };
  },
  created() {
    this.searchResult = [];
    // this.getCasinoNewGamesService()
  },
  methods: {
    loginAfter(walletList) {
      this.walletList = walletList;
    },
    hideShowLogin() {
      this.showLogin = false;
    },
    parentLogin() {
      this.showLogin = true;
      this.$emit('parentLogin');
    },
    profitChange(indexProfit) {
      this.indexProfit = indexProfit;
    }
  }
};
</script>
<style scoped></style>
