<script setup>
// 导入
import { onBeforeMount } from 'vue';
import { toast } from 'vue3-toastify';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ButtonItem from './button-item.vue';
import { formatTimeStamp, isTimeInRange } from '@/assets/tools/CommonTool';
import { joinPromotion, receivePromotion } from '@/assets/network/service/home';
import { useAppStore } from '@/store/store';
import emitter from '@/assets/tools/eventBus';

const appStore = useAppStore();
const router = useRouter();
// 传参
const props = defineProps({
  itemData: {
    type: Object,
    default: ()=>{}
  },
});

const emit = defineEmits(['updateItem']);
// 属性
const { t } = useI18n();
// 计算属性
// 生命周期
onBeforeMount(async () => {

});
// 方法
const clickItem = ()=> {
  const urlPath = `/promotionDetail?id=${props.itemData.id}`
  router.push(urlPath);
}

const clickBtn = async ()=> {
  const status = getPromotionStatus(props.itemData)
  if (status == 1) {
    clickItem()
  } else if (status == 2) {
    clickItem()
  } else if (status == 3) {
    if (appStore.isLogin) {
      try {
        const res = await joinPromotion({id: props.itemData.id})
        if (res.data.code == 200) {
          toast(t('success'), { autoClose: 2000, type: 'success' });
          emit('updateItem');
        } else {
          toast(res.data.msg, { autoClose: 2000, type: 'error' });
        }
      } catch (error) {
        const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
        let errorMsg = msg.length > 0 ? msg[0] : error.message;
        if (error.response.status === 500) {
          errorMsg = error.message;
        }
        toast(errorMsg, { autoClose: 2000, type: 'error' });
      }
    } else {
      emitter.emit('custom-login', 'login');
    }
  } else if (status == 4) {
    if (appStore.isLogin) {
      try {
        const res = await receivePromotion({promotion_id: props.itemData.id})
        if (res.data.code == 200) {
          toast(t('success'), { autoClose: 2000, type: 'success' });
          emit('updateItem');
        } else {
          toast(res.data.msg, { autoClose: 2000, type: 'error' });
        }
      } catch (error) {
        const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
        let errorMsg = msg.length > 0 ? msg[0] : error.message;
        if (error.response.status === 500) {
          errorMsg = error.message;
        }
        toast(errorMsg, { autoClose: 2000, type: 'error' });
      }
    } else {
      emitter.emit('custom-login', 'login');
    }
  }
}

const getPromotionStatus = (item)=> {
  // 1 进行中 2 已结束 3 加入 4 领奖
  const startTime = formatTimeStamp(item.start_time)
  const endTime = formatTimeStamp(item.end_time)
  const currentTime = formatTimeStamp()
  const isProgress = isTimeInRange(startTime, endTime, currentTime)
  if (item.is_prize) {
    return 4
  }
  else if (item.pre_condition?.is_join) {
    const joinStartTime = formatTimeStamp(item.pre_condition.join_start_time)
    const joinEndTime = formatTimeStamp(item.pre_condition.join_end_time)
    const isJoinTime = isTimeInRange(joinStartTime, joinEndTime, currentTime)
    if (item.is_joined) {
      if (isJoinTime) {
        return 1
      } else {
        if (isProgress) {
          return 1
        } else {
          return 2
        }
      }
    } else {
      if (isJoinTime) {
        return 3
      } else {
        if (isProgress) {
          return 1
        } else {
          return 2
        }
      }
    }
  }
  else if (isProgress) {
    return 1
  } else {
    return 2
  }
}
const getBtnText = ()=> {
  const status = getPromotionStatus(props.itemData)
  if (status == 1) {
    return t('In_Progress').toLocaleUpperCase()
  } else if (status == 2) {
    return t('Event_End').toLocaleUpperCase()
  } else if (status == 3) {
    return t('Join_In').toLocaleUpperCase()
  } else if (status == 4) {
    return t('Claim_It').toLocaleUpperCase()
  } else {
    return ''
  }
}
</script>

<template>
  <div class="relative bg-[#191F33] p-[15px] rounded-[8px] w-full overflow-hidden box-border" @click.stop="clickItem">
    <div class="rounded-[6px] overflow-hidden relative">
      <img class="w-full block h-[180px] object-cover" :src="props.itemData.image_url"/>
      <div class="start-time">{{ `${t('Start_Time')}: ${props.itemData.start_time}` }}</div>
    </div>
    <div class="mt-[15px] flex justify-between items-stretch gap-[15px]">
      <div class="text-[#5B7BAB] text-[12px] font-[400] leading-normal text-left w-full">
        <div>{{ `${t('Ends_at')} ${props.itemData.end_time}` }}</div>
        <div>{{ props.itemData.promotion_name }}</div>
      </div>
      <ButtonItem :btnText="getBtnText()" @click.stop="clickBtn"/>
    </div>
  </div>
</template>

<style scoped>
.start-time {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  background-color: #00000066;
  color: #ffffff;
  border-radius: 0 4px 4px 0;
}
</style>
