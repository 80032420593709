<!-- eslint-disable vue/no-dupe-keys -->
<script setup>
import myPage from '@/components/page/myPage.vue';
import { ref, defineProps, defineEmits, onMounted } from 'vue';
import { walletWithdrawRecordService, walletDepositRecordService } from '@/assets/network/service/wallet';
import { convertCurrency } from '@/assets/tools/CommonTool.js';
import symbolSearch from '@/components/symbol/symbolSearch.vue';
import { useClipboard } from '@vueuse/core';
import { toast } from 'vue3-toastify';

const { copy } = useClipboard({ legacy: true });
const transactionList = ref([]);
const props = defineProps({
  transactionType: {
    type: String,
    default: 'Deposit'
  },
  symbol: {
    type: Object,
    default: () => {}
  },
  symbols: {
    type: Array,
    default: () => []
  }
});
// const transaction_type = ref('Deposit')
// const transaction_type = ref('Withdraw')
const isSelectSymbol = ref(false);
// eslint-disable-next-line vue/no-dupe-keys
const symbol = ref({});
let page_num = ref(1);
let page_size = ref(4);
let totalRow = ref(0);
let totalPage = ref(0);
let total_amount = ref(0);
const myGetTransactionsList = (symbolParams) => {
  if (props.transactionType == 'deposit') {
    walletDepositRecordService({
      symbol: symbolParams ? symbolParams.symbol : symbol.value.symbol,
      page_num: page_num.value,
      page_size: page_size.value
    }).then((response) => {
      if (symbolParams) {
        symbol.value = symbolParams;
      }
      transactionList.value = response.data.data;
      totalRow.value = response.data.total_count || 0;
      total_amount.value = response.data.total_amount || 0;
      totalPage.value = Math.ceil(totalRow.value / page_size.value);
    });
  } else {
    walletWithdrawRecordService({
      symbol: symbolParams ? symbolParams.symbol : symbol.value.symbol,
      page_num: page_num.value,
      page_size: page_size.value
    }).then((response) => {
      if (symbolParams) {
        symbol.value = symbolParams;
      }
      transactionList.value = response.data.data;
      totalRow.value = response.data.total_count || 0;
      total_amount.value = response.data.total_amount || 0;
      totalPage.value = Math.ceil(totalRow.value / page_size.value);
    });
  }
};
const closeSymbolSearch = (symbolParams) => {
  if (symbolParams && symbolParams.symbol) {
    myGetTransactionsList(symbolParams);
  }
  isSelectSymbol.value = false;
};
const paginationChange = (changeNum) => {
  page_num.value = changeNum;
  myGetTransactionsList();
};
const emit = defineEmits(['closeTransaction']);
const close = () => {
  emit('closeTransaction');
};
onMounted(() => {
  console.log(props.transactionType);
  symbol.value = props.symbol;
  myGetTransactionsList();
});
const getCreatedTime = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  let hour = date.getHours();
  let m = date.getMinutes();
  let ss = date.getSeconds();
  return year + '/' + (month + 1 >= 10 ? month + 1 : '0' + (month + 1)) + '/' + (day >= 10 ? day : '0' + day) + ' ' + (hour >= 10 ? hour : '0' + hour) + ':' + (m >= 10 ? m : '0' + m) + ':' + (ss >= 10 ? ss : '0' + ss);
};
const getStatusCode = (status, symbol) => {
  if (props.transactionType == 'deposit') {
    if (status == 1) {
      return 'withdraw_deposit_status_complete';
    } else if (status == 0) {
      if (symbol == 'BRL' || symbol == 'MXN') {
        return 'withdraw_deposit_status_waiting_for_payment.';
      }
      return 'withdraw_deposit_status_in_processed';
    }
    return 'withdraw_deposit_status_failed';
  } else {
    if (status == 1) {
      return 'withdraw_deposit_status_complete';
    } else if (status == 2 || status == 3) {
      return 'withdraw_deposit_status_in_processed';
    } else if (status == 5) {
      return 'withdraw_deposit_status_reject';
    } else if (status == 6) {
      return 'withdraw_deposit_status_pass';
    }
    return 'withdraw_deposit_status_failed';
  }
};
</script>
<template>
  <div style="">
    <div class="my-dialog">
      <div class="dialog-main">
        <div class="tran-rows title">
          <div style="justify-content: left; white-space: nowrap; width: 70%; text-align: left; overflow: hidden">
            {{ $t(transactionType) + $t('record_of') + ' - ' }}
            {{ symbol.symbol }}
          </div>
          <div style="display: flex; justify-content: right; width: 30%">
            <img class="deposit_withdraw_img" @click="close()" src="../../assets/images/profile/profile-edit-close.svg" />
          </div>
        </div>
        <div class="tran-rows title-2">
          <div style="justify-content: flex-end; text-align: right; white-space: nowrap; width: 100%; padding-top: 0; display: flex; align-items: center; overflow: hidden">
            {{ $t('total_' + transactionType) }}
            <img :src="symbol.icon_url" style="width: 1.375rem; height: 1.375rem; padding: 0 0 0 0.5rem" @click="isSelectSymbol = !isSelectSymbol" />
            <div style="padding: 0 0.375rem" @click="isSelectSymbol = !isSelectSymbol">
              <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="8.4" viewBox="0 0 12 10" fill="none">
                <path d="M0.800001 0.880151L6.4 9.28015L12 0.880152" fill="#8EA3C3" />
              </svg>
            </div>
            <span @click="isSelectSymbol = !isSelectSymbol">{{ convertCurrency(total_amount, 2) }}</span>
          </div>
        </div>
        <div class="tran-rows flex-end">
          <symbol-search :isSelectSymbol="isSelectSymbol" :symbols="symbols" :symbol="symbol" @close="closeSymbolSearch"></symbol-search>
        </div>
        <div class="record-main">
          <div style="display: block; width: 100%" v-show="totalRow == 0">
            <div style="width: 100%; height: 12rem; justify-content: center; align-items: center">
              <div style="display: flex; justify-content: center; align-items: center; opacity: 0.5; margin-top: 5rem">
                <img src="../../assets/images/home/web_logo.svg" style="width: 13.6875rem; height: 4.375rem" />
              </div>
              <div class="no-data">{{ $t('no_data') }}</div>
            </div>
          </div>
          <div class="tran-rows record" v-for="(transaction, index) in transactionList" :key="index">
            <div class="first">
              <div class="left">
                <img :src="symbol.icon_url" />
                <div v-if="symbol.symbol == 'BRL' || symbol.symbol == 'MXN'">
                  {{ transaction.pay_method }}
                </div>
                <div v-else>{{ symbol.symbol }}</div>
              </div>
              <div class="right">
                <span v-if="symbol.symbol == 'BRL'">R$</span><span>{{ convertCurrency(transaction.amount, 2) }}</span>
              </div>
            </div>
            <div class="second"></div>
            <div class="first">
              <div class="left time">
                {{ getCreatedTime(new Date(transaction.create_time * 1000)) }}
                <span>{{ transaction.order_id }}</span>
              </div>
              <div class="right">
                <span v-if="symbol.symbol !== 'BRL' && symbol.symbol !== 'MXN'" :class="'status' + (' status' + transaction.status)">{{ $t(getStatusCode(transaction.status)) }}</span>

                <div
                  v-if="symbol.symbol === 'BRL' || symbol.symbol === 'MXN'"
                  @click="
                    () => {
                      copy(transaction.order_id);
                      toast($t('copy_successfully'), { autoClose: 1000 });
                    }
                  "
                  style="width: 1.375rem; float: right; cursor: pointer"
                >
                  <img src="../../assets/images/wallet/wallet-copy.svg" style="width: 1.375rem; float: right; cursor: pointer" />
                </div>
              </div>
            </div>
            <div class="first" v-if="symbol.symbol === 'BRL' || symbol.symbol === 'MXN'">
              <div class="left brl_status">
                {{ $t(getStatusCode(transaction.status)) }}
              </div>
            </div>
          </div>
        </div>

        <my-page @pageChange="paginationChange" :total-count="totalRow" :page="page_num" :page-size="page_size" :all-page="totalPage"> </my-page>
      </div>
    </div>
  </div>
</template>

<style scoped>
.web .dialog-main {
  min-height: 30rem;
}
.phone .dialog-main {
  height: 70vh;
  max-height: 70vh;
}
.phone .my-dialog .dialog-main {
  width: 95%;
  margin: auto;
  padding: 0;
}
.tran-rows {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: 0em;

  width: calc(100% - 1.75rem);
  padding: 1.125rem 0.875rem;
}
.tran-rows span {
  color: #ffffff;
  font-weight: 700;
}
.tran-rows.title {
  font-weight: 700;
  display: flex;
}

.tran-rows.title-2 {
  padding: 0 0.875rem;
}
.tran-rows.record {
  margin: 0.3125rem 0.875rem;
  padding: 0;
  background: #2c394e;
  border-radius: 0.5rem;
}
.tran-rows.record .left {
  width: 50%;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: nowrap;
}
.tran-rows.record .left span {
  padding-left: 0.5rem;
}
.tran-rows.record .left.time {
  font-size: 0.75rem;
}
.tran-rows.record .right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.tran-rows.record .right .status {
  font-size: 0.875rem;
  color: #f15a5a;
}
.tran-rows.record .right .status.status2 {
  color: var(--money-unit-color);
}
.tran-rows.record .right .status.status3 {
  color: var(--money-unit-color);
}
.tran-rows.record .second {
  background: #1e2736;
  height: 0.106875rem;
  margin: 0.625rem 0;
}
.tran-rows.record .right .status.status1 {
  color: var(--green-color);
}
.tran-rows.record img {
  width: 2rem;
  padding: 0 0.3rem 0 0;
}
.tran-rows.record .first {
  display: flex;
  align-items: center;
}
.record-main {
  overflow-y: auto;
  height: 26rem;
}
.record-main .tran-rows.record {
  padding: 0.625rem;
  width: calc(100% - 3rem);
}
.phone .record-main {
  max-height: calc(70vh - 8rem);
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.brl_status {
  color: #d0a358;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.tran-rows.flex-end {
  align-items: baseline;
}
</style>
