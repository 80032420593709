<script setup>
import { useI18n } from 'vue-i18n';
import { ref, defineProps, defineEmits, watchEffect } from 'vue';
import { getNotificationMessagesService, messageMakeAsReadService } from '@/assets/network/service/notification';
import pageLoading from '../loading/pageLoading.vue';
import { toast } from 'vue3-toastify';
import loadingAniJson from '@/assets/animation/expott_loading.json';
import { Vue3Lottie } from 'vue3-lottie';
import { useScreenBreakpoint } from '../../tools/composables/useScreenBreakpoint';
import screenBreakpoint from '../../tools/screenBreakpoint';
import myPage from '@/components/page/myPage.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const { t } = useI18n();
const textData = {
  notifications: t('notifications'),
  no_notifications_available: t('no_notifications_available'),
  your_interactions__here: t('your_interactions__here'),
  view_details: t('view_details'),
  make_as_read: t('make_as_read')
};
const matchSM = useScreenBreakpoint(screenBreakpoint.matchSM);

const props = defineProps({
  show: Boolean,
  type: {
    type: String,
    default: 'newMessage' // newMessage:新消息 notification:通知中心
  },
  newMessageData: Object
});
const emit = defineEmits(['makeAsRead', 'close']);

const newMessageDataRef = ref(null);
const messageList = ref({
  total_count: Number,
  data: [
    {
      id: 1,
      subject: '',
      content: '',
      is_read: false,
      detail_link: '',
      created_at: 0
    }
  ]
});
const totalPage = ref(0);
const page_num = ref(1);
const page_size = ref(10);
const isLoading = ref(false);
const confirmLoadingJson = ref(loadingAniJson);
const confirmLoading = ref({});
const paginationChange = async (pageNum) => {
  console.log(pageNum);
  page_num.value = pageNum;
  // handleFetchMessages()
};
const handleFetchMessages = async () => {
  try {
    console.log((page_num.value - 1) * 10);
    console.log(page_num.value * page_size.value);
    let params = {
      start: (page_num.value - 1) * 10,
      end: page_num.value * 10
    };
    const response = await getNotificationMessagesService(params);
    isLoading.value = false;
    messageList.value.total_count = response.data.total;
    messageList.value.data = response.data.data;
    totalPage.value = Math.ceil(messageList.value.total_count / page_size.value);
  } catch (error) {
    isLoading.value = false;
    messageList.value.data = [];
  }
};
const handleMessageMakeAsReadToServer = async (index) => {
  try {
    const param = {
      message_id: messageList.value.data[index].id,
      message_type: messageList.value.data[index].message_type
    };
    const response = await messageMakeAsReadService(param);
    confirmLoading.value[messageList.value.data[index].id.toString()] = false;
    if (response.data.code == 200) {
      messageList.value.data[index].is_read = true;
      emit('makeAsRead', messageList.value.data[index].id);
    } else {
      toast(response.data.msg, { autoClose: 3000, type: 'error' });
    }
  } catch (error) {
    confirmLoading.value[messageList.value.data[index].id.toString()] = false;
    const msg = error.response.data !== undefined ? Object.values(error.response.data) : '';
    let errorMsg = msg.length > 0 ? msg[0] : error.message;
    if (error.response.status === 500) {
      errorMsg = error.message;
    }
    toast(errorMsg, { autoClose: 3000, type: 'error' });
  }
};
const handleNewMessageMakeAsReadToServer = async (messageId, message_type) => {
  try {
    const param = {
      message_id: messageId,
      message_type: message_type
    };
    const response = await messageMakeAsReadService(param);
    if (response.data.code == 200) {
      emit('makeAsRead', messageId);
    }
  } catch (error) {
    console.log('handleNewMessageMakeAsReadToServer error:' + error);
  }
};

watchEffect(() => {
  if (props.type == 'notification' && props.show) {
    isLoading.value = true;
    handleFetchMessages();
  }
  if (props.type == 'newMessage' && props.newMessageData) {
    newMessageDataRef.value = props.newMessageData;
  }
});

const makeAsReadClick = (index) => {
  confirmLoading.value[messageList.value.data[index].id.toString()] = true;
  handleMessageMakeAsReadToServer(index);
};
const handleViewDetailClick = (index) => {
  if (props.type == 'newMessage') {
    if (newMessageDataRef.value && newMessageDataRef.value.id != null) {
      handleNewMessageMakeAsReadToServer(newMessageDataRef.value.id, newMessageDataRef.value.message_type);
    }
    if (newMessageDataRef.value && newMessageDataRef.value.detail_link != null && newMessageDataRef.value.detail_link.length > 0) {
      openUrl(messageList.value.data[index]);
    }
  } else {
    openUrl(messageList.value.data[index]);
  }
};
const openUrl = (notifyDate) => {
  if (notifyDate.link_type == 'External' || notifyDate.link_type == 'external') {
    if (notifyDate.detail_link) {
      if (notifyDate.detail_link.startsWith('https://') || notifyDate.detail_link.startsWith('http://')) {
        window.open(notifyDate.detail_link, '_blank');
      } else {
        window.open('https://' + notifyDate.detail_link, '_blank');
      }
    }
  } else {
    try {
      router.push({ path: notifyDate.detail_link });
    } catch (e) {
      console.log(e);
    }
  }
};
const formattedDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear().toString().slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  return `${month}/${day}/${year}, ${hours}:${minutes}`;
};
</script>

<template>
  <div :class="'bg-content ' + (matchSM ? 'matchSM' : '')" v-if="props.show">
    <div :class="'header ' + (matchSM ? 'matchSM' : '')">
      <div :class="'header-title ' + (matchSM ? 'matchSM' : '')">{{ textData.notifications }}</div>
      <button class="header-close" @click="emit('close')">
        <svg xmlns="http://www.w3.org/2000/svg" :width="matchSM ? '12' : '14'" :height="matchSM ? '12' : '14'" viewBox="0 0 14 14" fill="none">
          <path d="M0.364591 0.364595C0.850714 -0.12153 1.63887 -0.12153 2.125 0.364595L13.6353 11.875C14.1215 12.3611 14.1215 13.1493 13.6353 13.6354C13.1492 14.1215 12.361 14.1215 11.8749 13.6354L0.364592 2.12501C-0.12153 1.63888 -0.121531 0.850719 0.364591 0.364595Z" fill="var(--icon-bg-color)" />
          <path d="M13.6354 0.364594C14.1215 0.850718 14.1215 1.63888 13.6354 2.12501L2.12507 13.6354C1.63895 14.1215 0.850791 14.1215 0.364669 13.6354C-0.121453 13.1493 -0.121453 12.3611 0.364669 11.875L11.875 0.364594C12.3611 -0.121531 13.1493 -0.121531 13.6354 0.364594Z" fill="var(--icon-bg-color)" />
        </svg>
      </button>
    </div>
    <page-loading containerHeight="10rem" width="8rem" height="8rem" :show="props.type == 'notification' && isLoading"></page-loading>

    <!-- 消息中心空页面 -->
    <div class="empty" v-if="props.type == 'notification' && !isLoading && messageList.data.length == 0">
      <svg xmlns="http://www.w3.org/2000/svg" width="127" height="136" viewBox="0 0 127 136" fill="none">
        <path d="M98.1608 53.5732C98.1608 47.3289 93.3568 42.1247 87.5222 41.4301C87.1787 45.2469 87.1787 49.0636 87.1787 52.8803C87.1787 57.39 87.5222 61.554 87.8657 65.7163C93.3568 65.0234 98.1608 59.8193 98.1608 53.5732Z" fill="#1184FA" />
        <path d="M22.6628 79.9439L44.2819 131.986C45.3107 134.762 48.4006 136.15 51.1453 135.803C54.577 135.108 56.6364 131.986 55.9494 128.516L44.9672 67.4536L22.6611 79.9439H22.6628Z" fill="#2C394E" />
        <path d="M106.74 0.144958C98.1604 0.144958 90.6099 14.7174 87.8652 35.1868C97.1315 36.227 104.337 44.2077 104.337 53.9215C104.337 63.6353 97.1298 71.2688 87.8652 72.309C90.9534 92.0839 98.1604 105.963 106.74 105.963C117.722 105.963 126.986 82.3718 126.986 53.227C126.986 24.0822 118.064 0.144958 106.74 0.144958Z" fill="#4C5B73" />
        <path
          d="M20.2604 22.0043C14.4258 22.0043 9.62178 32.4127 8.59294 46.2905C3.78889 46.6378 0.0136719 50.8001 0.0136719 56.0043C0.0136719 61.2085 4.13072 65.7181 8.93645 65.7181H9.27995C11.3393 76.4738 15.4564 83.76 20.2621 83.76C26.782 83.76 32.2731 69.8822 32.2731 52.8822C32.2731 35.8822 26.782 22.0043 20.2621 22.0043H20.2604ZM3.10187 56.0043C3.10187 52.8822 5.16123 50.1056 7.90593 49.76V53.2294C7.90593 56.6989 8.24944 59.8228 8.59127 62.9433C5.50306 62.2504 3.10187 59.4738 3.10187 56.0043Z"
          fill="#384861"
        />
        <path d="M80.3152 52.8814C80.3152 31.0245 85.4627 12.2881 92.6697 4.30902C84.4339 10.5551 60.0684 26.1676 19.2314 22.3509V83.7609C60.0684 79.9442 84.4339 95.5568 92.6697 101.803C85.4627 93.4764 80.3152 74.7417 80.3152 52.8848V52.8814Z" fill="#384861" />
      </svg>
      <div class="empty-title">{{ textData.no_notifications_available }}</div>
      <div class="empty-desc">{{ textData.your_interactions__here }}</div>
    </div>

    <!-- 消息中心列表 -->
    <div class="container" v-if="props.type == 'notification' && !isLoading && messageList.data.length > 0">
      <div class="container-row" v-for="(item, index) in messageList.data" :key="index">
        <div class="container-row-time">{{ formattedDate(item.created_at) }}</div>
        <div class="container-row-desc">{{ item.content }}</div>
        <button class="container-row-button" @click="handleViewDetailClick(index)" v-if="item.detail_link">{{ textData.view_details }}</button>
        <div class="container-row-state">
          <button class="container-row-state-make-read" v-if="!confirmLoading[item.id] && !item.is_read" @click="makeAsReadClick(index)">
            {{ textData.make_as_read }}
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M9.00029 0C13.9717 0 18 4.02947 18 9.00029C18 13.9717 13.9705 18 9.00029 18C4.02773 18 0 13.9705 0 9.00029C0 4.02773 4.02947 0 9.00029 0ZM9.00029 1.16073C4.67019 1.16073 1.16073 4.66961 1.16073 9.00029C1.16073 13.3298 4.66961 16.8393 9.00029 16.8393C13.3298 16.8393 16.8393 13.3304 16.8393 9.00029C16.8393 4.67019 13.331 1.16073 9.00029 1.16073ZM12.9195 5.25636C13.0472 5.13232 13.2183 5.06296 13.3964 5.06301C13.5744 5.06307 13.7455 5.13253 13.8731 5.25665C14.0008 5.38077 14.0751 5.54977 14.0801 5.72776C14.0852 5.90575 14.0207 6.0787 13.9003 6.2099L7.95157 12.3281C7.82647 12.4566 7.6559 12.5309 7.47658 12.5349C7.29727 12.5389 7.12355 12.4724 6.99281 12.3496L4.10376 9.62999C4.03457 9.56944 3.97829 9.49557 3.93828 9.41278C3.89828 9.33 3.87537 9.24001 3.87092 9.14817C3.86647 9.05634 3.88057 8.96455 3.91239 8.87829C3.9442 8.79203 3.99308 8.71306 4.05609 8.64611C4.1191 8.57916 4.19496 8.52559 4.27914 8.48861C4.36331 8.45162 4.45408 8.43198 4.54601 8.43086C4.63795 8.42974 4.72916 8.44716 4.81422 8.48207C4.89927 8.51699 4.97642 8.56869 5.04104 8.63408L7.44607 10.8975L12.9195 5.25636Z"
                fill="var(--icon-bg-color)"
              />
            </svg>
          </button>
          <div class="container-row-state-readed" v-if="!confirmLoading[item.id] && item.is_read">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path
                d="M8.59995 0C3.96115 0 0.199951 3.76119 0.199951 8.4C0.199951 13.0388 3.96115 16.8 8.59995 16.8C13.2388 16.8 17 13.0388 17 8.4C17 3.76119 13.2388 0 8.59995 0ZM12.877 6.49791L9.12294 11.5021C8.92592 11.7636 8.61786 11.9176 8.29189 11.9212C8.09846 11.9212 7.90503 11.8675 7.74025 11.7636L4.72055 9.90806C4.39458 9.71463 4.19398 9.36358 4.20114 8.98388C4.20831 8.60418 4.41965 8.2603 4.75637 8.08119C5.09309 7.90209 5.49786 7.92 5.81667 8.12776L8.02682 9.48179L11.2041 5.24418C11.5516 4.78209 12.2071 4.68895 12.6728 5.03642C13.1313 5.3803 13.2244 6.03582 12.877 6.49791Z"
                fill="#1CA45F"
              />
            </svg>
          </div>
          <div class="container-row-state-confirm-loading" v-if="confirmLoading[item.id]">
            <vue3-lottie :animationData="confirmLoadingJson" :width="32" :height="32"></vue3-lottie>
          </div>
        </div>
      </div>

      <my-page @pageChange="paginationChange" :total-count="messageList.total_count" :page="page_num" :page-size="page_size" :all-page="totalPage"> </my-page>
    </div>

    <!-- 新消息 -->
    <div :class="'container-newMessage ' + (matchSM ? 'matchSM' : '')" v-if="props.type == 'newMessage'">
      <div :class="'container-newMessage-desc ' + (matchSM ? 'matchSM' : '')">{{ newMessageDataRef && newMessageDataRef.content }}</div>
      <button :class="'container-newMessage-button ' + (matchSM ? 'matchSM' : '')" @click="handleViewDetailClick">{{ textData.view_details }}</button>
    </div>
  </div>
</template>

<style scoped>
.bg-content {
  background-color: #1e2736;
  border-radius: 1.125rem;
  width: 28.875rem;
  padding: 1.3rem 1.1875rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.bg-content.matchSM {
  border-radius: 0.5rem;
  width: 14.25rem;
  padding: 0.5rem 0.625rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 1.875rem;
}
.header.matchSM {
  height: 1.75rem;
}
.header-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: capitalize;
}
.header-title.matchSM {
  font-size: 0.875rem;
}
.header-close {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  border: none;
  cursor: pointer;
}
.header-close:hover {
  filter: brightness(0) invert(1);
}

.empty {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.empty-title {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  margin-top: 1.75rem;
  text-transform: capitalize;
}
.empty-desc {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.875rem;
  text-transform: capitalize;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.25rem;
  width: 100%;
  max-height: 35rem;
  overflow: auto;
  padding: 0 0.5rem;
  margin-top: 1rem;
}
.container-row {
  background-color: #2c394e;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.875rem;
  width: calc(100% - 1.75rem);
}
.container-row-time {
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  width: 100%;
  line-height: 2rem;
  text-align: left;
}
.container-row-desc {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  width: 100%;
  line-height: 1.8rem;
  text-align: left;
  text-transform: capitalize;
  white-space: normal;
}
.container-row-button {
  background-color: transparent;
  border: none;
  color: #2283f6;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  min-height: 2rem;
  text-align: left;
  cursor: pointer;
  padding: 0;
  width: max-content;
  text-transform: capitalize;
}
.container-row-button:hover {
  opacity: 0.8;
}
.container-row-button:active {
  opacity: 0.8;
}
.container-row-state {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.container-row-state-make-read {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-8ea3c3);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 2rem;
  cursor: pointer;
  padding: 0;
  text-transform: capitalize;
}
.container-row-state-make-read:hover {
  opacity: 0.8;
}
.container-row-state-make-read:active {
  opacity: 0.8;
}
.container-row-state-readed {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-row-state-confirm-loading {
  display: flex;
  justify-content: end;
  align-items: center;
}

.container-newMessage {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.75rem;
}
.container-newMessage.matchSM {
  margin-top: 0.5rem;
}
.container-newMessage-desc {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.8rem;
  width: 100%;
  height: 9.875rem;
  display: grid;
  place-items: center;
  text-align: center;
  text-transform: capitalize;
}
.container-newMessage-desc.matchSM {
  font-size: 0.75rem;
  height: 5.75rem;
}
.container-newMessage-button {
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: capitalize;
  background-color: #1184fa;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.5rem;
  transition: all 0.25s;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  margin-top: 1.75rem;
}
.container-newMessage-button.matchSM {
  height: 1.75rem;
  font-size: 0.875rem;
  margin-top: 0.375rem;
}
.container-newMessage-button:hover {
  opacity: 0.8;
}
.container-newMessage-button:active {
  transform: scale(0.95);
}
</style>
